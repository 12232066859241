import React from 'react';
import {
  TheMindsetScreen,
  TheRulesScreen,
  TheStoryScreen,
  TheTeamsScreen,
  PrivacyScreen,
  TermsScreen,
} from '.';

export type AboutPages = 'mindset' | 'teams' | 'story' | 'rules';

export interface AboutPageObj {
  title: string;
  url: string;
  body: React.FC;
}

export const staticPages: { [key: string]: AboutPageObj } = {
  mindset: {
    title: 'The Mindset',
    url: '/about/mindset',
    body: TheMindsetScreen,
  },
  teams: { title: 'The Teams', url: '/about/teams', body: TheTeamsScreen },
  story: { title: 'The Story', url: '/about/story', body: TheStoryScreen },
  rules: { title: 'The Rules', url: '/about/rules', body: TheRulesScreen },
  privacy: { title: 'Privacy Policy', url: '/privacy', body: PrivacyScreen },
  terms: { title: 'Terms & Conditions', url: '/terms', body: TermsScreen },
};

export const aboutSteps: string[] = ['story', 'mindset', 'teams', 'rules'];
