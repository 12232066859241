import { PlaysObj, IconGroup } from 'types';

const emotions = [
  'grimace',
  'grin',
  'grin-hearts',
  'grin-tongue',
  'sad-cry',
  'sad-tear',
  'tired',
  'meh',
  'meh-blank',
  'dizzy',
  'flushed',
  'frown',
  'laugh',
  'grin-wink',
  'hand-peace',
  'hand-rock',
  'heart-broken',
];
const activities = [
  'bicycle',
  'prescription-bottle',
  'inbox',
  'bed',
  'teeth',
  'plane',
  'credit-card',
  'headphones',
  'walking',
  'hiking',
  'dumbbell',
  'basketball-ball',
  'baseball-ball',
  'microphone',
  'music',
  'shopping-cart',
  'palette',
  'pen',
  'phone',
  'road',
  'film',
  'bath',
  'joint',
  'book-open',
  'pray',
  'broom',
  'camera',
  'car',
  'code',
  'crown',
  'donate',
  'ship',
  'envelope',
  'fire',
  'gas-pump',
  'glasses',
  'skiing',
  'globe',
  'umbrella',
];
const nature = ['sun', 'moon', 'tree', 'cat', 'dog'];
const places = ['church', 'hospital', 'city', 'mosque', 'store'];
const objects = [
  'hourglass',
  'calendar',
  'list-ul',
  'lock',
  'star',
  'thermometer',
  'trophy',
];
const foodAndDrink = [
  'utensils',
  'hamburger',
  'coffee',
  'cookie',
  'glass-martini',
  'glass-whiskey',
  'wine-glass-alt',
];
const technology = [
  'gamepad',
  'desktop',
  'instagram',
  'snapchat',
  'facebook',
  'twitter',
  'linkedin-in',
  'twitch',
  'xbox',
  'playstation',
  'slack',
];

export const iconGroups: IconGroup[] = [
  { title: 'Nature', icons: nature },
  { title: 'Food & Drink', icons: foodAndDrink },
  { title: 'Activities', icons: activities },
  { title: 'Technology', icons: technology },
  { title: 'Emotions', icons: emotions },
  { title: 'Places', icons: places },
  { title: 'Objects', icons: objects },
];

export const defaultPlays: PlaysObj = {
  exercise: {
    id: 'exercise',
    name: 'Exercised',
    icon: 'running',
    enabled: true,
  },
  meditate: {
    id: 'meditate',
    name: 'Meditated',
    icon: 'brain',
    enabled: true,
  },
  journaled: {
    id: 'journaled',
    name: 'Journaled',
    icon: 'pencil-alt',
    enabled: true,
  },
  medication: {
    id: 'medication',
    name: 'Medication',
    icon: 'pills',
    enabled: true,
  },
  outside: {
    id: 'outside',
    name: 'Got Outside',
    icon: 'tree',
    enabled: true,
  },
  read: {
    id: 'read',
    name: 'Read',
    icon: 'book',
    enabled: true,
  },
  socialized: {
    id: 'socialized',
    name: 'Socialized',
    icon: 'user-friends',
    enabled: true,
  },
  work: {
    id: 'work',
    name: 'Work',
    icon: 'desktop',
    enabled: true,
  },
  'ate-healthy': {
    id: 'ate-healthy',
    name: 'Ate Healthy',
    icon: 'carrot',
    enabled: true,
  },
  'drank-alcohol': {
    id: 'drank-alcohol',
    name: 'Drank Alcohol',
    icon: 'cocktail',
    enabled: true,
  },
};
