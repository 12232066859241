import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from './Icon';
import Box from './Box';
import { IconNames } from 'types';

type Props = {
  getPrev: () => void;
  getNext: () => void;
  page: number;
  items: number;
};
const PaginationButtons: React.FC<Props> = ({
  getNext,
  getPrev,
  page,
  items,
}) => {
  return (
    <Box row justifyContent="center">
      <IconButton onClick={getPrev} disabled={page === 1}>
        <Icon name={IconNames.ChevronLeft} />
      </IconButton>
      <IconButton onClick={getNext} disabled={items < 10}>
        <Icon name={IconNames.ChevronRight} />
      </IconButton>
    </Box>
  );
};

export default PaginationButtons;
