import React, { ReactElement, useContext } from 'react';
import { Link, Chip, Typography } from '@material-ui/core';
import { useCurrentUser } from '../../hooks';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { isPlayerTeamCoach } from '../helpers';
import { UserProfileContext } from './GetStreamFeed';
import { TeamPlayer } from '../..//models/TeamPlayer';
import { UserData } from 'getstream';
import Icon from '../../components/Icon';
import Box from '../../components/Box';
import Paragraph from '../../components/Paragraph';
import { IconNames } from 'types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    type: {
      // flex: sideText ? 1 : 0,
      flex: 1,
    },
    text: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textAlignVertical: 'center',
      marginRight: 7,
      fontSize: '18px',
    },
    chip: {
      marginTop: -3,
    },
  }),
);

interface Props {
  sideText?: ReactElement<Text>;
  position?: 'left' | 'right';
  badgeSize?: 'full' | 'small';
  // user: {
  //   name?: string;
  //   teamsCoached?: { [key: string]: any };
  //   avatar: string;
  //   bio: string;
  // };
  user: UserData;
  //   style?: ViewStyle;
  style?: any;
  uid: string;
}

const UserTitle: React.FC<Props> = ({
  user,
  uid,
  style,
  position = 'right',
  badgeSize = 'full',
  sideText = null,
}) => {
  const classes = useStyles();
  const { updateSelectedPlayer, selectedTeamId } = useContext(
    UserProfileContext,
  );
  const { isCoach } = useCurrentUser();

  const userProfile: TeamPlayer = {
    id: uid,
    name: user.name ? user.name : '',
    avatar: user.avatar,
    email: '',
    bio: user.bio ? user.bio : '',
  };

  const isTeamCoach = isPlayerTeamCoach(user, selectedTeamId);

  return (
    <Box flexDirection="row" alignItems="flex-start">
      {/* {isPlayerTeamCoach(user, userDoc.team.id) && false ? ( */}
      {isCoach ? (
        <Box flexDirection="row" alignItems="flex-start">
          <Link
            className={classes.text}
            onClick={(): void => updateSelectedPlayer(userProfile)}>
            {user.name}
            {/* <Typography className={classes.type}>{sideText}</Typography> */}
          </Link>
          {isTeamCoach && <Icon name={IconNames.Coach} />}
        </Box>
      ) : (
        <Paragraph className={classes.text}>{user.name}</Paragraph>
      )}
    </Box>
  );
};

export default UserTitle;
