// @ts-nocheck
// Getstream types are super fucked up, and each dev implemented objects differently
import React, { useEffect, useState } from 'react';
import {
  FlatFeed,
  StreamClient,
  FeedRequestOptions,
  FeedResponse,
} from 'react-activity-feed';
import ActivityComponent from '../team/ActivityComponent';
import { DiscussionServiceType, Discussion } from '../../services/interfaces';
import { useCurrentUser } from '../../hooks';
import { ActivityData } from 'getstream';
import { StreamProvider } from '../../context';
import {
  //   Screen,
  //   HeaderSmall,
  //   Loading,
  Page,
} from '../../components';
import { useContainer, useRouteMatch } from '../../hooks';

type Props = {
  id?: string;
};

const DiscussionScreen: React.FC<Props> = ({ id }) => {
  const { user } = useCurrentUser();
  const container = useContainer();

  // Match pulls from the article slug for the URL - used in playbook article view
  // Or get it from id prop which is passed from ExerciseView within GamePlan view
  const { route2 } = useRouteMatch();
  const match = id ? id : route2;

  const service = container.getInstance<DiscussionServiceType>(
    'discussionService',
  );

  const [discussion, setDiscussion] = useState<Discussion | undefined>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Update article when match url changes - sadly couldn't figure out how to get useQuery to re-fire
    if (match) {
      const getNew = async (): Promise<void> => {
        setLoading(true);
        setError('');
        try {
          setDiscussion(await service.get(match));
        } catch (err) {
          setError('Could not find this discussion');
          setDiscussion(undefined);
        }
        setLoading(false);
      };
      getNew();
    }
  }, [match]);

  const activityData = discussion && (discussion.activity as ActivityData);

  // Conditional directing to proper team feed for coaches on multiple teams
  let teamId: string = 'PjzVhh60y46DRxkNsvjA'; // Empty team we're using just for discussion posts so they don't clog up team feeds

  if (activityData) {
    teamId =
      typeof activityData.object === 'string' &&
      // eslint-disable-next-line
      // @ts-ignore Getstream types are super fucked up, and each dev implemented objects differently
      activityData.object.includes('actor') // @ts-ignore // Posts that were posted now, GetStream is for some reason saving the .object as a string, so it needs to be parsed to dispaly properly
        ? JSON.parse(activityData.object).actor.data.teamId
        : typeof activityData.object === 'string' // Welcome posts weirdly store userId of the player they're welcoming as .object as opposed to getting enriched, haven't had time to update
        ? activityData.object
        : activityData.object.actor.data &&
          activityData.object.actor.data.teamId
        ? activityData.object.actor.data.teamId
        : user && user.team
        ? user.team.id
        : null;
  }

  // Temp fix for ghost dev user who doesn't have user data, long-term will not affect things.
  // @ts-ignore
  if (activityData && activityData.actor.data) {
    // Fix to make sure welcome notifications work with MHL HQ admin user
    // If the post is from MHL HQ (welcome post), then match the currentUser's team id
    if (
      // @ts-ignore
      activityData.actor.data.name === 'MHL  HQ' ||
      // @ts-ignore
      activityData.actor.data.name === 'MHL HQ'
    ) {
      if (user && user.team && user.team.id) {
        teamId = user.team.id;
      }
    }
  }

  const doFeedRequest = async (
    client: StreamClient,
    feedGroup: string,
    userId?: string,
    options?: FeedRequestOptions,
  ): Promise<FeedResponse> => {
    if (!user) throw new Error();

    // if (activityData && teamId) {
    return client
      .feed(feedGroup, teamId)
      .getActivityDetail(activityData.object.id || activityData.id, {
        ['user_id']: userId,
        withRecentReactions: true,
        withOwnReactions: true,
        withOwnChildren: true,
        ...options,
      });
    // }
  };

  return (
    <StreamProvider streamToken={user.streamToken}>
      <Page fullPage loading={loading} error={error}>
        <FlatFeed
          userId={user && user.id}
          feedGroup="team"
          Activity={ActivityComponent}
          options={{
            withOwnChildren: true,
            activity: activityData,
          }}
          doFeedRequest={doFeedRequest}
          styles={{
            container: {
              backgroundColor: 'transparent',
              paddingBottom: 10,
            },
          }}
          noPagination
        />
      </Page>
    </StreamProvider>
  );
};

export default DiscussionScreen;
