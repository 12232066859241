import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import {
  Section,
  SectionHalves,
  Footer,
  NavPadding,
  Paragraph,
  UL,
} from '../../components';
import { useLead } from '../../hooks';
import TextCta from './components/TextCta';
import FeaturesAccordion from './components/FeaturesAccordion';
import Testimonial from './components/Testimonial';
import HowItWorks from './components/HowItWorks';
import Syllabus from './components/Syllabus';
import CtaButton from './components/CtaButton';
import Stars from './components/Stars';

const bullets = [
  '100+ Daily Guided Journal Entries you can use whenever you feel stressed or anxious.',
  'Daily habit tracker and open-ended journal to help you track patterns and uncover causes.',
  'Structured exercises proven to help you feel less anxious.',
  '5 types of Weekly Check-In’s covering different therapy tools for anxiety.',
  '100+ notes and tips from therapists and social workers throughout to help guide, motivate, and encourage reflection.',
];

type Props = {
  team?: 'fd';
};

const LandingAnxietees: React.FC<Props> = ({ team }) => {
  const ctaButton = <CtaButton />;
  const { setPlan } = useLead();

  const fd = team === 'fd';

  const mainCondition = fd ? 'Depression' : 'Anxiety';
  const oppositeCondition = fd ? 'Anxiety' : 'Depression';

  useEffect(() => {
    setPlan('monthly995');
  }, []);

  return (
    <>
      <NavPadding />
      <Box>
        <Section height="lg">
          <SectionHalves
            src={fd ? 'app_team_fd' : 'app_team_anx'}
            title={`The Anti-${mainCondition} Digital Journal`}
            body={
              <>
                <Box my={2} display="flex">
                  <Stars />
                  <Paragraph color="textSecondary">312 Reviews</Paragraph>
                </Box>
                <Box my={2}>
                  <Paragraph color="primary" variant="subtitle2">
                    $9.95 a month{'  '}
                  </Paragraph>
                  <Paragraph color="secondary" style={{ paddingLeft: '5px' }}>
                    iOS Only - Free 7-day trial
                  </Paragraph>
                </Box>
                <Box my={2} display="flex">
                  {ctaButton}
                </Box>
                <Paragraph>
                  A perfect companion for your mental health journey, MHL's
                  Cognitive Behavioral Therapy (CBT)-based digital Anti-
                  {mainCondition} and Anti-{oppositeCondition} journal for iOS
                  is designed to help you track your emotions, become more aware
                  of thought patterns, and grow over time. Written by therapists
                  and supported by research.
                </Paragraph>
                <UL bullets={bullets} />
              </>
            }
            // button={ctaButton}
            // reverse
          />
        </Section>
        <Section background="primary" height="sm">
          <Testimonial num={2} />
        </Section>
        <Section height="lg">
          <SectionHalves
            src="mhl_asset_2_cropped"
            title="FAQs"
            reverse
            body={
              <>
                {/* <Paragraph gutterBottom>
                  The Mental Health League is a new digital platform that uses
                  community and coaching to build emotional fitness skills.
                </Paragraph>

                <Paragraph variant="subtitle2">FAQs</Paragraph> */}
                <FeaturesAccordion tier={2} />
              </>
            }
          />
        </Section>
        <Section background="offWhite" height="lg">
          <HowItWorks button={ctaButton} tier={1} />
        </Section>
        <Section height="lg">
          <Syllabus alignItems="flex-start" />
        </Section>
        {/* <Section background="primary" height="sm">
          <Testimonial num={2} />
        </Section>
        <Section height="lg">
          <WhyMhl alignItems="flex-start" />
        </Section>
        <Section height="lg" background="offWhite">
          <SectionHalves
            src="coaches"
            title="Meet Our Coaches"
            reverse
            body="Just like the pros, you deserve a coaching staff to get your mind right. MHL coaches are a group of social workers and counselors that provide daily check-ins, pro tips, and pep talks to pick you up when you’re down and celebrate when you’re up."
          />
        </Section> */}

        <Section height="sm" background="primary">
          <TextCta button={ctaButton} />
        </Section>
        <Footer />
      </Box>
    </>
  );
};

export default LandingAnxietees;
