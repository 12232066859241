import React from 'react';
import { Paragraph, Headline, Page } from '../../components';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  action: {
    color: '#B79D4B',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

type TierDescription = {
  title: string;
  subtitle: string;
};

interface Tier {
  title: string;
  subtitle: string;
  price: string;
  subheader?: string;
  description: TierDescription[];
  buttonText: string;
  buttonVariant: 'text' | 'outlined' | 'contained' | undefined;
}
const tier: Tier = {
  title: 'Subscription',
  subtitle: '',
  price: '5',
  description: [
    {
      title: 'How can I contact the MHL team for customer support?',
      subtitle:
        'For questions and suggestions, email us at hello@mentalhealthleague.com. If you are in a mental health emergency, please dial 911 or text HOME to 741741 to connect with a Crisis Text Line Counselor for free.',
    },
    {
      title: 'How do I cancel my subscription?',
      subtitle:
        'If you subscribed on the MHL website, you need to login on the MHL website, open the main menu, and click "Subscription". Options to upgrade, downgrade, and cancel your subscription are all there.',
    },
  ],
  buttonText: 'Join Now',
  buttonVariant: 'contained',
};

const FaqsScreen: React.FC = () => {
  const classes = useStyles();

  return (
    <Page fullPage={true}>
      <Headline center>FAQs</Headline>

      {tier.description.map((line, idx) => (
        <Accordion elevation={0} key={idx}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography className={classes.heading} color="textPrimary">
              {line.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{line.subtitle}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Page>
  );
};

export default FaqsScreen;
