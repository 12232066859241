import React from 'react';
import Fine from './Fine';
import Paragraph from './Paragraph';
import moment from 'moment';

interface Props {
  date: moment.Moment;
  dayNumber?: number;
}

const Day: React.FC<Props> = ({ date, dayNumber }) => {
  return (
    <>
      <Paragraph bold light gutterBottom>
        {moment(date).calendar(undefined, {
          sameDay: '[Today]',
          nextDay: 'ddd',
          lastDay: 'ddd',
          lastWeek: 'ddd',
          sameElse: 'ddd',
          nextWeek: 'ddd',
        })}
        {', '}
        {moment(date).format('MMM D')}{' '}
        {dayNumber && <Fine light>{`  Day ${dayNumber}`}</Fine>}
      </Paragraph>
    </>
  );
};

export default Day;
