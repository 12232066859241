import { Question } from 'types';

export const questionsWithAnswers = (questions: Question[]): Question[] => {
  return questions.filter(
    question =>
      question.type !== 'cover' &&
      question.type !== 'page' &&
      question.type !== 'recap',
  ) as Question[];
};
