const palette: Palette = {
  navy: {
    main: '#0b2240',
    light: '#38496B',
    dark: '#00001B',
  },
  red: {
    main: '#E7004C',
    light: '#ff5677',
    dark: '#A20735',
  },
  gold: {
    main: '#B79D4B', //'#C6AC5A',
    dark: '#856f1d', //'#937D2D',
    light: '#ebce79', //'#FBDE89',
  },
  silver: {
    main: '#b8b8b8', //'#C0C0C0',
    dark: '#909090', //#909090',
    light: '#dedede',
  },
  green: {
    main: '#B87333',
    light: '#EEA260',
    dark: '#844703',
  },
  gray: {
    // main: 'rgba(0, 0, 0, 0.6)',
    main: 'rgba(0, 0, 0, 0.7)',
    light: 'rgba(0, 0, 0, 0.35)',
    dark: 'rgba(0, 0, 0, 0.87)',
  },
  bronze: {
    main: '#8C7853', //'#9F7A34',
    light: 'rgba(0, 0, 0, 0.35)',
    dark: '#6C541E', //'#6d4e04',
  },
  white: {
    main: '#ffffff',
    dark: '#EFEFEF', //'#ffffff', // Making dark for the card flat
    light: '#EFEFEF',
  },
};

export default palette;

export type Hue = {
  main: string;
  light: string;
  dark: string;
};

export interface Palette {
  [key: string]: Hue;
}

export enum PaletteColors {
  Navy = 'navy',
  Red = 'red',
  Gold = 'gold',
  Silver = 'silver',
  Green = 'green',
  Gray = 'gray',
  White = 'white',
  Bronze = 'bronze',
}
