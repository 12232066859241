import React from 'react';
import { Select } from '../../components';
import { PublishStatus } from 'types';

type Props = {
  value: PublishStatus;
  onChange: (e: any) => void;
};

// Functions for creation and editing articles.
const PublishStatusSelect: React.FC<Props> = ({ value, onChange }) => {
  const statuses = Object.values(PublishStatus);

  return (
    <Select<PublishStatus>
      title="Publish Status"
      options={statuses}
      selected={value}
      onChange={onChange}
    />
  );
};

export default PublishStatusSelect;
