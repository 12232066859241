import React from 'react';
import { Typography } from '@material-ui/core';

const TheTeamsScreen: React.FC = () => {
  return (
    <>
      <Typography gutterBottom align="center" component="h3" variant="h6">
        Alone you're tough. With a team you can't be beaten.
      </Typography>

      <Typography gutterBottom align="center">
        The story behind each of the teams that make up the MHL.
      </Typography>

      <img
        style={{ marginBottom: '20px' }}
        alt="Team Anxietees"
        src={require('../../assets/images/landing/team-anxietees-starburst.jpg')}
      />

      <Typography variant="h4" color="primary">
        The Anxietees
      </Typography>

      <Typography gutterBottom variant="subtitle1" color="primary">
        All Wins. No Worries.
      </Typography>

      <Typography gutterBottom>
        <ul>
          <li>Rallying For: All forms of Anxiety</li>
          <li>Mascot: Manatee</li>
          <li>Colors: Green &amp; Silver</li>
          <li>Percentage of Population: 18%</li>
        </ul>
      </Typography>

      <Typography gutterBottom>
        Affecting 18% of the population, tackling a team for anxiety was a tall
        order. Anxiety disorder manifests itself in so many different ways, we
        were worried we wouldn’t find a team to unite them all. We settled on
        the manatee because it’s not just calm, but a unique sports mascot –
        just like each of our own relationships to anxiety. We stuck to soothing
        green colors of the sea and avoided bright contrasts to keep the energy
        aligned with the practice.
      </Typography>

      <img
        style={{ marginBottom: '20px' }}
        alt="Team Foggy Dogs"
        src={require('../../assets/images/landing/team-foggy-dogs-starburst.jpg')}
      />

      <Typography variant="h4" color="primary">
        The Foggy Dogs
      </Typography>

      <Typography gutterBottom variant="subtitle1" color="primary">
        Rise Above the Fog
      </Typography>

      <Typography gutterBottom>
        <ul>
          <li>Rallying For: All forms of Depression &amp; Grief</li>
          <li>Mascot: Golden Retriever</li>
          <li>Colors: Blue &amp; Sunshine Yellow</li>
          <li>Percentage of Population: 6.7%</li>
        </ul>
      </Typography>

      <Typography gutterBottom>
        Finding the right name for the teams in the MHL isn’t easy. We struggled
        with finding a way to talk about depression that wasn’t, well,
        depressing. Ultimately, we focused on the word “foggy” which is how some
        people describe the symptoms of a depressive episode. Using a dog as a
        mascot isn't just for the rhyme, it's a way to honor their service as
        emotional support companions for people with all mental health
        conditions. We hope its optimistic face rising above the fog reminds you
        of your capacity to do the same. #GoFoggyDogs
      </Typography>

      <img
        style={{ marginBottom: '20px' }}
        alt="Team Bipolar Bears"
        src={require('../../assets/images/landing/team-bipolar-bears-starburst.jpg')}
      />

      <Typography variant="h4" color="primary">
        The Bipolar Bears
      </Typography>

      <Typography gutterBottom variant="subtitle1" color="primary">
        Up, Down, but Never Out
      </Typography>

      <Typography gutterBottom>
        <ul>
          <li>Rallying For: Bipolar</li>
          <li>Mascot: Polar Bear</li>
          <li>Colors: Black &amp; Silver</li>
          <li>Percentage of Population: 2.6%</li>
        </ul>
      </Typography>

      <Typography gutterBottom>
        Without the Bipolar Bears, there would be no MHL. After being diagnosed
        with Bipolar, our Co-Founder Mike always referred to himself as a
        Bipolar Bear – and the rest is history. We chose black and white to
        represent the polarity of the ups &amp; downs, and the silver to
        encourage team members to fight for the middleground. Living with or
        supporting someone with Bipolar isn't easy, but together we can win.
      </Typography>

      <img
        style={{ marginBottom: '20px' }}
        alt="Team ADHBees"
        src={require('../../assets/images/landing/team-adhbees-starburst.jpg')}
      />

      <Typography variant="h4" color="primary">
        The ADHBees
      </Typography>

      <Typography gutterBottom variant="subtitle1" color="primary">
        The Hive Always Thrives
      </Typography>

      <Typography gutterBottom>
        <ul>
          <li>Rallying For: ADHD</li>
          <li>Mascot: Bee</li>
          <li>Colors: Red &amp; Gold</li>
          <li>Percentage of Population: 11%</li>
        </ul>
      </Typography>
      <Typography gutterBottom>
        We decided on ADHBees not just because of our own personal relationship,
        but because it’s a common mental health condition that’s commonly
        misunderstood. The play on words is easy to remember, and it introduced
        the best mascot. Bees are known for their constant activity, but they
        also have their less active, dormant states, which is a better
        reflection of the full spectrum of how ADHD can impact those affected.
        We knew yellow was the color, and ultimately choose a deep red to
        complement.
      </Typography>
    </>
  );
};

export default TheTeamsScreen;
