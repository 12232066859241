import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export { brandColors } from './colors';

const mhlNavy = '#0b2240';
// const mhlNavyLight = '#3b4e66';
export const mhlNavyLightbg = '#e8e9ed';
const mhlPink = '#e80a4d';
const mhlText = 'rgba(0, 0, 0, 0.6)';
const mhlGold = '#B79D4B';

export let theme = createMuiTheme({
  palette: {
    primary: {
      main: mhlNavy,
    },
    secondary: {
      main: mhlPink,
    },
    warning: {
      main: mhlGold,
    },
    text: {
      primary: mhlNavy,
    },
    inverse: {
      primary: 'white',
    },
  },
  typography: {
    fontSize: 18,
    fontFamily: [
      'apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'Arial',
      'Helvetica Neue',
      'Helvetica',
      'sans-serif',
    ].join(','),
    body1: {
      color: mhlText,
      lineHeight: 2,
      fontSize: '18px',
    },
    body2: {
      color: mhlText,
    },
    subtitle1: {
      fontWeight: '900',
      color: mhlText,
      fontStyle: 'italic',
    },
    subtitle2: {
      color: mhlNavy,
      fontWeight: '900',
      fontStyle: 'italic',
    },
    h1: {
      fontWeight: '900',
      color: mhlNavy,
      fontStyle: 'italic',
    },
    h2: {
      fontWeight: '900',
      fontStyle: 'italic',
      color: mhlNavy,
    },
    h3: {
      fontWeight: '900',
      color: mhlNavy,
      fontStyle: 'italic',
    },
    h4: {
      fontWeight: '900',
      color: mhlNavy,
      fontStyle: 'italic',
    },
    h5: {
      fontWeight: '900',
      color: mhlNavy,
      fontStyle: 'italic',
    },
    h6: {
      fontWeight: '900',
      color: mhlNavy,
      fontStyle: 'italic',
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiGrid: {
      // spacing: '8',
      // Name of the rule
      container: {
        // Some CSS
        alignItems: 'center',
        position: 'relative',
      },
    },
    MuiCard: {
      root: {
        // padding: '5%',
      },
    },
    MuiCardHeader: {
      title: {
        fontWeight: '700',
        fontSize: 24,
        color: mhlNavy,
      },
    },
    MuiButton: {
      root: {
        // borderRadius: '0px',
      },
      sizeLarge: {
        padding: '15px 25px',
        fontSize: '1.1375rem',
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: 20,
      },
    },
    MuiToggleButton: {
      root: {},
    },
    'Mui-selected': {
      color: mhlNavy,
    },
  },
});

theme = responsiveFontSizes(theme);
