// import BaseService from './BaseService';
import { Surveys as SurveysType } from './interfaces';
import { SurveyAnswers } from 'types';
import firebase from '../base';
import defaultValues from './RemoteConfig/defaultValues';
import { SurveysService } from './';
import { ApplicationError } from '../models/Errors';
import logger from './LoggerService';

class Surveys implements SurveysService {
  getAnswersForSurvey = async (
    userId: string,
  ): Promise<
    { [key in keyof SurveysType]: { name: string; answers: SurveyAnswers } }
  > => {
    // const surveys: any = firebase.remoteConfig().getValue('surveys');
    const surveys: any = defaultValues.surveys;

    const answers = await Promise.all(
      Object.keys(surveys).map(surveyId =>
        firebase
          .firestore()
          .doc(`surveys/${surveyId}/answers/${userId}`)
          .get()
          .then(snap => snap.data() as SurveyAnswers),
      ),
    );

    const answersBySurveyId: any = {};
    // | {}
    // | {
    //   [key in keyof SurveysType]: { name: string; answers: SurveyAnswers }
    // } = {};
    Object.keys(surveys).forEach((surveyId, idx) => {
      const userAnswers = answers[idx];
      if (userAnswers) {
        answersBySurveyId[surveyId] = {
          name: surveys[surveyId],
          answers: answers[idx],
        };
      }
    });

    return answersBySurveyId;
  };

  public async saveSurvey(
    surveyTitle: string,
    fields: { [key: string]: any },
  ): Promise<void> {
    const collection = firebase
      .firestore()
      .collection(`surveys/${surveyTitle}/answers`);

    try {
      await collection.add({
        ...fields,
        updatedAt: new Date(),
      });
    } catch (err) {
      logger.error(`Failed to save survey`);

      throw new ApplicationError('Unable to save survey');
    }
  }

  public async saveLead(fields: { [key: string]: any }): Promise<void> {
    try {
      await this.saveSurvey('leads', fields);
    } catch (err) {
      logger.error('Failed to save lead');
    }
  }
}

export default Surveys;
