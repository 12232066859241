import React, { useEffect } from 'react';
import { useCarousel } from '../hooks';
import Icon from './Icon';
import Box from './Box';
import Image from './Image';
import Paragraph from './Paragraph';
import { Mascot } from 'types';
import { Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import 'pure-react-carousel/dist/react-carousel.es.css';
import '../assets/styles/Carousel.scss';
import { brandColors } from '../assets/styles/colors';
import { IconNames } from 'types/ArticleTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    slide: {
      paddingLeft: '15%',
      paddingRight: '15%',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '38%',
        paddingRight: '38%',
      },
    },
  }),
);

type Props = {
  items: Mascot[];
  setState: (idx: number) => void;
};

const Carousel: React.FC<Props> = ({ setState, items }) => {
  const { currentSlide } = useCarousel();
  const classes = useStyles();

  useEffect(() => {
    setState(currentSlide);
  }, [currentSlide]);

  return (
    <>
      <Slider className={classes.slide}>
        {items.map((mascot: Mascot, idx: number) => (
          <Slide key={mascot.name} index={idx}>
            <Box className="slideImages">
              <Image
                className="mascotImg"
                src={mascot.name.toLowerCase().replace(' ', '-')}
              />
            </Box>
            <Box mt={-1}>
              <Paragraph align="center">{mascot.tagline}</Paragraph>
            </Box>
          </Slide>
        ))}
      </Slider>

      <Box display="flex" justifyContent="center" mt={1} mb={1}>
        <ButtonBack
          style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}>
          <Icon
            name={IconNames.ChevronLeft}
            style={{
              color: brandColors.brandPrimaryDark,
              fontSize: '45px',
              opacity: currentSlide === 0 ? 0.5 : 1,
            }}
          />
        </ButtonBack>
        <ButtonNext
          style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)' }}>
          <Icon
            name={IconNames.ChevronRight}
            style={{
              color: brandColors.brandPrimaryDark,
              fontSize: '45px',
              opacity: currentSlide === 4 ? 0.5 : 1,
            }}
          />
        </ButtonNext>
      </Box>
    </>
  );
};

export default Carousel;
