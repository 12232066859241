import React from 'react';
import {
  BaseActivityResponse,
  BaseReaction,
  ToggleChildReactionCallbackFunction,
} from 'react-activity-feed';
import { Button } from '@material-ui/core';
import { ThumbUpAltOutlined as ThumbUpAltOutlinedIcon } from '@material-ui/icons';
import { theme } from '../../assets/styles/theme';
interface Props {
  activity: BaseActivityResponse;
  reaction?: BaseReaction;
  onToggleCheer: () => Promise<void>;
  onToggleChildReaction?: ToggleChildReactionCallbackFunction;
  iconContainerStyle?: any;
}

const CheerComponent: React.FC<Props> = ({
  activity,
  reaction,
  onToggleCheer,
  onToggleChildReaction,
  iconContainerStyle,
}) => {
  const reactionKind: string = 'cheer';

  let counts, ownReactions;
  if (reaction && onToggleChildReaction) {
    counts = reaction.children_counts;
    ownReactions = reaction.own_children;
  } else {
    counts = activity.reaction_counts;
    ownReactions = activity.own_reactions;
  }

  const active =
    ownReactions &&
    ownReactions[reactionKind] &&
    ownReactions[reactionKind].length;

  const handlePress = (): Promise<void> => {
    if (reaction && onToggleChildReaction) {
      return onToggleChildReaction(reactionKind, reaction, {}, {});
    }

    return onToggleCheer();
  };

  const cheerColor = active
    ? theme.palette.primary.dark
    : theme.palette.primary.light;

  return (
    <Button
      startIcon={<ThumbUpAltOutlinedIcon />}
      onClick={handlePress}
      aria-label="cheer-activity"
      style={{ color: cheerColor }}>
      {counts && counts.cheer && counts.cheer}
      {counts && counts.cheer < 1 ? ' cheers' : ' cheer'}
    </Button>
  );
};

export default CheerComponent;
