import BaseService from './BaseService';
import {
  CoachesNotePayload,
  CoachesNote,
  CoachesNotesService,
  Unsubscriber,
  SubscriptionListener,
} from './interfaces';
import { docForPlayer } from '../models/FirebasePlayer';
import moment from 'moment';
import { ApplicationError } from '../models/Errors';
import firebase from 'firebase/app';

const COLLECTION = 'notes';

const makeCoachesNoteQuery = () => {
  const cache: {
    [key: string]: (
      listener: SubscriptionListener<CoachesNote[]>,
    ) => () => void;
  } = {};
  return function coachesNoteQuery(
    userId: string,
    service: CoachesNotesService,
  ): (listener: SubscriptionListener<CoachesNote[]>) => () => void {
    if (cache[userId]) return cache[userId];
    const subscribe = (
      listener: SubscriptionListener<CoachesNote[]>,
    ): Unsubscriber => service.subscribeToCoachesNotes(listener, { userId });
    cache[userId] = subscribe;
    return subscribe;
  };
};

export const coachesNoteQuery = makeCoachesNoteQuery();

class CoachesNotes extends BaseService implements CoachesNotesService {
  deleteNote = async (userId: string, noteId: string): Promise<void> => {
    try {
      await docForPlayer(userId)
        .collection(COLLECTION)
        .doc(noteId)
        .delete();
    } catch (err) {
      throw new ApplicationError('Unable to delete note');
    }
  };

  updateOrCreateNote = async (note: CoachesNotePayload): Promise<void> => {
    try {
      const { userId, id, content, updatedAt } = note;
      const newNote: any = {
        coachName: this.currentPlayer.name,
        coachId: this.currentPlayer.id,
        content,
        updatedAt: firebase.firestore.Timestamp.fromDate(updatedAt.toDate()),
      };
      if (id) {
        const scheduledPost = await docForPlayer(userId)
          .collection(COLLECTION)
          .doc(id)
          .get();
        await scheduledPost.ref.set(newNote);
      } else {
        await docForPlayer(userId)
          .collection(COLLECTION)
          .add(newNote);
      }
    } catch (err) {
      throw new ApplicationError('Error adding note. Please try again.');
    }
  };

  subscribeToCoachesNotes = (
    listener: SubscriptionListener<CoachesNote[]>,
    { userId }: { userId: string },
  ): Unsubscriber => {
    const subscription = docForPlayer(userId)
      .collection(COLLECTION)
      .orderBy('updatedAt', 'desc')
      .onSnapshot(snapshot =>
        listener(
          snapshot.docs.map(doc => ({
            coachId: doc.data().coachId,
            coachName: doc.data().coachName,
            content: doc.data().content,
            updatedAt: moment(doc.data().createdAt),
            id: doc.id,
            userId,
          })),
        ),
      );

    return subscription;
  };

  // Replaced by subscriber above
  getCoachesNotes = async (userId: string): Promise<CoachesNote[]> => {
    const notes: CoachesNote[] = [];
    await docForPlayer(userId)
      .collection(COLLECTION)
      .orderBy('updatedAt', 'desc')
      .get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          if (doc.data()) {
            notes.push({
              coachId: doc.data().coachId,
              coachName: doc.data().coachName,
              content: doc.data().content,
              updatedAt: moment(doc.data().createdAt),
              id: doc.id,
              userId,
            });
          } else {
            return null;
          }
        });
      })
      .catch(err => {
        this.logger.error(`Error getting notes -${err}`);
      });

    return notes;
  };
}

export default CoachesNotes;
