import React, { ReactElement, ReactNode } from 'react';
import { useFeature, Features, UseFeatureResponse } from '../hooks';

type ChildrenAsFunc = (response: UseFeatureResponse) => ReactElement;

interface Props {
  feature: Features;
  children: ReactNode | ChildrenAsFunc;
}

const Feature: React.FC<Props> = ({ feature, children }) => {
  const response = useFeature(feature);
  if (typeof children === 'function') {
    return (children as ChildrenAsFunc)(response);
  } else {
    return <>{response.enabled ? children : null}</>;
  }
};

export default Feature;
