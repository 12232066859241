import React from 'react';
import {
  Grid,
  Typography,
  Link,
  Box,
  List,
  ListItem,
} from '@material-ui/core/';
import ListItemLink from './ListItemLink';
import Paragraph from './Paragraph';
import Headline from './Headline';
import Section from './Section';
import { useCurrentUser } from '../hooks';

const footers = [
  {
    title: 'About',
    description: [
      { label: 'Manage Account', slug: 'dashboard', loggedIn: true },
      { label: 'Login', slug: 'login', loggedIn: false },
      // { label: 'About', slug: 'about' },
      { label: 'Pricing', slug: 'plans', loggedIn: false },
      { label: 'Playbook', slug: 'playbook', loggedIn: false },
      // { label: 'Jobs', slug: 'jobs' },
    ],
  },
  {
    title: 'Legal',
    description: [
      { label: 'Privacy policy', slug: 'privacy', loggedIn: false },
      { label: 'Terms', slug: 'terms', loggedIn: false },
    ],
  },
];

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © '}
      <Link color="inherit">Mental Health League</Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer: React.FC = () => {
  const { user } = useCurrentUser();

  return (
    // <Container maxWidth="md" component="footer" className={classes.footer}>
    <Section>
      <Grid
        container
        spacing={4}
        justify="space-evenly"
        alignItems="flex-start">
        <Grid item md={6}>
          <Headline>Questions?</Headline>
          <Paragraph>hello@mentalhealthleague.com</Paragraph>

          <Box mt={5}>
            <Copyright />
          </Box>
        </Grid>
        {footers.map(footer => (
          <Grid item md={3} key={footer.title}>
            <Typography variant="subtitle2" color="textPrimary">
              {footer.title}
            </Typography>
            <List disablePadding>
              {footer.description.map(
                item =>
                  (!item.loggedIn || (item.loggedIn && user)) && (
                    <ListItemLink
                      key={item.label}
                      to={item.slug}
                      primary={item.label}
                      disableGutters
                    />
                  ),
              )}
            </List>
          </Grid>
        ))}
      </Grid>
    </Section>
    // </Container>
  );
};

export default Footer;
