import { ApplicationError } from '../models/Errors';
import { isFirebaseTeam, teamCollection } from '../models/FirebaseTeam';
import mascotList from '../models/MascotList';
import Team from '../models/Team';
// import logger from '../services/LoggerService';

class ListTeamsService {
  public async perform(): Promise<Team[]> {
    const teams: Team[] = [];

    try {
      const result = await teamCollection()
        // .where('visibility', '==', Visibility.Public)
        .get();

      result.forEach(
        (snapshot): void => {
          const data = snapshot.data();

          if (data && isFirebaseTeam(data)) {
            const team: Team = {
              id: snapshot.id,
              name: data.name,
              mascot: mascotList[data.mascotId],
              visibility: data.visibility,
            };

            teams.push(team);
          }
        },
      );
    } catch (error) {
      //   logger.error(`Failed to query team list: ${error}`);
      Promise.reject(new ApplicationError('Unable to list teams.'));
    }

    return Promise.resolve(teams);
  }
}

export default ListTeamsService;
