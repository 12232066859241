import * as stream from 'getstream';
import moment from 'moment';
import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { ActivityData } from 'react-activity-feed';
import { useMutation } from '../../hooks';
import Scorecard, { DateFormat } from '../../models/Scorecard';
import StreamScorecard from '../../models/StreamScorecard';
import ScorecardService from '../../services/scorecard/Scorecard';
import logger from '../../services/LoggerService';
import UserTitle from './UserTitle';
import { Feature, Icon, Fine, Paragraph } from '../../components';
import { IconNames } from 'types';

interface Props {
  activity: ActivityData;
  actor: stream.UserResponse;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    result: {
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: theme.palette.primary.light,
    },
    body: {
      paddingTop: 0,
    },
  }),
);

const ActivityContentComponent: React.FC<Props> = ({ activity, actor }) => {
  const classes = useStyles();
  const [hidden, setHidden] = useState(!!activity.hidden);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Extra Actions Menu
  const handlePressActions = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Hide Posts
  //   const container = useContainer();
  //   const scorecardService = container.getInstance<ScorecardService>(
  //     'scorecardService',
  //   );
  const scorecardService = new ScorecardService();
  const { loading: mutating, mutate } = useMutation(() =>
    scorecardService.toggleActivityVisibility(activity),
  );

  const collectionEntry = activity.object as stream.CollectionEntry;
  if (!collectionEntry) {
    logger.warn(
      `Failed to parse collection object from activity ${
        activity.id
      }: ${JSON.stringify(activity.object)}`,
    );
    return null;
  }

  const streamScorecard = collectionEntry.data as StreamScorecard;
  if (!streamScorecard) {
    logger.warn(
      `Failed to parse stream scorecard from activity ${
        activity.id
      } collection object: ${JSON.stringify(collectionEntry.data)}`,
    );
    return null;
  }

  const scorecard = new Scorecard(
    moment(streamScorecard.date),
    streamScorecard.result,
    streamScorecard.why,
    moment(streamScorecard.createdAt).toDate(),
  );

  const today = moment();
  const yesterday = moment(today).subtract(1, 'day');

  let dateText: string;
  if (today.isSame(scorecard.date, 'day')) {
    dateText = 'Today';
  } else if (yesterday.isSame(scorecard.date, 'day')) {
    dateText = 'Yesterday';
  } else {
    dateText = scorecard.date.format(DateFormat.Default);
  }

  return (
    <>
      <CardHeader
        avatar={
          <Avatar
            aria-label={actor.data.name}
            className={classes.avatar}
            src={actor.data.avatar}
          />
        }
        action={
          <Feature feature="changeActivityVisibility">
            <Box flexDirection="row" alignItems="flex-start">
              {hidden ? (
                <Icon name={IconNames.Hidden} />
              ) : // <Typography style={{ marginRight: 16, color: '#e80a4d' }}>
              //   hidden
              // </Typography>
              null}
              <IconButton
                aria-controls="settings"
                aria-haspopup="true"
                onClick={handlePressActions}>
                <Icon name={IconNames.More} />
              </IconButton>
              <Menu
                id="settings"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem
                  disabled={mutating}
                  onClick={() => mutate(() => setHidden(!hidden))}>
                  {hidden ? 'Unhide Post' : 'Hide Post'}{' '}
                </MenuItem>
              </Menu>
            </Box>
          </Feature>
        }
        title={<UserTitle user={actor.data} uid={actor.id} />}
        subheader={
          <Box flexDirection="row">
            <Paragraph color="secondary" className={classes.result}>
              {scorecard.result}{' '}
            </Paragraph>
            <Fine>{dateText}</Fine>
          </Box>
        }
      />
      {/* <CardMedia
          className={classes.media}
          image="/static/images/cards/paella.jpg"
          title="Paella dish"
        /> */}
      <CardContent className={classes.body}>
        <Typography variant="body2" color="textSecondary" component="p">
          {scorecard.why}
        </Typography>
      </CardContent>
    </>
  );
};

export default ActivityContentComponent;
