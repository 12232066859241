import React, { useState, useEffect } from 'react';
import { ErrorMessage, Page, Headline, List } from '../../components';
import { Container, FormControl, Grid, TextField } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  useContainer,
  useMutation,
  useRouteMatch,
  useHistory,
  useContent,
} from '../../hooks';
import { ReflectionExercise } from 'types';
import { Button, Box } from '../../components';
import ReflectionPreview from './ReflectionPreview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideNav: {
      //   overflowY: 'scroll',
      //   borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
    scroll: {
      overflowY: 'scroll',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '95%',
    },
  }),
);

const mainHeight = {
  height: 'calc(100vh - 87px)',
  fallbacks: [
    { height: '-moz-calc(100vh - 87px)' },
    { height: '-webkit-calc(100vh - 87px)' },
    { height: '-o-calc(100vh - 87px)' },
  ],
};

type Props = {
  teamId?: string;
};

const ExerciseListScreen: React.FC<Props> = ({ teamId: defaultTeamId }) => {
  const classes = useStyles();
  const history = useHistory();
  const { route2 } = useRouteMatch();
  const { reflectionsArr } = useContent();
  //   const [error, setError] = useState('');
  const [selected, setSelected] = useState<ReflectionExercise | undefined>(
    undefined,
  );
  const [items, setItems] = useState<ReflectionExercise[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<ReflectionExercise[]>([]);

  const handleSearchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchTerm(event.target.value as string);
  };

  useEffect(() => {
    if (route2 && reflectionsArr) {
      setSelected(reflectionsArr.filter(item => item.id === route2)[0]);
    }
  }, [route2, reflectionsArr]);

  //   const { mutate, loading: deleting, error: deleteError } = useMutation(() =>
  //     service.deleteArticle(selected && selected.slug),
  //   );

  //   const handleDelete = async () => {
  //     try {
  //       mutate();
  //       setSelected(undefined);
  //     } catch (err) {
  //       setError(err);
  //     }
  //   };

  useEffect(() => {
    if (reflectionsArr && searchTerm) {
      const results = reflectionsArr.filter(item =>
        item.id.toLowerCase().includes(searchTerm),
      );

      setSearchResults(results);
    } else {
      setSearchResults(reflectionsArr ? reflectionsArr : []);
    }
  }, [searchTerm, reflectionsArr]);

  const handleSelect = (id: string) => {
    history.push(`/exercises/${id}`);
  };

  return (
    <Page fullPage maxWidth="xl" loading={!reflectionsArr}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={3} xl={2} className={classes.sideNav} style={mainHeight}>
          <Headline variant="h6">Exercises</Headline>
          <Box row>
            <Button
              onClick={() => history.push('/exercises/create')}
              text="Create"
              variant="outlined"
              fullWidth
              color="secondary"
              size="small"
            />
          </Box>

          <form noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <TextField
                id="filled-basic"
                onChange={e => handleSearchChange(e)}
                label="Search..."
                variant="filled"
              />
            </FormControl>
          </form>
          <List<ReflectionExercise>
            items={searchResults}
            onPress={handleSelect}
            selected={route2}
            secondary="status"
          />
        </Grid>
        <Grid item lg={1} />
        <Grid item xs={8} lg={6} className={classes.scroll} style={mainHeight}>
          {/* <ErrorMessage error={error ? error : deleteError} /> */}

          {!selected ? (
            <Container>
              <h3>Please select an exercise.</h3>
            </Container>
          ) : (
            <ReflectionPreview
              id={selected.id}
              //   deleting={deleting}
              //   handleDelete={handleDelete}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default ExerciseListScreen;
