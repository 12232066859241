import React from 'react';
import { Typography } from '@material-ui/core';

const PrivacyScreen: React.FC = () => {
  return (
    <>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Please read this Privacy Policy (this “
        </span>
        <b>Privacy Policy</b>
        <span style={{ fontWeight: 400 }}>
          ”) carefully. To use the Services that Mental Health League and its
          affiliates (collectively, “
        </span>
        <b>Mental Health League</b>
        <span style={{ fontWeight: 400 }}>”, “</span>
        <b>we</b>
        <span style={{ fontWeight: 400 }}>,” “</span>
        <b>us</b>
        <span style={{ fontWeight: 400 }}>,” or “</span>
        <b>our</b>
        <span style={{ fontWeight: 400 }}>
          ”) provide, you as a user of the Services must accept this Privacy
          Policy (“
        </span>
        <b>User</b>
        <span style={{ fontWeight: 400 }}>”, “</span>
        <b>you</b>
        <span style={{ fontWeight: 400 }}>”, “</span>
        <b>your</b>
        <span style={{ fontWeight: 400 }}>
          ”). If you do not consent to the terms of the Privacy Policy, please
          do not access or use the Services.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Personal Information</b>
        <span style={{ fontWeight: 400 }}>
          . Your privacy is important to Mental Health League, and Mental Health
          League is committed to carefully managing your individually
          identifiable information (“
        </span>
        <b>Personal Information</b>
        <span style={{ fontWeight: 400 }}>
          ”) in connection with the Services that Mental Health League provides.
          “
        </span>
        <b>Personal Information</b>
        <span style={{ fontWeight: 400 }}>
          ” means any information that may be used, either alone or in
          combination with other information, to personally identify an
          individual, including, but not limited to, a first and last name, a
          personal profile, an email address or other contact information. This
          Privacy Policy describes the information practices for Mental Health
          League, including what type of information is gathered and tracked,
          how the information is used, and with whom the information is shared.
          Mental Health League is committed to protecting the privacy of the
          data you provide in the Service as appropriate, but at the same time
          encouraging you to interact with and share information about your
          progress with other users using the Services.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Protected Health Information</b>
        <span style={{ fontWeight: 400 }}>
          . Mental Health League is also committed to protecting the privacy of
          your protected health information (“
        </span>
        <b>Protected Health Information</b>
        <span style={{ fontWeight: 400 }}>”). “</span>
        <b>Protected Health Information</b>
        <span style={{ fontWeight: 400 }}>
          ” is a subset of Personal Information that is information about you,
          including demographic information, that may identify you and that
          relates to your past, present or future physical or mental health or
          condition; related health care services; or related to the past,
          present, or future payment for the provision of health care to you.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          This Privacy Policy applies to our Services operated in the United
          States
        </span>
        <span style={{ fontWeight: 400 }}>, Korea and Japan,</span>
        <span style={{ fontWeight: 400 }}>
          {' '}
          and we provide this Privacy Policy explaining our online information
          practices and the choices you can make about the way Personal
          Information is collected and used in connection with the Service. By
          using the Website and the Mobile App, User acknowledges and accepts
          this Privacy Policy.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Each User’s participation is also governed by the “
        </span>
        <a href="https://mentalhealthleague.com/pages/mhl-app-terms-conditions">
          <b>Terms &amp; Conditions</b>
        </a>
        <span style={{ fontWeight: 400 }}>
          ,” which is entered into and agreed to by each User prior to using the
          Website and the Mobile App. Unless we define a term in this Privacy
          Policy, all capitalized terms used in this Privacy Policy have the
          same meanings set forth in our Terms &amp; Conditions. This Privacy
          Policy also applies to User’s use of interactive features or downloads
          that: (i) Mental Health League owns or controls; (ii) are available
          through the Website or the Mobile App; or (iii) interact with the
          Website (including through social media) and/or the Mobile App.
        </span>
      </Typography>
      <Typography component="h2" variant="h6" gutterBottom>
        Information That Mental Health League Collects
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Personal Information.  </b>
        <span style={{ fontWeight: 400 }}>
          We have implemented this Privacy Policy because your privacy, and the
          privacy of other Service users, is important to us. We operate in
          accordance with applicable data protection legislation and
          regulations. We recognize the importance of protecting the privacy of
          Personal Information collected about our users. This Privacy Policy
          discloses what Personal Information we gather, how we use it, and how
          you can correct or change it. It is our intention to give you as much
          control over your Personal Information as possible to preserve your
          privacy, while still allowing us to utilize that Personal Information
          in the course of our business to provide you a valuable service.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Consent and Modification</b>
        <span style={{ fontWeight: 400 }}>
          . By using the Service, you consent to the terms of this Privacy
          Policy and to our processing of Personal Information for the purposes
          set forth herein. If you do not agree to this Privacy Policy, please
          do not use the Service. We reserve the right, at our discretion, to
          change this Privacy Policy at any time, which change shall become
          effective upon posting by the Company on the Website, the Mobile App,
          via the Service or sending you an email or other notification. You
          will be deemed to have agreed to Additional Terms (as defined in the
          Terms &amp; Conditions) by your decision to continue accessing the
          Mobile App or the Website or otherwise using any of the Services
          following the date in which such Additional Terms become effective.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Receipt and Collection of Information</b>
        <span style={{ fontWeight: 400 }}>
          . We collect Personal Information from users. In each case, you will
          only be required to provide the Personal Information that we need in
          order to be able to provide the services you have requested. You may
          be entitled under data protection laws to access and review the
          Personal Information that we hold on you, if you provide the necessary
          consent and background information and pay any mandated fee. All such
          communications regarding access to your personal data should be
          addressed to:{' '}
        </span>
        <a href="mailto:hello@mentalhealthleague.com">
          <span style={{ fontWeight: 400 }}>hello@mentalhealthleague.com</span>
        </a>
        <span style={{ fontWeight: 400 }}>
          . Such inquiries should clearly be marked as data protection queries
          and as being time sensitive.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          In particular, we receive and/or collect Personal Information from you
          in the following ways: Mental Health League may collect and process
          Personal Information when User registers for or uses the Services
          and/or uses the Website and/or the Mobile App, interacts with the
          Website and/or the Mobile App, use any aspects of our Services or
          otherwise contacts Mental Health League. Mental Health League may
          collect and process certain Personal Information from User such as the
          User’s name, telephone number, e-mail address, mailing address,
          telephone number, user name, and password. Mental Health League uses
          the Users’ Personal Information that Mental Health League collects for
          the following general purposes: to fulfill User’s request for use of
          certain features of the Website and/or the Mobile App, to conduct
          surveys, and for Mental Health League’s billing purposes. Mental
          Health League may also collect and process Personal Information
          relating to User’s use of Mental Health League’s Services and any
          details of any e-mail correspondence or any other social media
          interactions that User has with Mental Health League. Mental Health
          League will collect only as much Personal Information as Mental Health
          League needs for the purposes specified in this Privacy Policy, for
          purposes specified at the time of collection of the Personal
          Information, or for other purposes that you consent to in the future.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League provides User the option, at User’s sole
          election, to upload a profile image that is viewable by other
          participants of the Services, but this is not mandatory. User may
          delete User’s profile picture at any time by logging into the Mobile
          App and visiting the profile settings page.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Personal Health Information</b>
        <span style={{ fontWeight: 400 }}>
          .  Mental Health League may obtain your personal health information
          from your health care provider upon User’s request and prior approval.
          Mental Health League may collect certain personal health information
          such as,{' '}
        </span>
        <span style={{ fontWeight: 400 }}>but not limited to</span>
        <span style={{ fontWeight: 400 }}> User’s</span> 
        <span style={{ fontWeight: 400 }}>
          mental health diagnoses, medication and treatment usage
        </span>
        <span style={{ fontWeight: 400 }}>
          , and gender, and display User’s biometrics to User based on these
          inputs.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Demographic &amp; Non-Identifying Information.  </b>
        <span style={{ fontWeight: 400 }}>
          Mental Health League may collect and process demographic information
          from or about User at the Website and the Mobile App from time to
          time, such as,{' '}
        </span>
        <span style={{ fontWeight: 400 }}>but not limited to</span>
        <span style={{ fontWeight: 400 }}>
          {' '}
          information about User’s gender, age,{' '}
        </span>
        <span style={{ fontWeight: 400 }}>
          race, employment status, relationship status,{' '}
        </span>
        <span style={{ fontWeight: 400 }}>country, and zip code (“</span>
        <b>Demographic Information</b>
        <span style={{ fontWeight: 400 }}>
          ”). Mental Health League may collect this information through various
          forms and in various places on the Website and/or the Mobile App,
          including account registration forms, contact forms,{' '}
        </span>
        <span style={{ fontWeight: 400 }}>surveys,</span>
        <span style={{ fontWeight: 400 }}>
          {' '}
          or when User otherwise interacts with the Website and/or the Mobile
          App. To the extent that Mental Health League combines any
          non-personally identifiable Demographic Information with User’s
          Personal Information that Mental Health League collects directly from
          User on the Website and the Mobile App, Mental Health League will
          treat the combined data as Personal Information under this Privacy
          Policy.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League also collects data about User, including about
          User’s make-up, fitness level, health indicators, and related
          information that does not identify User and that enables User to fully
          participate in the Service.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>
          Information Mental Health League Collects or Stores as User Accesses
          and Uses the Website and Service
        </b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          In addition to any Personal Information or other information that User
          choose to submit to Mental Health League via Mental Health League’s
          Website, Mental Health League and Mental Health League’s third-party
          service providers may use a variety of technologies that automatically
          (or passively) store or collect certain information whenever User
          visits or interacts with the Website (“
        </span>
        <b>Usage Information</b>
        <span style={{ fontWeight: 400 }}>
          ”). This Usage Information may be stored or accessed using a variety
          of technologies that may be downloaded to User’s personal computer,
          browser, laptop, tablet, mobile phone or other device (a “
        </span>
        <b>Device</b>
        <span style={{ fontWeight: 400 }}>
          ”) whenever User visits or interacts with Mental Health League’s
          Website and/or the Mobile App. To the extent Mental Health League
          associates Usage Information with User’s Personal Information Mental
          Health League collects directly from User on the Website, Mental
          Health League will treat it as Personal Information.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          This Usage Information may include:
        </span>
      </Typography>
      <ul>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            User’s IP address, UDID or other unique identifier (“
          </span>
          <b>Device Identifier</b>
          <span style={{ fontWeight: 400 }}>
            ”). A Device Identifier is a number that is automatically assigned
            to User’s Device used to access the Website, and Mental Health
            League’s computers identify User’s Device by its Device Identifier;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            User’s Device functionality (including browser, operating system,
            hardware, mobile network information);
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}> User’s subscription level;</span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            the URL that referred User to Mental Health League’s Website;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            the areas within Mental Health League’s Website and/or Mobile App
            that User visits and User’s activities there, including remembering
            User, User’s preferences and pages User requested and/or viewed;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}> User’s Device location;</span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            User’s Device characteristics; and
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            certain other Device data, including the time of day, among other
            information.
          </span>
        </Typography>
      </ul>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League may use various methods and technologies to store
          or collect Usage Information (“
        </span>
        <b>Tracking Technologies</b>
        <span style={{ fontWeight: 400 }}>
          ”). Tracking Technologies may set, change, alter or modify settings or
          configurations on User’s Device. The Tracking Technologies that may be
          used are the following (and subsequent technology and methods later
          developed which perform a similar function):
        </span>
      </Typography>
      <ul>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}> </span>
          <b>Cookies</b>
          <span style={{ fontWeight: 400 }}>
            . A cookie is a data file placed on a Device when it is used to
            visit the Website. A Flash cookie (or locally shared object) is a
            data file placed on a Device via the Adobe Flash plug-in that may be
            built-in to or downloaded by User to User’s Device. HTML5 cookies
            can be programmed through HTML5 local storage. Unlike Flash cookies,
            HTML5 cookies do not require a plug-in. Regular cookies may
            generally be disabled or removed by tools that are available as part
            of most commercial browsers, and in some but not all instances can
            be blocked in the future by selecting certain settings. Each browser
            User uses will need to be set separately and different browsers
            offer different functionality and options in this regard. Also,
            these tools may not be effective with regard to Flash cookies or
            HTML5 cookies. For information on disabling Flash cookies go to
            Adobe’s web site www.adobe.com. Please be aware that if User
            disables or removes cookies, Flash cookies, or HTML5 cookies on
            User’s Device, some parts of Mental Health League’s Website, Mobile
            App and/or Services may not function properly, and that when User
            revisits Mental Health League’s Website, Mobile App and Services
            User’s ability to limit cookies is subject to User’s browser
            settings and limitations.
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}> </span>
          <b>Web Beacons</b>
          <span style={{ fontWeight: 400 }}>
            . Small graphic images or other web programming code called “web
            beacons” (also known as “1×1 GIFs” or “clear GIFs”) may be included
            in pages and messages of our Website, Mobile App and Services. Web
            beacons may be invisible to User, but any electronic image or other
            web programming code inserted into a page or e-mail can act as a web
            beacon. Web beacons or similar technologies may be used for a number
            of purposes, including, without limitation, to count visitors to the
            Website, Mobile App and Services, to monitor how users navigate the
            Website, Mobile App and Services, to count how many e-mails that
            were sent were actually opened or to count how many particular
            articles or links were actually viewed.
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}> </span>
          <b>Embedded Scripts</b>
          <span style={{ fontWeight: 400 }}>
            . An embedded script is programming code that is designed to collect
            information about User’s interactions with the Website, Mobile App
            and Services, such as the links User clicks on. The code is
            temporarily downloaded onto User’s Device from Mental Health
            League’s web server and/or Mobile App or a third party service
            provider, is active only while User is connected to the Website
            and/or Mobile App, and is deactivated or deleted thereafter.
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }} />
          <b> ETag, or entity tag</b>
          <span style={{ fontWeight: 400 }}>
            . A feature of the cache in browsers. It is an opaque identifier
            assigned by a web server to a specific version of a resource found
            at a URL. If the resource content at that URL ever changes, a new
            and different ETag is assigned. Used in this manner ETags are a form
            of Device Identifier. ETag tracking may generate unique tracking
            values even where the consumer blocks HTTP, Flash, and/or HTML5
            cookies.
          </span>
        </Typography>
      </ul>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League may use Tracking Technologies for a variety of
          purposes, including:
        </span>
      </Typography>
      <ul>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}> </span>
          <b>Strictly Necessary</b>
          <span style={{ fontWeight: 400 }}>
            . Mental Health League may use cookies or other Tracking
            Technologies that Mental Health League considers is strictly
            necessary to allow User to use and access Mental Health League’s
            Website, Mobile App and Services, including cookies required for
            system administration, to prevent fraudulent activity, or to
            improve.
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}> </span>
          <b>Performance Related</b>
          <span style={{ fontWeight: 400 }}>
            . Mental Health League may use cookies or other Tracking
            Technologies that are useful in order to assess the performance of
            the Website, Mobile App and Services, including as part of Mental
            Health League’s analytic practices or otherwise to improve the
            content, products or services offered through the Website, Mobile
            App and Services.
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}> </span>
          <b>Functionality Related</b>
          <span style={{ fontWeight: 400 }}>
            . Mental Health League may use cookies or other Tracking
            Technologies that are required to offer User enhanced functionality
            when accessing the Website, including identifying User when User
            sign in to Mental Health League’s Website or keeping track of User’s
            specified preferences, including in terms of the presentation of
            content on Mental Health League’s Website.
          </span>
        </Typography>
      </ul>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League obtains User’s consent to Mental Health League’s
          information storage or collection Tracking Technologies by providing
          User with transparent information in Mental Health League’s Privacy
          Policy and providing User with the opportunity to make a choice to
          disable cookies as set forth above. Please note that Mental Health
          League is not required to obtain User’s consent to the information
          collection Tracking Technologies identified above that is strictly
          necessary. User has the right to object to other use of information
          storage or collection technologies.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          There may be other Tracking Technologies now and later devised and
          used by Mental Health League in connection with the Website, Mobile
          App and Services. Further, third parties may use Tracking Technologies
          with Mental Health League’s Website. Mental Health League does not
          control those Tracking Technologies and Mental Health League is not
          responsible for them. However, User consents to potentially
          encountering third party Tracking Technologies in connection with use
          of Mental Health League’s Website, Mobile App and Services and accepts
          that Mental Health League’s statements under this Privacy Policy do
          not apply to the Tracking Technologies or practices of such third
          parties. User should check the third party websites to confirm how
          User’s information is collected and used.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Various third parties are developing or have developed signals or
          other mechanisms for the expression of consumer choice regarding the
          collection of information about an individual consumer’s online
          activities over time and across third-party web sites or online
          services (e.g., browser do not track signals). Currently, Mental
          Health League does not monitor or take any action with respect to
          these signals or other mechanisms.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Information Third Parties Provide About User</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League may, from time to time, supplement the
          information Mental Health League collects directly from User at the
          Website, Mobile App and through Services with outside records from
          third parties for various purposes, including to enhance Mental Health
          League’s ability to serve User, to tailor Mental Health League’s
          content to User and to offer User opportunities that may be of
          interest to User. To the extent Mental Health League combines any
          non-personally identifiable information Mental Health League receives
          from those sources with User’s Personal Information Mental Health
          League collect on the Website, Mobile App and Services, it will be
          treated as Personal Information and Mental Health League will apply
          this Privacy Policy to such combined information, unless Mental Health
          League has disclosed otherwise.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Interactions with Third-Party Websites</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          The Website, Mobile App and Services may include functionality that
          allows certain kinds of interactions between the Website, Mobile App
          and Services and User’s account on a third-party web site or
          application. The use of this functionality may involve the third-party
          operator providing certain information, including Personal
          Information, to Mental Health League. For example, when User registers
          with the Website and/or Mobile App, User may have an option to use
          User’s Facebook, Google or other account provided by a third-party
          site or application to facilitate the registration and log-in or
          transaction process on the Website, Mobile App and Services or
          otherwise link accounts. If Mental Health League offers and User
          chooses to use this functionality to access Mental Health League’s
          Website, Mobile App and Services, the third-party site or application
          may send Personal Information about User to the Website and/or Mobile
          App. If so, Mental Health League will then treat it as Personal
          Information under this Privacy Policy, since Mental Health League is
          collecting it as a result of User’s accessing of and interaction on
          Mental Health League’s Website, Mobile App and Services. In addition,
          Mental Health League may provide third-party sites’ interfaces or
          links on the Website, Mobile App and Services to facilitate User’s
          sending a communication from the Website, Mobile App and Services. For
          example, Mental Health League may use third parties to facilitate
          emails, text messages, blog postings, tweets or Facebook postings.
          These third parties may retain any information used or provided in any
          such communications or other activities and these third parties’
          practices are not subject to Mental Health League’s Privacy Policy.
          Mental Health League may not control or have access to User’s
          communications through these third parties. Further, when User use
          third-party sites or services, User is using their services and not
          Mental Health League’s services and they, not Mental Health League,
          are responsible for their practices. User should review the applicable
          third-party privacy policies before using such third-party tools on
          Mental Health League’s Website.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>How Mental Health League Uses the Personal Information</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Except as set forth in this Privacy Policy or as specifically agreed
          to by User, Mental Health League will not disclose any information
          Mental Health League gathers from User on Mental Health League’s
          Website, Mobile App and Services. Except as set forth in this Privacy
          Policy, Mental Health League does not share User’s Personal
          Information with third parties for those third parties’ direct
          marketing purposes. Mental Health League may provide to third parties
          information about User that does not allow User to be identified or
          contacted, including where such information is combined with similar
          information of other users of Mental Health League’s Website, Mobile
          App and Services. For example, Mental Health League might inform third
          parties regarding the number of unique users who visit and/or use
          Mental Health League’s Services, the demographic breakdown of the
          registered users of Mental Health League’s Services, or the activities
          that users engage in while on Mental Health League’s Website and/or
          Mobile App.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          In connection with the Business Premium Services, the Business
          Customer may disclose certain Personal Information to Mental Health
          League. To the extent that Mental Health League collects and processes
          Personal Information supplied by the Business Customer, Mental Health
          League is considered to be the data controller for the purposes of
          European data protection legislation and Mental Health League will
          process User’s Personal Information in accordance with this Privacy
          Policy.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League may use User’s information that Mental Health
          League collects about User:
        </span>
      </Typography>
      <ul>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to contact User occasionally to inform User of new Services
            features, or news or articles Mental Health League thinks will be of
            interest to User. Mental Health League may send User regular updates
            on issues Mental Health League thinks will be of interest to User;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to contact User with regard to User’s use of the Services and, in
            Mental Health League’s discretion, changes to the Services and/or
            the service policies;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            for internal business purposes;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            for purposes disclosed at the time User provide User’s information
            or as otherwise set forth in this Privacy Policy;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to provide User with information or services or process transactions
            that User has requested or agreed to receive including to send User
            electronic newsletters;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to provide User with marketing materials or relevant advertising,
            promotions and recommendations from Mental Health League or our
            business partners;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to enable User to participate in a variety of the Service features;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to process User’s account registration, including verifying User’s
            information is active and valid;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to identify User as the author of any comments that User sends to
            Mental Health League to be posted on the Website or Mobile App;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to ensure that content on the Website and Mobile App is presented in
            the most effective manner for User and for User’s computer or mobile
            device;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to provide User with information relating to any events held by
            Mental Health League, where User has consented to be contacted for
            such purposes; and
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to provide User with access to User’s personal account.
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to analyze, operate, maintain and improve the App, to add new
            features and services to the App;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to customize content you see when you use the App;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to provide and deliver the products and services you request,
            process transactions and send you related information, including
            confirmations and reminders;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to customize product and service offerings and recommendations to
            you, including third-party products and offerings;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}> to verify your identity;</span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to send you technical notices, updates, security alerts and support
            and administrative messages;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            for billing (invoicing), account management and other administrative
            purposes, if applies;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to respond to your comments, questions and requests and provide
            customer service;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to monitor and analyze trends, usage and activities in connection
            with our App;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            solely with respect to information that you mark for sharing, for
            Company promotional purposes (except data from Apple HealthKit and
            Google Fit);
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            to link or combine with information we get from others or (and) from
            you to help understand your needs and provide you with better
            service (to use in training of neural networks, artificial
            intelligence, as well as for any other automated decision-making
            processing);
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            for scientific and academic research purposes; and
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            for any other purposes disclosed to you at the time we collect
            Personal Data or any other purposes indicated in this Privacy
            Policy;
          </span>
        </Typography>
        <Typography component="li" variant="body2">
          <span style={{ fontWeight: 400 }}>
            {' '}
            for emergency situations as described in “Emergency Disclosures”
            section below.
          </span>
        </Typography>
      </ul>
      <Typography variant="body2" gutterBottom>
        <b>Emergency Disclosures</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          We care about your safety. It’s our goal to support you in staying
          safe and healthy and to do so in the least invasive way possible
          (read: we don’t want to intrude in your life). You being alive is what
          matters most to us. In rare cases, this means we might contact law
          enforcement, state authorities, or emergency services if your messages
          indicate that you are at imminent risk of death or serious injury
          (e.g., if you have suicidal desire, a plan, access to means, and an
          imminent timeline), or if we reasonably believe that disclosure is
          necessary to prevent a person’s death or serious injury. At that
          point, we might ask you for some personally identifiable information
          (such as where you are or what your name is). We understand that this
          can be disruptive, and it won’t happen unless we think it’s necessary.
          (Read: just saying “I’m feeling suicidal” does not necessarily mean
          we’ll try to send someone.)
        </span>
        <span style={{ fontWeight: 400 }}>
           We’re not here to tell on you -- we’re here to do our best to support
          you and others in staying healthy and safe. We may attempt to locate
          you, but it may not always be possible.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          If the content of your messages leads us to reasonably suspect that
          you are a victim of emotional/physical abuse or neglect and are 17
          years old or younger or a vulnerable dependent adult, we may ask you
          for certain Personally Identifiable Information (such as your date of
          birth, name, and address) to help us file any required report with an
          appropriate state authority. You are of course not required to share
          this information with us.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Additionally, we may disclose Personally Identifiable Information we
          collect from you to third parties if we believe such disclosure is
          necessary: (A) to comply with the law or in response to a subpoena,
          court order, government request, or other legal process; (B) to
          protect the interests, rights, safety, or property of Mental Health
          League, its affiliates, employees, or agents, including but not
          limited to Mental Health League’s staff; (C) to enforce our Terms; (D)
          in connection with a sale, merger, or change of control of Mental
          Health League or its affiliates, or (E) to address fraud, security,
          technical issues, or to operate the Services or its infrastructure
          systems properly.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Note that Mental Health League’s staff is located across the United
          States, and may not be licensed in your state or trained in your
          state’s law. Other state laws may apply to your communications, and
          those state laws may not protect your communications to the same
          degree, or in the same way, as the laws of your state.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          User can always opt out of utilizing the Service, and accordingly opt
          out of Mental Health League’s use of User’s Personal Information, by
          sending an email to 
        </span>
        <span style={{ fontWeight: 400 }}>
          <a href="mailto:hello@mentalhealthleague.com">
            hello@mentalhealthleague.com
          </a>
        </span>
        <span style={{ fontWeight: 400 }}>.</span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>
          How and When Does Mental Health League Disclose Information To Third
          Parties?
        </b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League may share User’s Personal Information, including
          performance measurement information associated with User’s name that
          is collected or generated at the Website, Mobile App or through the
          Services.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Personal Information may occasionally be transferred to third parties
          who act for Mental Health League for further processing in accordance
          with the purposes for which the data was originally collected or for
          purposes to which User has subsequently consented. For example,
          sometimes a third party may have access to User’s Personal Information
          in order to support Mental Health League’s information technology or
          to handle mailings on Mental Health League’s behalf. If Mental Health
          League transfers User’s Personal Information to an outside agent or
          organization for such purpose, Mental Health League will take
          appropriate measures in an effort to protect User’s privacy and the
          Personal Information Mental Health League transfer.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League may share your Personal Information with various
          business partners. Some of these business partners may use your
          personal information to facilitate the offering of services or
          products that may be of interest to you. We may also share your
          Personal Information as otherwise described to you at the time of
          collection.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League may, in Mental Health League’s sole discretion,
          share or transfer Personal Information where permitted or required by
          law, to comply with legal process, to interact with anti-fraud
          databases, to protect User’s vital interests, to protect the security
          or integrity of Mental Health League’s databases or the Website and
          the Mobile App, to enforce the Terms &amp; Conditions or protect the
          business or reputation of Mental Health League and/or its Business
          Customers, to take precautions against legal liability, to protect and
          defend the rights or property of Mental Health League and/or its
          Business Customers, for required institutional risk control, or for
          resolving disputes, inquiries or complaints with respect to User’s use
          of the Website, Mobile App and Services.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League reserves the right to disclose and transfer all
          Personal Information: (i) to an operator of the Website or Mobile App
          or applicable database provided such entity agrees to be bound by the
          terms and conditions hereof to the extent applicable; or (ii) to a
          successor in interest of Mental Health League in connection with a
          merger, consolidation, restructuring, change of control, the sale of
          substantially all of Mental Health League’s interests and/or assets or
          other organizational change, including, during the course of any due
          diligence process, provided such successor entity agrees to be bound
          by the terms and conditions hereof.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>What About Information User Disclose Publicly Or To Others?</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>User-Generated Content and Public Information</b>
        <span style={{ fontWeight: 400 }}>. “</span>
        <b>User Content</b>
        <span style={{ fontWeight: 400 }}>
          ” is any content, materials or information (including without
          limitation, any text, information, graphics, messages, photos, images,
          nutritional information contributed to the Food Database and works of
          authorship kind), data, questions, comments, suggestions or other
          content, including personally identifiable information that you
          upload, send, email, display, perform, distribute, post or otherwise
          transmit to us, at our request or on your own, on, or through the
          Services (such as message boards, recipe logging), whether in
          connection with your use of the Services or through the use of any
          Third Party Websites or Third Party Services or otherwise, and whether
          publicly posted or privately transmitted.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League or others may store, display, reproduce, publish,
          distribute or otherwise use User Content online or offline in any
          media or format (currently existing or hereafter developed) and may or
          may not attribute it to User. Others may have access to this User
          Content and may have the ability to share it with third parties.
          Please think carefully before deciding what information User shares,
          including Personal Information, in connection with User’s User
          Content. Be aware that public postings (including group messaging with
          other Users) are not confidential. Please note that the Mental Health
          League does not control who will have access to the information that
          User chooses to make public, and cannot ensure that parties who have
          access to such publicly available information will respect User’s
          privacy or keep it secure. Mental Health League is not responsible for
          the privacy or security of any information that User makes publicly
          available on the Service, the Mobile App or Website or what others do
          with information User shares with them on the Mobile App and Website.
          Mental Health League is not responsible for the accuracy, use or
          misuse of any User Content that User discloses or receives from third
          parties through the Service.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Name and Likeness</b>
        <span style={{ fontWeight: 400 }}>
          . Mental Health League may also publish User’s name, voice, likeness
          and other Personal Information that is part of User’s User Content,
          and Mental Health League may use the content, or any portion of the
          content, for advertising, marketing, publicity and promotional
          activities, provided that Mental Health League will not exercise such
          rights as to any User Content which identifies User in a public manner
          (other than in providing the Services to the Business Customer and the
          Business Customer’s other participants) unless User provides a
          separate consent to Mental Health League in writing (email is
          sufficient) authorizing such use. For full terms and conditions
          regarding User Content User submits to the Service, please review
          Mental Health League’s Terms &amp; Conditions.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Profiles</b>
        <span style={{ fontWeight: 400 }}>
          . A User’s profile page may be, by default, set up to display
          information such as the User’s display name, images, location
          (city/state/country), groups that the User has joined and optional
          information added by User. Profile information is used by Mental
          Health League primarily to be presented back to and edited by User
          when User accesses the Service and to be presented to others permitted
          to view that information on the Service. Mental Health League may
          offer users the ability to manage their public profile and use profile
          preference settings, the functionality and features of which are
          subject to change from time to time, so visit it frequently to confirm
          the settings reflect User’s then current preferences and Mental Health
          League recommends that User does not post information or content as
          part of User’s profile that User is not prepared to make public.
          Changing setting options may not result in immediate changes to the
          settings, which are subject to Mental Health League’s operations and
          maintenance schedules. User should carefully consider the use of such
          settings to improve information display options and to ensure the
          settings are properly set and functioning in the manner desired.
          Notwithstanding the availability of profile preference settings, User
          should be aware that these settings are for convenience only, do not
          employ complex data security protection and may not be error free.
          Further, other users that have access may repost or otherwise make
          public User’s information or content. Accordingly, discretion and good
          judgment should be exercised when posting information or content as
          part of User’s profile.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Use of Anonymous Information</b>
        <span style={{ fontWeight: 400 }}>
          . We may use Anonymous Information (as defined below), or disclose it
          to third party service providers, to provide and improve the Service.
          We may also disclose Anonymous Information (with or without
          compensation) to third parties, including advertisers and partners,
          for purposes including, but not limited to, targeting advertisements.
          “
        </span>
        <b>Anonymous Information</b>
        <span style={{ fontWeight: 400 }}>
          ” means information which does not enable identification of an
          individual user, such as aggregated information about use of the
          Service.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Opting Out</b>
        <span style={{ fontWeight: 400 }}>
          . You may choose not to receive future promotional, advertising, or
          other Service-related notifications from us by changing the
          notification settings on your device. If you choose not to receive
          notifications, you may still use the Service but you may not receive,
          or may be unable to use, certain services that involve our interaction
          with you.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Choice</b>
        <span style={{ fontWeight: 400 }}>
          . At all times, you may choose whether or not to provide or disclose
          Personal Information. The notices that we provide on the Service in
          circumstances where we collect Personal Information should help you to
          make this choice. If you choose not to provide the Personal
          Information we request, you may still use the Service, but you may be
          unable to access certain programs and services that involve our
          interaction with you.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Access/Accuracy.</b>
        <span style={{ fontWeight: 400 }}>
          {' '}
          To the extent that you do provide us with Personal Information, we
          wish to maintain accurate Personal Information. If you would like to
          delete or correct any other of your Personal Information that we may
          be storing, you may submit a request to us by sending an email to 
        </span>
        <span style={{ fontWeight: 400 }}>
          <a href="mailto:hello@mentalhealthleague.com">
            hello@mentalhealthleague.com
          </a>
        </span>
        <span style={{ fontWeight: 400 }}>
          . Your email should include adequate details of your request.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Collection of Information by Third-Party Websites</b>
        <span style={{ fontWeight: 400 }}>
          . We may use a reputable third party to present or serve
          advertisements that you may see on the Service. These third party ad
          servers may use cookies, web beacons, clear gifs or similar
          technologies to help present such advertisements, and to help measure
          and research the advertisements’ effectiveness. The use of these
          technologies by these third party ad servers is subject to their own
          privacy policies and is not covered by our Privacy Policy.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Links to Other Websites</b>
        <span style={{ fontWeight: 400 }}>
          . The Service may contain links to third party websites that are not
          owned or controlled by us. We are not responsible for the privacy
          practices or the content of such other third party websites, and you
          visit them at your own risk.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Children’s Privacy</b>
        <span style={{ fontWeight: 400 }}>
          . The Service is neither directed to nor structured to attract
          children under the age of 13 years. Accordingly, we do not intend to
          collect Personal Information from anyone we know to be under 13 years
          of age. We will direct potential users under 13 years of age not to
          use the Service. If we learn that Personal Information of persons less
          than 13 years of age has been collected without verifiable parental
          consent, then we will take the appropriate steps to delete this
          information. To make such a request, or if there are any questions or
          concerns about the Privacy Policy for the Service or its
          implementation, please contact us at 
        </span>
        <span style={{ fontWeight: 400 }}>
          <a href="mailto:hello@mentalhealthleague.com">
            hello@mentalhealthleague.com
          </a>
        </span>
        <span style={{ fontWeight: 400 }}>.</span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Security</b>
        <span style={{ fontWeight: 400 }}>
          . The security of your Personal Information is important to us. We
          follow generally accepted industry standards, including the use of
          appropriate administrative, physical and technical safeguards, to
          protect the Personal Information submitted to us. However, no method
          of transmission over the Internet, or method of electronic storage, is
          100% secure. Therefore, while we strive to use commercially acceptable
          means to protect Personal Information, we cannot guarantee its
          absolute security or confidentiality. If you have any questions about
          security, you can contact us at 
        </span>
        <span style={{ fontWeight: 400 }}>
          <a href="mailto:hello@mentalhealthleague.com">
            hello@mentalhealthleague.com
          </a>
        </span>
        <span style={{ fontWeight: 400 }}>.</span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Please be aware that certain Personal Information and other
          information provided by you in connection with your use of the App may
          be stored on your device (even if we do not collect that information).
          You are solely responsible for maintaining the security of your device
          from unauthorized access.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Merger, Sale or Bankruptcy</b>
        <span style={{ fontWeight: 400 }}>
          . If we are acquired by or merged with a third party entity, or if we
          are subject to a bankruptcy or any comparable event, we reserve the
          right to transfer or assign Personal Information in connection
          therewith.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>California Online Privacy Protection Act Notice</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          On September 27, 2013, California enacted A.B. 370, amending the
          California Online Privacy Protection Act to require website operators
          like us to disclose how we respond to “Do Not Track Signals”; and
          whether third parties collect personally identifiable information
          about users when they visit us.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          (1) We do not track users, who do not interact with its sharing
          functionality across the web, and therefore do not use “do not track”
          signals.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          (2) We do not authorize the collection of personally identifiable
          information from our users for third party use through advertising
          technologies without separate member consent.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          California Civil Code Section 1798.83 also permits our customers who
          are California residents to request certain information regarding our
          disclosure of Personal Information to third parties for their direct
          marketing purposes. To make such a request, please send an email to 
        </span>
        <span style={{ fontWeight: 400 }}>
          <a href="mailto:hello@mentalhealthleague.com">
            hello@mentalhealthleague.com
          </a>
        </span>
        <span style={{ fontWeight: 400 }}>
          . Please note that we are only required to respond to one request per
          customer each year.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Personal Information Access and Corrections
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Upon receipt of User’s written request and upon Mental Health League’s
          receipt of enough information to permit Mental Health League to
          identify User’s Personal Information, Mental Health League will
          disclose to User the Personal Information Mental Health League holds
          about User. Mental Health League will also correct, amend or delete
          any Personal Information that is inaccurate and notify any third party
          recipients of the necessary changes. User may update any information
          User has given to Mental Health League by sending an email to 
        </span>
        <span style={{ fontWeight: 400 }}>
          <a href="mailto:hello@mentalhealthleague.com">
            hello@mentalhealthleague.com
          </a>
        </span>
        <span style={{ fontWeight: 400 }}>
          . Requests to delete Personal Information are subject to any
          applicable legal and ethical reporting or document retention
          obligations imposed on Mental Health League.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
         
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Our Accountability/Dispute Resolution</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League uses a self-assessment approach to assure
          compliance with this Privacy Policy and periodically verifies that the
          policy is accurate, comprehensive for the information intended to be
          covered, prominently displayed, completely implemented and accessible
          and in conformity with the promises in this policy. Mental Health
          League encourages interested persons to raise any concerns using the
          contact information provided and Mental Health League will investigate
          and attempt to resolve any complaints and disputes regarding use and
          disclosure of Personal Information in accordance with this policy.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Mental Health League Service: International Users</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League’s Service is operated in the United States. If
          User is located outside of the United States, please be aware that
          information Mental Health League collects, including Personal
          Information, will be transferred to, and processed, stored and used in
          the United States. The data protection laws in the United States may
          differ from those of the country in which User is located, and User’s
          Personal Information may be subject to access requests from
          governments, courts, or law enforcement in the United States according
          to laws of the United States. U.S. law may not provide the degree of
          protection for Personal Information that is available in other
          countries. Mental Health League takes commercially reasonable steps to
          ensure that User’s data is treated securely, including entering into
          appropriate data transfer agreements. By using the Service or
          providing Mental Health League with any information, User consents to
          the transfer to, and processing, usage, sharing and storage of User’s
          information, including Personal Information, in the United States as
          set forth in this Privacy Policy. If User does not consent to such
          transfer, User may not use the Service.  If you choose to access and
          use the Website, Mobile App and/or Service from outside the U.S. you
          acknowledge that you are doing so on your own initiative, at your own
          risk, and are responsible for compliance with applicable laws.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Business Transitions</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          In the event Mental Health League goes through a business transition,
          such as a merger, acquisition by another organization, or sale of all
          or a portion of its assets, User’s Personal Information might be among
          the assets transferred. User will be notified via this Website of any
          such change in ownership or control of User’s Personal Information.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>How can I get more information about the Privacy Policy?</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          If User has questions or concerns about the Privacy Policy, User can
          contact Mental Health League at:
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>Mental Health League, Inc.</span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>Attention: Privacy Officer</span>
      </Typography>

      <Typography variant="body2" gutterBottom>
        <a href="mailto:hello@mentalhealthleague.com">
          <span style={{ fontWeight: 400 }}>hello@mentalhealthleague.com</span>
        </a>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Updates/Modifications</b>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>
          Mental Health League reserves the right to amend this Privacy Policy
          at any time as needed with notice provided to User. Changes will take
          effect upon User’s acceptance of the modified terms and User’s
          continued use of the Website, Mobile App and/or Services.
        </span>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 400 }}>Last Updated: October 31, 2019</span>
      </Typography>
    </>
  );
};

export default PrivacyScreen;
