import React, {
  createContext,
  ReactChildren,
  useEffect,
  useState,
} from 'react';
import { buildContainer, dependenciesInstances } from '../services';
import { useCurrentUser } from '../hooks';
import { Loading } from '../components';

const initialContainer = buildContainer({});
export const ServicesContext = createContext(initialContainer);

export const ServicesProvider = ({
  children,
}: {
  children: ReactChildren | React.ReactElement;
}) => {
  // const [container, setContainer] = useState(initialContainer);
  const { user } = useCurrentUser();

  if (!user) {
    return <>{children}</>;
  }

  const [container, setContainer] = useState(initialContainer);

  useEffect(() => {
    if (user) {
      setContainer(
        buildContainer({
          currentPlayer: user,
        }),
      );
    }
  }, [user]);

  // if () {
  //   return ;
  // }

  return user && !dependenciesInstances.currentPlayer ? (
    <Loading isLoading={true} full />
  ) : (
    <ServicesContext.Provider value={container}>
      {children}
    </ServicesContext.Provider>
  );
};
