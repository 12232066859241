import React, { useState, useEffect, useRef } from 'react';
import {
  useCurrentUser,
  useMutation,
  useSubscription,
  useContainer,
} from '../../hooks';
import {
  Avatar,
  Typography,
  Divider,
  Grid,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  TextField,
} from '@material-ui/core';
import {
  ScheduledPostsService,
  ScheduledPost,
  SubscriptionListener,
  Unsubscriber,
} from '../../services';
import { Spinner, Button, ErrorMessage, Headline, Box } from '../../components';
import moment from 'moment';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as stream from 'getstream';

interface Props {
  teamId: string;
}

const makeScheduledPostsQuery = () => {
  const cache: {
    [key: string]: (
      listener: SubscriptionListener<ScheduledPost[]>,
    ) => () => void;
  } = {};
  return function scheduledPostsQuery(
    teamId: string,
    service: ScheduledPostsService,
  ): (listener: SubscriptionListener<ScheduledPost[]>) => () => void {
    if (cache[teamId]) return cache[teamId];
    const subscribe = (
      listener: SubscriptionListener<ScheduledPost[]>,
    ): Unsubscriber => service.subscribeToAll(listener, { teamId });
    cache[teamId] = subscribe;
    return subscribe;
  };
};

const postsQuery = makeScheduledPostsQuery();

// const TODAY = moment();
// const TOMORROW = moment(TODAY).add(1, 'day');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
  }),
);

const ScheduledPostsList: React.FC<Props> = ({ teamId }) => {
  const { user:currentUser } = useCurrentUser();
  const classes = useStyles();

  const [error, setError] = useState('');
  const [postId, setPostId] = useState<string>('');
  const [postContent, setPostContent] = useState('');
  const [postToDelete, setPostToDelete] = useState<undefined | string>();
  const [postDate, setPostDate] = useState<Date>(
    moment()
      .add(1, 'hour')
      .toDate(),
  );

  const container = useContainer();
  const schedulePostService = container.getInstance<ScheduledPostsService>(
    'scheduledPosts',
  );
  const { loading: postsLoading, data } = useSubscription<ScheduledPost[]>(
    postsQuery(teamId, schedulePostService),
  );

  // Add Post
  const {
    loading: postAddLoading,
    error: postAddError,
    mutate: postAddMutate,
  } = useMutation(() =>
    schedulePostService.updateOrCreatePost({
      id: postId,
      content: postContent,
      date: postDate,
      time: postDate,
      teamId: teamId,
      userId: currentUser?.id,
    }),
  );

  const handleAdd = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.preventDefault();

    postAddMutate();
    setPostContent('');
    if (postId) {
      setPostId('');
    }
  };

  // Add post immediately
  const client = container.getInstance<stream.Client>('streamClient');
  const teamFeed = client.feed('team', teamId);
  const activityRef = useRef<stream.ActivityData | undefined>();

  const {
    loading: postnowLoading,
    error: postNowError,
    mutate: postNowMutate,
  } = useMutation(() =>
    schedulePostService.updateOrCreatePost({
      id: postId,
      content: postContent,
      date: moment()
        .add(1, 'minute')
        .toDate(),
      time: moment()
        .add(1, 'minute')
        .toDate(),
      teamId: teamId,
      userId: currentUser?.id,
      activity: activityRef.current,
      status: 'success',
    }),
  );

  const addStreamActivity = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): Promise<void> => {
    event.preventDefault();

    try {
      if (currentUser) {

        const newActivity: stream.NewActivity = {
          actor: client.user(currentUser?.id),
          verb: 'post',
          type: 'discussion',
          message: postContent,
          object: {
            actor: { data: { teamId: teamId } },
          },
          foreign_id: postId,
          time: new Date().toISOString(),
        };
        const res = await teamFeed.addActivity(newActivity);
        activityRef.current = res as stream.ActivityData;
  
        postNowMutate();
        setPostContent('');
        if (postId) {
          setPostId('');
        }
      }
    } catch (err) {
      setError('Error creating stream activity');
    }
  };

  const handleDateChange = (date: moment.Moment) => {
    setPostDate(date.toDate());
  };

  // Edit Post
  const handleEdit = (
    editId: string,
    editContent: string,
    editDate: Date,
  ): void => {
    setPostContent(editContent);
    setPostId(editId);
    setPostDate(editDate);
  };

  // Delete Post
  const { mutate, loading: deleting } = useMutation(() =>
    schedulePostService.delete(postToDelete),
  );
  useEffect(
    function deleteSelectedPost() {
      if (postToDelete) {
        mutate();
        setPostToDelete(undefined);
      }
    },
    [postToDelete, mutate],
  );

  return postsLoading ? (
    <Spinner />
  ) : (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container justify="space-around" spacing={2}>
          <Grid item xs={12}>
            <Headline gutterBottom variant="h4">
              Scheduled Posts
            </Headline>
            <TextField
              id="filled-multiline-static"
              label={!postId ? 'Write Post' : 'Edit Post'}
              multiline
              fullWidth
              rows={4}
              value={postContent}
              onChange={event => setPostContent(event.target.value)}
              disabled={postAddLoading}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              minDate={moment().toDate()}
              label="Date"
              fullWidth
              format="MM/DD/yyyy"
              value={postDate}
              inputVariant="filled"
              onChange={event => event && handleDateChange(event)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Time"
              fullWidth
              value={postDate}
              inputVariant="filled"
              format="hh a"
              views={['hours']}
              onChange={event => event && handleDateChange(event)}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={postsLoading}
              variant="contained"
              color="secondary"
              fullWidth
              // loading={noteAddLoading}
              onClick={event => handleAdd(event)}>
              {!postId ? 'Schedule Post' : 'Edit Post'}
            </Button>
            <Box mt={1}>
              <Button
                disabled={postsLoading}
                variant="outlined"
                fullWidth
                loading={postnowLoading}
                onClick={event => addStreamActivity(event)}
                text="Add Now"
              />
            </Box>
            <ErrorMessage error={postAddError} />
            <ErrorMessage error={postNowError} />
            <ErrorMessage error={error} />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <List className={classes.root}>
        {data &&
          data.map(post => (
            <div key={post.id}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary">
                        {/* {post.userId} */}
                        {moment(post.runAt).format(
                          'ddd MMM Do, YYYY @ HH:mm a',
                        )}
                      </Typography>
                    </>
                  }
                  secondary={post.post.content}
                />
                <Button
                  disabled={deleting}
                  onClick={(): void => {
                    handleEdit(post.id, post.post.content, post.runAt);
                  }}
                  text="Edit"
                />

                <Button
                  disabled={deleting}
                  onClick={(): void => {
                    setPostToDelete(post.id);
                  }}
                  text="Delete"
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          ))}
      </List>
    </>
  );
};

export default ScheduledPostsList;
