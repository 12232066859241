import React, { useEffect } from 'react';
import Button from '../../../components/Button';
import Box from '@material-ui/core/Box';
import {
  EmojiEvents as TrophyIcon,
  Apple as AppleIcon,
  Android as AndroidIcon,
} from '@material-ui/icons';
import { useHistory, useUrlParams, useLead } from '../../../hooks';
import { iosAppStoreLink } from 'common';

type Props = {
  ctas?: 'os' | 'short' | 'cart' | 'apply';
};

const CtaButton: React.FC<Props> = ({ ctas }) => {
  const history = useHistory();
  const { setPlan, plan } = useLead();

  let params = useUrlParams();
  let cta = params.get('cta');
  let newPlan = params.get('plan');

  useEffect(() => {
    if (plan) {
      setPlan(newPlan);
    }
  }, []);

  const text =
    cta === 'short'
      ? 'Get Started'
      : cta === 'cart'
      ? 'Start Free Trial'
      : cta === 'apply'
      ? 'Apply Now'
      : "Let's Go!";

  const onClick =
    cta === 'apply'
      ? () => history.push('/survey')
      : cta === 'short'
      ? () => history.push('/survey?version=short')
      : cta === 'cart'
      ? () => history.push('/login?redirect=checkout')
      : () => history.push('/survey?version=short');

  const icon = <TrophyIcon />;

  return ctas === 'os' || cta === 'os' ? (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        // onClick={() => history.push('/plans')}
        onClick={() => (window.location.href = iosAppStoreLink)}
        // onClick={() => history.push('/login?redirect=checkout', ['/checkout'])}
        startIcon={<AppleIcon />}
        // text="Start Free Trial"
        text="Download iOS"
      />

      <Box mt={1}>
        <Button
          variant="text"
          color="primary"
          fullWidth={false}
          onClick={() => history.push('/android')}
          startIcon={<AndroidIcon />}
          text="Need Android?"
        />
      </Box>
    </>
  ) : (
    <Button text={text} onClick={onClick} startIcon={icon} />
  );
};

export default CtaButton;
