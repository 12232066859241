import React from 'react';
import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore';
import { IconNames, PublishStatus } from './ArticleTypes';
import { ExerciseTypes } from './GameplanTypes';
import { RawDraftContentState } from 'draft-js';
import * as moment from 'moment';

// export { TagsArr, Tags } from 'common';

export type Action<K, V = void> = V extends void
  ? { type: K }
  : { type: K } & V;

export interface AnyObj {
  [key: string]: any;
}

export interface ObjectType<T> {
  [key: string]: T;
}

export enum Platforms {
  Android = 'android',
  iOS = 'ios',
  Web = 'web',
  Mac = 'macos',
  Windows = 'windows',
}

export type HeaderImageTypes = 'user' | 'logo' | 'mascot' | 'none';

export type ChangeEvent = React.ChangeEvent<{ value: unknown }>;

// export type MouseEvent = React.MouseEvent<HTMLDivElement, React.MouseEvent>;
export type MouseEvent = React.MouseEvent;
// export type EventHandler = React.MouseEventHandler<HTMLDivElement>;
export type EventHandler = React.MouseEvent<HTMLDivElement, React.MouseEvent>;
// export type MouseEvent = React.MouseEvent<any, React.MouseEvent>;

export type BaseDoc = {
  createdAt?: Date;
  updatedAt?: Date;
};

export type IdDoc = {
  id: string;
};

export type SubscriptionListener<T> = (data: T) => void;
export type Unsubscriber = () => void;

export type Subscription<T> = (
  listener: SubscriptionListener<T>,
  ...args: any
) => Unsubscriber;

export type OptionValue = string | number;
export type Timestamp = FirebaseFirestoreTypes.Timestamp;
export type FieldValue = FirebaseFirestoreTypes.FieldValue;

export type Edges = 'right' | 'bottom' | 'left' | 'top';

export type StringObj = {
  [key: string]: string;
};

export enum ScorecardResult {
  Win = 'win',
  Loss = 'loss',
  Tie = 'tie',
}

export type Directions =
  | 'vertical'
  | 'horizontal'
  | 'both'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right';

export type Sizes = 'small' | 'medium' | 'large';

export type ButtonVariants = 'bordered' | 'contained' | 'transparent';

export type Tab = {
  label: string;
  icon?: string;
  press?: () => void;
};

export type StatDisplayObj = {
  label: string;
  value: string | number;
  subValue?: string | number;
};

export interface Scorecard {
  readonly date: moment.Moment;
  readonly result?: ScorecardResult;
  readonly why?: string;
  readonly plays?: string[];

  readonly createdAt?: Date;
  isPersisted(): boolean;
}

export interface ScorecardPlay {
  id: string;
  name: string;
  icon: string;
  enabled: boolean;
}

export type PlaysObj = { [key: string]: ScorecardPlay };

export interface CombinedPlayStat extends ScorecardPlay {
  completed: number;
  winPercentage: number; // Convert from number to display
  lossPercentage: number;
  tiePercentage: number;
  completionPercentage: number;
}

export interface CombinedPlayObj {
  [key: string]: CombinedPlayStat;
}

export interface ScorecardDocument {
  result: string;
  date: string;
  createdAt: number;
  why?: string;
  plays?: string[];
  shared?: string;
}

export interface ArticlePage {
  title: string;
  body: string;
  imageUrl?: string;
  // next_btn: string;
}

// export interface Article {
//   rating: boolean;
//   pages: ArticlePage[];
// }

// export interface Articles {
//   [name: string]: Article;
// }

export type SurveyAnswers = {
  [sectionId: string]: string[];
};

enum Visibility {
  Public = 'public',
  Private = 'private',
}

export interface Team {
  id: string;
  name: string;
  mascot: any;
  visibility: Visibility;
  default?: boolean;
}

export type CustomClaims = {
  role: string;
  team: string | null;
  account: string;
};

export type HuddleType = {
  enabled: boolean;
  headline: string;
  dayOfWeek: number;
  hour: number;
  minute: number;
  group: string;
  articleId: string;
  url: string;
  version: string;
  build: number;
  forceUpgrade: boolean;
  iosUrl: string;
  upgradeText: string;
  upgradeForceTitle: string;
  upgradeForceBody: string;
};

// Tag is now deprecated
export type Tag = {
  id: string;
  text: string;
};

export enum Tags {
  BipolarBears = 'Bipolar Bears',
  Anxietees = 'Anxietees',
  FoggyDogs = 'Foggy Dogs',
  ADHBees = 'ADHBees',
  Affirmation = 'Affirmation',
  Community = 'Community',
  Motivation = 'Motivation',
  Education = 'Education',
  CoachesCorner = 'CoachesCorner',
  MhlNews = 'MHL News',
  Fundamentals = 'Fundamentals',
  Offense = 'Offense',
  Defense = 'Defense',
  Teamwork = 'Teamwork',
  Admin = 'Admin',
  Survey = 'Survey',
}

export const TagsArr = Object.values(Tags);

// export interface TagObj {
//   [tag in Object.values(TagsBase)]: Tag;
// }

export type IconTypes =
  | 'FontAwesome5'
  | 'AntDesign'
  | 'Entypo'
  | 'EvilIcons'
  | 'Feather'
  | 'FontAwesome'
  | 'Foundation'
  | 'Ionicons'
  | 'MaterialCommunityIcons'
  | 'MaterialIcons'
  | 'Octicons'
  | 'SimpleLineIcons'
  | 'Zocial';

export type Quote = {
  text: string;
  author?: string;
};

export interface QuoteDoc extends Quote {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export type QuestionTypes =
  | 'textarea'
  | 'recap'
  | 'single'
  | 'slider'
  | 'multiple'
  | 'cover'
  | 'rating'
  | 'page';

export type QuestionBase = {
  question: string;
  helper?: string | RawDraftContentState; // This is fine print text that explains the question
};

export interface ChoiceQuestion extends QuestionBase {
  options: string[];
  helper?: string;
}

export interface MultipleChoiceQuestion extends ChoiceQuestion {
  answer?: string[];
  type: 'multiple';
  helper?: string;
}

export interface SingleChoiceQuestion extends ChoiceQuestion {
  answer?: string;
  type: 'single';
  helper?: string;
}

export interface SliderQuestion extends QuestionBase {
  answer?: number;
  type: 'slider';
  helper?: string;
}

export interface RatingQuestion extends QuestionBase {
  answer?: number;
  type: 'rating';
  helper?: string;
}

export interface TextAreaQuestion extends QuestionBase {
  answer?: string;
  type: 'textarea';
  helper?: string;
}

export interface CoverQuestion extends QuestionBase {
  type: 'cover';
  helper?: string;
}

export interface PageQuestion extends QuestionBase {
  type: 'page';
  imageUrl?: string;
  helper?: string | RawDraftContentState;
}

export interface RecapQuestion extends QuestionBase {
  type: 'recap';
  helper?: string;
}

export type Question =
  | TextAreaQuestion
  | SliderQuestion
  | SingleChoiceQuestion
  | RatingQuestion
  | MultipleChoiceQuestion;

export type Step =
  | TextAreaQuestion
  | SliderQuestion
  | SingleChoiceQuestion
  | PageQuestion
  | CoverQuestion
  | RecapQuestion
  | RatingQuestion
  | MultipleChoiceQuestion;

export enum SharingTypes {
  Private = 'private',
  Coach = 'coach',
  Team = 'team',
}

export interface Reflection extends BaseDoc {
  questions: Step[];
  // id: string;
  // title: string;
}

// export interface Reflection extends ReflectionBase {
//   shared: SharingTypes;
// }

export type IconGroup = {
  title: string;
  icons: string[];
};

export enum CelebrationFilter {
  Win = 'win',
  Loss = 'loss',
  Tie = 'tie',
  WeeklyRecap = 'weeklyRecap',
}

export type CelebrationText = {
  headline: string;
  text?: string;
  type?: CelebrationFilter;
};

export interface ExerciseBase extends BaseDoc {
  id: string;
  type: ExerciseTypes;
  title: string;
  description?: string;
  icon: IconNames;
  tags?: Tags[];
  iconType?: IconTypes;
  sharingEnabled: boolean;
  ratingEnabled: boolean;
  celebration?: CelebrationText;
  action?: 'completeGameplan';
  data: { [key: string]: any };
}

export interface Exercise extends ExerciseBase {
  status: PublishStatus;
  ratingSum?: number;
  ratingsSubmitted?: number;
  ratingAverage?: number;
}

export type RatingUpdate = {
  ratingSum?: number | FieldValue;
  ratingsSubmitted?: number | FieldValue;
  ratingAverage?: number | FieldValue;
};

export type ArticleExerciseData = { id: string; title: string };

export interface ReflectionExercise extends Exercise {
  type: 'reflection';
  data: Reflection;
}

export interface ReflectionExerciseObj {
  [key: string]: ReflectionExercise;
}

export interface ArticleExercise extends Exercise {
  type: 'article';
  data: ArticleExerciseData;
}
export interface ExerciseRecordBase extends ExerciseBase {
  completed: boolean;
  date: string;
  week: number;
  completedAt: Date;
  rating?: number;
}

export interface ReflectionRecord extends ExerciseRecordBase {
  type: 'reflection';
  data: Reflection;
  shared: SharingTypes;
}

export interface ArticleRecord extends ExerciseRecordBase {
  type: 'article';
  data: ArticleExerciseData;
}

export type ExerciseRecord = ReflectionRecord | ArticleRecord;

export interface ExerciseRecords {
  [key: string]: ExerciseRecord;
}

export type AnswerOpt = string | string[] | number | number[];

export type Answer = {
  [key: string]: AnswerOpt;
};

export interface SurveyType {
  questions: Question[];
  answers: Answer;
}

export interface ReflectionState {
  questions: Step[];
  currentIdx: number;
}

export interface SurveyState {
  form: Answer;
  currentStep: number;
  totalSteps: number;
}

export interface ScorecardPlay {
  id: string;
  name: string;
  icon: string;
  enabled: boolean;
}

export enum SurveyTypes {
  WeeklyRecap = 'weeklyRecap',
  PostGameSurvey = 'postGameSurvey',
  DayOneSurvey = 'dayOneSurvey',
  Reflection = 'reflection',
}

export type RecapNew = {
  date: string;
  week: number;
  surveyId: string;
  answers: Answer;
  questions: Question[];
  createdAt: Date;
  updatedAt: Date;
  shared: 'private' | 'coach';
};

export interface Recap extends RecapNew {
  id: string;
}

export type Commenter = {
  id: string;
  name: string;
  avatar?: string;
};

export type Comment = {
  id?: string;
  user: Commenter;
  comment: string;
  updatedAt: string;
  createdAt?: string;
  hidden: boolean;
  articleId?: string;
};

export type Toggle = {
  id?: string;
  user: Commenter;
  isActive: boolean;
  type: 'completion' | 'like';
  updatedAt: string;
  createdAt?: string;
  hidden: boolean;
  articleId?: string;
};

export interface UserProfile {
  name?: string;
  bio?: string;
  avatar?: string;
  birthday?: string;
  utcOffset?: number;
}

export interface Time {
  hour: number;
  minute: number;
}

export interface Reminder {
  enabled: boolean;
  time: Time;
}

export type TogglePath = { collection: string; id: string; action: string };

export type CoachesNotePayload = {
  id: string;
  content: string;
  updatedAt: moment.Moment;
  userId: string;
};

export type CoachesNote = {
  id: string;
  coachId: string;
  coachName: string;
  content: string;
  updatedAt: moment.Moment;
  userId: string;
};

export type AssistType = 'comment' | 'cheer';
