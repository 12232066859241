import React, { useState, useEffect } from 'react';
import { List, Typography } from '@material-ui/core';
import { ArticleItemLink } from './';
import { Article } from 'types';
import { useRouteMatch } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'scroll',
    },
  }),
);

const sideNav = {
  height: 'calc(100vh - 247px)',
  fallbacks: [
    { height: '-moz-calc(100vh - 247px)' },
    { height: '-webkit-calc(100vh - 247px)' },
    { height: '-o-calc(100vh - 247px)' },
  ],
};

interface Props {
  articles: Article[] | null;
  selectArticle(player: Article): void;
}

const ArticleList: React.FC<Props> = ({ articles, selectArticle }) => {
  const classes = useStyles();
  let {
    // path,
    url,
  } = useRouteMatch();

  const [articleList, setArticleList] = useState<Article[] | null>([]);

  useEffect(() => {
    setArticleList(articles);
  }, [articles]);
  return (
    <List className={classes.root} style={sideNav}>
      {articleList && articleList.length > 0 ? (
        articleList.map((article, idx) => (
          <ArticleItemLink
            to={`${url}/${article.slug}`}
            article={article}
            selectArticle={selectArticle}
            key={idx + article.slug}
          />
        ))
      ) : (
        <Typography>No articles</Typography>
      )}
    </List>
  );
};

export default ArticleList;
