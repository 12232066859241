import React from 'react';
import { ApplicationError } from '../models/Errors';
import ModelError from '../models/ModelError';
import Paragraph from './Paragraph';

interface Props {
  error?: Error | string;
  center?: boolean;
}

const ErrorMessage: React.FC<Props> = ({
  error,
  center,
}): React.ReactElement | null => {
  const text =
    undefined || error === null
      ? null
      : error instanceof ModelError
      ? error.errors().join('\n')
      : error instanceof ApplicationError
      ? error.message
      : typeof error === 'string'
      ? error
      : !error
      ? null
      : 'An unknown error occurred';

  return error ? (
    <Paragraph
      fine
      slim
      style={{ textAlign: center ? 'center' : 'left' }}
      color="secondary">
      {text}
    </Paragraph>
  ) : null;
};

export default ErrorMessage;
