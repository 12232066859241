import BaseService from './BaseService';
import { GamesService } from './interfaces';
import { docForPlayer } from '../models/FirebasePlayer';
import GameCollection from '../models/GameCollection';
import Scorecard from '../models/Scorecard';
import moment from 'moment';

class Games extends BaseService implements GamesService {
  getPlayerGamesAndStats = async (
    userId: string,
    month: string,
  ): Promise<GameCollection> => {
    const scorecards: Scorecard[] = [];

    const query = docForPlayer(userId)
      .collection('seasons')
      .doc(month)
      .collection('scorecards');

    const snapshot = await query.get();

    snapshot.docs.forEach(doc => {
      if (doc.data()) {
        scorecards.push(
          new Scorecard(
            moment(doc.data().date),
            doc.data().result,
            doc.data().why,
            new Date(doc.data().createdAt),
            doc.data().plays,
            doc.id,
          ),
        );
      } else {
        return null;
      }
    });

    let endOfMonth = moment(month).endOf('month');
    const today = moment();
    if (endOfMonth > today) {
      endOfMonth = today;
    }
    const startOfMonth = moment(month).startOf('month');

    const newGames: GameCollection = await new GameCollection(
      startOfMonth,
      endOfMonth,
      scorecards,
    );

    return newGames;
  };
}

export default Games;
