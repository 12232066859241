import React, { useState } from 'react';
import {
  Button,
  ErrorMessage,
  Headline,
  TextField,
  Box,
  Paragraph,
  Page,
  Card,
  Logo,
} from '../components';
import { CardContent, Collapse, Typography } from '@material-ui/core';
import { ForgotPasswordService } from '../services';
import { useCurrentUser, useHistory } from '../hooks';

const ForgotPasswordScreen: React.FC = () => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isReset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePressForgotPassword = async (): Promise<void> => {
    if (error) {
      setError('');
    }
    setLoading(true);
    const service = new ForgotPasswordService();
    try {
      await service.perform(email);
      setReset(true);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
    return Promise.resolve();
  };

  return (
    <Page disableGutters maxWidth="xs">
      <Box>
        <Card padding>
          <CardContent>
            <Headline align="center" variant="h5" gutterBottom>
              Forgot password?
            </Headline>
            <ErrorMessage center error={error} />
            {isReset ? (
              <Paragraph>Check your email for a reset password link.</Paragraph>
            ) : (
              <>
                <Box mb={3}>
                  <TextField
                    label="Email"
                    value={email}
                    autoCapitalize="none"
                    autoFocus
                    changeState={setEmail}
                    onKeyPress={handlePressForgotPassword}
                  />
                </Box>

                <Box mb={3}>
                  <Button
                    loading={loading}
                    onClick={handlePressForgotPassword}
                    text="Reset Password"
                    fullWidth
                  />
                </Box>
                <Button
                  onClick={() => history.push('/login')}
                  variant="text"
                  size="small"
                  fullWidth
                  style={{ color: '#999' }}
                  text="Back to Login"
                />
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

export default ForgotPasswordScreen;
