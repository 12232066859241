import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Card,
  Icon,
  Box,
  Page,
  ErrorMessage,
  ScrollToTop,
  ToggleButton,
  Headline,
  RichEditor,
} from '../../components';
import { IconButton, Divider } from '@material-ui/core';
import {
  useContainer,
  useNavigation,
  useMutation,
  useLocation,
} from '../../hooks';
import {
  QuestionTypes,
  PageQuestion,
  CoverQuestion,
  TextAreaQuestion,
  SingleChoiceQuestion,
  MultipleChoiceQuestion,
  PublishStatus,
  ReflectionExercise,
  FirestoreServiceType,
  IconNames,
  Collections,
} from 'types';
import { slugify } from '../helpers';
import { PublishStatusSelect, IconSelect, TagSelect } from '../forms';

const baseQuestion = {
  question: '',
};

const defaultPage: PageQuestion = {
  ...baseQuestion,
  imageUrl: '',
  type: 'page',
};

const defaultCover: CoverQuestion = {
  ...baseQuestion,
  type: 'cover',
};

const defaultTextarea: TextAreaQuestion = {
  ...baseQuestion,
  type: 'textarea',
};

const defaultSingle: SingleChoiceQuestion = {
  ...baseQuestion,
  type: 'single',
  options: ['a', 'b', 'c'],
};

const defaultMultiple: MultipleChoiceQuestion = {
  ...baseQuestion,
  type: 'multiple',
  options: ['a', 'b', 'c'],
};

const initialReflection: ReflectionExercise = {
  data: {
    questions: [
      { ...defaultCover },
      { ...defaultPage },
      { ...defaultTextarea },
    ],
  },
  id: '',
  title: '',
  type: 'reflection',
  tags: [],
  icon: IconNames.Brain,
  status: PublishStatus.Draft,
  sharingEnabled: false,
  ratingEnabled: false,
};

// type Props = {
//   editing?: ReflectionExercise;
// };

// Functions for creation and editing articles.
const ExerciseCreateScreen: React.FC = () => {
  // const editing = {};
  const initialState = { ...initialReflection };
  const { state: params } = useLocation<{
    editing: ReflectionExercise | undefined;
  }>();

  const editing = params?.editing;

  const [error, setError] = useState<Error | string>('');
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();

  // if editing article, load that state, end use initial
  const [state, setState] = useState<ReflectionExercise>(
    !!editing ? editing : { ...initialState },
  );

  const questions = state.data.questions;

  // Transform GamePlan name into slugified id
  useEffect(() => {
    if (!editing) {
      setState({ ...state, id: slugify(state.title) });
    }
  }, [state.title]);

  // Update state when feature prop changes - feature prop is loading when editing
  useEffect(() => {
    if (editing) {
      setState(editing);
    }
  }, [editing]);

  // If not editing, reset state to original. Without this, it was keeping state from previously created exercise when you went to create a 2nd new one.
  // useEffect(() => {
  //   if (!editing) {
  //     resetState();
  //   }
  // }, []);

  const container = useContainer();
  const service = container.getInstance<FirestoreServiceType>(
    'firestoreService',
  );

  //   const query = service.addOrUpdateDoc<ReflectionExercise>(state);

  const { mutate, loading: saving, error: errorSaving } = useMutation(() =>
    service.addOrUpdateDoc<ReflectionExercise>(Collections.Exercises, state),
  );

  const resetState = () => {
    console.log('INISITAL STATE', { ...initialState });
    setState({ ...initialState });
    setError('');
    setLoading(false);
  };

  // useEffect(() => {
  //   if (!editing) {
  //     resetState();
  //   }
  // }, []);

  const handleSave = async () => {
    const handleMutate = (result: 'success' | 'error') => {
      if (result === 'success') {
        setError('Exercise saved');
      } else {
        setError('Exercise error');
      }
      window.scrollTo(0, 0);
    };

    mutate(
      () => handleMutate('success'),
      () => handleMutate('error'),
    );
  };

  const moveExercise = (
    // dayIdx: number,
    old_index: number,
    new_index: number,
  ) => {
    // Copy state
    const newState = { ...state };
    const theExercises = newState.data.questions;

    // Rearrange their index
    theExercises.splice(new_index, 0, theExercises.splice(old_index, 1)[0]);

    setState(newState); //{ ...state, exercisesByDay[dayIdx]: newState });
  };

  const removeExercise = (idx: number) => {
    // Copy state articles
    const newState = { ...state };
    const theExercises = newState.data.questions;

    // Remove the idx article from the array
    theExercises.splice(idx, 1);

    // Update state with new Exercise arr after removal
    setState(newState);
  };

  const updateExercise = (
    // dayIdx: number,
    idx: number,
    type: QuestionTypes,
    value: string,
  ) => {
    const newState = { ...state };

    setState(newState);
  };

  const addExercise = () => {
    const newState = { ...state };

    newState.data.questions.push({ ...defaultTextarea });
    setState({ ...state });
  };

  const toggleType = (value: QuestionTypes, idx: number) => {
    const newState = { ...state };
    newState.data.questions[idx].type = value;

    if (value === 'multiple') {
      newState.data.questions[idx] = { ...defaultMultiple };
    }
    if (value === 'single') {
      newState.data.questions[idx] = { ...defaultSingle };
    }

    setState(newState);
  };

  return (
    <Page fullPage maxWidth="xl" error={error}>
      <ScrollToTop state={error} />
      <Button
        text="‹ Go Back"
        variant="text"
        fullWidth
        onPress={() => navigation.navigate('/exercises')}
      />
      <Button
        text="Reset State"
        variant="text"
        fullWidth
        onPress={resetState}
      />
      <Headline gutterBottom>{editing ? 'Edit' : 'Create'} Exercise</Headline>
      <ErrorMessage error={errorSaving} />

      <Card padding>
        <TextField
          id="title"
          label="Name"
          fullWidth
          value={state.title}
          onChange={event => {
            setState({ ...state, title: event.target.value });
          }}
          variant="filled"
        />

        <TextField
          id="id"
          label="Id aka Slug"
          fullWidth
          value={state.id}
          onChange={event => {
            setState({ ...state, id: event.target.value });
          }}
          variant="filled"
        />

        <TextField
          id="description"
          label="Description"
          fullWidth
          value={state.description}
          onChange={event => {
            setState({ ...state, description: event.target.value });
          }}
          variant="filled"
        />

        <PublishStatusSelect
          value={state.status}
          onChange={e => setState({ ...state, status: e })}
        />

        <TagSelect
          value={state.tags}
          onChange={e => setState({ ...state, tags: e })}
        />

        <IconSelect
          value={state.icon}
          onChange={e => setState({ ...state, icon: e })}
        />

        <ToggleButton
          type="boolean"
          label="Sharing enabled?"
          value={state.sharingEnabled}
          setValue={bool => setState({ ...state, sharingEnabled: bool })}
        />
        <ToggleButton
          type="boolean"
          label="Rating enabled?"
          value={state.ratingEnabled}
          setValue={bool => setState({ ...state, ratingEnabled: bool })}
        />
      </Card>

      <Box mt={5}>
        <Headline gutterBottom>Exercises</Headline>
      </Box>

      <Card padding>
        {questions.map((exercise, idx) => {
          return (
            <div key={idx}>
              <Box row alignItems="flex-start">
                <Box flex={2}>
                  <Headline>#{idx + 1}</Headline>
                  <Box row>
                    <IconButton
                      disabled={idx === questions.length - 1}
                      size="small"
                      onClick={() => moveExercise(idx, idx + 1)}>
                      <Icon name={IconNames.ChevronDown} />
                    </IconButton>
                    <IconButton
                      disabled={idx === 0}
                      size="small"
                      onClick={() => moveExercise(idx, idx - 1)}>
                      <Icon name={IconNames.ChevronUp} />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => removeExercise(idx)}>
                      <Icon name={IconNames.Close} />
                    </IconButton>
                  </Box>
                </Box>
                <Box flex={17}>
                  <ToggleButton
                    buttons={[
                      `cover`,
                      `page`,
                      `textarea`,
                      `slider`,
                      `multiple`,
                      `single`,
                      `recap`,
                    ]}
                    value={`${exercise.type}`}
                    setValue={(val: QuestionTypes) => toggleType(val, idx)}
                  />

                  <TextField
                    gutterTop={1}
                    size="small"
                    id={`${idx}-title`}
                    label="Question"
                    fullWidth
                    value={exercise.question}
                    onChange={event => {
                      const newState = { ...state };
                      exercise.question = event.target.value;
                      // exercise.id = slugify(event.target.value);
                      setState(newState);
                    }}
                    variant="filled"
                  />

                  {exercise.type === 'page' ? (
                    <>
                      <TextField
                        id="page-image"
                        label="Image URL"
                        fullWidth
                        value={exercise.imageUrl}
                        onChange={event => {
                          const newState = { ...state };
                          exercise.imageUrl = event.target.value;
                          setState(newState);
                        }}
                        variant="filled"
                      />
                      <img
                        src={
                          exercise.imageUrl
                            ? exercise.imageUrl
                            : 'https://apps4rent.com/support/wp-content/plugins/accelerated-mobile-pages/images/SD-default-image.png'
                        }
                        style={{ width: 600, height: 315 }}
                        alt="Page Image Preview"
                      />

                      <RichEditor
                        state={exercise.helper ? exercise.helper : ''}
                        setState={event => {
                          const newState = { ...state };
                          // @ts-ignore
                          exercise.helper = event;
                          setState(newState);
                        }}
                      />
                    </>
                  ) : (
                    <TextField
                      gutterTop={1}
                      size="small"
                      id={`${idx}-helper`}
                      label="Helper Text"
                      fullWidth
                      value={exercise.helper}
                      onChange={event => {
                        const newState = { ...state };
                        exercise.helper = event.target.value;
                        setState(newState);
                      }}
                      variant="filled"
                    />
                  )}

                  {(exercise.type === 'multiple' ||
                    exercise.type === 'single') && (
                    <>
                      <Headline small>Options</Headline>
                      {exercise.options &&
                        exercise.options.map((option, idx2) => (
                          <TextField
                            key={idx2}
                            gutterTop={1}
                            size="small"
                            id={`option-${idx2}`}
                            label={`option-${idx2}`}
                            fullWidth
                            value={option}
                            onChange={event => {
                              const newState = { ...state };
                              exercise.options[idx2] = event.target.value;
                              setState(newState);
                            }}
                            variant="filled"
                          />
                        ))}

                      <Box row mt={1} mb={2}>
                        <Button
                          text="Remove Option"
                          size="small"
                          variant="text"
                          onClick={() => {
                            const newState = { ...state };
                            exercise.options.splice(
                              exercise.options.length - 1,
                              1,
                            );
                            setState(newState);
                          }}
                        />
                        <Button
                          text="Add Options"
                          size="small"
                          variant="text"
                          onClick={() => {
                            const newState = { ...state };
                            exercise.options = [...exercise.options, ''];
                            setState(newState);
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              {idx + 1 !== questions.length && (
                <Box mt={4} mb={3}>
                  <Divider />
                </Box>
              )}
            </div>
          );
        })}
        <Box my={2}>
          <Button
            size="small"
            text="Add Exercise"
            fullWidth
            variant="text"
            onPress={addExercise}
          />
        </Box>
      </Card>

      <Card padding>
        <Headline>Celebration</Headline>

        <TextField
          id="celebration-headline"
          label="Celebration Headline"
          fullWidth
          value={state.celebration?.headline}
          onChange={event => {
            setState({
              ...state,
              celebration: {
                ...state.celebration,
                headline: event.target.value,
              },
            });
          }}
          variant="filled"
        />

        <TextField
          id="celebration-text"
          label="Celebration Text"
          fullWidth
          disabled={!state.celebration?.headline}
          value={state.celebration?.text}
          onChange={event => {
            setState({
              ...state,
              // @ts-ignore - Disabled unless admin enters a celebration headline
              celebration: {
                ...state.celebration,
                text: event.target.value,
              },
            });
          }}
          variant="filled"
        />
      </Card>

      {/* <Box mb={2} mt={5}>
        <Headline variant="h6">Final Exercise</Headline>

        <Card padding>
          <Box row>
            <Icon
              name="medal"
              style={{ marginRight: '20px', fontSize: '36px' }}
            />

            <Box flex={1}>
              <TextField
                id="final-exercise-name"
                label="Name"
                fullWidth
                value={state.finalExercise ? state.finalExercise.name : ''}
                onChange={event => {
                  const newState = { ...state };
                  if (newState.finalExercise) {
                    newState.finalExercise.name = event.target.value;
                  }
                  setState(newState);
                }}
                variant="filled"
              />

              <TextField
                id="final-exercise-next-btn"
                label="Next Button Text"
                fullWidth
                value={state.finalExercise ? state.finalExercise.next_btn : ''}
                onChange={event => {
                  const newState = { ...state };
                  if (newState.finalExercise) {
                    newState.finalExercise.next_btn = event.target.value;
                  }
                  setState(newState);
                }}
                variant="filled"
              />
            </Box>
          </Box>
        </Card>
      </Box> */}

      <Box mb={4}>
        <Button text="Save" fullWidth disabled={saving} onClick={handleSave} />
      </Box>
    </Page>
  );
};

export default ExerciseCreateScreen;
