import * as stream from 'getstream';
import Player from '../models/Player';

type Params = {
  currentPlayer: Player;
};

export default function buildStreamClient({
  currentPlayer,
}: Params): stream.StreamClient | void {
  const { streamToken = null } = currentPlayer;
  if (
    process.env.REACT_APP_STREAM_API_KEY &&
    process.env.REACT_APP_STREAM_APP_ID
  ) {
    return stream.connect(
      process.env.REACT_APP_STREAM_API_KEY,
      streamToken,
      process.env.REACT_APP_STREAM_APP_ID,
    );
  }
}
