import firebase from 'src/base';
import { PublishStatus } from 'types';
import { Query } from './interfaces';

export const publishedArticlesQuery = firebase
  .firestore()
  .collection('articles')
  .where('status', '!=', PublishStatus.Deleted)
  .orderBy('status');

export const queryCollection = (collection: string): Query => {
  return firebase.firestore().collection(collection);
};
