import { StreamClient } from 'getstream';
import Player from '../models/Player';
import FirebaseFirestoreTypes from '@firebase/firestore-types';
import { Logger } from './LoggerService';
import { Tracker } from './TrackerService';
import { FirestoreServiceType } from 'types';
// import UpdatePlayerService from './UpdatePlayerService';

export type BaseServiceContructorOptions = {
  streamClient: StreamClient;
  currentPlayer: Player;
  logger: Logger;
  tracker: Tracker;
  // updatePlayerService: UpdatePlayerService;
  firestore: FirebaseFirestoreTypes.FirebaseFirestore;
  firestoreService: FirestoreServiceType;
};

class BaseService {
  protected streamClient: StreamClient;
  protected currentPlayer: Player;
  protected logger: Logger;
  protected tracker: Tracker;
  // protected updatePlayerService: UpdatePlayerService;
  protected firestore: FirebaseFirestoreTypes.FirebaseFirestore;
  protected firestoreService: FirestoreServiceType;
  constructor({
    streamClient,
    currentPlayer,
    logger,
    tracker,
    // updatePlayerService,
    firestore,
    firestoreService,
  }: BaseServiceContructorOptions) {
    this.streamClient = streamClient;
    this.currentPlayer = currentPlayer;
    this.logger = logger;
    this.tracker = tracker;
    // this.updatePlayerService = updatePlayerService;
    this.firestore = firestore;
    this.firestoreService = firestoreService;
  }
}

export default BaseService;
