import React, { useState } from 'react';
import { ReactionResponse } from 'getstream';
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CommentBoxComponent from './CommentBoxComponent';
import CheerReactionComponent from './CheerReactionComponent';
import { useCurrentUser } from '../../hooks';
import moment from 'moment';
import { ChatBubbleOutline as ChatBubbleOutlineIcon } from '@material-ui/icons';
import { theme } from '../../assets/styles/theme';
import UserTitle from './UserTitle';

interface CommentData {
  text: string;
}

interface Props {
  comment: ReactionResponse;
  onDeleteComment(reaction: ReactionResponse): Promise<void>;
  commentEnabled?: boolean;
  onAddCommentReaction: (
    kind: 'comment' | 'cheer',
    comment: ReactionResponse,
    text?: string,
    onSuccess?: () => void,
  ) => Promise<void>;
  onDeleteCommentReaction: (
    commentParent: ReactionResponse,
    targetReaction: ReactionResponse,
  ) => Promise<void>;
  containerStyle?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
    },
    type: {
      flex: 1,
    },
    avatar: {
      backgroundColor: theme.palette.primary.light,
    },
    text: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textAlignVertical: 'center',
    },
    badge: {
      marginLeft: 4,
      marginRight: 4,
      backgroundColor: theme.palette.primary.light,
    },
    badgeText: {
      color: 'white',
      fontSize: 12,
    },
    content: {
      paddingTop: 0,
      paddingBottom: theme.spacing(1),
    },
    body: {
      paddingTop: 0,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
);

const CommentItemComponent: React.FC<Props> = ({
  comment,
  onDeleteComment,
  commentEnabled = false,
  onAddCommentReaction,
  onDeleteCommentReaction,
  containerStyle = {},
}) => {
  const classes = useStyles();
  const { user: currentUser } = useCurrentUser();
  const [showCommentBox, toggleCommentBox] = useState<boolean>(false);
  const handleCommentToggle = () => {
    toggleCommentBox(!showCommentBox);
  };
  const commentData = comment.data as CommentData;
  const isOwner = comment.user_id === currentUser?.id;

  // Extra Actions Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handlePressActions = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteComment = async () => {
    await onDeleteComment(comment);
  };

  const subComments =
    comment.latest_children && comment.latest_children.comment
      ? comment.latest_children.comment
      : [];

  return (
    <div style={containerStyle}>
      <CardHeader
        avatar={
          <Avatar
            aria-label={comment.user.data.name}
            className={classes.avatar}
            src={comment.user.data.avatar}
          />
        }
        action={
          isOwner && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <IconButton
                aria-controls="settings"
                aria-haspopup="true"
                onClick={handlePressActions}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="settings"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={handleDeleteComment}>
                  Delete Comment
                </MenuItem>
              </Menu>
            </div>
          )
        }
        title={<UserTitle uid={comment.user.id} user={comment.user.data} />}
        subheader={moment(comment.created_at).fromNow()}
      />
      <CardContent className={classes.content}>
        {typeof commentData.text === 'string' && (
          <Typography variant="body2" color="textSecondary" component="p">
            {commentData.text}
          </Typography>
        )}
      </CardContent>
      <CardActions disableSpacing style={{ justifyContent: 'space-evenly' }}>
        <CheerReactionComponent
          reaction={comment}
          size={22}
          onPress={() => {
            return onAddCommentReaction('cheer', comment);
          }}
        />
        {commentEnabled && (
          <Button
            startIcon={<ChatBubbleOutlineIcon />}
            onClick={handleCommentToggle}
            aria-label="toggle comment box">
            {comment.children_counts &&
              comment.children_counts.comment &&
              comment.children_counts.comment}{' '}
            Reply
          </Button>
        )}
      </CardActions>

      <CommentBoxComponent
        onAddComment={(text): Promise<void> => {
          return onAddCommentReaction('comment', comment, text);
        }}
        showCommentBox={showCommentBox}
      />

      {
        <>
          <Divider />
          {subComments.map((subComment: ReactionResponse) => (
            <CommentItemComponent
              // {...props}
              onAddCommentReaction={onAddCommentReaction}
              onDeleteCommentReaction={onDeleteCommentReaction}
              key={subComment.id}
              containerStyle={{
                paddingRight: 0,
                paddingLeft: 24,
                paddingTop: 0,
                paddingBottom: 0,
                borderLeftWidth: '5px',
                borderLeftColor: theme.palette.primary.main,
              }}
              comment={subComment}
              commentEnabled={false}
              onDeleteComment={(): Promise<void> =>
                onDeleteCommentReaction(comment, subComment)
              }
            />
          ))}
        </>
      }
    </div>
  );
};

export default CommentItemComponent;
