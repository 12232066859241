import moment from 'moment';

import Game from './Game';
import Scorecard from './Scorecard';
import Stats from './Stats';

class GameCollection {
  public startDate: moment.Moment;
  public endDate: moment.Moment;
  public data: Game[];
  public stats: Stats;
  public completionStreak: number;

  constructor(
    startDate: moment.Moment,
    endDate: moment.Moment,
    scorecards: Scorecard[],
  ) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.stats = new Stats(startDate, endDate);
    this.completionStreak = 0;

    const daysInSeason = endDate.diff(startDate, 'days') + 1;
    const games = new Array(daysInSeason);

    for (const scorecard of scorecards) {
      if (scorecard.date < startDate || scorecard.date > endDate) {
        continue;
      }

      const index = this.endDate.diff(scorecard.date, 'days');
      games[index] = new Game(scorecard.date, scorecard);
    }

    let activeStreak = true;
    for (let i = daysInSeason; i > 0; i--) {
      const index = daysInSeason - i;

      if (!games[index]) {
        const date = moment(this.endDate).subtract(index, 'days');
        games[index] = new Game(date);
      }

      // Check if the game is before today – was issue where end date wasn't being reset to today, so comp streak would be 0 since end date was 2 weeks in future
      const isBeforeToday = moment(games[index].date.toDate()).isBefore(
        undefined,
        'day',
      );

      if (activeStreak && games[index].scorecard) {
        this.completionStreak++;
      } else if (isBeforeToday) {
        activeStreak = false;
      }

      this.stats.record(games[index]);
    }

    this.data = games;
  }
}

export default GameCollection;
