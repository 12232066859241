import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import {
  AnalyticsServiceType,
  Week,
  PlatformStat,
  SubsStat,
  Timestamp,
  FbUserPlan,
  TotalsMap,
  Platforms,
  UserPlan,
  PlaysObj,
  TotalStats,
  Player,
  Collections,
  SubscriptionListener,
  Unsubscriber,
  Subscription,
} from 'types';
import { convertToTimestamp, convertTimestamp, isPlanActive } from 'common';
import moment from 'moment';
import { ApplicationError } from '../models/Errors';
import BaseService from './BaseService';

export const fireFbEvent = (event: string, value?: any) => {
  ReactPixel.track(event, value ? value : {});
};

export const fireGaEvent = (
  category: string,
  action: string,
  label?: string,
) => {
  ReactGA.event({
    category,
    action,
    label: label ? label : undefined,
  });
};

class AnalyticsService extends BaseService implements AnalyticsServiceType {
  private COLLECTION = this.firestore.collection('analytics');
  public totalsDoc = this.COLLECTION.doc('total');

  public seasonQuery = this.COLLECTION.doc('season')
    .collection('allSeasons')
    .orderBy('id', 'desc');

  public weeklyQuery = this.COLLECTION.doc('weekly')
    .collection('weeks')
    .orderBy('id', 'desc');

  private dateFormat = 'YYYY-MM-DD';
  private defaultPlatformStat: PlatformStat = {
    android: 0,
    ios: 0,
    web: 0,
    total: 0,
  };

  private defaultSubsStat: SubsStat = {
    ...this.defaultPlatformStat,
    promoCode: 0,
  };

  // Exclude test users
  private shouldExcludeFromData = (email?: string): boolean => {
    return !email ? false : email.includes('test');
  };

  // Get Sunday date string
  private getSundayString = (createdAt: Timestamp): string => {
    const createdAtMoment = moment(createdAt.toDate());
    const sunday = createdAtMoment.isoWeekday(7).format(this.dateFormat);
    return sunday;
  };

  private getSundayFromUnix = (unix: number): string => {
    const createdAtMoment = moment.unix(unix);
    const sunday = createdAtMoment.isoWeekday(7).format(this.dateFormat);
    return sunday;
  };

  private shouldIncrement = (condition?: boolean) => {
    return condition === undefined ? true : condition;
  };

  private incrementSubs = (
    statObj: SubsStat,
    type: 'iosIap' | 'android' | 'stripe' | 'promoCode',
    shouldIncrement?: boolean,
  ) => {
    // Increment if shouldIncrement is undefined or false
    const willIncrement = this.shouldIncrement(shouldIncrement);

    if (willIncrement) {
      // Increment stat total
      statObj.total++;

      // Increment New Subscription Platforms
      if (type === 'iosIap') {
        statObj.ios++;
      }
      if (type === 'android') {
        statObj.android++;
      }
      if (type === 'stripe') {
        statObj.web++;
      }
      if (type === 'promoCode') {
        statObj.promoCode++;
      }
    }
  };

  private incrementPlatform = (
    statObj: PlatformStat,
    platform: Platforms | undefined,
    shouldIncrement?: boolean,
  ) => {
    // Increment if shouldIncrement is undefined or false
    const willIncrement = this.shouldIncrement(shouldIncrement);

    // Create PlatformStat object if it doesn't already exist
    if (!statObj) {
      statObj = { ...this.defaultPlatformStat };
    }

    if (willIncrement) {
      // Increment Totals
      statObj.total++;

      // Increment Platforms
      if (platform) {
        if (platform === Platforms.iOS) {
          statObj.ios++;
        }
        if (platform === Platforms.Android) {
          statObj.android++;
        }
        if (platform === Platforms.Web) {
          statObj.web++;
        }
      }
    }
  };

  // Somewhat broken bc it can't go back in time
  // Show only players that stopped being active that week if re-run after that week has occured
  private getActivePlayers = async (
    startDate: string,
    endDate: string,
  ): Promise<PlatformStat> => {
    const active: PlatformStat = { ...this.defaultSubsStat };

    // Count all user signups that were created between dates
    try {
      const collection = this.firestore
        .collection('players')
        .where('latestScorecardOn', '>=', startDate)
        .where('latestScorecardOn', '<=', endDate);

      await collection.get().then(snapshots =>
        snapshots.docs.forEach(doc => {
          const data = doc.data() as Player;

          if (data) {
            // Only increment is not a test user
            if (!this.shouldExcludeFromData(data.email)) {
              // Early account didn't have created_at field it seems
              this.incrementPlatform(active, Platforms.iOS); //data.platform);
            }
          }
          return Promise.resolve();
        }),
      );
      return active;
    } catch (error) {
      this.logger.error(`Error getting weekly signup analytics - ${error}`);
      return Promise.reject();
    }
  };

  private getSignups = async (
    startDate: Date,
    endDate: Date,
  ): Promise<PlatformStat> => {
    const signups: PlatformStat = { ...this.defaultSubsStat };

    // Count all user signups that were created between dates
    try {
      const collection = this.firestore
        .collection('players')
        .where('createdAt', '>', startDate)
        .where('createdAt', '<', endDate);

      await collection.get().then(snapshots =>
        snapshots.docs.forEach(doc => {
          const data = doc.data() as Player;

          if (!this.shouldExcludeFromData(data.email)) {
            // Early account didn't have createdAt field it seems
            this.incrementPlatform(signups, Platforms.iOS); //data.platform);
          }
          return Promise.resolve();
        }),
      );
      return signups;
    } catch (error) {
      this.logger.error(`Error getting weekly signup analytics - ${error}`);
      return Promise.reject();
    }
  };

  private getSubStats = async (
    startDate: Date,
    endDate: Date,
  ): Promise<{
    newSubs: SubsStat;
    cancellations: SubsStat;
  }> => {
    const newSubs: SubsStat = { ...this.defaultSubsStat };
    const cancellations: SubsStat = { ...this.defaultSubsStat };

    try {
      // Can't do where query by subproperty
      // So query for all users with a plan - inefficient but no other way
      const collection = this.firestore.collection('players').orderBy('plan');

      await collection.get().then(snapshots =>
        snapshots.docs.forEach(doc => {
          const data = doc.data();
          // Early account didn't have created_at field it seems

          if (!!data.plan) {
            const plan = data.plan as FbUserPlan;
            const startTimestamp = convertToTimestamp(startDate);

            // Only increment new subcribers if createdAt field is within the range
            const shouldIncrement =
              !!plan.createdAt &&
              convertTimestamp(plan.createdAt).toDate() < endDate &&
              convertTimestamp(plan.createdAt).toDate() > startDate;

            // console.log(
            //   'SHOULD INCREMENT',
            //   data.email,
            //   shouldIncrement,
            //   plan.createdAt,
            //   convertTimestamp(plan.createdAt).toDate(),
            // );

            this.incrementSubs(newSubs, plan.type, shouldIncrement);

            // Only increment cancelled subcribers if cancelAtUnix matches startTimestamp
            let shouldIncrementCancelled =
              !!plan.canceledAtUnix &&
              this.getSundayFromUnix(plan.canceledAtUnix) ===
                this.getSundayString(startTimestamp);

            if (!plan.canceledAtUnix && plan.type === 'stripe') {
              if (plan.nextRenewalDate) {
                shouldIncrementCancelled =
                  moment(startDate) >= moment(plan.nextRenewalDate);
              } else {
                // Typo fixed on 7/27/21 where on start subs it logged renewal with type
                // Can remove on 8/27/21
                // Only applies to new users that haven't been subscribed for month than a month
                shouldIncrementCancelled =
                  // @ts-ignore
                  moment(startDate) >= moment(plan.nextRenewelDate);
              }
            }

            this.incrementSubs(
              cancellations,
              plan.type,
              shouldIncrementCancelled,
            );
          }
          return Promise.resolve();
        }),
      );
    } catch (error) {
      this.logger.error(`Error getting weekly signup analytics - ${error}`);
      return Promise.reject();
    }

    return { newSubs, cancellations };
  };

  public addWeek = async (weekEndDate: string): Promise<void> => {
    // dateStr passed in is the final day of the previous week
    // So add 8 days so that the end timestamp is at midnight of day after end date for following week
    // And add 1 days so the start timestamp is at midnight of the proper startDate

    const docMoment = moment(weekEndDate);
    const docId = docMoment.format(this.dateFormat);
    const endMoment = moment(weekEndDate).add(1, 'days');
    const startMoment = moment(weekEndDate).subtract(6, 'days');
    const endDate = endMoment.toDate();
    const startDate = startMoment.toDate();

    try {
      // const { plays, completions } = await this.getPlays(startDate, endDate);
      const signups = await this.getSignups(startDate, endDate);
      const activePlayers = await this.getActivePlayers(
        startMoment.format(this.dateFormat),
        endMoment.subtract(1, 'day').format(this.dateFormat),
      );
      const { newSubs, cancellations } = await this.getSubStats(
        startDate,
        endDate,
      );

      const thisWeek: Omit<Week, 'updatedAt'> = {
        id: docId,
        year: docMoment.year(),
        isoWeek: docMoment.isoWeek(),
        startDate: startMoment.format(this.dateFormat),
        endDate: docId,
        // plays: plays ? plays : { ...this.defaultPlatformStat },
        // completions: completions
        //   ? completions
        //   : { ...this.defaultPlatformStat },
        // favs: favorites,
        signups,
        activePlayers,
        newSubs,
        cancellations,
      };

      const collRef = this.firestore.collection('analytics/weekly/weeks');
      this.firestoreService.addOrUpdateDoc(collRef, thisWeek);
    } catch (error) {
      console.log("Error getting new week's stats", error);
      return Promise.reject(`Error getting new weeks stats - ${error}`);
    }
  };

  public addSeason = async (month: string): Promise<void> => {
    // dateStr passed in is the final day of the previous week
    // So add 8 days so that the end timestamp is at midnight of day after end date for following week
    // And add 1 days so the start timestamp is at midnight of the proper startDate

    const docMoment = moment(month);
    const docId = docMoment.format('YYYY-MM');
    const endMoment = moment(month).endOf('month');
    const startMoment = moment(month).startOf('month');
    const endDate = endMoment.toDate();
    const startDate = startMoment.toDate();

    try {
      // const { plays, completions } = await this.getPlays(startDate, endDate);
      const signups = await this.getSignups(startDate, endDate);
      const activePlayers = await this.getActivePlayers(
        startMoment.format(this.dateFormat),
        endMoment.format(this.dateFormat),
      );
      const { newSubs, cancellations } = await this.getSubStats(
        startDate,
        endDate,
      );

      const thisSeason: Omit<Week, 'isoWeek' | 'updatedAt'> = {
        id: docId,
        year: docMoment.year(),
        startDate: startMoment.format(this.dateFormat),
        endDate: docId,
        signups,
        activePlayers,
        newSubs,
        cancellations,
      };

      const collRef = this.firestore.collection('analytics/season/allSeasons');
      this.firestoreService.addOrUpdateDoc(collRef, thisSeason);
    } catch (error) {
      return Promise.reject(`Error getting new weeks stats - ${error}`);
    }
  };

  public updateTotals = async (): Promise<void> => {
    const collection = this.firestore.collection('players');
    let users = 0;
    let hasFinishedOnboarding = 0;
    let completedScorecard = 0;
    let completed3Scorecards = 0;
    let completed7Scorecards = 0;
    let completed28Scorecards = 0;
    const activeSubs = this.defaultSubsStat;
    try {
      await collection.get().then(snapshots =>
        snapshots.docs.forEach(doc => {
          const data = doc.data() as Player;

          // Increment total users
          if (data && !this.shouldExcludeFromData(data.email)) {
            users++;

            if (data.hasFinishedOnboarding) {
              hasFinishedOnboarding++;
            }

            if (data.scorecardsCount > 0) {
              completedScorecard++;
            }

            if (data.scorecardsCount >= 3) {
              completed3Scorecards++;
            }

            if (data.scorecardsCount >= 7) {
              completed7Scorecards++;
            }

            if (data.scorecardsCount >= 28) {
              completed28Scorecards++;
            }

            // Increment ActiveSubs if plan exists and is active
            if (
              !!data.plan &&
              isPlanActive(data.plan) &&
              !data.plan.canceledAtUnix // Exclude account that have cancelled
            ) {
              const plan = data.plan as UserPlan;
              this.incrementSubs(activeSubs, plan.type);
            }
          }
        }),
      );

      await this.firestoreService.addOrUpdateDoc(Collections.Analytics, {
        id: 'total',
        users,
        activeSubs,
        hasFinishedOnboarding,
        completedScorecard,
        completed3Scorecards,
        completed7Scorecards,
        completed28Scorecards,
        updatedAt: new Date(),
      });

      // await this.totalsDoc.update({
      //   users,
      //   activeSubs,
      //   updatedAt: new Date(),
      // });

      return Promise.resolve();
    } catch (error) {
      console.log('Error updating totals', error);
      return Promise.reject(`Error updating total analytics ${error}`);
    }
  };

  private buildTotalsMap = (totals: TotalStats): TotalsMap => {
    const totalsMap: TotalsMap = {
      users: totals.users,
      subscribers: totals.activeSubs.total,
      scorecardsCount: totals.scorecardsCount,
      hasFinishedOnboarding: totals.hasFinishedOnboarding,
      completedScorecard: totals.completedScorecard,
      completed3Scorecards: totals.completed3Scorecards,
      completed7Scorecards: totals.completed7Scorecards,
      completed28Scorecards: totals.completed28Scorecards,
      assists: totals.assists,
      comments: totals.comments,
      cheers: totals.cheers,
      exercisesCompleted: totals.exercisesCompleted,
      articlesCompleted: totals.articlesCompleted,
      updatedAt: moment(totals.updatedAt.toDate()).fromNow(),
    };

    return totalsMap;
  };

  public getTotals = async (): Promise<TotalsMap> => {
    try {
      const doc = await this.totalsDoc.get();
      const data = doc.data() as TotalStats;

      const totals = this.buildTotalsMap(data);

      return totals;
    } catch (error) {
      return Promise.reject(`Error getting totals - ${error}`);
    }
  };

  public getNew = async (): Promise<void> => {
    const docRef = this.firestore.collection('analytics').doc('total');
    const doc = await docRef.get();
    const data = doc.data();

    const start = new Date();
    const collection = this.firestore
      .collectionGroup('plays')
      .where('createdAt', '>', start);
  };

  private checkToAddNextWeek = async (): Promise<void> => {
    // Get the Sunday from the week before
    const lastSunday = moment()
      .subtract(7, 'days') // Move to week prior
      .isoWeekday(7) // Set it to Sunday of that week
      .format(this.dateFormat);

    // Check if the weekly doc exists yet and add it if it doesn't
    this.firestore
      .collection('analytics/weekly/weeks')
      .doc(lastSunday)
      .get()
      .then(docSnapshot => {
        if (docSnapshot.exists) {
          return Promise.resolve();
        } else {
          this.addWeek(lastSunday);
          return Promise.resolve();
        }
      })
      .catch(err => Promise.reject(err));
  };

  public get = async (): Promise<Week[]> => {
    const collection = this.firestore
      .collection('analytics/weekly/weeks')
      .orderBy('endDate', 'desc');

    try {
      const weeks: Week[] = [];

      await collection.get().then(snapshots =>
        snapshots.docs.forEach(doc => {
          const data = doc.data() as Week;
          weeks.push({ ...data });
        }),
      );

      return weeks;
    } catch (error) {
      this.logger.error(error);
      throw new ApplicationError('Error getting weekly analytics');
    }
  };

  // @ts-ignore - idfk how to get this to work
  // public listenToWeeks: Subscription<Week[]> = () => {
  public listenToWeeks: Subscription<Week[]> = (
    listener: SubscriptionListener<Week[]>,
  ): Unsubscriber =>
    this.weeklyQuery.onSnapshot(snapshot =>
      listener(snapshot.docs.map(doc => ({ ...doc.data() } as Week))),
    );

  public listenToSeasons: Subscription<Week[]> = (
    listener: SubscriptionListener<Week[]>,
  ): Unsubscriber =>
    this.seasonQuery.onSnapshot(snapshot =>
      listener(snapshot.docs.map(doc => ({ ...doc.data() } as Week))),
    );
}

export default AnalyticsService;
