import * as stream from 'getstream';
import React from 'react';
import moment from 'moment';
import { Avatar, CardContent, CardHeader, Box } from '@material-ui/core';
import { Fine, Paragraph, Icon, Headline } from '../../components';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ActivityData } from 'react-activity-feed';
import logger from '../../services/LoggerService';
import UserTitle from './UserTitle';
import { Recap } from '../../services';
import { DateFormat } from '../../models/Scorecard';
import { questions as questionsObj } from '../survey/questions';
import { questionsWithAnswers, capitalize } from 'common';
import { Question, IconNames } from 'types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    result: {
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: theme.palette.primary.light,
    },
    body: {
      paddingTop: 0,
    },
  }),
);

export type AnswerOpt = string | string[] | number | number[];

interface Props {
  activity: ActivityData;
  actor: stream.UserResponse;
}

const RecapActivityContent: React.FC<Props> = ({ activity, actor }) => {
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Extra Actions Menu
  // const handlePressActions = (
  //   event: React.MouseEvent<HTMLButtonElement>,
  // ): void => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const hidden = !!activity.hidden;
  const answers = activity.object.data.answers as { [key: string]: AnswerOpt };
  const questions = activity.object.data.data?.questions as Question[];

  const collectionEntry = activity.object as stream.CollectionEntry;
  if (!collectionEntry) {
    logger.warn(
      `Failed to parse collection object from activity ${
        activity.id
      }: ${JSON.stringify(activity.object)}`,
    );
    return null;
  }

  const recap = collectionEntry.data as Recap;
  if (!recap) {
    logger.warn(
      `Failed to parse stream recap from activity ${
        activity.id
      } collection object: ${JSON.stringify(collectionEntry.data)}`,
    );
    return null;
  }

  const today = moment();
  const yesterday = moment(today).subtract(1, 'day');

  let dateText: string;
  if (today.isSame(recap.date, 'day')) {
    dateText = 'Today';
  } else if (yesterday.isSame(recap.date, 'day')) {
    dateText = 'Yesterday';
  } else {
    dateText = moment(recap.date).format(DateFormat.Default);
  }

  return (
    <>
      <CardHeader
        avatar={
          <Avatar
            aria-label={actor.data.name}
            className={classes.avatar}
            src={actor.data.avatar}
          />
        }
        action={
          // <Feature feature="changeActivityVisibility">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            {hidden ? <Icon name={IconNames.Hidden} /> : null}
          </div>
          // </Feature>
        }
        title={<UserTitle user={actor.data} uid={actor.id} />}
        subheader={<Fine>{dateText}</Fine>}
      />

      {questions ? (
        questionsWithAnswers(questions).map((item: Question, idx: number) => (
          <Box key={idx} mt={1} mb={1}>
            <Fine fine light>
              {item.question}
            </Fine>

            <Paragraph slim>
              {item.answer && Array.isArray(item.answer)
                ? item.answer.join(', ')
                : item.answer}
            </Paragraph>
          </Box>
        ))
      ) : answers ? (
        Object.values(answers).map((item: AnswerOpt, idx: number) => (
          <CardContent className={classes.body}>
            <Fine fine light>
              {questionsObj[Object.keys(answers)[idx]]
                ? questionsObj[Object.keys(answers)[idx]].question
                : `${capitalize(
                    Object.keys(answers)[idx].replace(/-/g, ' '),
                  )}?`}
            </Fine>
            <Box>
              <Paragraph>
                {Array.isArray(item) ? item.join(', ') : item}
              </Paragraph>
            </Box>
          </CardContent>
        ))
      ) : (
        <Box px={2}>
          <Headline small gutterBottom={false}>
            Recap Unavailable
          </Headline>
          <Paragraph slim>
            This recap is using an old format. Ask admin for more info.
          </Paragraph>
        </Box>
      )}

      {/* <Paragraph slim light fine>
        {actor.data.mascotId && `${actor.data.mascotId} - `}
        {actor.data.scorecardsCount &&
          `${actor.data.scorecardsCount} scorecards - `}
        Debut{' '}
        {actor.data.firstScorecardOn &&
          daysSinceDebut(actor.data.firstScorecardOn)}
      </Paragraph> */}
    </>
  );
};

export default RecapActivityContent;
