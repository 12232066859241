import React from 'react';
import { Spinner } from '../components';

const JobsScreen: React.FC = () => {
  window.location.href =
    'https://medium.com/mental-health-league/mhl-is-hiring-mental-health-coaches-community-manager-and-software-developer-50f02a4f35b7';
  return <Spinner />;
};

export default JobsScreen;
