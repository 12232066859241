export type Question = {
  id: string;
  title?: string;
  type: 'textarea' | 'single' | 'multiple' | 'slider' | 'recap' | 'cover';
  text?: string;
  question: string;
  helper?: string;
  options?: string[];
  btnText?: string;
};

export const questions: { [key: string]: Question } = {
  weekly_congrats: {
    id: 'weekly_congrats',
    question: 'Weekly Recap',
    type: 'cover',
    text: "Congratulations on coming this far. Let's reflect on the week...",
    btnText: 'Next',
  },
  rate_mh: {
    id: 'rate_mh',
    question: 'How would you rate your mental health?',
    helper: '1 = Really down, 10 = Best ever',
    type: 'slider',
    btnText: 'Next',
  },
  biggest_win: {
    id: 'biggest_win',
    question: 'What was your biggest win of the week?',
    helper: 'What did you do well or overcome? What are you grateful for?',
    type: 'textarea',
    btnText: 'Next',
  },
  biggest_learning: {
    id: 'biggest_learning',
    question: 'What was your biggest learning of the week?',
    helper: 'What was challenging this week? What can you learn from it?',
    type: 'textarea',
    btnText: 'Next',
  },
  goal: {
    id: 'goal',
    question: 'What is your goal for next week?',
    helper: 'What can get you moving in the right direction?',
    type: 'textarea',
    btnText: 'Next',
  },
  feedback: {
    id: 'feedback',
    question: 'How can we help you better?',
    helper: 'Ideas or improvements?',
    type: 'textarea',
    btnText: 'Next',
  },
  weekly_close: {
    id: 'weekly_close',
    question: 'Weekly Recap',
    text:
      "Congrats on tackling another week. See you on the field. We'll be rooting for you!",
    type: 'recap',
    btnText: 'Finish',
  },
  day_one_congrats: {
    id: 'day_one_congrats',
    question: 'Day One Recap',
    type: 'cover',
    text: "Congratulations, You've completed day 1! A few questions...",
    btnText: 'Next',
  },
  day_one_weekly_goal: {
    id: 'day_one_weekly_goal',
    question:
      "What's the biggest thing you can do in the next week to improve your mental health?",
    // helper: '...',
    type: 'textarea',
    btnText: 'Next',
  },
  why_mhl: {
    id: 'why_mhl',
    question: 'Why did you decide to join the MHL?',
    // helper: '...',
    type: 'textarea',
    btnText: 'Next',
  },
  any_questions: {
    id: 'any_questions',
    question: 'Do you have any questions?',
    // helper: '...',
    type: 'textarea',
    btnText: 'Next',
  },
  see_you_tomorrow: {
    id: 'see_you_tomorrow',
    question: 'Will we see you tomorrow?',
    // helper: '...',
    type: 'single',
    options: ["Yes, I'll see you tomorrow!", 'No, probably not'],
    btnText: 'Next',
  },
  day_one_close: {
    id: 'close',
    question: 'Day One Recap',
    text: 'Awesome. Hope to see you tomorrow!',
    type: 'recap',
    btnText: 'Finish',
  },
  post_camp_intro: {
    id: 'post_camp_intro',
    question: 'Post-Camp Recap',
    type: 'cover',
    text:
      "Congratulations! You've completed Training Camp. Now, just a few questions to reflect.",
    btnText: 'Next',
  },
  tracking_helped: {
    id: 'tracking_helped',
    question:
      'Tracking my mental health gave me a better understanding of my mental state.',
    type: 'single',
    options: ['Yes', 'No'],
    btnText: 'Next',
  },
  better_decisions: {
    id: 'better_decisions',
    question:
      'Tracking my mental health helped me make better decisions throughout my day.',
    type: 'single',
    options: ['Yes', 'No'],
    btnText: 'Next',
  },
  why_like: {
    id: 'why_like',
    question:
      "If Yes, what did you learn or like about it? If false, what didn't you like about it?",
    type: 'textarea',
    btnText: 'Next',
  },
  sharing_helped: {
    id: 'sharing_helped',
    question:
      'Sharing my progress with others encouraged me to keep tracking and helped keep me accountable.',
    type: 'single',
    options: ['Yes', 'No'],
    btnText: 'Next',
  },
  why_like_sharing: {
    id: 'why_like_sharing',
    question:
      'What in particular did you like or dislike about sharing with the team?',
    type: 'textarea',
    btnText: 'Next',
  },
  team_beneficial: {
    id: 'team_beneficial',
    question: 'Participating with a team was beneficial to my mental health.',
    type: 'single',
    options: ['Yes', 'No'],
    btnText: 'Next',
  },
  team_explanation: {
    id: 'team_explanation',
    question:
      'If true, why? Any specific experiences or benefits? If false, why?',
    type: 'textarea',
    btnText: 'Next',
  },
  referrer_score: {
    id: 'referrer_score',
    question: 'How likely are you to refer the MHL to others?',
    helper: '1 = Not At All, 10 = Definitely Will Share',
    type: 'slider',
    btnText: 'Next',
  },
  review: {
    id: 'review',
    question: 'Write a review for us to use on our website?',
    type: 'textarea',
    btnText: 'Next',
  },
  anything_else: {
    id: 'anything_else',
    question: "Anything else you'd like us to know?",
    type: 'textarea',
    btnText: 'Next',
  },
  post_camp_close: {
    id: 'post_camp_close',
    question: 'Post-Camp Recap',
    text: 'Awesome, excited to keep it going!',
    type: 'recap',
    btnText: 'Finish',
  },
};
