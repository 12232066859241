import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import GameCollection from '../../models/GameCollection';

interface Props {
  games: GameCollection;
  userId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    question: {
      paddingTop: '10px',
      color: '#999',
    },
    result: {
      fontWeight: 700,
    },
    why: {
      fontSize: '.8rem',
    },
    plays: {
      fontSize: '.8rem',
    },
    scorecard: {
      marginBottom: '20px',
    },
  }),
);

const GamesTab: React.FC<Props> = ({ userId, games }) => {
  const classes = useStyles();

  return !games ? (
    <Typography>No scorecard data.</Typography>
  ) : (
    <>
      {games.data.map(game => (
        <div className={classes.scorecard} key={game.date.format('YYYY-MM-DD')}>
          <Typography variant="subtitle2" className={classes.question}>
            {game.date.format('ddd MM-DD-YYYY')}
          </Typography>
          {game.scorecard ? (
            game.scorecard.shared === 'true' ? (
              <>
                <Typography color="primary" className={classes.result}>
                  {game.scorecard.result}
                </Typography>
                <Typography className={classes.why}>
                  {game.scorecard.why}
                </Typography>

                <Typography className={classes.plays} color="textSecondary">
                  {game.scorecard.plays
                    ? game.scorecard.plays.length <= 0
                      ? 'No Plays'
                      : game.scorecard.plays.join(', ')
                    : 'Plays Disabled'}
                </Typography>
              </>
            ) : (
              <Typography className={classes.why}>Private</Typography>
            )
          ) : (
            <Typography>---</Typography>
          )}
        </div>
      ))}
    </>
  );
};

export default GamesTab;
