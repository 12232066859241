import React, { useState } from 'react';
import { Box, Tabs as MtTabs, Tab } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

type Props = {
  panels: any;
  centered?: boolean;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </span>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Tabs: React.FC<Props> = ({ centered, panels }) => {
  // Tabs
  const [tab, setTab] = useState(0);
  const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <MtTabs
        value={tab}
        onChange={handleTab}
        aria-label="Team tabs"
        indicatorColor="secondary"
        textColor="primary"
        centered={!centered ? true : false}>
        {Object.keys(panels).map((key: string, idx: number) => (
          <Tab key={key} label={key} {...a11yProps(idx)} />
        ))}
      </MtTabs>
      {Object.values(panels).map((value: any, idx: number) => (
        <TabPanel key={idx} value={tab} index={idx}>
          {value}
        </TabPanel>
      ))}
    </>
  );
};

export default Tabs;
