import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { Card, Button, Box, Headline, Paragraph, Page } from '../components';
import Link from '@material-ui/core/Link';
import { useCurrentUser, useHistory } from '../hooks';
import { Redirect } from 'react-router-dom';

const SubscriptionScreen: React.FC = () => {
  const { user,  activePlan } = useCurrentUser();
  const history = useHistory();

  return user ? (
    <Page fullPage>
      <Box mt={4}>
        <Card padding>
          <Headline center variant="h4" align="center" gutterBottom>
            Subscription
          </Headline>

          {!user.plan ? (
            <>
              <Typography gutterBottom>
                You don't have an active MHL subscription. Subscribe below! If
                you have any issues, email us at hello@mentalhealthleague.com
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                size="large"
                onClick={() => history.push('/plans')}>
                View Plans
              </Button>
            </>
          ) : (
            <>
              <Paragraph>
                <b>Email:</b> {user.email}
              </Paragraph>
              <Paragraph>
                <b>Payment Type:</b>{' '}
                {user.plan.type === 'iosIap'
                  ? 'Apple iOS'
                  : user.plan.type === 'stripe'
                  ? 'Website (Stripe)'
                  : user.plan.type === 'promoCode'
                  ? 'Promo Code'
                  : 'N/A'}
              </Paragraph>
              {user?.plan?.planId && (
                <Paragraph>
                  <b>Plan</b>: {user.plan.planId}
                </Paragraph>
              )}
              {user?.plan?.trial_period_ends && (
                <Paragraph>
                  <b>Trial Period End</b>: {user.plan.trial_period_ends}
                </Paragraph>
              )}
              <Paragraph>
                <b>Status:</b>: {activePlan ? 'Active' : 'Inactive'}
              </Paragraph>

              {user.plan.code && (
                <Paragraph>
                  <b>Promo Code</b>: {user.plan.code}
                </Paragraph>
              )}

              <Box my={2}>
                <Divider />
              </Box>

              <Headline variant="h6">Manage Subscription</Headline>
            </>
          )}

          {user.plan && (
            <>
              {user.plan.type === 'stripe' ? (
                <>
                  <Paragraph gutterBottom>
                    To manage your subscription, please click the button below.
                  </Paragraph>
                  <Button
                    onClick={() => history.push('/stripe')}
                    text="Manage Subscription"
                  />
                </>
              ) : user.plan.type === 'iosIap' ? (
                <Typography gutterBottom>
                  <Link href="https://support.apple.com/en-us/HT202039">
                    Click here
                  </Link>
                  to learn how to cancel your subscription on Apple iOS.
                </Typography>
              ) : user.plan.type === 'promoCode' ? (
                <Typography gutterBottom>
                  You subscribed for free using an friends and family access
                  code. Enjoy!
                </Typography>
              ) : user.plan.type === 'android' ? (
                <Typography gutterBottom>
                  <Link href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en">
                    Click here
                  </Link>
                  to learn how to cancel your subscription on Android.
                </Typography>
              ) : (
                <Typography gutterBottom>
                  Subscription type unknown. Please contact us at
                  hello@mentalhealthleague.com with any questions.
                </Typography>
              )}
            </>
          )}
        </Card>
      </Box>
    </Page>
  ) : (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  );
};

export default SubscriptionScreen;
