import { RawDraftContentState } from 'draft-js';
import { QuoteDoc, Question, IconTypes } from 'types';

export enum IconNames {
  Brain = 'brain',
  Medal = 'medal',
  Playbook = 'book',
  Book = 'book-open',
  List = 'clipboard-list',
  Clipboard = 'clipboard',
  Dumbbell = 'dumbbell',
  Stats = 'chart-bar',
  Team = 'users',
  Calendar = 'calendar-check',
  Check = 'check-square-o',
  Discussion = 'discussion',
  News = 'newspaper',
  Comments = 'comments',
  Pencil = 'pencil-alt',
  Marker = 'marker',
  Heart = 'heart',
  Sticky = 'sticky-note',
  LineGraph = 'chart-line',
  Balance = 'balance-scale',
  Run = 'running',
  Bed = 'bed',
  Paragraph = 'paragraph',
  UL = 'list-ul',
  Bullhorn = 'bullhorn',
  Trophy = 'trophy',
  Baseball = 'baseball-ball',
  Basketball = 'basketball-ball',
  Football = 'football-ball',
  Bowling = 'bowling-ball',
  Golf = 'golf-ball',
  Volleyball = 'volleyball-ball',
  Hockey = 'hockey-puck',
  Star = 'star',
  Water = 'water',
  Tv = 'tv',
  Music = 'music',
  Broom = 'broom',
  Smile = 'smile',
  Mountain = 'mountain',
  Moon = 'moon',
  Sun = 'sun',
  Tree = 'tree',
  Snow = 'snowflake',
  Headphones = 'headphones',
  Microphone = 'microphone-alt',
  Phone = 'phone-alt',
  Pager = 'pager',
  Apple = 'apple',
  Food = 'food-fork-drink',
  Assist = 'hands-helping',
  Handshake = 'handshake',
  GamePlan = 'gamepad',
  User = 'user-alt',
  Reminders = 'clock-o',
  Hidden = 'hidden',
  Coach = 'whistle',
  Close = 'times',
  Notifications = 'bell',
  Settings = 'cog',
  Support = 'support',
  FAQs = 'question-circle',
  Feedback = 'feedback',
  'ChevronLeft' = 'chevron-left',
  'ChevronRight' = 'chevron-right',
  'ChevronUp' = 'chevron-up',
  'ChevronDown' = 'chevron-down',
  More = 'dots-three-vertical',
  Logout = 'log-out',
}

// export type IconNames =
//   | 'hidden'
//   | 'coach'
//   | 'chevron-right'
//   | 'chevron-left'
//   | 'chevron-up'
//   | 'chevron-down'
//   | 'close'
//   | 'brain'
//   | 'bullhorn'
//   | 'trophy'
//   | 'star'
//   | 'medal'
//   | 'book'
//   | 'calendar-check'
//   | 'check-square-o'
//   | 'discussion'
//   | 'comments';

export type FeatureTypes = 'article' | 'reflection' | 'discussion';

export type Page = {
  body: string | RawDraftContentState;
  imageURL: string;
  next_btn?: string;
  title: string;
  // type: 'article' | 'question';
};

export interface QuestionPage extends Page {
  question: Question;
}

export enum PublishStatus {
  Published = 'published',
  Admin = 'admin',
  Draft = 'draft',
  Review = 'review',
  Rejected = 'rejected',
  Deleted = 'deleted',
}

export type ArticleRatings = {
  helpful: number;
  notHelpful: number;
};

export interface Article {
  id: string;
  slug: string;
  rating: boolean;
  pages: Page[];
  author?: string;
  authorImg?: string;
  status: PublishStatus;
  ratings?: ArticleRatings;
  commentsEnabled: boolean;
  sharingEnabled: boolean;
  challengeEnabled: boolean;
  externalLink?: string;
  next_btn?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export type ArticleColorOptions =
  | 'brandPrimary'
  | 'brandInfo'
  | 'textColor'
  | 'brandDark'
  | 'lightTextColor'
  | undefined;

export type FeaturedArticle = {
  id: string;
  type: FeatureTypes;
  label?: string;
  title: string;
  icon: IconNames; //string;
  iconType?: IconTypes;
  background?:
    | 'brandPrimary'
    | 'brandInfo'
    | 'textColor'
    | 'brandDark'
    | 'lightTextColor'
    | undefined;
  bordered?:
    | 'brandPrimary'
    | 'brandInfo'
    | 'textColor'
    | 'brandDark'
    | 'lightTextColor'
    | undefined;
  important?: boolean;
};

export interface FeaturedType {
  date: Date;
  articles: FeaturedArticle[];
  quote: QuoteDoc;
}

export interface FeaturesObj {
  [key: string]: FeaturedType;
}

export interface ArticlesObj {
  [key: string]: Article;
}
