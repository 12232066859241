import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Card,
  Icon,
  Box,
  Page,
  ToggleButton,
  Headline,
} from '../../components';
import {
  ArticleSelect,
  DiscussionSelect,
  IconSelect,
  ReflectionSelect,
} from '../forms';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Divider,
} from '@material-ui/core';
import logger from '../../services/LoggerService';

import { useContainer, useContent, useHistory } from '../../hooks';
import { GamePlanServiceType } from '../../services/interfaces';
import {
  GamePlanTemplate,
  OldArticleExercise,
  OldSurveyExercise,
  OldDiscussionExercise,
  OldScorecardExercise,
  OldReflectionExercise,
  ExerciseTypes,
  IconNames,
  OldExercise,
} from 'types';
import { slugify } from '../helpers';

const defaultScorecard: OldScorecardExercise = {
  name: 'Fill Out Scorecard',
  type: 'scorecard',
  options: {},
};

// @ts-ignore
const defaultArticle: OldArticleExercise = {
  name: '',
  icon: IconNames.News,
  options: {
    article: '',
  },
  type: 'article',
};

const defaultDiscussion: OldDiscussionExercise = {
  name: '',
  icon: IconNames.Comments,
  options: {
    discussion: '',
  },
  type: 'discussion',
};

const defaultReflection: OldReflectionExercise = {
  name: '',
  icon: IconNames.Brain,
  options: {
    reflection: '',
  },
  type: 'reflection',
};

const defaultSurvey: OldSurveyExercise = {
  name: '',
  icon: IconNames.Pencil,
  options: {
    survey: '',
  },
  type: 'feedback',
};

const initialState: GamePlanTemplate = {
  default: false,
  name: '',
  id: '',
  finalExercise: {
    icon: 'medal',
    name: 'Game Plan Complete!',
    next_btn: 'Keep playing?',
  },
  exercisesByDay: {
    1: [defaultScorecard],
  },
};

type Props = {
  editing?: GamePlanTemplate;
};

// Functions for creation and editing articles.
const GamePlanCreateScreen: React.FC<Props> = ({ editing }) => {
  const [error, setError] = useState<Error | string>('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { reflections } = useContent();

  // if editing article, load that state, end use initial
  const [state, setState] = useState<GamePlanTemplate>(
    !!editing ? editing : initialState,
  );
  const days = Object.keys(state.exercisesByDay).length;

  // Transform GamePlan name into slugified id
  useEffect(() => {
    if (!editing) {
      setState({ ...state, id: slugify(state.name) });
    }
  }, [state.name]);

  // Update state when feature prop changes - feature prop is loading when editing
  useEffect(() => {
    if (editing) {
      setState(editing);
    }
  }, [editing]);

  const container = useContainer();
  const service = container.getInstance<GamePlanServiceType>('gameplanService');

  const resetState = () => {
    setState(initialState);
    setError('');
    setLoading(false);
  };

  const handleSave = async () => {
    // if (!state.date) {
    //   setError('Must fill out all fields');
    // } else {

    try {
      setError('');
      setLoading(true);
      if (!!editing) {
        await service.edit(state);
      } else {
        await service.create(state);
      }
      setError('Gameplan Saved!');
      // resetState();
    } catch (err) {
      logger.error(`Error adding gameplan - ${err}`);
      setError(err);
    }
    setLoading(false);
    window.scrollTo(0, 0);
  };

  const addDay = () => {
    const newState = { ...state };
    newState.exercisesByDay[days + 1] = [{ ...defaultScorecard }];

    setState(newState);
  };

  const moveDay = (old_index: number, new_index: number) => {
    // Copy state
    const newState = { ...state };
    const exercises = newState.exercisesByDay;

    // Copy days before re-assigning
    const dayToMove = [...exercises[old_index]];
    const dayToBump = [...exercises[new_index]];

    // Rearrange their index
    exercises[old_index] = dayToBump;
    exercises[new_index] = dayToMove;

    setState(newState);
  };

  const removeDay = (dayIdx: number) => {
    // Copy state
    const newState = { ...state };

    // Loop starting at the deleted day and move every day after it up
    for (let i = dayIdx; i < days; i++) {
      newState.exercisesByDay[i] = [...newState.exercisesByDay[i + 1]];
    }

    // Remove last day completely from state obj
    delete newState.exercisesByDay[days];

    // Update state after removal
    setState(newState);
  };

  const moveExercise = (
    dayIdx: number,
    old_index: number,
    new_index: number,
  ) => {
    // Copy state
    const newState = { ...state };
    const theExercises = newState.exercisesByDay[dayIdx];

    // Rearrange their index
    theExercises.splice(new_index, 0, theExercises.splice(old_index, 1)[0]);

    setState(newState); //{ ...state, exercisesByDay[dayIdx]: newState });
  };

  const removeExercise = (dayIdx: number, idx: number) => {
    // Copy state articles
    const newState = { ...state }; // [...state.exercisesByDay[dayIdx]];
    const theExercises = newState.exercisesByDay[dayIdx];
    // const newState = [...state.articles];

    // Remove the idx article from the array
    theExercises.splice(idx, 1);

    // Update state with new Exercise arr after removal
    setState(newState);
  };

  const updateExercise = (
    dayIdx: number,
    idx: number,
    type: ExerciseTypes,
    value: string,
  ) => {
    const newState = { ...state };

    // Customize options based on exercise type
    let update: { [key: string]: string } = {};

    if (type === 'feedback') {
      update = { survey: value };
    }

    if (type === 'discussion') {
      update = { discussion: value };
    }

    if (type === 'article') {
      update = { article: value };
    }

    if (type === 'reflection') {
      update = { reflection: value };
    }

    if (newState.exercisesByDay) {
      newState.exercisesByDay[dayIdx][idx].options = update;

      const reflectionObj = reflections && reflections[value];
      if (reflections && reflectionObj) {
        newState.exercisesByDay[dayIdx][idx].name = reflectionObj.title;
        newState.exercisesByDay[dayIdx][idx].icon = reflectionObj.icon;
      }

      if (type === 'discussion') {
        newState.exercisesByDay[dayIdx][idx].icon = IconNames.Comments;
      }

      if (type === 'article') {
        newState.exercisesByDay[dayIdx][idx].icon = IconNames.News;
      }
    }
    setState(newState);
  };

  const addExercise = (type: ExerciseTypes, dayIdx: number) => {
    const newState = { ...state };

    // Add the default Exercise Type of choice
    let newExercise: OldExercise = { ...defaultArticle };
    if (type === 'discussion') {
      newExercise = { ...defaultDiscussion };
    } else if (type === 'scorecard') {
      newExercise = { ...defaultScorecard };
    } else if (type === 'feedback') {
      newExercise = { ...defaultSurvey };
    }

    newState.exercisesByDay[dayIdx].push(newExercise);
    setState(newState);
  };

  const toggleType = (value: ExerciseTypes, dayIdx: number, idx: number) => {
    const newState = { ...state };
    const thisExercise = newState.exercisesByDay[dayIdx][idx];
    thisExercise.type = value;
    setState(newState);
  };

  return (
    <Page fullPage maxWidth="xl" error={error}>
      <Button
        text="‹ Go Back"
        variant="text"
        fullWidth
        onPress={() => history.push('/gameplans')}
      />
      <Headline gutterBottom>{editing ? 'Edit' : 'Create'} Game Plan</Headline>

      <Card padding>
        <TextField
          id="title"
          label="Name"
          fullWidth
          value={state.name}
          onChange={event => {
            setState({ ...state, name: event.target.value });
          }}
          variant="filled"
        />

        <TextField
          id="id"
          label="Id aka Slug"
          fullWidth
          value={state.id}
          onChange={event => {
            setState({ ...state, id: event.target.value });
          }}
          variant="filled"
        />

        <ToggleButton
          type="boolean"
          label="Default gameplan?"
          value={state.default}
          setValue={bool => setState({ ...state, default: bool })}
        />
        {/* <InputLabel htmlFor="standard-adornment-password">
          No. of Days
        </InputLabel>
        <Input
          id="outlined-number"
          type="number"
          fullWidth
          value={days}
          // onChange={e => setDays(+e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <Button size="small" text="Update" />
            </InputAdornment>
          }
        /> */}
      </Card>

      <Box mt={5}>
        <Headline gutterBottom>Exercises</Headline>
      </Box>
      {Object.values(state.exercisesByDay).map((day, dayIdx) => {
        const thisDay = dayIdx + 1; // This is the actual key of the day object, since it's not an array it doesn't start at 0
        return (
          <div key={dayIdx}>
            <Box row mb={2}>
              <Headline variant="h6">Day {thisDay}</Headline>
              <IconButton
                disabled={thisDay === days}
                size="small"
                onClick={() => moveDay(thisDay, thisDay + 1)}>
                <Icon name={IconNames.ChevronDown} />
              </IconButton>
              <IconButton
                disabled={thisDay === 1}
                size="small"
                onClick={() => moveDay(thisDay, thisDay - 1)}>
                <Icon name={IconNames.ChevronUp} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => removeDay(thisDay)}
                disabled={days === 1}>
                <Icon name={IconNames.Close} />
              </IconButton>
            </Box>

            <Card padding>
              {day.map((exercise, idx) => {
                // console.log('EXERCISE', exercise.options);
                return (
                  <div key={idx}>
                    <Box row alignItems="flex-start">
                      <Box flex={2}>
                        <Headline>#{idx + 1}</Headline>
                        <Box row>
                          <IconButton
                            disabled={idx === day.length - 1}
                            size="small"
                            onClick={() => moveExercise(thisDay, idx, idx + 1)}>
                            <Icon name={IconNames.ChevronDown} />
                          </IconButton>
                          <IconButton
                            disabled={idx === 0}
                            size="small"
                            onClick={() => moveExercise(thisDay, idx, idx - 1)}>
                            <Icon name={IconNames.ChevronUp} />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => removeExercise(thisDay, idx)}>
                            <Icon name={IconNames.Close} />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box flex={17}>
                        <ToggleButton
                          buttons={[
                            `scorecard`,
                            'reflection',
                            `discussion`,
                            `article`,
                            `feedback`,
                            `recap`,
                          ]}
                          value={`${exercise.type}`}
                          setValue={(val: ExerciseTypes) =>
                            toggleType(val, thisDay, idx)
                          }
                        />

                        {exercise.type === 'article' && (
                          <ArticleSelect
                            value={exercise.options && exercise.options.article}
                            onChange={event => {
                              updateExercise(
                                thisDay,
                                idx,
                                'article',
                                event.target.value as string,
                              );
                            }}
                          />
                        )}

                        {exercise.type === 'feedback' && (
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="status">Feedback Id</InputLabel>
                            <Select
                              labelId="feedback-id"
                              id="feedback-id"
                              value={
                                exercise.options && exercise.options.survey
                              }
                              onChange={event => {
                                updateExercise(
                                  thisDay,
                                  idx,
                                  'feedback',
                                  event.target.value as string,
                                );
                              }}>
                              {[
                                'defaultSurvey',
                                'dayOneSurvey',
                                'postGameSurvey',
                              ].map((survey, idx) => (
                                <MenuItem key={idx} value={survey}>
                                  {survey}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}

                        {exercise.type === 'reflection' && (
                          <ReflectionSelect
                            value={
                              exercise.options && exercise.options.reflection
                            }
                            onChange={event => {
                              updateExercise(
                                thisDay,
                                idx,
                                'reflection',
                                event.target.value as string,
                              );
                            }}
                          />
                        )}

                        {exercise.type === 'discussion' && (
                          <DiscussionSelect
                            value={
                              exercise.options && exercise.options.discussion
                            }
                            onChange={event => {
                              updateExercise(
                                thisDay,
                                idx,
                                'discussion',
                                event.target.value as string,
                              );
                            }}
                          />
                        )}

                        <TextField
                          gutterTop={1}
                          size="small"
                          id={`${thisDay}-${idx}-name`}
                          label="Exercise Name"
                          fullWidth
                          value={exercise.name}
                          onChange={event => {
                            const newState = { ...state };
                            exercise.name = event.target.value;
                            setState(newState);
                          }}
                          variant="filled"
                        />

                        <IconSelect
                          value={exercise.icon}
                          onChange={e => {
                            const newState = { ...state };
                            exercise.icon = e;
                            setState(newState);
                          }}
                        />
                      </Box>
                    </Box>
                    {idx + 1 !== day.length && (
                      <Box mt={4} mb={3}>
                        <Divider />
                      </Box>
                    )}
                  </div>
                );
              })}
              <Box my={2}>
                <Button
                  size="small"
                  text="Add Exercise"
                  fullWidth
                  variant="text"
                  onPress={() => addExercise('scorecard', thisDay)}
                />
              </Box>
            </Card>
          </div>
        );
      })}

      <Box my={2}>
        <Button
          text="Add Day"
          variant="outlined"
          fullWidth
          disabled={loading}
          onClick={addDay}
        />
      </Box>

      <Box mb={2} mt={5}>
        <Headline variant="h6">Final Exercise</Headline>

        <Card padding>
          <Box row>
            <Icon
              name={IconNames.Medal}
              style={{ marginRight: '20px', fontSize: '36px' }}
            />

            <Box flex={1}>
              <TextField
                id="final-exercise-name"
                label="Name"
                fullWidth
                value={state.finalExercise ? state.finalExercise.name : ''}
                onChange={event => {
                  const newState = { ...state };
                  if (newState.finalExercise) {
                    newState.finalExercise.name = event.target.value;
                  }
                  setState(newState);
                }}
                variant="filled"
              />

              <TextField
                id="final-exercise-next-btn"
                label="Next Button Text"
                fullWidth
                value={state.finalExercise ? state.finalExercise.next_btn : ''}
                onChange={event => {
                  const newState = { ...state };
                  if (newState.finalExercise) {
                    newState.finalExercise.next_btn = event.target.value;
                  }
                  setState(newState);
                }}
                variant="filled"
              />
            </Box>
          </Box>
        </Card>
      </Box>

      <Box mb={4}>
        <Button text="Save" fullWidth disabled={loading} onClick={handleSave} />
      </Box>
    </Page>
  );
};

export default GamePlanCreateScreen;
