import React from 'react';
import { theme } from '../assets/styles/theme';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    absolute: {
      position: 'absolute',
      bottom: -20,
      width: '130%',
      left: '50%',
      zIndex: -1,
      minHeight: 110,
    },
  }),
);

const Crescent: React.FC<Props> = ({ height, width, color, ...props }) => {
  const classes = useStyles();

  const translate = {
    '-webkit-transform': 'translateX(-50%)',
    transform: 'translateX(-50%)',
  };

  return (
    <div className={classes.absolute} style={translate}>
      <svg viewBox="0 0 556.09 100.09" width="100%" height="auto" {...props}>
        <path
          d="M0 0v.09c0 37.63 94.55 100 278.04 100 183.54 0 278.04-62.37 278.04-100V0H0z"
          fill={color ? color : theme.palette.primary.main}
        />
      </svg>
    </div>
  );
};

export default Crescent;
