import React, { useState } from 'react';
import { StyleProp, ViewStyle, View } from 'react-native';
import Spinner from './Spinner';
import { Colors } from 'common/src/theme/light';
import { useEffect } from 'react';

interface Props {
  isLoading: boolean;
  noDelay?: boolean;
  style?: StyleProp<ViewStyle>;
  text?: string;
  full?: boolean;
  bg?: Colors;
}

const minimumLoadingTime = 500;

const Loading: React.FC<Props> = ({
  style,
  noDelay,
  text,
  full,
  bg,
  children = null,
  ...props
}) => {
  const [isSimulatingLoading, setSimulatedLoading] = useState(
    noDelay ? false : true,
  );

  useEffect(() => {
    setTimeout(() => {
      setSimulatedLoading(false);
    }, minimumLoadingTime);

    return clearTimeout();
  }, []);

  const child = typeof children === 'function' ? children() : children;

  return props.isLoading || isSimulatingLoading ? (
    <Spinner text={text} />
  ) : (
    child
  );
};

export default Loading;
