// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import Box from './Box';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  RawDraftContentState,
} from 'draft-js';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
// import createEmojiPlugin from '@draft-js-plugins/emoji';
import createToolbarPlugin, {
  Separator,
} from '@draft-js-plugins/static-toolbar';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  // BlockquoteButton,
} from 'draft-js-buttons';

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;
const plugins = [staticToolbarPlugin];

// Creates an Instance. At this step, a configuration object can be passed in
// as an argument.
// const emojiPlugin = createEmojiPlugin();
// const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

type Props = {
  state: string | RawDraftContentState;
  setState: React.Dispatch<React.SetStateAction<string | RawDraftContentState>>;
};

const RichEditor: React.FC<Props> = ({ state, setState }) => {
  // DRAFT JS
  const ogState = useRef<EditorState>(state ? state : null);

  const [editorState, setEditorState] = useState<EditorState>(
    typeof ogState.current === 'string'
      ? createEditorStateWithText(ogState.current)
      : !ogState.current
      ? EditorState.createEmpty()
      : EditorState.createWithContent(convertFromRaw(ogState.current)),
  );

  useEffect(() => {
    setState(convertToRaw(editorState.getCurrentContent()));
  }, [editorState]);

  const editor = React.useRef(null);

  const focusEditor = () => {
    if (editor) {
      // tslint:disable-next-line
      //@ts-ignore
      editor.current.focus();
    }
  };

  return (
    <div
      style={{
        border: '1px solid gray',
        minHeight: '6em',
        padding: '15px',
      }}
      onClick={focusEditor}>
      <Toolbar style={{ display: 'flex', marginBottom: '15px' }}>
        {// may be use React.Fragment instead of div to improve perfomance after React 16
        externalProps => (
          <Box display="flex" flexDirection="row">
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            {/* <CodeButton {...externalProps} /> */}
            <Separator {...externalProps} />
            <HeadlineOneButton {...externalProps} />
            <HeadlineTwoButton {...externalProps} />
            <HeadlineThreeButton {...externalProps} />
            <UnorderedListButton {...externalProps} />
            <OrderedListButton {...externalProps} />
            {/* <BlockquoteButton {...externalProps} />
                <CodeBlockButton {...externalProps} /> */}
          </Box>
        )}
      </Toolbar>

      <Editor
        readOnly={false}
        editorState={editorState}
        onChange={setEditorState}
        plugins={plugins}
        ref={editor}
      />
    </div>
  );
};

export default RichEditor;
