import React from 'react';
import {
  PageHeading,
  Headline,
  Button,
  Box,
  Tabs,
  ErrorMessage,
  Page,
} from '../../components';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { AnalyticsServiceType, Week, TotalsMap } from 'types';
import {
  useContainer,
  useQuery,
  useMutation,
  useSubscription,
} from '../../hooks';
import moment from 'moment';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

type TableProps = {
  type: 'weekly' | 'seasons';
  // stats: Week[] | null;
  service: AnalyticsServiceType;
};

const StatsTable: React.FC<TableProps> = ({ type, service }) => {
  const classes = useStyles();
  const isSeason = type === 'seasons';
  const { data } = useSubscription<Week[]>(
    !isSeason ? service.listenToWeeks : service.listenToSeasons,
  );

  const { mutate, error, loading } = useMutation(
    isSeason ? service.addSeason : service.addWeek,
  );

  // const { data: seasons } = useSubscription<Week[]>(service.listenToSeasons);
  const handleMutate = (date: string) => {
    mutate(undefined, undefined, date);
  };

  const firstRow = data && moment(data[0]?.id);
  const lastRow = data && moment(data[data.length - 1]?.id);

  const nextEndMoment =
    firstRow && isSeason
      ? firstRow.add(1, 'month')
      : firstRow
      ? firstRow.add(7, 'days')
      : undefined;

  const previousEndMoment =
    lastRow && isSeason
      ? lastRow.subtract(1, 'month')
      : lastRow
      ? lastRow.subtract(7, 'days')
      : undefined;

  const hasAnother = moment().isAfter(nextEndMoment, 'day');
  const nextUpdateDate = nextEndMoment?.add(1, 'day').format('YYYY-MM-DD');

  const columns = [
    'id',
    'signups',
    'activePlayers',
    'newSubs',
    'cancellations',
    'scorecardsCount',
    'assists',
    'comments',
    'cheers',
    'exercisesCompleted',
    'articlesCompleted',
    'updatedAt',
  ];

  return (
    <>
      <ErrorMessage error={error} />
      <Box>
        <Button
          text={hasAnother ? 'add' : `Can't add until ${nextUpdateDate}`}
          size="small"
          variant="text"
          onPress={() => {
            if (nextEndMoment) {
              handleMutate(nextEndMoment.format('YYYY-MM-DD'));
            }
          }}
          disabled={!hasAnother}
          loading={loading}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((key: string) => {
                return <TableCell key={key}>{key}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map(row => (
                <TableRow key={row.id}>
                  {columns.map((key: string) => {
                    // @ts-ignore
                    return !!row[key] ? (
                      <TableCell key={key}>
                        {key === 'updatedAt'
                          ? moment(row[key].toDate()).fromNow()
                          : // @ts-ignore
                          typeof row[key] === 'string' ||
                            // @ts-ignore
                            typeof row[key] === 'number'
                          ? // @ts-ignore
                            row[key]
                          : // @ts-ignore
                            row[key].total}
                      </TableCell>
                    ) : (
                      <TableCell key={key}>--</TableCell>
                    );
                  })}
                  <TableCell>
                    <Button
                      size="small"
                      variant="text"
                      text="Refresh"
                      onPress={() => handleMutate(row.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        text={`Add ${previousEndMoment &&
          previousEndMoment.format('YYYY-MM-DD')}`}
        size="small"
        variant="text"
        loading={loading}
        onPress={() => {
          if (previousEndMoment) {
            handleMutate(previousEndMoment.format('YYYY-MM-DD'));
          }
        }}
      />
    </>
  );
};

const AnalyticsScreen: React.FC = () => {
  const classes = useStyles();

  const container = useContainer();
  const service = container.getInstance<AnalyticsServiceType>(
    'analyticsService',
  );

  const {
    data: totals,
    error: totalsError,
    loading: totalsLoading,
    fetch: fetchTotals,
  } = useQuery<TotalsMap>(service.getTotals);

  const { loading: mutateLoading, error: mutateError, mutate } = useMutation(
    service.updateTotals,
  );

  // Handle Totals Update
  const handleUpdate = () => {
    mutate();
    fetchTotals();
  };

  return (
    <Page maxWidth="xl" fullPage>
      <PageHeading text="Analytics" />

      <Box m={2}>
        <Box row justifyContent="space-between">
          <Headline small>Total</Headline>
          <Button
            text="update (refresh after)"
            size="small"
            variant="text"
            onPress={handleUpdate}
            disabled={mutateLoading}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {totals &&
                  Object.keys(totals).map((key: string) => {
                    return <TableCell key={key}>{key}</TableCell>;
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {totals &&
                  Object.values(totals).map((stat, idx) => (
                    <TableCell key={idx}>{stat}</TableCell>
                  ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Tabs
        panels={{
          Weekly: <StatsTable type="weekly" service={service} />,
          Season: <StatsTable type="seasons" service={service} />,
        }}
      />
    </Page>
  );
};

export default AnalyticsScreen;
