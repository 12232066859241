import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Star as StarIcon } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Section } from '../../../components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      color: 'yellow',
    },
    center: {
      textAlign: 'center',
    },
    callout: {
      textAlign: 'center',
      background: theme.palette.primary.main,
      // [theme.breakpoints.up('md')]: {
      //   padding: '30px 5%',
      // },
      '& h3': {
        color: theme.palette.secondary.main,
      },
      '& h2': {
        color: 'white',
      },
      '& h4': {
        color: theme.palette.secondary.main,
      },
      '& h1': {
        color: 'white',
      },
      '& h6': {
        color: 'white',
      },
      '& span': {
        color: 'white',
      },
    },
  }),
);

const Testimonials: React.FC = () => {
  const classes = useStyles();

  return (
    <Section background="primary">
      <Grid container spacing={4} className={classes.callout}>
        <Grid item xs={12} className={classes.center}>
          <Typography component="h1" variant="h3" gutterBottom>
            Real Results
          </Typography>
          <Typography variant="subtitle1">
            from men and women of all ages
          </Typography>
        </Grid>
        <Grid md={6} item>
          <Typography variant="h1" component="h4">
            93%
          </Typography>
          <Typography variant="h6">
            of MHL players said it gave them a better understanding of their
            mental health
          </Typography>
        </Grid>
        <Grid md={6} item>
          <Typography variant="h1" component="h4">
            95%
          </Typography>
          <Typography variant="h6">
            of MHL players said it gave them the ACCOUNTABILITY and MOTIVATION
            to improve
          </Typography>
        </Grid>
        <Grid md={6} item>
          {/* <Avatar
            className={classes.avatarImg}
            src="https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1709/vadymvdrobot170903536/86690172-photo-of-excited-man-fan-in-blue-t-shirt-standing-isolated-over-white-background-looking-camera-wear.jpg?ver=6"
          /> */}
          <StarIcon className={classes.action} />
          <StarIcon className={classes.action} />
          <StarIcon className={classes.action} />
          <StarIcon className={classes.action} />
          <StarIcon className={classes.action} />

          <Typography variant="h6">
            "This program takes the elusive thing that is mental wellness and
            makes it understandable, with coaching and team support that keep
            you accountable and always improving. One of the best mental health
            apps on the market!"
          </Typography>
          <Typography component="span" variant="subtitle1">
            - Logan
          </Typography>
        </Grid>
        <Grid md={6} item>
          {/* <Avatar
            className={classes.avatarImg}
            src="https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1709/vadymvdrobot170903536/86690172-photo-of-excited-man-fan-in-blue-t-shirt-standing-isolated-over-white-background-looking-camera-wear.jpg?ver=6"
          /> */}
          <StarIcon className={classes.action} />
          <StarIcon className={classes.action} />
          <StarIcon className={classes.action} />
          <StarIcon className={classes.action} />
          <StarIcon className={classes.action} />

          <Typography variant="h6">
            "The MHL really made me realize what made me tick. It helped me
            determine what is healthy for me and what is not. I got to find out
            what really causes my losses on a daily basis."
          </Typography>
          <Typography component="span" variant="subtitle1">
            - Alan
          </Typography>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Testimonials;
