import React from 'react';
import Paragraph from './Paragraph';
import { CardProps, CardActionArea } from '@material-ui/core';
import { brandColors } from '../assets/styles/theme';
import Card from './Card';
import Box from './Box';
import Icon, { IconNames } from './Icon';
import { IconTypes } from 'types';

interface Props {
  background?:
    | 'brandPrimary'
    | 'brandInfo'
    | 'textColor'
    | 'brandDark'
    | 'lightTextColor';
  bordered?:
    | 'brandPrimary'
    | 'brandInfo'
    | 'textColor'
    | 'brandDark'
    | 'lightTextColor';
  onPress?(): void;
  iconName?: IconNames;
  iconType?: IconTypes;
  text: string;
  label?: string;
}

const CardButton: React.FC<Props & CardProps> = ({
  background,
  bordered,
  iconName,
  iconType,
  text,
  label,
  onPress,
  ...props
}) => {
  return (
    <Card
      square
      bordered={bordered}
      style={{
        marginBottom: '16px',
        backgroundColor: background
          ? brandColors[background]
          : bordered
          ? 'white'
          : brandColors.brandInfo,
      }}>
      <CardActionArea style={{ padding: '5%' }} onClick={onPress}>
        <Box row alignItems="center">
          {iconName && (
            <Icon
              name={iconName}
              style={{
                color: bordered ? brandColors[bordered] : 'white',
                fontSize: '36px',
                marginRight: '24px',
              }}
            />
          )}
          <Box>
            {label && (
              <Paragraph
                fine
                bold
                style={{
                  color:
                    bordered === 'brandInfo' || background === 'brandInfo'
                      ? brandColors.brandInfoDark
                      : background
                      ? 'white'
                      : bordered
                      ? brandColors.textColor
                      : 'white',
                }}>
                {label}
              </Paragraph>
            )}
            <Paragraph
              bold
              style={{
                marginRight: 20,
                color: background
                  ? 'white'
                  : bordered
                  ? brandColors[bordered]
                  : 'white',
              }}>
              {text}
            </Paragraph>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default CardButton;
