import moment from 'moment';

type InputObject = Pick<moment.MomentInputObject, 'year' | 'month' | 'date'>;
interface Input {
  start?: InputObject;
  end?: InputObject;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
}

class Season {
  private minDate: moment.Moment;
  private maxDate: moment.Moment;

  public start: moment.Moment;
  public end: moment.Moment;

  constructor(input: Input = {}) {
    const today = moment();

    const defaultStart = {
      year: today.year(),
      month: today.month(),
      date: 1,
    };
    const startDate = moment({ ...defaultStart, ...input.start });
    // console.log('SEASON MODEL INPUT START DATE', input.start);
    // console.log('SEASON MODEL STATER DATE', startDate);
    const defaultEnd = {
      year: startDate.year(),
      month: startDate.month(),
      date: moment(startDate)
        .endOf('month')
        .date(),
    };

    const endDate = moment({ ...defaultEnd, ...input.end });

    this.maxDate = input.maxDate || today;
    this.minDate = input.minDate || startDate;

    if (startDate.isBefore(this.minDate, 'day')) {
      this.start = this.minDate;
    } else {
      this.start = startDate;
    }

    if (endDate.isAfter(this.maxDate, 'day')) {
      this.end = this.maxDate;
    } else {
      this.end = endDate;
    }

    // console.log('SEASON MODEL FINAL START', this.start, this.minDate);
  }

  static forDate(date: moment.Moment): Season {
    return new this({ start: { year: date.year(), month: date.month() } });
  }

  public daysInSeason(): number {
    return this.end.diff(this.start, 'days') + 1;
  }

  public equals(other: Season): boolean {
    return this.start.isSame(other.start) && this.end.isSame(other.end);
  }

  public previous(): Season {
    const prevMonth = moment(this.start);
    prevMonth.subtract(1, 'month');
    // console.log('SEASON MODEL NEXT SEASON', prevMonth.format('YYYY-MM-DD'));
    return new Season({
      start: { year: prevMonth.year(), month: prevMonth.month() },
      minDate: this.minDate,
      maxDate: this.maxDate,
    });
  }

  public next(): Season {
    const nextMonth = moment(this.start);
    nextMonth.add(1, 'month');
    // console.log('SEASON MODEL NEXT SEASON', nextMonth.format('YYYY-MM-DD'));

    return new Season({
      start: { year: nextMonth.year(), month: nextMonth.month() },
      minDate: this.minDate,
      maxDate: this.maxDate,
    });
  }
}

export default Season;
