import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export interface CalloutProps {
  title: string | React.ReactNode;
  body?: string | React.ReactNode;
  inverse?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inverse: {
      textAlign: 'center',
      background: theme.palette.primary.main,
      padding: '20px',
      [theme.breakpoints.up('md')]: {
        padding: '5%',
      },
      '& h2': {
        color: 'white',
      },
      '& h4': {
        color: theme.palette.secondary.main,
      },
      '& h1': {
        color: 'white',
      },
      '& h6': {
        color: 'white',
      },
    },
    img: {
      width: '25%',
      height: 'auto',
      // [theme.breakpoints.up('sm')]: {
      //   width: '20%',
      //   // marginBottom: '0%',
      // },
    },
  }),
);

const Callout: React.FC<CalloutProps> = ({ title, body, inverse }) => {
  const classes = useStyles();

  return (
    <Box mt={inverse ? 0 : 4} mb={inverse ? 0 : 8}>
      <Grid container className={inverse ? classes.inverse : undefined}>
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="subtitle2"
            color="secondary"
            align="center"
            style={{ fontWeight: 900 }}>
            {title}
          </Typography>
          <Typography component="h2" variant="h5" align="center">
            {/* MHL's app combines sports and clinical psychology to help you stop
            spiraling and win more days. */}
            {body && body}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Callout;
