export const shortSurvey = [
  {
    id: 'phone_os',
    title: 'The MHL Combine',
    question: 'What type of phone do you have?',
    type: 'single',
    options: ['iPhone', 'Android'],
    button: 'Next',
  },
  {
    title: 'The MHL Combine',
    id: 'improve',
    type: 'multiple',
    question: 'What are you hoping to improve upon?',
    options: [
      'Accountability To Stick To My Self-Care',
      'A Positive Supportive System',
      'Psychological Hacks To Change My Behavior',
      'To Learn More About My Triggers',
      "To Know That I'm Not Alone",
      'None Of The Above',
    ],
    button: 'Submit',
  },
  {
    id: 'therapy',
    title: 'The MHL Combine',
    question:
      'Are you willing to commit to improving yourself for the next 28 days?',
    type: 'single',
    options: ['Yes', 'No'],
    button: 'Next',
  },
  {
    id: 'therapy',
    title: 'The MHL Combine',
    question: 'Are you ready to start your championship season?',
    type: 'single',
    options: ['Yes', 'No'],
    button: 'Next',
  },
  {
    title: 'The MHL Combine',
    id: 'email',
    type: 'singleInput',
    question: "What's your email?",
    options: [],
    button: 'Apply',
  },
];

export const defaultSurvey = [
  {
    id: 'phone_os',
    title: 'The MHL Combine',
    question: 'What type of phone do you have?',
    type: 'single',
    options: ['iPhone', 'Android'],
    button: 'Next',
  },
  {
    id: 'current_mental_health',
    title: 'The MHL Combine',
    question: 'How would you rate your current mental health?',
    type: 'single',
    options: ['1 - Poor', '2 - Not Good', '3 - Okay', '4 - Good', '5 - Great'],
    button: 'Next',
  },
  {
    id: 'therapy',
    title: 'The MHL Combine',
    question: 'Are you currently in therapy?',
    type: 'single',
    options: ['Yes', 'No'],
    button: 'Next',
  },
  {
    title: 'The MHL Combine',
    id: 'opponents',
    type: 'multiple',
    question: 'Which opponents are you competing against?',
    options: [
      'Anxiety',
      'Stress',
      'Depression',
      'Bipolar',
      'ADHD',
      'PTSD',
      'No Specific Opponent',
      'Other',
    ],
    button: 'Next',
  },
  {
    id: 'gender',
    title: 'The MHL Combine',
    question: 'Gender?',
    type: 'single',
    options: ['Male', 'Female', 'Non-Binary', 'Prefer Not To Say'],
    button: 'Next',
  },
  {
    title: 'The MHL Combine',
    id: 'employment',
    type: 'single',
    question: "What's your employment status?",
    options: [
      'Student',
      'Self-Employed',
      'Employed',
      'Unemployed',
      'Retired',
      'Prefer Not To Say',
    ],
    button: 'Next',
  },
  {
    id: 'sexuality',
    title: 'The MHL Combine',
    question: 'What is your sexuality?',
    type: 'single',
    options: [
      'Straight',
      'Gay',
      'Lesbian',
      'Bisexual',
      'Other',
      'Prefer Not To Say',
    ],
    button: 'Next',
  },
  {
    id: 'living_environment',
    title: 'The MHL Combine',
    question: 'Where do you live?',
    type: 'single',
    options: ['City', 'Suburbs', 'Rural', 'Prefer Not To Say'],
    button: 'Next',
  },
  {
    id: 'relationship',
    title: 'The MHL Combine',
    question: 'Relationship Status?',
    type: 'single',
    options: [
      'Single',
      'In A Relationship',
      'Married',
      'Separated',
      'Divorced',
      'Widowed',
      "It's Complicated",
      'Prefer Not To Say',
    ],
    button: 'Next',
  },
  {
    id: 'children',
    title: 'The MHL Combine',
    question: 'Do you have children?',
    type: 'single',
    options: [
      'Yes, I have Children',
      "No, I Don't Have Children",
      'Prefer Not To Say',
    ],
    button: 'Next',
  },
  {
    title: 'The MHL Combine',
    id: 'race',
    type: 'multiple',
    question: 'How do you identify?',
    options: [
      'Black / African Descent',
      'White / Caucasian',
      'Hispanic / Latino',
      'Asian',
      'Native Hawaiian / Pacific Islander',
      'Indigenous',
      'Other',
      'Prefer Not To Say',
    ],
    button: 'Next',
  },
  {
    title: 'The MHL Combine',
    id: 'improve',
    type: 'multiple',
    question: 'What are you hoping to improve upon?',
    options: [
      'Accountability To Stick To My Self-Care',
      'A Positive Supportive System',
      'Psychological Hacks To Change My Behavior',
      'To Learn More About My Triggers',
      "To Know That I'm Not Alone",
      'None Of The Above',
    ],
    button: 'Submit',
  },
  {
    title: 'The MHL Combine',
    id: 'email',
    type: 'singleInput',
    question: "What's your email?",
    options: [],
    button: 'Apply',
  },
];
