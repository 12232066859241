import React from 'react';
import Box, { BoxProps } from './Box';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gradient: {
      background: `linear-gradient(90deg, ${
        theme.palette.secondary.main
      } 30%, ${theme.palette.primary.main} 90%)`,
      height: 'calc(100vh + 13px)',
      justifyContent: 'center',
      textAlign: 'center',
    },
    inverse: {
      background: theme.palette.primary.main,
      '& p': {
        color: 'white !important',
        '& span': {
          color: 'white',
        },
      },
      '& h1, h2, h3, h4, h5, h6, span': {
        color: 'white',
      },
    },
    offWhite: {
      background: '#EFEFEF',
    },
    box: {
      overflowX: 'hidden',
    },
  }),
);

interface Props {
  background?: 'primary' | 'secondary' | 'gradient' | 'offWhite';
  height?: string | 'sm' | 'md' | 'lg' | 'xl';
}

const Section: React.FC<Props & BoxProps> = ({
  children,
  background,
  height,
  ...props
}) => {
  const classes = useStyles();
  const verticalSpacingDesktop =
    height === 'xl' ? 16 : height === 'lg' ? 12 : height === 'sm' ? 4 : 8;

  return (
    <Box
      py={{ xs: 4, md: verticalSpacingDesktop }}
      px={{ xs: 2, sm: 3, md: 4, lg: 6 }}
      className={
        background === 'primary'
          ? classes.inverse
          : background === 'offWhite'
          ? classes.offWhite
          : background === 'gradient'
          ? classes.gradient
          : undefined
      }
      {...props}>
      {children}
    </Box>
  );
};

export default Section;
