// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  EditorState,
  ContentState,
  convertFromRaw,
  RawDraftContentState,
} from 'draft-js';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';

type Props = {
  state?: string | RawDraftContentState;
};

const RichText: React.FC<Props> = ({ state, setState, readOnly = false }) => {
  // DRAFT JS
  const translateRawState = (): ContentState => {
    return typeof state === 'string'
      ? createEditorStateWithText(state)
      : !state
      ? EditorState.createEmpty()
      : EditorState.createWithContent(convertFromRaw(state));
  };
  const [editorState, setEditorState] = useState<EditorState>(
    translateRawState(),
  );

  useEffect(() => {
    setEditorState(translateRawState());
  }, [state]);

  return (
    <Editor
      readOnly={readOnly}
      editorState={editorState}
      onChange={() => null}
    />
  );
};

export default RichText;
