// Pulls parameter data from internal navigation
import { useLocation as ogUseLocation } from 'react-router-dom';

export const useLocation = <T>() => {
  let location: any = ogUseLocation();
  let state: T | undefined = location.state;
  let pathname: string | null = location.pathname;

  return { location, state, pathname };
};
