import React, { CSSProperties } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { brandColors } from '../assets/styles/theme';

export type TypographyComponents =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'paragraph';
export interface ParagraphProps {
  gb?: number;
  gt?: number;
  gv?: number;
  size?: number;
  center?: boolean;
  fine?: boolean;
  light?: boolean;
  slim?: boolean;
  bold?: boolean;
  component?: TypographyComponents;
  // color?:
  //   | 'brandPrimary'
  //   | 'brandInfo'
  //   | 'textColor'
  //   | 'brandDark'
  //   | 'lightTextColor';
}

const Paragraph: React.FC<ParagraphProps & TypographyProps> = ({
  style,
  children,
  size,
  gt,
  gb,
  gv,
  center,
  light,
  slim,
  fine,
  bold,
  ...props
}) => {
  const propStyle: CSSProperties = {};

  if (fine) {
    propStyle.fontSize = '14px';
  }

  if (slim) {
    propStyle.lineHeight = '26px';
  }

  if (bold) {
    propStyle.fontWeight = 'bold';
  }

  if (light) {
    propStyle.color = brandColors.lightTextColor;
  }

  return (
    <Typography
      component={props.component}
      align={center ? 'center' : 'inherit'}
      style={{ ...propStyle, ...style }}
      {...props}>
      {children}
    </Typography>
  );
};

export default Paragraph;
