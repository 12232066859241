// Material-UI Documentation for autocomplete - https://material-ui.com/components/autocomplete/
import React, { useEffect } from 'react';
import { Tags, TagsArr } from 'types';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

type Props = {
  value?: Tags[];
  onChange: (e: any) => void;
};

const TagSelect: React.FC<Props> = ({ value, onChange }) => {
  // const fixedOptions: Tag[] = value ? value : [];
  const [tags, setTags] = React.useState<Tags[]>(value ? value : []);

  useEffect(() => {
    onChange(tags);
  }, [tags]);

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel id="tags">Tags</InputLabel>
      <Autocomplete
        title="Tags"
        multiple
        id="tag-select"
        value={tags}
        onChange={(event, newValue) => {
          setTags([...newValue]);
        }}
        options={TagsArr}
        getOptionLabel={(option: Tags) => option}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option: Tags, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        // style={{ width: 500 }}
        renderInput={params => (
          <TextField
            {...params}
            label="Tags"
            variant="standard"
            placeholder="Choose tags..."
          />
        )}
      />
    </FormControl>
  );
};

export default TagSelect;
