import React, { useState, useEffect } from 'react';
import { ArticleList, ErrorMessage, Page, Headline } from '../../components';
import { Container, FormControl, Grid, TextField } from '@material-ui/core';
import { Article, ArticlesObj, PublishStatus } from 'types';
import { ArticleServiceType } from '../../services/interfaces';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  useContainer,
  useMutation,
  useSubscription,
  useRouteMatch,
  useHistory,
  useContent,
} from '../../hooks';
import ArticleViewScreen from './ArticleViewScreen';
import { Button, Box } from '../../components';
import { slugify } from '../helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideNav: {
      overflowY: 'scroll',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
    scroll: {
      overflowY: 'scroll',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '95%',
    },
  }),
);

const mainHeight = {
  height: 'calc(100vh - 87px)',
  fallbacks: [
    { height: '-moz-calc(100vh - 87px)' },
    { height: '-webkit-calc(100vh - 87px)' },
    { height: '-o-calc(100vh - 87px)' },
  ],
};

type Props = {
  teamId?: string;
};

const ArticlesScreen: React.FC<Props> = ({ teamId: defaultTeamId }) => {
  const classes = useStyles();
  const history = useHistory();
  const { articles: data } = useContent();
  const [currentArticle, setCurrentArticle] = useState<Article | undefined>(
    undefined,
  );
  const { route2 } = useRouteMatch();

  const container = useContainer();
  const service = container.getInstance<ArticleServiceType>('articleService');

  const [articles, setArticles] = useState<Article[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (data) {
      setArticles(
        Object.values(data).filter(art => art.status !== PublishStatus.Deleted),
      );
    }
  }, [data]);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Article[]>([]);

  const handleSearchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchTerm(event.target.value as string);
  };

  const selectArticle = (art: Article): void => {
    history.push(`/articles/${art.slug}`);
  };

  useEffect(() => {
    if (route2 && data) {
      setCurrentArticle(data[route2]);
    }
  }, [route2, data]);

  const { mutate, loading: deleting, error: deleteError } = useMutation(() =>
    service.deleteArticle(currentArticle && currentArticle.slug),
  );

  const handleDelete = async () => {
    try {
      mutate();
      setCurrentArticle(undefined);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    if (articles) {
      const results = articles.filter(article =>
        article.slug.toLowerCase().includes(searchTerm),
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, articles]);

  // Reset filter when used on team page
  //   useEffect(() => {
  //     setTeamId(defaultTeamId ? defaultTeamId : 'All Teams');
  //   }, [defaultTeamId]);

  return (
    <Page fullPage maxWidth="xl" loading={!articles}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={3} xl={2} className={classes.sideNav} style={mainHeight}>
          <Headline variant="h6">Articles</Headline>
          <Box row>
            <Button
              onClick={() => history.push('/articles/create')}
              text="Create"
              variant="outlined"
              fullWidth
              color="secondary"
              size="small"
            />

            <Button
              onClick={() => history.push('/features', { articles })}
              text="Feature"
              variant="outlined"
              fullWidth
              color="secondary"
              size="small"
            />
          </Box>

          <form noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <TextField
                id="filled-basic"
                onChange={e => handleSearchChange(e)}
                label="Search..."
                variant="filled"
              />
            </FormControl>
          </form>
          <ArticleList articles={searchResults} selectArticle={selectArticle} />
        </Grid>
        <Grid item lg={1} />
        <Grid item xs={8} lg={6} className={classes.scroll} style={mainHeight}>
          <ErrorMessage error={error ? error : deleteError} />

          {!currentArticle ? (
            <Container>
              <h3>Please select an article.</h3>
            </Container>
          ) : (
            <ArticleViewScreen
              article={currentArticle}
              deleting={deleting}
              handleDelete={handleDelete}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default ArticlesScreen;
