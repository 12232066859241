import moment from 'moment';
import { ScorecardResult as Result, ExerciseRecords } from 'types';

enum DateFormat {
  Default = 'ddd MMM Do, YYYY',
}

class Scorecard {
  readonly id?: string;
  readonly date: moment.Moment;
  readonly result?: Result;
  readonly kpi?: number;
  readonly why?: string;
  readonly plays?: string[];
  readonly shared?: 'true' | 'false' | 'false - Not on team' | undefined;
  readonly flagged?: boolean;
  readonly comments?: number;
  readonly cheers?: number;
  readonly createdAt?: Date;
  readonly exercises?: ExerciseRecords;

  constructor(
    date: moment.Moment,
    result?: Result,
    why?: string,
    createdAt?: Date,
    plays?: string[],
    kpi?: number,
    shared?: 'true' | 'false' | 'false - Not on team' | undefined,
    flagged?: boolean,
    id?: string,
    comments?: number,
    cheers?: number,
    exercises?: ExerciseRecords,
  ) {
    this.date = date;
    this.result = result;
    this.kpi = kpi;
    this.why = why;
    this.createdAt = createdAt;
    this.plays = plays;
    this.shared = shared;
    this.flagged = flagged;
    this.id = id;
    this.comments = comments;
    this.cheers = cheers;
    this.exercises = exercises;
  }

  public isPersisted(): boolean {
    return this.createdAt !== undefined;
  }

  public static fromScorecard(
    scorecard: Scorecard,
    newDate?: moment.Moment,
    newResult?: Result,
    newWhy?: string,
    newCreatedAt?: Date,
    newPlays?: string[],
    newKpi?: number,
  ): Scorecard {
    const { date, result, createdAt, plays, why, kpi } = scorecard;
    return new Scorecard(
      newDate || date,
      newResult || result,
      newWhy || why,
      newCreatedAt || createdAt,
      newPlays || plays,
      newKpi || kpi,
    );
  }
}

export default Scorecard;
export { DateFormat, Result };
