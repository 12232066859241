import React from 'react';
import {
  Box,
  TextField as MuiTextField,
  TextFieldProps,
  InputLabel,
} from '@material-ui/core';
import { slugify } from '../../../common';

type Props = {
  onKeyPress?: () => void;
  changeState?: (text: string) => void;
  gutterTop?: number;
  gutterBottom?: number;
  box?: any;
  title?: string;
};

const TextField: React.FC<Props & TextFieldProps> = ({
  onKeyPress,
  changeState,
  gutterTop = 0,
  gutterBottom = 0,
  title,
  box,
  ...props
}) => {
  // Format onChange function properly to setState or leave uncontrolled
  let onChangeFunc: any = changeState
    ? (e: any) => changeState(e.target.value)
    : undefined;

  // Handle legacy onChange prop textFields
  if (!changeState && props.onChange) {
    onChangeFunc = props.onChange;
  }

  // If onKeyPress is defined, call passed function
  const keyPressed = (event: any): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (onKeyPress) {
        onKeyPress();
      }
    }
  };

  return (
    <Box mt={gutterTop} mb={gutterBottom} style={{ width: '100%' }}>
      {title && <InputLabel id={slugify(title)}>{title}</InputLabel>}
      <MuiTextField
        onChange={onChangeFunc}
        variant="filled"
        onKeyPress={onKeyPress ? keyPressed : undefined}
        fullWidth={props.fullWidth ? props.fullWidth : true}
        name={props.label ? props.label.toString().toLowerCase() : undefined}
        {...props}
      />
    </Box>
  );
};

export default TextField;
