import React, { useState, useEffect } from 'react';
import MultipleChoice from './MultipleChoice';
import SingleInput from './SingleInput';
import DownloadScreen from '../DownloadScreen';
import {
  LoginCustom,
  ProgressBar,
  Page,
  Card,
  Confetti,
} from '../../components';
import { shortSurvey, defaultSurvey } from './defaultSurvey';
import { IconButton } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useUrlParams } from '../../hooks';
import { useHistory } from 'react-router-dom';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CalculatingBars from '../landing/components/CalculatingBars';

export type Question = {
  title: string;
  id: string;
  type: string; //'single' | 'multiple';
  question: string;
  options: string[];
  button: string;
};

type Answer = {
  [key: string]: string[] | [];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoSpacing: {
      paddingTop: '30px',
    },
    backButton: {
      position: 'fixed',
      top: '10px',
      left: '15px',
    },
  }),
);

export interface SurveyType {
  form: {} | any;
  currentStep: number;
  totalSteps: number;
}

const SurveyScreen: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  const params = useUrlParams();
  const isShort = params.get('version') === 'short';
  const survey = isShort ? shortSurvey : defaultSurvey;

  const [state, setState] = useState<SurveyType>({
    form: {},
    currentStep: 1,
    totalSteps: survey.length,
  });

  const updateForm = (newState: any) => {
    setState(newState);
  };

  // const skipAhead = () => {
  //   setState({ ...state, currentStep: 11 });
  // };

  const goBack = () => {
    setState({ ...state, currentStep: state.currentStep - 1 });
  };

  useEffect(() => {
    if (state.currentStep === -1) {
      history.push('/android');
    } else if (state.currentStep === 0) {
      history.push('/');
    }
  }, [state.currentStep]);

  const progress = ((state.currentStep - 1) / state.totalSteps) * 100;

  return (
    <>
      {state.currentStep === state.totalSteps + 1 && <Confetti />}
      <Page maxWidth="sm" fullPage={true}>
        {/* {currentUser && currentUser.teamsCoached && ( */}
        {/* <Button fullWidth onClick={skipAhead}>
        Skip Ahead
      </Button> */}
        {/* )} */}
        <IconButton
          className={classes.backButton}
          edge="start"
          // color="white"
          onClick={() => goBack()}>
          <ArrowBackIos style={{ height: '20px' }} />
        </IconButton>

        {/* <Typography color="textSecondary">
        Step {state.currentStep} of {state.totalSteps}
      </Typography> */}

        <Card padding>
          {state.currentStep <= state.totalSteps && (
            <ProgressBar value={progress} />
          )}
          {survey.map((question: Question, idx: number) =>
            question.type === 'singleInput' ? (
              <SingleInput
                key={question.id}
                question={question}
                updateForm={updateForm}
                parentState={state}
                currentStep={idx + 1}
              />
            ) : (
              <MultipleChoice
                key={question.id}
                question={question}
                updateForm={updateForm}
                parentState={state}
                currentStep={idx + 1}
              />
            ),
          )}
          {state.currentStep === state.totalSteps + 1 && (
            <div className={classes.logoSpacing}>
              <CalculatingBars isShort={isShort} parentState={state} />
            </div>
          )}
          {state.currentStep === state.totalSteps + 2 && (
            <div className={classes.logoSpacing}>
              <LoginCustom
                redirect="/checkout"
                raised={false}
                // currentStep={state.currentStep}
                // updateForm={updateForm}
                // parentState={state}
              />
            </div>
          )}
          {state.currentStep === state.totalSteps + 3 && (
            <div className={classes.logoSpacing}>
              <DownloadScreen parentState={state} />
            </div>
          )}
        </Card>
      </Page>
    </>
  );
};

export default SurveyScreen;
