import React from 'react';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Surveys as SurveysType } from '../../services/interfaces';
import { SurveyAnswers } from 'types';
import { useContainer, useCache } from '../../hooks';
import { SurveysService } from '../../services';

interface Props {
  userId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    question: {
      paddingTop: '10px',
      color: '#999',
    },
    header: {
      marginTop: '20px',
    },
  }),
);

const BioTab: React.FC<Props> = ({ userId }) => {
  const classes = useStyles();
  const container = useContainer();
  const surveysService = container.getInstance<SurveysService>(
    'surveysService',
  );
  const { loading, data } = useCache<
    | { [key in keyof SurveysType]: { name: string; answers: SurveyAnswers } }
    | null
  >(surveysService.getAnswersForSurvey, [userId]);

  return loading ? (
    <CircularProgress />
  ) : data ? (
    <>
      {Object.values(data).map((survey: any) => (
        <div key={survey.name}>
          <Typography variant="h6" className={classes.header} gutterBottom>
            {survey.name}
          </Typography>
          <Divider />
          {Object.entries(survey.answers).map(
            ([question, answers]: [any, any]) => (
              <div key={question}>
                <Typography variant="subtitle2" className={classes.question}>
                  {question}
                </Typography>
                {answers.map((answer: string) => (
                  <Typography key={answer} gutterBottom>
                    {answer}
                  </Typography>
                ))}
              </div>
            ),
          )}
        </div>
      ))}
    </>
  ) : (
    <Typography>No survey data.</Typography>
  );
};

export default BioTab;
