import moment from 'moment';
import Team from './Team';
import { Mascot } from './Mascot';
import { isPlanActive, defaultPlays } from 'common';
import { Platforms, PlaysObj, UserPlan } from 'types';

class Player {
  public id: string;
  public email: string;
  public name: string;
  public team?: Team;
  public streamToken?: string;
  public firstScorecardOn?: moment.Moment;
  public hasFinishedOnboarding: boolean;
  public teamsCoached?: { [teamId: string]: Team };
  public bio?: string;
  public avatar?: string;
  public firstScorecardPlayOn?: moment.Moment;
  public scorecardsCount: number;
  public scorecardPlaysEnabled?: boolean;
  public latestScorecardOn?: moment.Moment;
  public fcmTokens?: string[];
  public birthday?: string | undefined;
  public scorecardPlaysList: PlaysObj;
  public welcomed?: boolean;
  public mascot?: Mascot;
  public utcOffset?: number;
  public plan: UserPlan | undefined;
  public stripeId: string | undefined;
  public kpiEnabled?: boolean;
  public kpiLabel: string;
  public platform?: Platforms;
  public ratingShown?: number;
  public ratingSuccess?: number;

  constructor(
    id: string,
    email: string,
    name: string,
    team: Team | undefined,
    streamToken?: string,
    firstScorecardOn?: moment.Moment | string,
    hasFinishedOnboarding?: boolean,
    teamsCoached?: { [teamId: string]: Team },
    bio?: string,
    avatar?: string,
    firstScorecardPlayOn?: moment.Moment | string,
    scorecardsCount?: number,
    scorecardPlaysEnabled?: boolean,
    latestScorecardOn?: moment.Moment | string,
    fcmTokens?: string[],
    birthday?: string | undefined,
    scorecardPlaysList?: PlaysObj,
    welcomed?: boolean,
    mascot?: Mascot,
    utcOffset?: number,
    plan?: UserPlan | undefined,
    stripeId?: string | undefined,
    kpiEnabled?: boolean,
    kpiLabel?: string,
    platform?: Platforms,
    ratingShown?: number,
    ratingSuccess?: number,
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.hasFinishedOnboarding = hasFinishedOnboarding || false;
    this.team = team;
    this.teamsCoached = teamsCoached;
    this.streamToken = streamToken;
    this.bio = bio || '';
    this.fcmTokens = fcmTokens || [];
    this.avatar = avatar;
    this.scorecardsCount = scorecardsCount || 0;
    this.scorecardPlaysEnabled = scorecardPlaysEnabled || false;
    if (firstScorecardOn !== undefined) {
      this.firstScorecardOn = moment.isMoment(firstScorecardOn)
        ? firstScorecardOn
        : moment(firstScorecardOn);
    }
    if (firstScorecardPlayOn !== undefined) {
      this.firstScorecardPlayOn = moment.isMoment(firstScorecardPlayOn)
        ? firstScorecardPlayOn
        : moment(firstScorecardPlayOn);
    }
    if (latestScorecardOn !== undefined) {
      this.latestScorecardOn = moment.isMoment(latestScorecardOn)
        ? latestScorecardOn
        : moment(latestScorecardOn);
    }
    this.birthday = birthday;
    this.scorecardPlaysList = scorecardPlaysList
      ? scorecardPlaysList
      : defaultPlays;
    this.welcomed = welcomed;
    this.mascot = mascot;
    this.utcOffset = utcOffset;
    this.plan = plan;
    this.stripeId = stripeId;
    this.kpiEnabled = kpiEnabled;
    this.kpiLabel = kpiLabel ? kpiLabel : 'Mood';
    this.platform = platform;
    this.ratingShown = ratingShown;
    this.ratingSuccess = ratingSuccess;
  }

  public get isOnTeam(): boolean {
    return !!this.team;
  }

  public get isCoach(): boolean {
    return !!this.teamsCoached;
  }

  public get isNewPlayer(): boolean {
    return this.firstScorecardOn === undefined
      ? false
      : moment().diff(this.firstScorecardOn, 'days') < 7
      ? true
      : false;
  }

  public get isLapsedPlayer(): boolean {
    return this.latestScorecardOn === undefined
      ? true
      : !this.isNewPlayer && moment().diff(this.latestScorecardOn, 'days') > 7
      ? true
      : false;
  }

  public get lastScorecardFromNow(): string {
    return this.latestScorecardOn === undefined
      ? 'Never'
      : this.latestScorecardOn.fromNow();
  }

  // Need to push Cloud functions and then only have isPlanActive as conditional after 1 month
  // Estimated to do so on Aug 15, 2021
  public get isPro(): boolean {
    return !this.plan && !this.stripeId
      ? false
      : this.plan &&
        (this.plan.status === 'active' || this.plan.status === 'trialing')
      ? true
      : isPlanActive(this.plan);
  }
}

export type EditablePlayerFields = Partial<
  Pick<
    Player,
    | 'team'
    | 'bio'
    | 'avatar'
    | 'name'
    | 'firstScorecardOn'
    | 'latestScorecardOn'
    | 'fcmTokens'
    | 'hasFinishedOnboarding'
    | 'firstScorecardPlayOn'
    | 'scorecardPlaysEnabled'
    | 'birthday'
    | 'email'
    | 'scorecardPlaysList'
    | 'mascot'
    | 'kpiLabel'
    | 'kpiEnabled'
    | 'ratingShown'
    | 'ratingSuccess'
  >
>;

export default Player;
