import React from 'react';
import { Typography } from '@material-ui/core';

const TheRulesScreen: React.FC = () => {
  return (
    <>
      <img
        alt="MHL Training Celebration"
        src={require('../../assets/images/landing/the-rules.jpg')}
      />

      <Typography gutterBottom>
        Before you step onto the field, you have to learn the rules of the game.
        Here's what you need to know...
      </Typography>

      <Typography gutterBottom component="h2" variant="h5" color="primary">
        Rule #1: There Is No Off-Season
      </Typography>

      <Typography gutterBottom>
        Your opponent doesn't take any days off, so neither can you. It doesn't
        matter if you're feeling up, down, left, or right, you've got to get on
        the field and stick to your game plan. If you've been on a win streak,
        good, keep it that way. Your opponent is still there, just waiting for
        you to slip up. If you've been losing, that's fine – even the greats
        lose sometimes. Use it as fuel. Everyone loves an underdog.
      </Typography>

      <Typography gutterBottom component="h2" variant="h5" color="primary">
        Rule #2: You Are Already A Champion
      </Typography>

      <Typography gutterBottom>
        Beating your invisible opponent(s) will be ridiculously difficult. There
        will be dark days. Treatments will fail. Field conditions will be
        brutal. But you are not weak. You are not broken. Win or lose, you are a
        champion as long as you keep fighting.
      </Typography>

      <Typography gutterBottom component="h2" variant="h5" color="primary">
        Rule #3: Track &amp; Improve Every Day
      </Typography>

      <Typography gutterBottom>
        What gets measured, gets managed. If you want to win, you need to track
        your progress EVERY DAY and learn from both your wins and your losses.
        If you win, figure out why and keep doing it. If you lose, do the same.
        Then optimize your game plan and try the next day. To help you do this,
        we recommend using The MHL Scorecard – explained on the next page.
      </Typography>

      <Typography gutterBottom component="h2" variant="h5" color="primary">
        Rule #4: It's A Team Sport
      </Typography>

      <Typography gutterBottom>
        By yourself, you're tough, but with the team you can't be beaten. When
        things get tough, lean on your teammates and your fans for tips and
        motivation; and when you're feeling strong, give that energy to others.
        Isolation is never the answer.
      </Typography>

      <Typography gutterBottom component="h2" variant="h5" color="primary">
        Rule #5: Never Quit
      </Typography>

      <Typography gutterBottom>
        No matter what you're going through, quitting isn't the answer. Your
        team needs you. Your fans need you. Keep fighting and stage a comeback
        bigger than Lebron & Kyrie winning the championship after being down 3-1
        against the Warriors.
      </Typography>
    </>
  );
};

export default TheRulesScreen;
