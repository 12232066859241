import BaseService from './BaseService';
import {
  ScheduledPostsService,
  ScheduledPostPayload,
  ScheduledPost,
  SubscriptionListener,
} from './interfaces';
import { ApplicationError } from '../models/Errors';
import moment from 'moment';

const COLLECTION = 'scheduled-posts';
class ScheduledPosts extends BaseService implements ScheduledPostsService {
  private collection = this.firestore.collection(COLLECTION);
  public query = this.collection.orderBy('activity');

  public get = async (id: string): Promise<ScheduledPost> => {
    try {
      const docRef = this.collection.doc(id);
      const doc = await docRef.get();

      if (doc.exists) {
        return doc.data() as ScheduledPost;
      } else {
        // this.logger.error('Fetched article does not exist');
        throw new ApplicationError('ScheduledPost does not exist');
      }
    } catch (err) {
      throw new ApplicationError(
        'Error fetching this ScheduledPost. Try again.',
      );
    }
  };

  public updateOrCreatePost = async (
    scheduledPostPayload: ScheduledPostPayload,
  ): Promise<void> => {
    try {
      const {
        date,
        time,
        content,
        teamId,
        id,
        userId,
        activity,
        status,
      } = scheduledPostPayload;
      const runAt = new Date(date);
      runAt.setHours(time.getHours());
      runAt.setMinutes(0);
      runAt.setSeconds(0);

      // Excluding timing check for post now posts - which are marked as 'success' immediately
      if (status !== 'success' && moment(runAt) < moment()) {
        return Promise.reject('You cannot schedule posts in the past');
      }

      const post: Partial<ScheduledPost> = {
        status: status ? status : 'pending',
        teamId,
        post: {
          content,
        },
        runAt: runAt, //firebase.firestore.Timestamp.fromDate(runAt),
      };

      if (activity) {
        if (typeof activity.object === 'string') {
          activity.object = JSON.parse(activity.object);
        }
        post.activity = activity;
      }

      if (userId) {
        post.userId = userId;
      }

      if (id) {
        try {
          const scheduledPost = await this.collection.doc(id).get();
          await scheduledPost.ref.set(post);
        } catch (err) {
          this.logger.error(`Error updating scheduled post -${err}`);
        }
      } else {
        try {
          await this.collection.add(post);
        } catch (err) {
          this.logger.error(`Error creating new scheduled post -${err}`);
        }
      }
    } catch {
      throw new ApplicationError(
        'Is not possible to create your post, please try again later',
      );
    }
  };

  public findAll = async (teamId: string): Promise<ScheduledPost[]> => {
    try {
      return await this.collection
        // .where('userId', '==', teamId)
        .where('status', '==', 'pending')
        .where('teamId', '==', teamId)
        .get()
        .then(snapshots =>
          snapshots.docs.map(
            doc =>
              ({
                ...doc.data(),
                id: doc.id,
                runAt: doc.data().runAt.toDate(),
              } as ScheduledPost),
          ),
        );
    } catch {
      throw new ApplicationError('Unable to retrieve list');
    }
  };

  public delete = async (postId?: string): Promise<void> => {
    try {
      await this.collection.doc(postId).delete();
    } catch {
      throw new ApplicationError('Unable to delete post');
    }
  };

  public subscribeToAll = (
    listener: SubscriptionListener<ScheduledPost[]>,
    { teamId }: { teamId: string },
  ): (() => void | void) => {
    let team = teamId;

    // if (this.currentPlayer.team && this.currentPlayer.team.id) {
    //   team = this.currentPlayer.team.id;
    // }

    const subscription = this.collection
      .where('status', '==', 'pending')
      .where('teamId', '==', team)
      .onSnapshot(snapshot =>
        listener(
          snapshot.docs.map(
            doc =>
              ({
                ...doc.data(),
                id: doc.id,
                runAt: doc.data().runAt.toDate(),
              } as ScheduledPost),
          ),
        ),
      );
    return subscription;
  };
}

export default ScheduledPosts;
