import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElement, StripeCardElementOptions } from '@stripe/stripe-js';
import {
  Box,
  Card,
  InputAdornment,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Page, TextField, Button, Paragraph } from '../../components';
import SupportEmail from '../landing/components/SupportEmail';
import Avatar from '../../components/Avatar';
import { Lock as LockIcon } from '@material-ui/icons';
import { useCurrentUser } from '../../hooks';
import app from '../../base';
import logger from '../../services/LoggerService';
import { theme } from '../../assets/styles/theme';
import { useHistory } from 'react-router-dom';
import { fireFbEvent, fireGaEvent } from '../../services/AnalyticsService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useLead } from '../../hooks';

const CheckoutScreen: React.FC = () => {
  const history = useHistory();
  const { user: currentUser } = useCurrentUser();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { plan } = useLead();
  // const [planId] = useState<string | undefined>(plan.stripeId);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      logger.error('Stripe not loading');
      return;
    }

    const cardElement: StripeCardElement | null = elements.getElement(
      CardElement,
    );

    if (cardElement) {
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        setError(
          'Error occurred. Make you you filled out all fields. If problem persists, email hello@mentalhealthleague.com',
        );
        setLoading(false);
      }

      if (token) {
        await app
          .functions()
          .httpsCallable('onStartSubscription')({
            source: token.id,
            plan: plan.stripeId,
            trial_period_days: plan.trialLength,
          })
          .then(res => {
            fireFbEvent('StartTrial', {
              value: plan.price,
              currency: 'USD',
            });

            fireGaEvent('User', 'Start Trial');

            history.push(`/download?trial=${plan.trialLength}`);
          })
          .catch(err => {
            logger.error(`Error starting subscription - ${err}`);
            setError(
              'There was an error creating your subscription. Please try again. If problem persists, email hello@mentalhealthleague.com.',
            );
            setLoading(false);
          });
      }
    }
  };

  const CARD_OPTIONS: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: theme.palette.secondary.main,
        fontWeight: '500',
        // fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: '#999' },
        '::placeholder': { color: '#999' },
      },
      invalid: {
        // iconColor: '#ffc7ee',
        color: theme.palette.secondary.main,
      },
    },
  };

  const [stripeLoading, setStripeLoading] = useState(true);

  return (
    <Page fullPage disableGutters maxWidth="xs">
      <Box mb={3} mx={2}>
        <Typography align="center" variant="h4">
          Checkout
        </Typography>
        <Typography align="center" color="textSecondary" component="p">
          Free {plan.trialLength}-day trial. Cancel anytime.
        </Typography>
        {currentUser && currentUser.email && (
          <Typography
            align="center"
            variant="caption"
            color="textSecondary"
            component="p">
            {currentUser.email}
          </Typography>
        )}
      </Box>
      <TableContainer component={Paper} square>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Amount Paid Now
              </TableCell>
              <TableCell align="right">$0</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Monthly cost after trial
              </TableCell>
              <TableCell align="right">${plan.price}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Card square>
        {error && (
          <Paragraph slim color="secondary">
            {error}
          </Paragraph>
        )}

        <form onSubmit={handleSubmit}>
          {currentUser && !currentUser.email && (
            <Box mb={4}>
              <TextField
                id="email-input"
                gutterTop={1}
                label="Email"
                type="email"
                variant="outlined"
                box={'mt={5}'}
                value={currentUser ? currentUser.email : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}

          <Box mb={1} mt={2} px={2}>
            <Box mb={1}>
              <Typography variant="subtitle2" component="span">
                Enter payment info...
              </Typography>
              {stripeLoading && <CircularProgress size={18} />}
            </Box>
            <CardElement
              options={CARD_OPTIONS}
              onReady={(element: StripeCardElement) => {
                setStripeLoading(false);
                element.focus();
              }}
              // onFocus={() => }
            />
          </Box>
          <Box mt={2} mb={2} px={2}>
            <Button
              onClick={e => handleSubmit(e)}
              type="submit"
              disabled={loading}
              loading={loading}
              variant="contained"
              color="secondary"
              fullWidth
              text="Start Trial"
            />
          </Box>
        </form>
        {/* <Box mt={2}>
          <Button
            onClick={handleCancel}
            type="submit"
            disabled={loading}
            variant="contained"
            color="secondary"
            fullWidth
            size="large">
            Cancel Subscription
          </Button>
        </Box> */}
      </Card>

      <Box mx={2}>
        <SupportEmail />

        <Box mt={4} flexDirection="row" display="flex">
          <Avatar src="vosters-prof" />
          <div>
            <Typography variant="subtitle2" style={{ color: '#aaa' }}>
              "I'm excited to have you on the team. We'll be with you every step
              of the way."
            </Typography>
            <Typography variant="caption" style={{ color: '#aaa' }}>
              - Mike Vosters, MHL Commissioner
            </Typography>
          </div>
        </Box>
      </Box>
    </Page>
  );
};

export default CheckoutScreen;
