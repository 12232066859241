import React from 'react';
import {
  Paragraph,
  Box,
  Headline,
  Loading,
  Icon,
  PageBody,
  Button,
  Divider,
} from '../../components';
import { Question, Step } from 'types';
import { useContent, useNavigation } from '../../hooks';
import { brandColors } from 'src/assets/styles/colors';

interface Props {
  id?: string;
}

const ReflectionPreview: React.FC<Props> = ({ id }) => {
  const { reflectionsArr } = useContent();
  const reflection = reflectionsArr?.filter(
    reflection => reflection.id === id,
  )[0];
  const navigation = useNavigation();

  return (
    <Loading isLoading={!reflection}>
      {reflection ? (
        <>
          <Headline>
            <Icon name={reflection.icon} />
            {reflection.title}
          </Headline>

          <Box row mt={-2} mb={2}>
            <Paragraph bold style={{ marginRight: 7 }}>
              {reflection.status}
            </Paragraph>

            <Paragraph style={{ marginRight: 7 }}>
              // Sharing:{' '}
              {reflection.sharingEnabled
                ? reflection.sharingEnabled.toString()
                : 'false'}{' '}
              // Rating:{' '}
              {reflection.ratingEnabled
                ? reflection.ratingEnabled.toString()
                : 'false'}
            </Paragraph>
            {reflection.tags && (
              <Paragraph>// Tags: {reflection.tags.join(', ')}</Paragraph>
            )}
          </Box>
          <Box mb={2}>
            <Button
              variant="outlined"
              size="small"
              text="Edit"
              onPress={() =>
                navigation.navigate('/exercises/create', {
                  editing: reflection,
                })
              }
            />
          </Box>
          {reflection.data.questions.map((item: Question | Step, idx) => (
            <Box key={idx + 1} mb={2}>
              <Paragraph color="secondary" fine bold slim>
                Step {idx} | {item.type}
              </Paragraph>

              <Paragraph slim bold color="primary">
                {item.question}
              </Paragraph>

              {item.helper && <PageBody body={item.helper} />}

              {/* @ts-ignore */}
              {item.options && (
                <>
                  {/* @ts-ignore */}
                  {item.options.map((option, idx) => (
                    <Paragraph slim light key={`${idx}-${option}`}>
                      {/* @ts-ignore */}
                      {idx} - {option}
                    </Paragraph>
                  ))}
                </>
              )}
              <Box mt={2}>
                <Divider />
              </Box>
            </Box>
          ))}

          {reflection.celebration && (
            <>
              <Paragraph color="secondary" fine bold slim>
                Celebration Text
              </Paragraph>
              <Paragraph slim bold color="primary">
                {reflection.celebration?.headline}
              </Paragraph>

              <Paragraph slim light>
                {/* @ts-ignore */}
                {reflection.celebration?.text}
              </Paragraph>
            </>
          )}
        </>
      ) : (
        <Paragraph>No reflection found</Paragraph>
      )}
    </Loading>
  );
};

export default ReflectionPreview;
