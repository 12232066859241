import app from '../base';
import { TeamPlayer } from '../models/TeamPlayer';
import moment from 'moment';

const PLAYERS_COLLECTION = 'players';
const playersCollection = app.firestore().collection(PLAYERS_COLLECTION);

const makePlayersInTeam = () => {
  const cache: { [key: string]: () => Promise<TeamPlayer[]> } = {};
  return function playersInTeamQuery(
    teamId?: string,
  ): () => Promise<TeamPlayer[]> {
    const teamFilter = teamId ? teamId : 'allTeams';
    if (cache[teamFilter]) return cache[teamFilter];
    const fetch = (): Promise<TeamPlayer[]> => findAllPlayersByTeam(teamId);
    cache[teamFilter] = fetch;
    return fetch;
  };
};

export const findAllPlayersByTeam = (
  teamID?: string,
): Promise<TeamPlayer[]> => {
  let query = playersCollection.where('name', '>', '');

  if (teamID) {
    query = query.where('teamId', '==', teamID);
  }

  return query.get().then(playersSnapshot =>
    playersSnapshot.docs
      .filter(
        playerDoc =>
          playerDoc.data() !== undefined &&
          // Filter out players that haven't been active recently
          moment(playerDoc.data().latestScorecardOn) >
            moment().subtract(14, 'days'),
      )
      .map(playerDoc => ({
        id: playerDoc.id,
        name: playerDoc.data().name,
        email: playerDoc.data().email,
        bio: playerDoc.data().bio,
        avatar: playerDoc.data().avatar,
        latestScorecardOn: playerDoc.data().latestScorecardOn,
        firstScorecardOn: playerDoc.data().firstScorecardOn,
        createdAt: moment(playerDoc.data().createdAt.toDate()),
      })),
  );
};

export const playersInTeam = makePlayersInTeam();

export default {
  findAllPlayersByTeam,
};
