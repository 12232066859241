import React, { useState, createContext } from 'react';
import {
  FlatFeed,
  StreamClient,
  FeedRequestOptions,
  FeedResponse,
} from 'react-activity-feed';
import { Typography } from '@material-ui/core';
import { GridProps } from '@material-ui/core/Grid';
import { useCurrentUser } from '../../hooks';
import { InvalidPlayerProfileError } from '../../models/Errors';
import ActivityComponent from './ActivityComponent';
import { Spinner, Paragraph, Grid } from '../../components';
import { TeamPlayer } from '../../models/TeamPlayer';
import PlayerProfile from '../player/PlayerProfile';

type Props = {
  teamId: string;
};

interface UserProfileContextType {
  selectedPlayer: TeamPlayer | null;
  updateSelectedPlayer: any;
  selectedTeamId: string;
}

export const UserProfileContext = createContext<UserProfileContextType>({
  selectedPlayer: null,
  updateSelectedPlayer: null,
  selectedTeamId: 'noTeam',
});

const GetStreamFeed: React.FC<Props & GridProps> = ({ teamId, ...props }) => {
  // const classes = useStyles();
  const { user:currentUser } = useCurrentUser();

  // Load User Profile
  const [selectedPlayer, setSelectedPlayer] = useState<TeamPlayer | null>(null);

  const updateSelectedPlayer = (player: TeamPlayer): void => {
    setSelectedPlayer(player);
  };

  if (!currentUser?.streamToken) {
    // return <ErrorScreen error={new InvalidPlayerProfileError()} />;
    return <Paragraph>No Stream Token</Paragraph>;
  }

  const doFeedRequest = (
    teamId: string,
    client: StreamClient,
    feedGroup: string,
    userId?: string,
    options?: FeedRequestOptions,
  ): Promise<FeedResponse> => {
    if (!teamId) {
      return Promise.reject(new InvalidPlayerProfileError());
    }

    return client.feed(feedGroup, teamId).get({
      user_id: userId,
      withRecentReactions: true,
      withOwnReactions: true,
      withOwnChildren: true,
      ...options,
    });
  };

  return (
    <UserProfileContext.Provider
      value={{ selectedPlayer, updateSelectedPlayer, selectedTeamId: teamId }}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} sm={6} {...props}>
          <FlatFeed
            key={teamId}
            feedGroup="team"
            // options={{ reactions: { recent: true } }}
            userId={currentUser.id}
            LoadingIndicator={Spinner}
            Placeholder={
              <Typography>
                Nothing to see here. Fill out your scorecard to get the team
                started.
              </Typography>
            }
            doFeedRequest={(...args: any) =>
              doFeedRequest(teamId, args[0], args[1], args[2], args[3])
            }
            // Notifier={NotifierComponent}
            Activity={ActivityComponent}
            notify
          />
        </Grid>
        <Grid item xs={12} sm={6} {...props}>
          {selectedPlayer && <PlayerProfile player={selectedPlayer} />}
        </Grid>
      </Grid>
    </UserProfileContext.Provider>
  );
};

export default GetStreamFeed;
