import React from 'react';
// import { Experiment, Variant } from 'react-optimize';
import Landing1 from './landing/Landing1';
import Landing2 from './landing/Landing2';
import Landing3 from './landing/Landing3';
import Landing4 from './landing/Landing4';
import Landing5 from './landing/Landing5';
import Landing8 from './landing/Landing8';
import Landing9 from './landing/Landing9';
import LandingBuzzfeed from './landing/LandingBuzzfeed';
import { useRouteMatch } from 'react-router-dom';

const LandingScreen = () => {
  let match = useRouteMatch('/join/:version');
  let version;

  if (match) {
    version = match.params.version;
  }

  const Version =
    +version === 8
      ? Landing8
      : +version === 5
      ? Landing5
      : +version === 4
      ? Landing4
      : +version === 3
      ? Landing3
      : +version === 2
      ? Landing2
      : +version === 1
      ? Landing1
      : version === '6-reasons-why'
      ? LandingBuzzfeed
      : Landing9;

  return <Version />;
};

export default LandingScreen;
