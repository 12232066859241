export type BrandColors = keyof typeof brandColors;

export const brandColors: { [key: string]: string } = {
  brandPrimary: '#E7004C',
  brandPrimaryDark: '#A20735',
  brandPrimaryLight: '#ff5677',
  brandSecondary: '#0b2240',
  brandSecondaryLight: '#38496B',
  brandSecondaryDark: '#00001B',
  brandInfo: '#B79D4B', //'#C6AC5A',
  brandInfoDark: '#856f1d', //'#937D2D',
  brandInfoLight: '#ebce79', //'#FBDE89',
  brandSuccess: '#5cb85c',
  brandDanger: '#d9534f',
  brandWarning: '#f0ad4e',
  brandDark: 'rgba(11, 36, 64, 1)',
  brandDarkLight: 'rgba(11, 36, 64, 0.5)',
  brandLight: 'rgba(0, 0, 0, 0.37)',
  lightGray: '#838383',
  offWhite: '#EFEFEF',
  white: '#ffffff',
  placeholderGray: '#dbdbdb',
  dimGray: '#6b6b6b',
  lavender: '#e8e9ed',
  anxSecondary: '#21362C',
  anxSecondaryLight: '#45763C',
  anxPrimary: '#B87333',
  anxPrimaryLight: '#EEA260',
  anxPrimaryDark: '#844703',
  textColor: 'rgba(0, 0, 0, 0.6)',
  lightTextColor: 'rgba(0, 0, 0, 0.35)',
  darkTextColor: 'rgba(0, 0, 0, 0.87)',
};
