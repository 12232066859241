import React from 'react';
import Paragraph, { ParagraphProps } from './Paragraph';

const Fine: React.FC<ParagraphProps> = ({ children, ...props }) => {
  return (
    <Paragraph fine light style={{ lineHeight: '22px' }} {...props}>
      {children}
    </Paragraph>
  );
};

export default Fine;
