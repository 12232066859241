import React from 'react';
import { default as MuiStepper } from '@material-ui/core/Stepper';
import StepButton from '@material-ui/core/StepButton';
import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import { AboutPageObj } from '../flows/static/StaticNavigator';
import { useHistory, useParams } from 'react-router';
import { staticPages } from '../flows/static';

type Props = {
  steps: string[];
  about: boolean;
};

const Stepper: React.FC<Props> = ({ steps, about, children }) => {
  let { pageId } = useParams();
  let activeStep = steps.indexOf(pageId);
  const history = useHistory();

  return (
    <>
      <MuiStepper nonLinear alternativeLabel activeStep={activeStep}>
        {steps.map((key: string) => {
          const page: AboutPageObj = staticPages[key];
          return (
            <Step key={page.url}>
              <StepButton onClick={() => history.push(`${page.url}`)}>
                {page.title.slice(4)}
              </StepButton>
            </Step>
          );
        })}
      </MuiStepper>
      {children}

      <Box display="flex" mt={4} justifyContent="space-between">
        {activeStep !== 0 ? (
          <Button
            disabled={activeStep === 0}
            onClick={() => {
              window.scrollTo(0, 0);
              return history.push(staticPages[steps[activeStep - 1]].url);
            }}>
            Back
          </Button>
        ) : (
          <Button />
        )}
        {activeStep < steps.length - 1 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              window.scrollTo(0, 0);
              return history.push(staticPages[steps[activeStep + 1]].url);
            }}>
            Next
          </Button>
        )}
      </Box>
    </>
  );
};

export default Stepper;
