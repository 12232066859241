import React, { ReactNode } from 'react';
import {
  Container,
  Grid,
  CssBaseline,
  ContainerProps,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import NavPadding from './NavPadding';
import Spinner from './Spinner';
import ErrorMessage from './ErrorMessage';
import Logo from './Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      height: 'calc(100vh + 13px)',
      justifyContent: 'center',
      textAlign: 'center',
    },
    gradient: {
      background: `linear-gradient(90deg, ${
        theme.palette.secondary.main
      } 30%, ${theme.palette.primary.main} 90%)`,
    },
    logoFixed: {
      position: 'absolute',
      width: '100%',
      top: 0,
    },
  }),
);

interface Props {
  children: ReactNode | HTMLCollection;
  gradient?: boolean;
  fullPage?: boolean;
  disableGutters?: boolean;
  error?: Error | string;
  loading?: boolean;
}

const Page: React.FC<Props & ContainerProps> = ({
  children,
  gradient,
  fullPage,
  disableGutters,
  loading = false,
  error = '',
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="sm"
        component="main"
        disableGutters={disableGutters ? disableGutters : false}
        {...props}>
        {fullPage && <NavPadding />}
        <Logo background />
        <Grid
          container
          className={`${!gradient ? '' : classes.gradient} ${
            fullPage ? '' : classes.center
          }`}>
          <Grid xs={12} item>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <ErrorMessage error={error} />
                {children}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Page;
