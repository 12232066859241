import React, { useState } from 'react';

export const Lead = React.createContext<any>({
  lead: null,
  setLead: null,
});

export type PlanPrice = {
  id: string;
  stripeId: string | undefined;
  price: number;
  trialLength: number;
};

export const plans: { [key: string]: PlanPrice } = {
  monthly5: {
    id: 'monthly5',
    stripeId: process.env.REACT_APP_STRIPE_PLAN_5,
    price: 5,
    trialLength: 7,
  },
  monthly9: {
    id: 'monthly9',
    stripeId: process.env.REACT_APP_STRIPE_PLAN_9,
    price: 9,
    trialLength: 7,
  },
  monthly10: {
    id: 'monthly10',
    stripeId: process.env.REACT_APP_STRIPE_PLAN_10,
    price: 10,
    trialLength: 7,
  },
  monthly14: {
    id: 'monthly14',
    stripeId: process.env.REACT_APP_STRIPE_PLAN_14,
    price: 14,
    trialLength: 7,
  },
  monthly20: {
    id: 'monthly20',
    stripeId: process.env.REACT_APP_STRIPE_PLAN_14,
    price: 20,
    trialLength: 7,
  },
  monthly25: {
    id: 'monthly25',
    stripeId: process.env.REACT_APP_STRIPE_PLAN_25,
    price: 25,
    trialLength: 7,
  },
  monthly999: {
    id: 'monthly999',
    stripeId: process.env.REACT_APP_STRIPE_PLAN_999,
    price: 9.99,
    trialLength: 7,
  },
  monthly1999: {
    id: 'monthly1999',
    stripeId: process.env.REACT_APP_STRIPE_PLAN_1999,
    price: 19.99,
    trialLength: 7,
  },
  monthly995: {
    id: 'monthly995',
    stripeId: process.env.REACT_APP_STRIPE_PLAN_995,
    price: 9.95,
    trialLength: 7,
  },
};

export const LeadProvider: React.FC = ({ children }) => {
  const [lead, setLead] = useState(null);
  const [plan, togglePlan] = useState(plans.monthly9);

  const setPlan = (newPlan: keyof typeof plans) => {
    if (plans[newPlan]) {
      togglePlan(plans[newPlan]);
    }
  };

  return (
    <Lead.Provider
      value={{
        lead,
        setLead,
        plan,
        setPlan,
      }}>
      {children}
    </Lead.Provider>
  );
};
