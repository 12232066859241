export enum Collections {
  Exercises = 'exercises',
  Players = 'players',
  Articles = 'articles',
  Quotes = 'quotes',
  ScheduledPosts = 'scheduled-posts',
  Teams = 'teams',
  Features = 'articles-featured',
  ArticleRatings = 'article-ratings',
  GamePlans = 'gameplans',
  Analytics = 'analytics',
  Discussions = 'discussions',
}
