type ErrorField<T> = keyof T | 'base';

class ModelError<T> extends Error {
  private _errors: { [key: string]: string[] };

  constructor() {
    super(`Model contains errors`);
    Object.setPrototypeOf(this, ModelError.prototype);

    this._errors = {};
  }

  public addError(field: ErrorField<T>, message: string): void {
    if (field in this._errors) {
      this._errors[field as string].push(message);
    } else {
      this._errors[field as string] = [message];
    }
  }

  public fieldHasError(field: ErrorField<T>): boolean {
    return field in this._errors;
  }

  public errorsForField(field: ErrorField<T>): string[] {
    return this._errors[field as string] || [];
  }

  public hasErrors(): boolean {
    return Object.keys(this._errors).length > 0;
  }

  public errors(): string[] {
    const messages: string[] = [];
    for (const key of Object.keys(this._errors)) {
      messages.push(...this._errors[key]);
    }
    return messages;
  }
}

export default ModelError;
