import FirebasePlayer, { docForPlayer } from '../models/FirebasePlayer';
import firebase from 'firebase/app';
import FirebaseTeam, {
  docForTeam,
  isFirebaseTeam,
} from '../models/FirebaseTeam';
import mascotList, { defaultMascot } from '../models/MascotList';
// import Mascot, { MascotId } from '../models/Mascot';
import { Player, Mascot, MascotId, Team } from 'types';
import logger from '../services/LoggerService';
import { FbUser, DocumentSnapshot } from './interfaces';
import { compareObjects, defaultPlays } from 'common';

class ObserveCurrentPlayer {
  private auth: FbUser | null = null;
  private authUnsubscriber?: () => void;
  private user?: Player;
  private userUnsubscriber?: () => void;
  private setUser: React.Dispatch<React.SetStateAction<Player | null>>;
  private setLoading: React.Dispatch<React.SetStateAction<boolean>>;

  constructor(
    setUser: React.Dispatch<React.SetStateAction<Player | null>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) {
    this.setUser = setUser;
    this.setLoading = setLoading;
  }

  public subscribe(): void {
    // Stop any existing subscriptions
    this.unsubscribe();

    this.authUnsubscriber = firebase
      .auth()
      .onAuthStateChanged((newAuth: FbUser | null) => {
        // Unsubscribe from previous userDoc listener if exists
        if (this.userUnsubscriber) {
          this.userUnsubscriber();
        }

        // If no user, set state to default (pass no parameters)
        if (!newAuth) {
          this.setUser(null);
          this.setLoading(false);
          this.user = undefined;
          this.auth = null;
        } else {
          // Set loading state while user doc is built
          this.setLoading(true);

          // Set the user id for analytics
          firebase.analytics().setUserId(newAuth.uid);

          // If auth changed, subscribe to userDoc
          // Removing conditional bc it was blocking setUser - was from legacy code and don't think it's necessary
          this.auth = newAuth;
          this.subscribeToUserDoc(newAuth);
        }
      });

    return;
  }

  public unsubscribe(): void {
    if (this.authUnsubscriber) this.authUnsubscriber();
    if (this.userUnsubscriber) this.userUnsubscriber();
  }

  private subscribeToUserDoc = async (newAuth: FbUser): Promise<void> => {
    this.userUnsubscriber = docForPlayer(newAuth.uid).onSnapshot(
      async (snapshot: DocumentSnapshot) => {
        const firePlayer = snapshot.data() as FirebasePlayer;

        // If user isn't logged in or doc doesn't exist
        if (!newAuth.email || !firePlayer || !snapshot) {
          this.setUser(null);
          this.setLoading(false);
          return Promise.resolve();
        }

        let team: Team | undefined;
        if (firePlayer.teamId) {
          let firebaseTeam: FirebaseTeam | undefined;

          try {
            const teamSnapshot = await docForTeam(firePlayer.teamId).get();
            const teamData = teamSnapshot.data() || {};

            if (isFirebaseTeam(teamData)) {
              firebaseTeam = teamData as FirebaseTeam;
            }
          } catch (error) {
            logger.error(
              `Failed to load team id '${firePlayer.teamId}' for player '${firePlayer.id}': ${error}`,
            );
          }

          if (firebaseTeam) {
            const mascot = mascotList[firebaseTeam.mascotId] || defaultMascot;
            team = {
              id: firePlayer.teamId,
              name: firebaseTeam.name,
              mascot: mascot,
              visibility: firebaseTeam.visibility,
            };
            firebase.analytics().setUserProperties({ Team: firebaseTeam.name });
            firebase.analytics().setUserProperties({ Mascot: mascot.name });
          }
        }

        // Build user state
        let chosenMascot: Mascot | undefined;
        if (firePlayer.mascotId) {
          chosenMascot = mascotList[firePlayer.mascotId];
        } else if (team && team.mascot) {
          chosenMascot = team.mascot;
        } else {
          chosenMascot = mascotList[MascotId.MHL];
        }

        let teamsCoached: { [teamId: string]: Team } | undefined;
        if (firePlayer.teamsCoached) {
          teamsCoached = {};
          for (const teamSnapshot of await Promise.all(
            Object.keys(firePlayer.teamsCoached).map(teamId =>
              docForTeam(teamId).get(),
            ),
          )) {
            const coachedTeam = teamSnapshot.data() as FirebaseTeam;

            firebase.analytics().setUserProperties({
              Role: coachedTeam ? 'Coach' : 'Player',
            });
            if (!coachedTeam) continue;
            const mascot = mascotList[coachedTeam.mascotId];
            if (mascot) {
              teamsCoached[teamSnapshot.id] = {
                id: teamSnapshot.id,
                name: coachedTeam.name,
                visibility: coachedTeam.visibility,
                mascot,
              };
            }
          }
        }

        const newPlayer = new Player(
          newAuth.uid,
          newAuth.email,
          (firePlayer ? firePlayer.name : newAuth.displayName) || '',
          team || undefined,
          snapshot.exists ? firePlayer.streamToken : undefined,
          snapshot.exists ? firePlayer.firstScorecardOn : undefined,
          snapshot.exists ? firePlayer.hasFinishedOnboarding : undefined,
          teamsCoached,
          snapshot.exists ? firePlayer.bio : undefined,
          snapshot.exists ? firePlayer.avatar : undefined,
          snapshot.exists ? firePlayer.firstScorecardPlayOn : undefined,
          snapshot.exists ? firePlayer.scorecardsCount : undefined,
          snapshot.exists ? firePlayer.scorecardPlaysEnabled : undefined,
          snapshot.exists ? firePlayer.latestScorecardOn : undefined,
          snapshot.exists ? firePlayer.fcmTokens : undefined,
          snapshot.exists ? firePlayer.birthday : undefined,
          snapshot.exists && !!firePlayer.scorecardPlaysList
            ? firePlayer.scorecardPlaysList
            : defaultPlays,
          snapshot.exists ? firePlayer.welcomed : undefined,
          chosenMascot,
          snapshot.exists ? firePlayer.utcOffset : undefined,
          snapshot.exists ? firePlayer.plan : undefined,
          snapshot.exists ? firePlayer.stripeId : undefined,
          snapshot.exists ? firePlayer.kpiEnabled : undefined,
          snapshot.exists ? firePlayer.kpiLabel : undefined,
        );

        if (
          !this.user ||
          (this.user && !compareObjects<Player>(this.user, newPlayer))
        ) {
          this.user = newPlayer;
          this.setUser(newPlayer);
        }
        this.setLoading(false);

        firebase.analytics().setUserProperties({
          PlaysEnabled: firePlayer.scorecardPlaysEnabled
            ? 'PlaysOn'
            : 'PlaysOff',
        });
      },
    );
  };
}

export default ObserveCurrentPlayer;
