import React from 'react';
import { ListItemIcon, ListItemText, List, ListItem } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

type Props = {
  bullets: string[];
};

const UL: React.FC<Props> = ({ bullets }) => {
  return (
    <List component="ul" disablePadding>
      {bullets.map((bullet, idx) => (
        <ListItem key={idx} disableGutters>
          <ListItemIcon
            style={{
              minWidth: '50px',
              alignSelf: 'flex-start',
              marginTop: '10px',
            }}>
            <CheckCircleIcon color="primary" />
          </ListItemIcon>
          <ListItemText id="switch-list-label-wifi" primary={bullet} />
        </ListItem>
      ))}
    </List>
  );
};

export default UL;
