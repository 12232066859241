import { Moment } from 'moment';

import Scorecard from './Scorecard';

class Game {
  public date: Moment;
  public scorecard?: Scorecard;

  constructor(date: Moment, scorecard?: Scorecard) {
    this.date = date;
    this.scorecard = scorecard;
  }
}

export default Game;
