import React, { useState, ChangeEvent } from 'react';
import {
  Collapse,
  CardContent,
  InputAdornment,
  IconButton,
  TextField,
} from '@material-ui/core';
import { ApplicationError } from '../../models/Errors';
import { Send as SendIcon } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { mhlNavyLightbg } from '../../assets/styles/theme';

interface Props {
  onAddComment(comment: string): Promise<void>;
  showCommentBox: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    body: {
      paddingTop: '10px',
    },
    collapse: {
      backgroundColor: mhlNavyLightbg,
    },
  }),
);

const CommentBoxComponent: React.FC<Props> = ({
  onAddComment,
  showCommentBox,
}) => {
  const classes = useStyles();
  const [text, setText] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleAddComment = async (event?: React.MouseEvent): Promise<void> => {
    if (event) {
      event.preventDefault();
    }

    if (text.length <= 0) {
      setError('Comments can not be blank.');
      new ApplicationError('Comments can not be blank.');
      return;
    }
    try {
      // Note: No need to clean up state as the comment box is unmounted on success
      await onAddComment(text);
      setText('');
    } catch (error) {
      setError(error);
    }
  };

  const keyPressed = (event: any): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddComment();
    }
  };

  return (
    <Collapse in={showCommentBox} timeout="auto" className={classes.collapse}>
      <CardContent className={classes.body}>
        {showCommentBox && (
          <TextField
            error={!!error}
            multiline
            fullWidth
            helperText={error}
            id="comment-input"
            value={text}
            autoFocus
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ): void => setText(event.target.value)}
            onKeyPress={keyPressed}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={!text}
                    aria-label="add comment"
                    color="primary"
                    onClick={(e): any => handleAddComment(e)}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </CardContent>
    </Collapse>
  );
};

export default CommentBoxComponent;
