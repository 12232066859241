import React from 'react';
import OgConfetti from 'react-confetti';
import { brandColors } from '../assets/styles/colors';
import Box from './Box';

const Confetti = () => {
  return (
    <Box style={{ zIndex: -1 }}>
      <OgConfetti
        recycle
        colors={[
          brandColors.brandInfoLight,
          brandColors.brandInfo,
          brandColors.brandInfoDark,
        ]}
        gravity={0.25}
        initialVelocityY={250}
      />
    </Box>
  );
};

export default Confetti;
