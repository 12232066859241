import React from 'react';
import { SectionHalves, Paragraph } from '../../../components';
import {
  Typography,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
} from '@material-ui/core';

type Props = {
  tier?: number;
  size?: 'lg' | 'sm';
  button?: React.ReactElement; //{ text: string; onClick: () => void; icon?: any };
};

const bullets = [
  'Fill out an application',
  'Get paired with a coaching staff and team just like you',
  'Follow our daily mental health game plan including articles, pep talks, journaling, and habit tracking',
  'Get a better understanding of your mental health and how to manage it',
];

const notebook = [
  'Write about the event',
  'Note the emotion',
  'Track the intensity',
  'Identify patterns',
  'Reframe your perspective',
];

const tiers = [bullets, notebook];

const HowItWorks: React.FC<Props> = ({ tier, size, button }) => {
  return (
    <>
      <SectionHalves
        src="mhl_asset_3"
        title={tier === 1 ? 'How does it work?' : 'How It Works'}
        button={button}
        body={
          <>
            <Paragraph gutterBottom>
              MHL uses Cognitive Behavior Therapy to help you tackle your
              anxiety and depression. CBT is considered the “gold standard” of
              therapy for managing anxiety. CBT was developed by psychiatrist
              Aaron Beck in the 1960s. He noticed that his clients often engaged
              in destructive thought patterns that negatively affected their
              feelings and behaviors. He reasoned that if they could change the
              stories they told themselves, their resulting feelings and
              behaviors might change, too.
            </Paragraph>

            <List component="ol">
              {tiers[tier && tiers[tier] ? tier : 0].map((bullet, idx) => (
                <ListItem key={idx} disableGutters>
                  <ListItemIcon
                    style={{
                      minWidth: '50px',
                      alignSelf: 'flex-start',
                      marginTop: '10px',
                    }}>
                    <Typography
                      style={{
                        backgroundColor: 'white',
                        borderRadius: '60px',
                        width: '35px',
                        height: '35px',
                        textAlign: 'center',
                        color: '#0b2240',
                      }}>
                      <b>{idx + 1}</b>
                    </Typography>
                  </ListItemIcon>
                  <ListItemText id="switch-list-label-wifi" primary={bullet} />
                </ListItem>
              ))}
            </List>
          </>
        }
      />
    </>
  );
};

export default HowItWorks;
