import React from 'react';
import { Grid, GridProps, Box } from '@material-ui/core';
import { Headline, Paragraph, UL } from '../../../components';

const bullets = [
  'The No Off-Season Mindset',
  'Develop keystone habits',
  'Defining your win',
  'Reframing your losses',
  'Understanding your triggers',
  'Tackling overthinking',
  'Changing your reference points',
  'No zero days',
  'The power of routine',
  'Taming comparison traps',
  'Overcoming thought distortions',
  'Building mental resilience',
  'Coping mechanisms & life hacks',
  'Utilizing smart goals',
  'The power of teamwork',
];
const arrHalf = Math.floor(bullets.length / 2);

const Syllabus: React.FC<GridProps> = ({ ...props }) => {
  return (
    <Grid container spacing={4} alignItems="center" {...props}>
      <Grid md={4} item>
        <Box mt={1}>
          <Headline gutterBottom>What You'll Learn</Headline>
          <Paragraph>
            Core psychology and behavior change to help tame your stress,
            depression, and other challenges.
          </Paragraph>
        </Box>
      </Grid>
      <Grid md={4} item>
        <UL bullets={bullets.slice(0, arrHalf)} />
      </Grid>
      <Grid md={4} item>
        <UL bullets={bullets.slice(arrHalf + 1)} />
      </Grid>
    </Grid>
  );
};

export default Syllabus;
