import React, { useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { Question, SurveyType } from './SurveyScreen';
import { TextField } from '../../components';
import { fireFbEvent } from '../../services/AnalyticsService';

interface Props {
  question: Question;
  updateForm: (newState: any) => void;
  parentState: SurveyType;
  currentStep: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '20px',
      textAlign: 'center',
      alignItems: 'center',
    },
    nextBtn: {
      position: 'fixed',
      bottom: 0,
      width: '105%',
    },
    gutterBottomHalf: {
      marginBottom: '8px',
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '& button': {
        marginBottom: '15px',
        borderRadius: '4px !important',
        borderColor: 'rgba(0, 0, 0, 0.12) !important',
      },
    },
    selected: {
      color: `${theme.palette.primary.main} !important`,
    },
    gutterTop: {
      marginTop: theme.spacing(1),
    },
  }),
);

const SingleInput: React.FC<Props> = ({
  question,
  updateForm,
  parentState,
  currentStep,
}) => {
  const classes = useStyles();

  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const handleNext = (goToStep: number, singleAnswer?: string[]) => {
    if (!value) {
      setError('Answer required!');
      return;
    }

    const newState = {
      ...parentState,
      form: {
        ...parentState.form,
        [question.id]: value,
      },
      currentStep: goToStep,
    };

    if (question.id === 'email') {
      fireFbEvent('Lead');
    }

    updateForm(newState);
  };

  return currentStep !== parentState.currentStep ? null : (
    // return (
    <div className={classes.questionContainer}>
      <Typography
        variant="subtitle2"
        color="primary"
        style={{ fontWeight: 900 }}
        className={classes.gutterBottomHalf}>
        {question.question}
      </Typography>
      <Typography
        color="textSecondary"
        gutterBottom
        variant="caption"
        component="p">
        One more thing...
      </Typography>
      {error && (
        <Typography color="error" variant="subtitle2" gutterBottom>
          {error}
        </Typography>
      )}

      <TextField
        id="email-input"
        fullWidth
        autoFocus
        label="Email"
        type="email"
        value={value}
        onKeyPress={() => handleNext(parentState.currentStep + 1)}
        changeState={setValue}
      />

      <Button
        onClick={() => handleNext(parentState.currentStep + 1)}
        variant="contained"
        color="secondary"
        fullWidth
        className={classes.nextBtn}
        disabled={!value}
        size="large">
        {parentState.totalSteps !== currentStep ? 'Next' : 'Submit'}
      </Button>
    </div>
  );
};

export default SingleInput;
