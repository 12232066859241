import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress, {
  LinearProgressProps,
} from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

type Props = {
  value: number;
};

const ProgressBar: React.FC<Props & LinearProgressProps> = ({
  value,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1} mt={3}>
          <LinearProgress value={value} variant="determinate" {...props} />
        </Box>
      </Box>
      {/* <LinearProgressWithLabel value={value} {...props} /> */}
    </div>
  );
};

export default ProgressBar;
