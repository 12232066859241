import React from 'react';
import { RawDraftContentState } from 'draft-js';
import Paragraph from '../Paragraph';
import RichText from '../RichText';

type Props = {
  body: string | RawDraftContentState;
};

const PageBody: React.FC<Props> = ({ body }) => {
  return (
    <>
      {typeof body === 'string' ? (
        <Paragraph>{body}</Paragraph>
      ) : (
        <RichText state={body} />
      )}
    </>
  );
};

export default PageBody;
