import React, { PropsWithChildren } from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
} from '@material-ui/core';
import { slugify } from 'common';
import { OptionValue, IconNames } from 'types';
import Icon from '../Icon';

interface Props<T extends OptionValue> {
  options: T[];
  title: string;
  onChange: (e: any) => void;
  selected: T;
  type?: 'icon';
}

const Select = <T extends string | number>(
  props: PropsWithChildren<Props<T>>,
) => {
  const { title, options, onChange, selected, type } = props;
  const id = title.toLowerCase();

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel id={id}>{title}</InputLabel>
      <MuiSelect
        labelId={id}
        id={id}
        value={selected}
        onChange={e => onChange(e.target.value as T)}>
        {options.map(option => (
          <MenuItem key={`${slugify(option)}`} value={`${slugify(option)}`}>
            {/* @ts-ignore */}
            {type === 'icon' && <Icon name={option as IconNames} />}
            {option}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
