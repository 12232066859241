import firebase from 'firebase/app';
import { ApplicationError } from '../models/Errors';
import { UploadTaskSnapshot } from '@firebase/storage-types';

const AVATAR_COLLECTION = 'users-avatars';

const uploadAvatar = (uri: string): Promise<string> => {
  const user = firebase.auth().currentUser;
  if (!user) {
    return Promise.resolve('No User');
  }
  return new Promise((resolve, reject) => {
    const onError = () =>
      reject(new ApplicationError('Failed to upload avatar photo'));
    firebase
      .storage()
      .ref(`${AVATAR_COLLECTION}/${user.uid}`)
      .putString(uri)
      .on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (uploadSnapshot: UploadTaskSnapshot | any) => {
          if (uploadSnapshot.state === firebase.storage.TaskState.SUCCESS) {
            if (!uploadSnapshot.ref.getDownloadURL()) {
              onError();
            } else {
              const downloadURL = uploadSnapshot.ref.getDownloadURL();
              resolve(downloadURL);
            }
          } else if (
            uploadSnapshot.state === firebase.storage.TaskState.ERROR
          ) {
            onError();
          }
        },
      );
  });
};

export default {
  uploadAvatar,
};
