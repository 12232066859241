import React from 'react';
import {
  //   Button,
  Card,
  Box,
  // CardActions,
  // CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
  Container,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { NavPadding, Image, Paragraph, Button } from '../components';

const useStyles = makeStyles(theme => ({
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 2, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  action: {
    color: '#B79D4B',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

type TierDescription = {
  title: string;
  subtitle: string;
};

interface Tier {
  title: string;
  subtitle: string;
  price: string;
  subheader?: string;
  description: TierDescription[];
  buttonText: string;
  buttonVariant: 'text' | 'outlined' | 'contained' | undefined;
}

const OfferScreen: React.FC = ({ history }: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <NavPadding />
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom>
          How It Works
        </Typography>
        <Paragraph align="center" slim color="textSecondary">
          They'll be with you every step of the way.
        </Paragraph>
      </Container>
      {/* End hero unit */}
      <Grid container alignItems="center" justify="center">
        <Grid item key={'tier'} xs={12} md={6}>
          <Card style={{ marginBottom: 10 }}>
            <CardHeader
              title="Your Team"
              subheader={
                <>
                  <Image my={2} src="all-mascots" />
                  <Paragraph slim>
                    You'll be joining a small group of people similar to you.
                    You can choose your mascot once you download the app.
                  </Paragraph>
                </>
              }
            />
          </Card>

          <Card style={{ marginBottom: 10 }}>
            <CardHeader
              title="Your Coaches"
              subheader={
                <>
                  <Image my={2} src="coaches-two" />
                  <Paragraph slim>
                    Wade's a social worker. Mike's a behavior change coach.
                  </Paragraph>
                </>
              }
            />
          </Card>

          <Box mb={4}>
            <Button
              fullWidth
              text="What's Next"
              onClick={() => history.push('/plans')}
              // onClick={() => history.push('/checkout')}
            />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default OfferScreen;
