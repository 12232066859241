import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Headline, Paragraph } from '../../../components';
import { useLead } from '../../../hooks';

type Props = {
  button?: React.ReactElement; //{ text: string; onClick: () => void; icon?: any };
  playbook?: boolean;
};

const TextCta: React.FC<Props> = ({ button, playbook = false }) => {
  const { plan } = useLead();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box mt={8} mb={8} textAlign="center">
          <Headline gutterBottom>Try the MHL free for 7 days</Headline>
          <Paragraph gutterBottom>
            {playbook
              ? 'Tackle your mental health with daily tracking, training, and team support. Sign up for the MHL today!'
              : "We're confident you'll feel more motivated, gain accountability, think more positively, and gain a better understanding of how your mind works."}
          </Paragraph>

          {/* {plan && plan.price <= 10 && (
            <Paragraph color="secondary" gutterBottom>
              50% off this week only!
            </Paragraph>
          )} */}

          {button && button}
        </Box>
      </Grid>
    </Grid>
  );
};
export default TextCta;
