import React from 'react';
import {
  Headline,
  Page,
  Paragraph,
  Box,
  Day,
  Footer,
  ScrollToTop,
  CardButton,
  Section,
  Button,
} from '../../components';
import { usePagination, useHistory } from '../../hooks';
import firebase from 'firebase/app';
import TextCta from '../landing/components/TextCta';
import {
  NavigateNext as NavgateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { FeaturedType } from 'types';
import moment from 'moment';
import { IconNames } from 'types';

const PlaybookScreen = () => {
  const history = useHistory();

  const {
    items,
    isLoading,
    isStart,
    isEnd,
    getPrev,
    getNext,
    page,
  } = usePagination<FeaturedType>(
    firebase
      .firestore()
      .collection('/articles-featured')
      .orderBy('date', 'desc'),
    {
      limit: 10,
    },
  );

  return (
    <>
      <Page fullPage loading={isLoading}>
        <ScrollToTop state={page} />
        <Box mt={2}>
          <Headline>MHL Playbook</Headline>
          <Paragraph gutterBottom>
            Mental health tips, tricks, and player highlights
          </Paragraph>
        </Box>

        {items &&
          items.map((feature: FeaturedType, idx: number) => (
            <Box key={feature.date.toString()}>
              <Box pt={4}>
                {moment(feature.date) && <Day date={moment(feature.date)} />}
              </Box>
              {feature.articles &&
                feature.articles.map((art, idx2) => (
                  <CardButton
                    key={idx2}
                    bordered="lightTextColor"
                    label={art.label}
                    text={art.title}
                    iconName={art.icon ? art.icon : IconNames.Brain}
                    onPress={
                      (): void =>
                        history.push(`/playbook/${art.id ? art.id : art.id}`)
                      // navigation.navigate(routes.Article, {
                      //   article: art.articleId,
                      // })
                    }
                  />
                ))}
            </Box>
          ))}

        <Box row justifyContent="center" alignItems="center" mb={4}>
          {page > 1 && (
            <IconButton onClick={getPrev} disabled={isStart}>
              <NavigateBeforeIcon />
            </IconButton>
          )}
          <Paragraph>Page {page}</Paragraph>
          <IconButton onClick={getNext} disabled={isEnd}>
            <NavgateNextIcon />
          </IconButton>
        </Box>
      </Page>
      <Section height="sm" background="primary" mt={6}>
        <TextCta
          button={
            <Button text="Learn More" onClick={() => history.push('/')} />
          }
        />
      </Section>
      <Footer />
    </>
  );
};

export default PlaybookScreen;
