import React from 'react';
import { CardHeader, CssBaseline, Grid } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import {
  NavPadding,
  PageHeading,
  Image,
  Card,
  Paragraph,
  Button,
  Headline,
  Box,
  Page,
} from '../components';
import Stars from './landing/components/Stars';

const useStyles = makeStyles(theme => ({
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 2, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  action: {
    color: '#B79D4B',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

type TierDescription = {
  title: string;
  subtitle: string;
};

interface Tier {
  title: string;
  subtitle: string;
  price: string;
  subheader?: string;
  description: TierDescription[];
  buttonText: string;
  buttonVariant: 'text' | 'outlined' | 'contained' | undefined;
}

const OfferScreen: React.FC = ({ history }: any) => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      {/* Hero unit */}
      <NavPadding />
      <Page fullPage>
        <PageHeading
          center
          text="The Bootcamp"
          subheader="28 Days To Get Your Mind Into Shape"
        />
        {/* <Headline align="center">The Bootcamp</Headline>
        <Paragraph align="center" slim color="textSecondary">
          28 Days To Get Your Mind Into Shape
        </Paragraph> */}
      </Page>
      {/* End hero unit */}
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} md={6}>
          <Card style={{ marginBottom: 10 }}>
            <CardHeader
              title="How It Works"
              subheader={
                <>
                  <Box row alignContent="center">
                    <Stars />
                    <Paragraph fine>213 Reviews</Paragraph>
                  </Box>
                  {/* <Image my={2} src="bootcamp" /> */}
                  <Paragraph slim gutterBottom>
                    MHL is a social mental health tracking app at it's core.
                    Think of it like your local gym. Then, our Bootcamp is like
                    an All-Pro practice facility with a training staff.
                  </Paragraph>
                  <Paragraph slim gutterBottom>
                    Each day, you'll track your mental health, with an option to
                    share it with your team and coaching staff to get feedback.
                  </Paragraph>

                  <Paragraph slim>
                    On top of that, you'll also get daily educational articles,
                    pep talks, and discussion questions to help you go deep.
                  </Paragraph>
                </>
              }
            />
          </Card>
          <Card style={{ marginBottom: 10 }}>
            <CardHeader
              title="The Teams"
              subheader={
                <>
                  <Image my={2} src="all-mascots" />
                  <Paragraph slim>
                    After you join, you'll pick your mascot and opt-into a team
                    of people just like you.
                  </Paragraph>
                </>
              }
            />
          </Card>

          <Card style={{ marginBottom: 10 }}>
            <CardHeader
              title="Your Coaches"
              subheader={
                <>
                  <Image my={2} src="coaches-two" />
                  <Paragraph slim>
                    Wade's a social worker. Mike's a behavior change coach who
                    happens to be bipolar and ADHD. Together, they built the MHL
                    2k21 Bootcamp to be a new, badass way of helping anyone get
                    their mind right.
                  </Paragraph>
                </>
              }
            />
          </Card>

          <Box mb={4}>
            <Button
              fullWidth
              text="What's Next"
              onClick={() => history.push('/plans')}
              // onClick={() => history.push('/checkout')}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default OfferScreen;
