import React, { useState, MouseEvent, useEffect } from 'react';
import {
  Button,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
  Container,
} from '@material-ui/core/';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { makeStyles } from '@material-ui/core/styles';
import { NavPadding, Paragraph, Box } from '../components';
import Testimonial from './landing/components/Testimonial';
import SupportEmail from './landing/components/SupportEmail';
import { fireFbEvent } from '../services/AnalyticsService';
import { useLead, useHistory, useUrlParams, useNavigation } from '../hooks';
import FeaturesAccordion from './landing/components/FeaturesAccordion';
import { plans, PlanPrice } from '../context/Lead';
import { Card } from '../components';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    width: '100%',
  },
  selected: {
    color: `${theme.palette.primary.main} !important`,
  },
  toggleButton: {
    width: '33%',
    // color: theme.palette.primary.main,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 2, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  action: {
    color: '#B79D4B',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

type TierDescription = {
  title: string;
  subtitle: string;
};

interface Tier {
  title: string;
  subtitle: string;
  price: string;
  subheader?: string;
  description: TierDescription[];
  buttonText: string;
  buttonVariant: 'text' | 'outlined' | 'contained' | undefined;
}
const tiers: Tier[] = [
  {
    title: 'Just $0.50 per day',
    subtitle: '7-day free trial - Cancel anytime',
    price: '20',
    description: [
      {
        title: 'Daily personal coaching',
        subtitle:
          'Each team is led by multiple trained behavior change coaches with backgrounds in psychology, social work, or counseling',
      },
      {
        title: 'Daily progress tracking',
        subtitle:
          "Just like any other sport, you'll have a daily scorecard complete with habit tracking and other metrics that helps you learn and improve",
      },
      {
        title: 'A curated supportive team',
        subtitle:
          "Fill out a survey and we'll pair you with a curated team of players similar to you for daily motivation and support",
      },
      {
        title: '28-day educational game plan',
        subtitle:
          'Daily educational and motivational content from coaches and psychologists to teach you terminology, coping skills, and exercises',
      },
      {
        title: 'Weekly Team Huddles on Zoom',
        subtitle:
          'Optionally join community calls each week where we discuss our wins & learnings, and set goals for the following week',
      },
      {
        title: '95% chance of improvement',
        subtitle:
          '95% of our players report that they improved their mental health in just 28 days – thanks to the support and accountability they received from their team and coach',
      },
    ],
    buttonText: 'Join Now',
    buttonVariant: 'contained',
  },
];

type Props = {
  friends?: boolean;
};

const PlansScreen: React.FC<Props> = ({ friends }) => {
  const classes = useStyles();
  const { plan, setPlan } = useLead();
  const history = useHistory();
  const navigation = useNavigation();

  const params = useUrlParams();
  let veterans = params.get('veterans');

  if (friends) {
    setPlan('monthly10');
  }

  const availPlans = [plans.monthly10, plans.monthly20, plans.monthly25];
  const [pickedPlan, pickPlan] = useState<string | any>(
    plan ? plan.id : availPlans[1].id,
  );

  const selectPlan = (
    event: MouseEvent<HTMLElement>,
    newPlan: string[] | [],
  ) => {
    pickPlan(newPlan);
  };

  useEffect(() => {
    if (pickedPlan) {
      setPlan(pickedPlan);
    }
  }, [pickedPlan]);

  const retailPrice = plan.price * 3;

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <NavPadding />
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom>
          {friends || veterans ? 'Friends Plan' : 'The Plan'}
        </Typography>
        <Paragraph slim align="center" color="textSecondary">
          {friends || veterans
            ? 'Thanks for sticking with us from the start!'
            : `Daily motivation, education, and coaching for just $${(
                plan.price / 30
              ).toFixed(2)} cents per day.`}
        </Paragraph>
      </Container>
      {/* End hero unit */}
      <Grid container alignItems="center" justify="center">
        {tiers.map(tier => (
          // Enterprise card is full width at sm breakpoint
          <Grid item key={tier.title} xs={12} md={6}>
            <Card padding>
              {veterans || friends ? (
                <CardHeader
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  className={classes.cardHeader}
                  title={
                    <Box mt={2}>
                      <Typography variant="subtitle2">
                        Pick your price
                      </Typography>
                    </Box>
                  }
                  subheader={
                    <>
                      <Box mt={2}>
                        <ToggleButtonGroup
                          className={classes.buttonGroup}
                          exclusive={true}
                          value={pickedPlan}
                          onChange={selectPlan}
                          aria-label="Survey">
                          {availPlans.map((option: PlanPrice) => (
                            <ToggleButton
                              className={classes.toggleButton}
                              key={option.id}
                              value={option.id}
                              aria-label={option.id}
                              classes={{ selected: classes.selected }}>
                              ${option.price}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                      </Box>
                      <Box mt={2} mb={2}>
                        <Button
                          fullWidth
                          disabled={!pickedPlan}
                          size="large"
                          variant={tier.buttonVariant}
                          color="secondary"
                          onClick={() => {
                            navigation.navigate('/login?redirect=checkout', {
                              redirect: '/checkout',
                            });
                          }}
                          // onClick={() => {
                          //   history.push('/login?redirect=checkout', [
                          //     '/checkout',
                          //   ]);
                          // }}
                          // onClick={() => history.push('/checkout')}
                        >
                          {!pickedPlan
                            ? 'Pick your price'
                            : `Pay $${plans[pickedPlan].price} / month`}
                        </Button>
                      </Box>
                    </>
                  }
                />
              ) : (
                <CardHeader
                  title={
                    <>
                      <div className={classes.cardPricing}>
                        <Box row justifyContent="center">
                          <Box mr={1}>
                            <Typography
                              component="h2"
                              variant="h3"
                              color="textPrimary">
                              ${plan.price}
                            </Typography>
                          </Box>
                          <Typography
                            component="h2"
                            variant="h3"
                            color="textSecondary">
                            <s>${retailPrice}</s>
                          </Typography>
                        </Box>
                        <Typography variant="h6" color="textSecondary">
                          /month
                        </Typography>
                      </div>
                    </>
                  }
                  subheader={
                    <>
                      {plan.price <= 14 && (
                        <Typography
                          component="p"
                          color="secondary"
                          variant="subtitle2">
                          {((1 - plan.price / retailPrice) * 100).toFixed(0)}%
                          off today only!
                        </Typography>
                      )}
                      {friends && (
                        <>
                          <Typography
                            component="p"
                            color="textSecondary"
                            variant="subtitle2">
                            50% off friends only!
                          </Typography>
                          <Typography
                            component="p"
                            color="textSecondary"
                            variant="subtitle2">
                            Typically $20 / month
                          </Typography>
                        </>
                      )}
                      <Typography
                        component="p"
                        color="textSecondary"
                        variant="caption">
                        {tier.subtitle}
                      </Typography>
                      {/* <Typography variant="caption">{tier.title}</Typography> */}
                      <Box mt={2}>
                        <Button
                          fullWidth
                          size="large"
                          variant={tier.buttonVariant}
                          color="secondary"
                          onClick={() => {
                            fireFbEvent('AddToCart');
                            history.push('/login?redirect=checkout', [
                              '/checkout',
                            ]);
                          }}
                          // onClick={() => history.push('/checkout')}
                        >
                          {tier.buttonText}
                        </Button>
                      </Box>
                    </>
                  }
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  // action={tier.title !== 'Team' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
              )}
              {/* <CardActions>
                <Button
                  fullWidth
                  size="large"
                  variant={tier.buttonVariant}
                  color="secondary"
                  onClick={() =>
                    history.push('/login?redirect=checkout', ['/checkout'])
                  }
                  // onClick={() => history.push('/checkout')}
                >
                  {tier.buttonText}
                </Button>
              </CardActions> */}
              {/* <CardContent> */}
              <Box mt={4} mb={2}>
                <Typography align="center" variant="h6">
                  What's Included
                </Typography>
              </Box>

              <FeaturesAccordion />

              {/* {tier.description.map((line, idx) => (
                <Accordion elevation={0} key={idx}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className={classes.heading} color="textPrimary">
                      {line.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{line.subtitle}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))} */}

              {/* </CardContent> */}
            </Card>
            <SupportEmail />
            <Testimonial size="sm" />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default PlansScreen;
