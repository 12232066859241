import React from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import { makeStyles } from '@material-ui/core/styles';
import Paragraph from './Paragraph';
import Box from './Box';

type Props = {
  buttons?: string[];
  type?: 'boolean';
  exclusive?: boolean;
  value: string | boolean;
  label?: string;
  setValue: React.Dispatch<React.SetStateAction<any>>;
};

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    width: '100%',
  },
  selected: {
    color: `${theme.palette.primary.main} !important`,
  },
  toggleButton: {
    width: '33%',
    // color: theme.palette.primary.main,
  },
}));

const ToggleButton: React.FC<Props> = ({
  buttons,
  type,
  exclusive = true,
  value,
  label,
  setValue,
}) => {
  const classes = useStyles();

  const buttonArr =
    type === 'boolean' ? ['true', 'false'] : buttons ? buttons : [];
  const convertedValue = typeof value === 'boolean' ? value.toString() : value;
  const width = { width: `${100 / buttonArr.length}%` };

  const selectItem = (e: React.MouseEvent, selected: string) => {
    if (selected === 'true') {
      setValue(true);
    } else if (selected === 'false') {
      setValue(false);
    } else {
      setValue(selected);
    }
  };

  return (
    <Box mt={2}>
      {label && <Paragraph>{label}</Paragraph>}
      <ToggleButtonGroup
        style={{ width: '100%' }}
        exclusive={exclusive}
        value={convertedValue}
        onChange={selectItem}
        aria-label="Survey">
        {buttonArr.map(option => (
          <MuiToggleButton
            style={width}
            key={option}
            value={option}
            aria-label={option}
            classes={{ selected: classes.selected }}>
            {option}
          </MuiToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

export default ToggleButton;
