import BaseService from './BaseService';
import { ApplicationError } from '../models/Errors';
import firebase from 'firebase/app';
import {
  ArticleFeaturesServiceType,
  SubscriptionListener,
  Unsubscriber,
  FeaturedUpdate,
} from './interfaces';
import { Article, FeaturesObj, FeaturedType } from 'types';
import moment from 'moment';

const COLLECTION = 'articles-featured';
const collection = firebase.firestore().collection(COLLECTION);

class ArticleFeaturesService extends BaseService
  implements ArticleFeaturesServiceType {
  //   public getFeatures = async (): Promise<FeaturedArticle[]> => {
  //     // If coach, get all articles. If not coach, exclude unpublished articles
  //     // const query = !this.currentPlayer.teamsCoached
  //     //   ? collection.where('status', '==', 'published')
  //     //   : collection;

  //     const query = collection;

  //     const articles: FeaturedArticle[] = [];

  //     try {
  //       await query.get().then(snapshots =>
  //         snapshots.docs.forEach(doc => {
  //           const data = doc.data() as Article;
  //           if (data.status !== PublishStatus.Deleted) {
  //             articles.push({ ...data });
  //           }
  //         }),
  //       );

  //       return articles;
  //     } catch {
  //       throw new ApplicationError('Unable to retrieve list');
  //     }
  //   };

  updateFeature = async (
    featureId: string,
    updates: Partial<Article>,
  ): Promise<void> => {
    try {
      const docRef = collection.doc(featureId);
      const doc = await docRef.get();

      if (doc.exists) {
        await docRef.update(updates);
      } else {
        throw new ApplicationError('Article does not exist');
      }
    } catch (err) {
      this.logger.error('Article does not exist');
      throw new ApplicationError('Article does not exist');
    }
  };

  public deleteFeature = async (featureId: string): Promise<void> => {
    try {
      await collection.doc(featureId).delete();
    } catch {
      this.logger.error('Feature does not exist');
      throw new ApplicationError('Unable to delete article');
    }
  };

  public listenToFeatures = (
    listener: SubscriptionListener<FeaturesObj | null>,
  ): Unsubscriber => {
    const features: FeaturesObj = {};

    const subscription = collection
      .limit(14)
      .orderBy('date', 'desc')
      .onSnapshot(snapshot => {
        snapshot.docs.forEach(doc => {
          const data = doc.data() as FeaturedType;

          features[doc.id] = { ...data };
        });
        listener(features);
      });

    return subscription;
  };

  public addOrEditFeature = async (feature: FeaturedUpdate): Promise<void> => {
    try {
      const dateString = moment(feature.date).format('YYYY-MM-DD');
      const docRef = collection.doc(dateString);
      const doc = await docRef.get();

      if (doc.exists) {
        await docRef.update({ ...feature, updatedAt: new Date() });
      } else {
        await docRef.set({
          ...feature,
          date: dateString,
          updatedAt: new Date(),
          createdAt: new Date(),
        });
      }
    } catch (err) {
      this.logger.error(`Error adding feature - ${err}`);
    }
  };
}

export default ArticleFeaturesService;
