import React from 'react';
import { Headline, Paragraph, Page, Image, Section } from '../../components';
import { Box, Typography, Link } from '@material-ui/core';
import { ImageNames } from '../../models/Image';
import Testimonials from './components/Testimonials';
import TextCta from './components/TextCta';
import CtaButton from './components/CtaButton';
import { useHistory } from '../../hooks';

interface Reason {
  imgSrc?: ImageNames;
  title: string;
  body: string;
}

const reasons: Reason[] = [
  {
    imgSrc: 'mhl_asset_3',
    title: 'It’s 20% of the cost of text therapy',
    body:
      'While text therapy often costs $100+ a month, the MHL is currently 50% off at only $10 a month. This includes a daily mental health tracking app, daily educational content, weekly group huddles, AND daily feedback from coaches who are licensed social workers. Hearing that plus the fact that they offer a 7-day free trial and I had to give it a try. ',
  },
  {
    imgSrc: 'coaches',
    title: 'Their coaches keep me motivated and check in on me every day',
    body:
      "So when you signup, you’ll get put on a team with multiple coaches. These coaches' entire job is to keep you motivated and check in on you and your team. So each day, you just have to share your check-in with them and they’ll give you feedback within the day.",
  },
  {
    imgSrc: 'app-scorecard',
    title: 'Their daily content has taught me so much',
    body:
      'On top of the daily responses, their coaching staff publishes daily educational content, challenges, and community stories based on what they’re seeing people need help with. Each week has a new theme. Last week was all about reframing negative thought patterns while this week was about how to improve your relationships.',
  },
  {
    imgSrc: 'app-team',
    title: 'I no longer feel alone',
    body:
      'One of the best parts of the MHL is the team. Your team is a group of other MHL players similar to you. Each day, you can read through their check-ins and offer support, and they’ll do the same for you. At first I was hesitant to share, but soon I realized that was the best part. I loved getting to know everyone else and learning from their experiences.',
  },
  {
    title: 'Their weekly huddles are so uplifting',
    body:
      'As a bonus, the MHL hosts optional weekly team Huddles on Zoom where you can hop on to discuss your weekly wins, losses, and set a goal to improve your mental health for the next week. It’s become a weekly ritual for me that helps hold me accountable to my self-care.',
  },
  {
    imgSrc: 'mhl_asset_2_cropped',
    title: 'It feels more like fantasy football than therapy',
    body:
      'The branding, the mascots, and everything else about the MHL is just pure sports. It doesn’t feel super science-y or therapy-y, but at the same time is based on proven cognitive-behavioral therapy techniques that help you analyze and improve your anxiety and depression.',
  },
];

const LandingBuzzfeed: React.FC = () => {
  const ctaButton = <CtaButton />;
  const history = useHistory();

  return (
    <>
      <Page fullPage>
        <Headline variant="h5" gutterBottom>
          6 Reasons Why I’ll Only Use This Mental Health App (Even After Trying
          A Bunch of Others).
        </Headline>

        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Image
              src="prof-alan"
              imageStyle={{ width: '40px', marginRight: '5px' }}
            />
            <Paragraph>By Alan Reichle</Paragraph>
          </Box>
          <Paragraph color="textSecondary" gutterBottom>
            Nov 4, 2020
          </Paragraph>
        </Box>

        {reasons.map((reason, idx) => (
          <Box mt={2}>
            {reason.imgSrc && <Image src={reason.imgSrc} />}

            <Headline variant="h5" gutterBottom>
              <Typography
                component="span"
                style={{
                  backgroundColor: '#0b2240',
                  borderRadius: '60px',
                  width: '35px',
                  height: '35px',
                  textAlign: 'center',
                  color: 'white',
                  display: 'inline-block',
                  marginRight: '10px',
                }}>
                <b>{idx + 1}</b>
              </Typography>
              {reason.title}
            </Headline>

            <Paragraph gutterBottom>{reason.body}</Paragraph>

            {idx === 0 && (
              <Typography gutterBottom>
                <Link
                  color="secondary"
                  style={{
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    history.push('/plans');
                  }}>
                  Start Free Trial ›
                </Link>
              </Typography>
            )}
          </Box>
        ))}
      </Page>
      <Section height="sm" background="offWhite">
        <TextCta button={ctaButton} />
      </Section>

      <Testimonials />
    </>
  );
};

export default LandingBuzzfeed;
