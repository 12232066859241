import React from 'react';
import { ActivityData } from 'react-activity-feed';
import { UserResponse } from 'getstream';
import { Paragraph, Headline } from '../../components';
import { CardContent } from '@material-ui/core';

interface Props {
  activity: ActivityData;
  actor: UserResponse;
}

const DiscussionActivityContent: React.FC<Props> = ({ activity }) => {
  return (
    <CardContent>
      <Paragraph bold>Discussion</Paragraph>

      <Headline
        small={activity.message && activity.message.length > 30 ? true : false}
        center={false}>
        {/* Prev dev used inconsistent format so welcome posts had .message and schedule didn't - I updated to standardize later on */}
        {activity.message ? activity.message : activity.object}
      </Headline>
    </CardContent>
  );
};

export default DiscussionActivityContent;
