import React, { useState, MouseEvent } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { Question, SurveyType } from './SurveyScreen';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Apple as AppleIcon, Android as AndroidIcon } from '@material-ui/icons';

type Answer = {
  [key: string]: string[] | [];
};

interface Props {
  question: Question;
  updateForm: (newState: any) => void;
  parentState: SurveyType;
  currentStep: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '20px',
      textAlign: 'center',
      alignItems: 'center',
    },
    nextBtn: {
      position: 'fixed',
      bottom: 0,
      width: '105%',
    },
    gutterBottomHalf: {
      marginBottom: '8px',
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '& button': {
        marginBottom: '15px',
        borderRadius: '4px !important',
        borderColor: 'rgba(0, 0, 0, 0.12) !important',
      },
    },
    selected: {
      color: `${theme.palette.primary.main} !important`,
    },
    gutterTop: {
      marginTop: theme.spacing(1),
    },
  }),
);

const MultipleChoice: React.FC<Props> = ({
  question,
  updateForm,
  parentState,
  currentStep,
}) => {
  const classes = useStyles();

  const [error, setError] = useState<string | null>(null);
  const [answers, setAnswers] = useState<string[] | []>(() => []);
  const [isDisabled, setDisabled] = useState<boolean>(true);

  const handleNext = (goToStep: number, singleAnswer?: string[]) => {
    if (
      answers.length === 0 &&
      goToStep > currentStep &&
      question.type !== 'single'
    ) {
      setError('You must choose least one option');

      return;
    }

    const newState = {
      ...parentState,
      form: {
        ...parentState.form,
        [question.id]: singleAnswer ? singleAnswer : answers,
      },
      currentStep: goToStep,
    };

    updateForm(newState);
  };

  const selectAnswer = (
    event: MouseEvent<HTMLElement>,
    newAnswers: string[] | [],
  ) => {
    // Reset error if it exists
    if (error) {
      setError(null);
    }
    // Remove disabled state if answer is selected
    if (newAnswers.length !== 0) {
      setDisabled(false);
    }
    // Convert single answer to array for proper state
    if (typeof newAnswers === 'string') {
      newAnswers = [newAnswers];
    }

    const nextStep =
      newAnswers[0] === 'Android' ? -1 : parentState.currentStep + 1;

    // Autoamtically move to next step when single is selected
    if (question.type === 'single') {
      handleNext(nextStep, newAnswers);
    } else {
      setAnswers(newAnswers ? newAnswers : []);
    }
  };

  return currentStep !== parentState.currentStep ? null : (
    // return (
    <div className={classes.questionContainer}>
      <Typography
        variant="subtitle2"
        color="primary"
        style={{ fontWeight: 900 }}
        className={classes.gutterBottomHalf}>
        {question.question}
      </Typography>
      <Typography
        color={question.type === 'single' ? 'textSecondary' : 'error'}
        gutterBottom
        variant="caption"
        component="p">
        {question.type === 'single'
          ? '* Select one *'
          : '* Select all that apply *'}
      </Typography>
      {error && (
        <Typography color="error" variant="subtitle2" gutterBottom>
          {error}
        </Typography>
      )}
      <ToggleButtonGroup
        className={classes.buttonGroup}
        exclusive={question.type === 'single' ? true : false}
        style={{ marginBottom: question.type === 'multiple' ? '70px' : 0 }}
        value={answers}
        onChange={selectAnswer}
        aria-label="Survey">
        {question.options.map((option: string) => (
          <ToggleButton
            key={option}
            value={option}
            aria-label={option}
            style={{
              lineHeight: '22px',
              paddingTop: '15px',
              paddingBottom: '15px',
            }}
            classes={{ selected: classes.selected }}>
            {option === 'iPhone' ? (
              <>
                <AppleIcon />{' '}
              </>
            ) : option === 'Android' ? (
              <>
                <AndroidIcon />{' '}
              </>
            ) : null}
            {option}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      {question.type === 'multiple' && (
        <Button
          onClick={() => handleNext(parentState.currentStep + 1)}
          variant="contained"
          color="secondary"
          fullWidth
          className={classes.nextBtn}
          disabled={isDisabled}
          size="large">
          {parentState.totalSteps !== currentStep ? 'Next' : 'Finish'}
        </Button>
      )}
    </div>
  );
};

export default MultipleChoice;
