import React from 'react';
import { Page, Button, Headline, Paragraph, RichText } from '../../components';
import { Article } from 'types';
import { useHistory } from '../../hooks';
import { Box } from '@material-ui/core';

type Props = {
  article: Article | undefined;
  handleDelete(articleId: string): void;
  deleting: boolean;
};

const ArticleViewScreen: React.FC<Props> = ({
  article,
  handleDelete,
  deleting,
}) => {
  const history = useHistory();

  return article ? (
    <>
      <Box display="flex" justifyContent="space-between">
        <Button
          text="Edit"
          size="small"
          onClick={() => history.push('create', { article })}
        />
        <Paragraph align="center">{article.slug}</Paragraph>
        <Button
          variant="text"
          size="small"
          onClick={() => handleDelete(article.id)}
          text="Delete"
          disabled={deleting}
        />
      </Box>

      {article.pages ? (
        article.pages.map((page, idx) => (
          <div key={idx}>
            <hr />
            {page.imageURL ? (
              <img alt={page.title} src={page.imageURL} />
            ) : (
              <Paragraph gutterBottom>No Image</Paragraph>
            )}

            <Headline gutterBottom align="center">
              {page.title}
            </Headline>

            {article.author && (
              <Paragraph gutterBottom>By {article.author}</Paragraph>
            )}

            <Paragraph gutterBottom>Page {idx + 1}</Paragraph>
            {typeof page.body === 'string' ? (
              page.body.split('\n').map((p, idx) => (
                <Paragraph key={idx} gutterBottom>
                  {p}
                </Paragraph>
              ))
            ) : (
              <RichText state={page.body} />
            )}

            <Box mt={2}>
              <Button variant="contained" disabled color="secondary">
                {page.next_btn}
              </Button>
            </Box>
          </div>
        ))
      ) : (
        <Paragraph>No pages</Paragraph>
      )}
    </>
  ) : (
    // <Container>
    <h3>Please select an article.</h3>
    // </Container>
  );
};

export default ArticleViewScreen;
