import React from 'react';
import {
  VisibilityOff as HiddenIcon,
  Star as StarIcon,
  Sports as WhistleIcon,
  ChevronRight,
  ChevronLeft,
  FitnessCenter as DumbbellIcon,
  MoreVert as MoreVertIcon,
  KeyboardArrowUp as ChevronUp,
  InsertEmoticon as SmileIcon,
  FilterHdr as MountainIcon,
  Mic as MicrophoneIcon,
  MusicNote as MusicIcon,
  KeyboardArrowDown as ChevronDown,
  Close as CloseIcon,
  Forum as DiscussionIcon,
  Create as PencilIcon,
  Headset as HeadphonesIcon,
  Phone as PhoneIcon,
  Person as UserIcon,
  BorderColor as MarkerIcon,
  WbSunny as SunIcon,
  Nature as TreeIcon,
  AcUnit as SnowIcon,
  Note as StickyIcon,
  SportsEsports as GamePlanIcon,
  ContactSupport as SupportIcon,
  Alarm as RemindersIcon,
  Help as FAQsIcon,
  ExitToApp as LogoutIcon,
  Apple as AppleIcon,
  People as TeamIcon,
  TrendingUp as LineGraphIcon,
  Restaurant as FoodIcon,
  BarChart as StatsIcon,
  ListAlt as ListIcon,
} from '@material-ui/icons';
import { IconProps } from '@material-ui/core';
import { brandColors } from '../assets/styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBrain,
  faBullhorn,
  faWater,
  faStar,
  faMoon,
  faBed,
  faHeartbeat,
  faTv,
  faClipboardList,
  faMedal,
  faBaseballBall,
  faBasketballBall,
  faFootballBall,
  faHockeyPuck,
  faGolfBall,
  faVolleyballBall,
  faTrophy,
  faPager,
  faBalanceScale,
  faNewspaper,
  faBook,
  faListUl,
  faCalendarCheck,
  faRunning,
  faParagraph,
  faCheckSquare,
  faBroom,
  faCog,
  faBell,
  faComments,
  faHandsHelping,
  faHandshake,
} from '@fortawesome/free-solid-svg-icons';
import { IconNames as IconNamesType } from 'types';

export type IconNames = IconNamesType;

type Props = {
  name: IconNamesType;
};

const Icon: React.FC<Props & IconProps> = ({ name, ...props }) => {
  return name === IconNamesType.Hidden ? (
    <HiddenIcon style={{ color: brandColors.brandPrimary }} />
  ) : name === IconNamesType.Coach ? (
    <WhistleIcon style={{ color: brandColors.brandInfo }} />
  ) : name === IconNamesType.Close ? (
    <CloseIcon style={props.style} />
  ) : name === IconNamesType.Discussion ? (
    <DiscussionIcon style={props.style} />
  ) : name === IconNamesType.LineGraph ? (
    <LineGraphIcon style={props.style} />
  ) : name === IconNamesType.Team ? (
    <TeamIcon style={props.style} />
  ) : name === IconNamesType.Dumbbell ? (
    <DumbbellIcon style={props.style} />
  ) : name === IconNamesType.Mountain ? (
    <MountainIcon style={props.style} />
  ) : name === IconNamesType.Smile ? (
    <SmileIcon style={props.style} />
  ) : name === IconNamesType.Microphone ? (
    <MicrophoneIcon style={props.style} />
  ) : name === IconNamesType.Apple ? (
    <AppleIcon style={props.style} />
  ) : name === IconNamesType.Sun ? (
    <SunIcon style={props.style} />
  ) : name === IconNamesType.Food ? (
    <FoodIcon style={props.style} />
  ) : name === IconNamesType.Reminders ? (
    <RemindersIcon style={props.style} />
  ) : name === IconNamesType.Support ? (
    <SupportIcon style={props.style} />
  ) : name === IconNamesType.Music ? (
    <MusicIcon style={props.style} />
  ) : name === IconNamesType.Snow ? (
    <SnowIcon />
  ) : name === IconNamesType.FAQs ? (
    <FAQsIcon />
  ) : name === IconNamesType.List ? (
    <ListIcon />
  ) : name === IconNamesType.Stats ? (
    <StatsIcon />
  ) : name === IconNamesType.Sticky ? (
    <StickyIcon />
  ) : name === IconNamesType.GamePlan ? (
    <GamePlanIcon />
  ) : name === IconNamesType.Tree ? (
    <TreeIcon />
  ) : name === IconNamesType.Logout ? (
    <LogoutIcon />
  ) : name === IconNamesType.Headphones ? (
    <HeadphonesIcon />
  ) : name === IconNamesType.Marker ? (
    <MarkerIcon />
  ) : name === IconNamesType.Phone ? (
    <PhoneIcon />
  ) : name === IconNamesType.User ? (
    <UserIcon />
  ) : name === IconNamesType.More ? (
    <MoreVertIcon />
  ) : name === IconNamesType.Clipboard ? (
    // <ClipboardIcon />
    <FontAwesomeIcon icon={faClipboardList} style={props.style} />
  ) : name === IconNamesType.Balance ? (
    <FontAwesomeIcon icon={faBalanceScale} style={props.style} />
  ) : name === IconNamesType.Paragraph ? (
    <FontAwesomeIcon icon={faParagraph} style={props.style} />
  ) : name === IconNamesType.Moon ? (
    <FontAwesomeIcon icon={faMoon} style={props.style} />
  ) : name === IconNamesType.Run ? (
    <FontAwesomeIcon icon={faRunning} style={props.style} />
  ) : name === IconNamesType.Broom ? (
    <FontAwesomeIcon icon={faBroom} style={props.style} />
  ) : name === IconNamesType.Water ? (
    <FontAwesomeIcon icon={faWater} style={props.style} />
  ) : name === IconNamesType.Pager ? (
    <FontAwesomeIcon icon={faPager} style={props.style} />
  ) : name === IconNamesType.Tv ? (
    <FontAwesomeIcon icon={faTv} style={props.style} />
  ) : name === IconNamesType.Heart ? (
    <FontAwesomeIcon icon={faHeartbeat} style={props.style} />
  ) : name === IconNamesType.UL ? (
    <FontAwesomeIcon icon={faListUl} style={props.style} />
  ) : name === IconNamesType.Comments ? (
    <FontAwesomeIcon icon={faComments} style={props.style} />
  ) : name === IconNamesType.Baseball ? (
    <FontAwesomeIcon icon={faBaseballBall} style={props.style} />
  ) : name === IconNamesType.Basketball ? (
    <FontAwesomeIcon icon={faBasketballBall} style={props.style} />
  ) : name === IconNamesType.Football ? (
    <FontAwesomeIcon icon={faFootballBall} style={props.style} />
  ) : name === IconNamesType.Volleyball ? (
    <FontAwesomeIcon icon={faVolleyballBall} style={props.style} />
  ) : name === IconNamesType.Golf ? (
    <FontAwesomeIcon icon={faGolfBall} style={props.style} />
  ) : name === IconNamesType.Hockey ? (
    <FontAwesomeIcon icon={faHockeyPuck} style={props.style} />
  ) : name === IconNamesType.Brain ? (
    <FontAwesomeIcon icon={faBrain} style={props.style} />
  ) : name === IconNamesType.Bullhorn ? (
    <FontAwesomeIcon icon={faBullhorn} style={props.style} />
  ) : name === IconNamesType.Bed ? (
    <FontAwesomeIcon icon={faBed} style={props.style} />
  ) : name === IconNamesType.News ? (
    <FontAwesomeIcon icon={faNewspaper} style={props.style} />
  ) : name === IconNamesType.Pencil ? (
    <PencilIcon />
  ) : name === IconNamesType.Trophy ? (
    <FontAwesomeIcon icon={faTrophy} style={props.style} />
  ) : name === IconNamesType.Medal ? (
    <FontAwesomeIcon icon={faMedal} style={props.style} />
  ) : name === IconNamesType.Assist ? (
    <FontAwesomeIcon icon={faHandsHelping} style={props.style} />
  ) : name === IconNamesType.Handshake ? (
    <FontAwesomeIcon icon={faHandshake} style={props.style} />
  ) : name === IconNamesType.Star ? (
    <FontAwesomeIcon icon={faStar} style={props.style} />
  ) : name === IconNamesType.Notifications ? (
    <FontAwesomeIcon icon={faBell} style={props.style} />
  ) : name === IconNamesType.Book ? (
    <FontAwesomeIcon icon={faBook} style={props.style} />
  ) : name === IconNamesType.Settings ? (
    <FontAwesomeIcon icon={faCog} style={props.style} />
  ) : name === IconNamesType.Calendar ? (
    <FontAwesomeIcon icon={faCalendarCheck} style={props.style} />
  ) : name === IconNamesType.Check ? (
    <FontAwesomeIcon icon={faCheckSquare} style={props.style} />
  ) : name === IconNamesType.ChevronUp ? (
    <ChevronUp />
  ) : name === IconNamesType.ChevronDown ? (
    <ChevronDown />
  ) : name === IconNamesType.ChevronRight ? (
    <ChevronRight style={props.style} />
  ) : name === IconNamesType.ChevronLeft ? (
    <ChevronLeft style={props.style} />
  ) : (
    <StarIcon />
  );
};

export default Icon;
