import React, { useState } from 'react';
import {
  Page,
  Button,
  ErrorMessage,
  Spinner,
  CardButton,
} from '../../components';
import {
  Typography,
  Box,
  Divider,
  Collapse,
  ListItem,
  List,
  ListItemText,
} from '@material-ui/core';
import { FeaturedType, FeaturesObj } from 'types';
import { useContainer, useHistory, useSubscription } from '../../hooks';
import { ArticleFeaturesServiceType } from '../../services/interfaces';
import moment from 'moment';
import FeaturesCreateScreen from './FeaturesCreateScreen';
import { IconNames } from 'types';

// Functions for creation and editing articles.
const FeaturesScreen: React.FC = () => {
  const history = useHistory();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [editFeature, setEditFeature] = useState<undefined | FeaturedType>();

  const container = useContainer();
  const service = container.getInstance<ArticleFeaturesServiceType>(
    'articleFeaturesService',
  );

  const {
    loading: featuresLoading,
    data: features,
  } = useSubscription<FeaturesObj | null>(service.listenToFeatures);

  const handleEdit = (feature: FeaturedType) => {
    setEditFeature(feature);
    setOpen(true);
    window.scrollTo(0, 0);
  };

  const onSave = () => {
    setOpen(false);
    if (editFeature) {
      setEditFeature(undefined);
    }
  };

  const handleDelete = async (feat: string): Promise<void> => {
    setLoading(true);
    try {
      await service.deleteFeature(feat);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return featuresLoading ? (
    <Spinner />
  ) : (
    <Page fullPage>
      <Button
        color="secondary"
        text="‹ Go Back"
        fullWidth
        variant="text"
        onClick={() => history.push('/articles')}
      />
      <Typography gutterBottom align="center" variant="h4">
        Featured Articles
      </Typography>
      <Box mb={2}>
        <ErrorMessage center error={error} />
      </Box>

      <Button
        fullWidth
        text={!isOpen ? 'Add Feature' : 'Close'}
        variant={!isOpen ? 'contained' : 'outlined'}
        onClick={() => setOpen(!isOpen)}
      />
      <Collapse in={isOpen} timeout="auto">
        {editFeature && (
          <Button
            variant="text"
            onPress={() => setEditFeature(undefined)}
            text="Reset Feature"
          />
        )}
        <FeaturesCreateScreen
          editing={editFeature}
          features={features}
          onSave={onSave}
        />
      </Collapse>

      <List>
        {features &&
          Object.values(features).map((feature, idx) => (
            <Box key={idx}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary">
                        {moment(feature.date).format('ddd MMM Do, YYYY')}
                      </Typography>
                    </>
                  }
                  secondary={
                    <>
                      {feature.articles &&
                        feature.articles.map((art, idx2) => (
                          <CardButton
                            bordered={art.bordered}
                            background={art.background}
                            text={art.title}
                            label={art.label}
                            key={idx2}
                            iconName={art.icon ? art.icon : IconNames.Brain}
                          />
                        ))}
                      <Box display="flex" justifyContent="space-between">
                        <Button
                          disabled={loading}
                          onClick={(): void => {
                            handleEdit(feature);
                          }}
                          size="small"
                          variant="text"
                          text="Edit"
                        />

                        <Button
                          disabled={loading}
                          onClick={() =>
                            handleDelete(
                              moment(feature.date).format('YYYY-MM-DD'),
                            )
                          }
                          size="small"
                          variant="text"
                          text="Delete"
                        />
                      </Box>
                    </>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </Box>
          ))}
      </List>
    </Page>
  );
};

export default FeaturesScreen;
