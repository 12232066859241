import React from 'react';
import { Box, Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';

const SupportEmail: React.FC = () => {
  return (
    <>
      <Box mt={6} flexDirection="row" display="flex">
        <MailIcon
          style={{ height: 30, width: 60, marginRight: 10, color: '#aaa' }}
        />
        <Box>
          <Typography variant="subtitle2" style={{ color: '#aaa' }}>
            Questions?{' '}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ color: '#aaa', fontSize: '12px' }}>
            <a
              style={{ color: '#aaa' }}
              href="mailto:hello@mentalhealthleague.com">
              hello@mentalhealthleague.com
            </a>
            {/* hello@mentalhealthleague.com */}
          </Typography>
        </Box>
      </Box>
      <Box mt={6} flexDirection="row" display="flex">
        <PhoneIcon
          style={{ height: 30, width: 60, marginRight: 10, color: '#aaa' }}
        />
        <Box>
          <Typography variant="subtitle2" style={{ color: '#aaa' }}>
            Talk to a Coach?{' '}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ color: '#aaa', fontSize: '12px' }}>
            <a
              style={{ color: '#aaa' }}
              href="https://calendly.com/mentalhealthleague/15min">
              Schedule An Intro Call
            </a>
            {/* hello@mentalhealthleague.com */}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SupportEmail;
