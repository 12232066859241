import { Timestamp } from './types';

export type IapPurchase = {
  bundleId: string;
  transactionId: string;
  productId: string;
  purchaseDate: number;
  quantity: number;
  expirationDate: number;
  isTrialPeriod?: boolean; // only for subscriptions and if extented = true
  isInIntroOfferPeriod?: boolean; // only for subscriptions and if extented = true, since v1.5.1
  environment?: string; // only if extented = true
  originalPurchaseDate?: number; // only if extented = true
  applicationVersion?: string; // only if extented = true
  originalApplicationVersion?: string; // only if extented = true
};

export type Product = {
  bundleId: string;
  expirationDate: Timestamp; //FirebaseFirestore.Timestamp;
  originalTransactionId: string;
  productId: PlanId;
  purchaseDate: number;
  quantity: number;
  transactionId: string;
};

export type ReceiptIap = {
  userId: string;
  receipt: string;
  verified: boolean;
  products: Product[] | IapPurchase[];
  timestamp: number;
  timestampDate: Date;
  environment: 'sandbox' | 'production';
};

export type IapValidate = {
  receipt: any;
  productId: string;
};

export enum PlanId {
  Monthly = 'wellemental_pro',
  Yearly = 'wellemental_pro_year',
  Free = 'free',
}

export type PlanTypes = 'iosIap' | 'promoCode' | 'android' | 'stripe';

export enum SubStatus {
  Canceled = 'canceled',
  Active = 'active',
  Trial = 'trialing',
  Unpaid = 'unpaid',
  Incomplete = 'incomplete',
  Incomplete_Expired = 'incomplete_expired',
  Past_Due = 'past_due',
}

type UserPlanBase = {
  type: PlanTypes;
  auto_renew_status: boolean;
  nextRenewalDate: string | null; // Just storing so humans can easily read it in database - null could be removed, didn't have time to test if IAP always delivered a expirateUnix
  nextRenewalUnix: number; // unix timestamp
  startDate: string | null; // Just storing so humans can easily read it in database - null could be removed, didn't have time to test if IAP always delivered a startUnix
  startUnix: number; // unix timestamp
  lastVerified: string; // Last time a function validated it's active state
  canceledAtUnix?: number;
  planId: string; // Sku or product id
  status: SubStatus;
  stripeEvents?: string[];
  orderId?: string;
  code?: string;
  purchaseToken?: string; // For Android validation
  packageName?: string; // For Android validation
  trial_period_ends: string;
};

// For use when saving to Firebase
export interface UserPlan extends UserPlanBase {
  createdAt: Date;
}

// For use when getting from Firebase
export interface FbUserPlan extends UserPlanBase {
  createdAt: Timestamp;
}
