import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  ErrorMessage,
  Card,
  Icon,
  Box,
  ToggleButton,
  Paragraph,
} from '../../components';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  CardContent,
} from '@material-ui/core';
import logger from '../../services/LoggerService';
import { useContainer } from '../../hooks';
import {
  ArticleFeaturesServiceType,
  FeaturedUpdate,
} from '../../services/interfaces';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
  ReflectionSelect,
  ArticleSelect,
  DiscussionSelect,
  IconSelect,
} from '../forms';
import {
  FeaturedType,
  FeaturedArticle,
  ArticleColorOptions,
  FeaturesObj,
  FeatureTypes,
  IconNames,
} from 'types';

interface Props {
  editing: FeaturedType | undefined;
  features: FeaturesObj | null;
  onSave: () => void;
}

type Colors =
  | 'brandPrimary'
  | 'brandInfo'
  | 'textColor'
  | 'brandDark'
  | 'lightTextColor'
  | undefined;

const colorOptions: Partial<ArticleColorOptions>[] = [
  'brandInfo',
  'brandPrimary',
  'textColor',
  'brandDark',
  'lightTextColor',
];

const initialFeature: FeaturedArticle = {
  id: '',
  label: '',
  title: '',
  background: colorOptions[0],
  bordered: undefined,
  icon: IconNames.Brain,
  type: 'article',
};

const initialState: FeaturedUpdate = {
  date: moment().toDate(),
  articles: [initialFeature],
};

// Functions for creation and editing articles.
const FeaturesCreateScreen: React.FC<Props> = ({
  editing,
  features,
  onSave,
}) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // if editing article, load that state, end use initial
  const [state, setState] = useState<FeaturedUpdate>(
    !!editing ? editing : initialState,
  );

  // Update state when feature prop changes - feature prop is loading when editing
  useEffect(() => {
    if (editing) {
      setState(editing);
    }
  }, [editing]);

  const container = useContainer();

  const service = container.getInstance<ArticleFeaturesServiceType>(
    'articleFeaturesService',
  );

  const resetState = () => {
    setState(initialState);
    setError('');
    setLoading(false);
  };

  const handleSave = async () => {
    if (!state.date) {
      setError('Must fill out all fields');
    } else if (
      features &&
      features[moment(state.date).format('YYYY-MM-DD')] &&
      !editing
    ) {
      setError(
        'A feature already exists on this date. Scroll down and click to edit it.',
      );
    } else {
      // Firestore won't store undefined values, but select components won't allow null values
      // So before we save in database, we remove any undefined bg or border fields from obj
      const newState = { ...state };

      for (let i = 0; i < newState.articles.length; i++) {
        // Add type if not already on object - failsafe for early features that didn't have type
        if (!newState.articles[i].type) {
          newState.articles[i].type = 'article';
        }
        if (newState.articles[i].bordered === undefined) {
          delete newState.articles[i].bordered;
        }
        if (newState.articles[i].background === undefined) {
          delete newState.articles[i].background;
        }
      }

      try {
        setLoading(true);
        service.addOrEditFeature(newState);
        resetState();
      } catch (err) {
        logger.error(`Error adding article feature - ${err}`);
        setError(err);
      }
      onSave();
      setLoading(false);
    }
    window.scrollTo(0, 0);
  };

  const moveFeature = (old_index: number, new_index: number) => {
    // Copy state articles
    const newFeat = [...state.articles];

    // Rearrange their index
    newFeat.splice(new_index, 0, newFeat.splice(old_index, 1)[0]);
    setState({ ...state, articles: newFeat });
  };

  const addFeature = () => {
    const newState: FeaturedUpdate = { ...state };
    newState.articles.push(initialFeature);
    setState(newState);
  };

  const removeFeature = (idx: number) => {
    // Copy state articles
    const newFeat = [...state.articles];

    // Remove the idx article from the array
    newFeat.splice(idx, 1);

    // Update state with newFeat arr after removal
    setState({ ...state, articles: newFeat });
  };

  const updateDate = (value: Date) => {
    setState({ ...state, date: value });
  };

  const updateFeature = (idx: number, update: Partial<FeaturedArticle>) => {
    const newState: FeaturedUpdate = { ...state };

    // Auto-switch feature icon to comments if it's a discussion
    if (newState.articles[idx].type === 'discussion') {
      newState.articles[idx].icon = IconNames.Comments;
    }

    newState.articles[idx] = { ...newState.articles[idx], ...update };

    setState(newState);
  };

  // Wildly annoying hack to appease firestore, material UI selects, and toggle Button
  // This switches between bordered and background style while maintaining color state
  // Easier way to do this would be to re-architect FeatureArticle to have style type and color instead of background and bordered fields
  const toggleStyle = (val: string) => {
    const split = val.split('-');
    const idx = +split[0];
    const value = split[1];

    if (value === 'bordered') {
      updateFeature(idx, {
        bordered: state.articles[idx].background,
        background: undefined,
      });
    } else if (value === 'background') {
      updateFeature(idx, {
        bordered: undefined,
        background: state.articles[idx].bordered,
      });
    }
  };

  return (
    <>
      <ErrorMessage center error={error} />
      <Box mt={3}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            fullWidth
            disablePast
            disabled={!!editing}
            openTo="year"
            format="MM/DD/yyyy"
            label="Date"
            views={['year', 'month', 'date']}
            value={state.date}
            onChange={event => event && updateDate(event.toDate())}
          />
        </MuiPickersUtilsProvider>
      </Box>

      {state.articles.map((feature, idx: number) => (
        <Card gutterBottom key={`${idx}-${feature.id}`}>
          <CardContent>
            <Box row justifyContent="space-between">
              <Paragraph bold>Feature #{idx + 1}</Paragraph>
              <Box row>
                <IconButton
                  disabled={idx === state.articles.length - 1}
                  size="small"
                  onClick={() => moveFeature(idx, idx + 1)}>
                  <Icon name={IconNames.ChevronDown} />
                </IconButton>
                <IconButton
                  disabled={idx === 0}
                  size="small"
                  onClick={() => moveFeature(idx, idx - 1)}>
                  <Icon name={IconNames.ChevronUp} />
                </IconButton>
                <IconButton size="small" onClick={() => removeFeature(idx)}>
                  <Icon name={IconNames.Close} />
                </IconButton>
              </Box>
            </Box>
            <FormControl fullWidth margin="normal">
              <ToggleButton
                buttons={['reflection', 'article', 'discussion']}
                value={`${feature.type}`}
                setValue={(val: FeatureTypes) =>
                  updateFeature(idx, { type: val })
                }
              />
            </FormControl>
            {feature.type === 'discussion' ? (
              <DiscussionSelect
                value={feature.id}
                onChange={event => {
                  updateFeature(idx, {
                    id: event.target.value as string,
                  });
                }}
              />
            ) : feature.type === 'reflection' ? (
              <ReflectionSelect
                value={feature.id}
                onChange={event => {
                  updateFeature(idx, {
                    id: event.target.value as string,
                  });
                }}
              />
            ) : (
              <ArticleSelect
                value={feature.id}
                onChange={event => {
                  updateFeature(idx, {
                    id: event.target.value as string,
                  });
                }}
              />
            )}

            <TextField
              gutterTop={3}
              id="feature-title"
              label="Feature Title"
              fullWidth
              value={feature.title}
              onChange={event => {
                updateFeature(idx, {
                  title: event.target.value,
                });
              }}
              variant="filled"
            />

            <TextField
              gutterTop={3}
              id="feature-label"
              label="Feature Label"
              fullWidth
              value={feature.label}
              onChange={event => {
                updateFeature(idx, {
                  label: event.target.value,
                });
              }}
              variant="filled"
            />

            <IconSelect
              value={feature.icon}
              onChange={event => {
                updateFeature(idx, {
                  icon: event as IconNames,
                });
              }}
            />

            <ToggleButton
              buttons={[`${idx}-bordered`, `${idx}-background`]}
              value={
                state.articles[idx].bordered
                  ? `${idx}-bordered`
                  : `${idx}-background`
              }
              setValue={toggleStyle}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel id="color">Color</InputLabel>
              <Select
                labelId="border-color"
                id="border-color"
                value={feature.bordered ? feature.bordered : feature.background}
                onChange={event => {
                  updateFeature(
                    idx,
                    feature.bordered
                      ? {
                          bordered: event.target.value as ArticleColorOptions,
                        }
                      : {
                          background: event.target.value as ArticleColorOptions,
                        },
                  );
                }}>
                {colorOptions.map(color => (
                  <MenuItem key={`border-${color}`} value={color}>
                    {color ? color : 'undefined'}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      ))}
      <Button
        text="Add another feature"
        variant="outlined"
        fullWidth
        disabled={loading}
        onClick={addFeature}
      />
      <Button
        text="Save"
        color="secondary"
        fullWidth
        disabled={loading}
        onClick={handleSave}
      />
    </>
  );
};

export default FeaturesCreateScreen;
