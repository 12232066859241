import React, { useEffect, useState } from 'react';
import { Spinner } from '../components';
import {
  Article,
  FeaturesObj,
  FeaturedType,
  ArticlesObj,
  ObjectType,
  GamePlanTemplate,
  ReflectionExercise,
  FirestoreServiceType,
  Collections,
} from 'types';
import { Discussion, GamePlanServiceType } from '../services/interfaces';
import { useContainer, useCurrentUser, useSubscription } from '../hooks';
import logger from '../services/LoggerService';
import { publishedArticlesQuery } from '../services/queries';

interface ContentTypes {
  articles: ArticlesObj | null;
  gameplans: GamePlanTemplate[] | null;
  discussions: ObjectType<Discussion> | null;
  discussionsArr: Discussion[] | null;
  features: FeaturesObj | null;
  reflections?: ObjectType<ReflectionExercise> | null;
  reflectionsArr: ReflectionExercise[] | null;
}

interface IndividualContent {
  article?: Article;
  gameplan?: GamePlanTemplate;
  discussion?: Discussion;
  feature?: FeaturedType;
  reflection?: ReflectionExercise;
}

type ContentKeys = keyof ContentTypes;

interface ContentContext extends ContentTypes {
  loading: boolean;
}

export const Content = React.createContext<ContentContext>({
  articles: {},
  discussions: {},
  discussionsArr: [],
  gameplans: [],
  reflections: {},
  reflectionsArr: [],
  loading: false,
  features: {},
});

export const ContentProvider = ({ children }: { children: any }) => {
  const [gameplans, setGameplans] = useState<GamePlanTemplate[] | null>(null);
  const [features, setFeatures] = useState<FeaturesObj | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { user: currentUser } = useCurrentUser();

  if (!currentUser?.isCoach) {
    return children;
  }

  const container = useContainer();
  const gameplanService = container.getInstance<GamePlanServiceType>(
    'gameplanService',
  );

  const firestoreService = container.getInstance<FirestoreServiceType>(
    'firestoreService',
  );

  // const articles: ArticlesObj = {};
  // const articlesLoading = false;
  // const discussions: ObjectType<Discussion> | null = null;
  // const discussionsLoading = false;
  // const reflections: ObjectType<ReflectionExercise> | null = null;
  // const reflectionsLoading = false;

  const {
    data: articles,
    loading: articlesLoading,
  } = useSubscription<ArticlesObj | null>(
    firestoreService.listenToCollection,
    publishedArticlesQuery,
    'object',
  );

  const {
    data: discussions,
    loading: discussionsLoading,
  } = useSubscription<ObjectType<Discussion> | null>(
    firestoreService.listenToCollection,
    Collections.Discussions,
    'object',
  );

  const {
    data: reflections,
    loading: reflectionsLoading,
  } = useSubscription<ObjectType<ReflectionExercise> | null>(
    firestoreService.listenToCollection,
    Collections.Exercises,
    'object',
  );

  const getDbContent = async (type?: ContentKeys): Promise<void> => {
    try {
      // Get all content and just store it
      // Need to store in local storage soon, and just have this pull when refresh required
      // const dbArticles = await articleService.getAll();
      const dbGamePlans = await gameplanService.getAll();

      // Update state with firestore data
      // setArticles(dbArticles);
      setGameplans(dbGamePlans);
    } catch (err) {
      setError(`Error fetching content from database - ${err}`);
      logger.error(`Error getting firestore content data - ${err}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentUser && currentUser.isCoach) {
      getDbContent();
    }
  }, [currentUser]);

  const isLoading =
    loading || discussionsLoading || articlesLoading || reflectionsLoading;

  if (isLoading) {
    return <Spinner text="Loading content..." />;
  }

  return (
    <Content.Provider
      value={{
        articles,
        discussions,
        discussionsArr: discussions && Object.values(discussions),
        reflections,
        reflectionsArr: reflections && Object.values(reflections),
        // setContent,
        gameplans,
        features,
        loading: isLoading,
        // refreshContent: getDbContent,
      }}>
      {children}
    </Content.Provider>
  );
};
