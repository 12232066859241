import BaseService from './BaseService';
import {
  DocumentReference,
  DocumentSnapshot,
  SubscriptionListener,
  Unsubscriber,
  Query,
  QuerySnapshot,
  DocumentData,
  CollectionReference,
  FirestoreServiceType,
} from './interfaces';
import { IdDoc, Collections } from 'types';
// import { docForPlayer, scorecardDoc } from '../models/FirebasePlayer';

class FirestoreService extends BaseService implements FirestoreServiceType {
  private addTimestamps = (
    type: 'create' | 'update',
  ): { updatedAt: Date; createdAt?: Date } => {
    const updatedAt = new Date();

    return type === 'create'
      ? { createdAt: new Date(), updatedAt }
      : { updatedAt };
  };

  public addOrUpdateDoc = async <T extends IdDoc>(
    collection: Collections | CollectionReference<T>,
    obj: T,
  ): Promise<void> => {
    const docRef: DocumentReference =
      typeof collection === 'string'
        ? this.firestore.collection(collection).doc(obj.id)
        : collection.doc(obj.id);

    // Need to update to have it load with the ID and overwrite existing, change from .add to .set
    try {
      const doc = await docRef.get();

      if (doc.exists) {
        await docRef.update({ ...obj, ...this.addTimestamps('update') });
      } else {
        await docRef.set({ ...obj, ...this.addTimestamps('update') });
      }
    } catch (err) {
      this.logger.error('Error saving reflection', err);
      return Promise.reject(`Error saving reflection - ${err}`);
    }

    return Promise.resolve();
  };

  public listenToCollection = <T>(
    listener: SubscriptionListener<T | null>,
    collection: string | Query,
    type?: 'array' | 'object',
  ): Unsubscriber => {
    const isObject = type === 'object';
    const items: any = isObject ? {} : [];

    const query =
      typeof collection === 'string'
        ? this.firestore.collection(collection)
        : collection;

    const subscription = query.onSnapshot(
      (snapshot: QuerySnapshot<DocumentData>) => {
        snapshot.docs.forEach((doc: DocumentSnapshot) => {
          const data = doc.data() as T;

          // if (data.status !== PublishStatus.Deleted) {
          //   articles[doc.id] = { ...data };
          // }

          if (Array.isArray(items)) {
            items.push({ ...data });
          } else {
            items[doc.id] = { ...data };
          }

          listener(items);
        });
      },
    );

    return subscription;
  };

  public listenToDoc = async <T>(
    doc: string,
    setState: React.Dispatch<React.SetStateAction<T | null>>,
  ): Promise<void> => {
    const query: DocumentReference = this.firestore.doc(doc);

    const subscription = query.onSnapshot(
      async (snapshot: DocumentSnapshot) => {
        const data = snapshot.data() as T;
        setState(data);
        return;
      },
    );
  };
}

export default FirestoreService;
