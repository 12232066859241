import moment from 'moment';
import React from 'react';
import { ActivityProps, UserResponse } from 'react-activity-feed';
import ActivityFooterComponent from './ActivityFooterComponent';
import { Feature, Card } from '../../components';
import PostActivityContent from './PostActivityContent';
import DiscussionActivityContent from './DiscussionActivityContent';
import ScheduledActivityContent from './ScheduledActivityContent';
import RecapActivityContent from './RecapActivityContent';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { brandColors } from '../../assets/styles/theme';

const activityContentTypes: { [key: string]: any } = {
  post: PostActivityContent,
  welcome: ScheduledActivityContent,
  scheduled: ScheduledActivityContent,
  recap: RecapActivityContent,
  discussion: DiscussionActivityContent,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
      padding: '0px !important',
      marginBottom: '20px',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: theme.palette.primary.light,
    },
  }),
);

const ActivityComponent: React.FC<ActivityProps> = ({
  userId,
  activity,
  onToggleReaction,
  onAddReaction,
  onRemoveReaction,
  onAddChildReaction,
  onRemoveChildReaction,
  onToggleChildReaction,
}) => {
  const classes = useStyles();
  if (!userId) return null;

  let actor = activity.actor as UserResponse;

  if (!actor) {
    actor = {
      id: 'unknown',
      created_at: moment().toISOString(), // eslint-disable-line @typescript-eslint/camelcase
      updated_at: moment().toISOString(), // eslint-disable-line @typescript-eslint/camelcase
      data: {
        name: 'Unknown User',
      },
    };
  }

  const Content =
    activityContentTypes[activity.type ? activity.type : activity.verb];

  const isCurrentUsersPost = actor.id === userId;

  return (
    <Feature feature="changeActivityVisibility">
      {({ enabled: hideActivityEnabled }): React.ReactElement | null => {
        // Hide post unless the current user is a coach OR it's that user's own post
        const isCoach = hideActivityEnabled;

        // If activity is hidden, don't show the post
        // UNLESS the user is a coach OR is the person who posted the post
        if (activity.hidden && !isCoach && !isCurrentUsersPost) {
          return null;
        }

        return (
          <Card
            className={classes.root}
            raised
            padding={false}
            variant="elevation"
            style={{
              borderTopColor:
                activity.hidden && isCoach // Only show hidden red border to coaches
                  ? brandColors.brandPrimary
                  : Content === RecapActivityContent
                  ? brandColors.brandPrimary
                  : Content !== PostActivityContent
                  ? '#e1c773'
                  : 'undefined',
              borderTopWidth:
                (activity.hidden && isCoach) || Content !== PostActivityContent // Only have border width if it's hidden and the person is not a coach, or if it's a scheduled post
                  ? 5
                  : 0,
            }}>
            <Content activity={activity} actor={actor} />
            <ActivityFooterComponent
              activity={activity}
              userId={userId}
              onToggleReaction={onToggleReaction}
              onAddReaction={onAddReaction}
              onRemoveReaction={onRemoveReaction}
              onAddChildReaction={onAddChildReaction}
              onRemoveChildReaction={onRemoveChildReaction}
              onToggleChildReaction={onToggleChildReaction}
            />
          </Card>
        );
      }}
    </Feature>
  );
};

export default ActivityComponent;
