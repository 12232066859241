import React from 'react';
import { useCurrentUser } from '../hooks';
import { Route, Redirect } from 'react-router-dom';
import { Spinner } from '../components';

function PrivateRoute({ component: RouteComponent, ...rest }) {
  const { user, activePlan, isCoach, loading } = useCurrentUser();

  const prosOnly = rest.pro;
  const coachesOnly = rest.coach;
  const loggedIn = !!user;

  return loading ? (
    <Spinner />
  ) : (
    <Route
      {...rest}
      render={({ location }) =>
        // If not logged in, redirect to login screen (since these are all privateRoutes)
        !loggedIn ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        ) : // If pages is for coaches only and user is a coach, let them access the page
        coachesOnly && isCoach ? (
          <RouteComponent />
        ) : // If page is for pro users only and player IS NOT a pro, redirect them to the plans page
        prosOnly && !activePlan ? (
          <Redirect to="/plans" />
        ) : // If page is for pro users only and play IS PRO, let them access the page
        prosOnly && activePlan ? (
          <RouteComponent />
        ) : (
          // If page just requires login and they're logged in, or other fringe cases, let them continue to page
          <RouteComponent />
        )
      }
    />
  );
}

export default PrivateRoute;
