import React, { useState, useEffect, PropsWithChildren } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { brandColors } from '../../assets/styles/theme';
import DataListItem from './DataListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'scroll',
    },
  }),
);

const sideNav = {
  height: 'calc(100vh - 247px)',
  fallbacks: [
    { height: '-moz-calc(100vh - 247px)' },
    { height: '-webkit-calc(100vh - 247px)' },
    { height: '-o-calc(100vh - 247px)' },
  ],
};

interface Props<T> {
  items: T[] | null;
  selected?: string;
  onPress: any; //(id: string) => void;
  primary?: keyof T;
  secondary?: keyof T;
}

const List = <T extends { id: string; [key: string]: any }>(
  props: PropsWithChildren<Props<T>>,
) => {
  const { items, selected, onPress, primary, secondary } = props;
  const classes = useStyles();

  return (
    <MuiList className={classes.root} style={sideNav}>
      {items && items.length > 0 ? (
        items.map((item: T, idx: number) => {
          const isSelected = selected === item.id;

          return (
            <DataListItem<T>
              selected={!!isSelected}
              onPress={onPress}
              key={`${idx}-${item.id}`}
              item={item}
              primary={primary}
              secondary={secondary}
            />
          );
        })
      ) : (
        <Typography>Nothing to see here</Typography>
      )}
    </MuiList>
  );
};

export default List;
