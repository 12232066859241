import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Slide,
  Button,
  Toolbar,
  Typography,
  // useScrollTrigger,
} from '@material-ui/core';
import {
  Group as GroupIcon,
  Home as HomeIcon,
  Menu as MenuIcon,
  Sports as WhistleIcon,
  AccountCircle as UserIcon,
  Dashboard as DashboardIcon,
  ExitToApp as LogoutIcon,
  FindInPage as JobsIcon,
  QuestionAnswer as QuestionIcon,
  CreditCard as CreditCardIcon,
  // MailOutline as MailIcon,
  Star as StarIcon,
  MenuBook as ArticleIcon,
  InfoOutlined as AboutIcon,
  Forum as DiscussionIcon,
} from '@material-ui/icons';
import { ListItemLink } from './';
import { useHistory, useLocation } from 'react-router-dom';
import {
  // Crescent,
  Logo,
  // Button,
} from '../components';
import app from '../base';
import { useCurrentUser, usePageviews } from '../hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      // position: 'fixed',
      zIndex: 1,
      margin: '20px 0',
    },
    appBar: {
      color: theme.palette.secondary.dark,
      boxShadow: 'none',
    },
    menuButton: {
      // marginRight: theme.spacing(2),
      position: 'absolute',
      left: '15px',
      color: theme.palette.secondary.dark,
      [theme.breakpoints.down('md')]: {
        marginTop: -5,
      },
    },
    rightButton: {
      position: 'absolute',
      right: '20px',
      [theme.breakpoints.down('md')]: {
        marginTop: -10,
      },
    },
    title: {
      flexGrow: 1,
    },
    list: {
      width: 300,
    },
    fullList: {
      width: 'auto',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

type Props = {
  simple?: boolean;
  title?: string;
};

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const Nav: React.FC<Props> = props => {
  usePageviews();
  const classes = useStyles();
  const { user: currentUser } = useCurrentUser();
  const history = useHistory();
  const { pathname } = useLocation();
  // const trigger = useScrollTrigger();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const anchor = 'left';

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <List>
        {!!currentUser ? (
          <>
            <ListItem
              button
              onClick={() => history.push('/dashboard')}
              alignItems="flex-start">
              {currentUser.avatar ? (
                <ListItemAvatar>
                  <Avatar
                    alt={currentUser.name && currentUser.name}
                    src={currentUser.avatar}
                  />
                </ListItemAvatar>
              ) : (
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
              )}

              <ListItemText
                primary={
                  currentUser.name ? currentUser.name : currentUser.email
                }
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="secondary">
                    View Account
                  </Typography>
                }
              />
            </ListItem>
            <Divider />

            {currentUser && currentUser.teamsCoached && (
              <>
                <ListItemLink to="/team" primary="Team" icon={<GroupIcon />} />
                <ListItemLink
                  to="/articles"
                  primary="Articles"
                  icon={<ArticleIcon />}
                />
                <ListItemLink
                  to="/gameplans"
                  primary="Gameplans"
                  icon={<WhistleIcon />}
                />
                <ListItemLink
                  to="/analytics"
                  primary="Analytics"
                  icon={<WhistleIcon />}
                />
                <ListItemLink
                  to="/exercises"
                  primary="Exercises"
                  icon={<WhistleIcon />}
                />
                <ListItemLink
                  to="/discussions"
                  primary="Discussions"
                  icon={<DiscussionIcon />}
                />
                <ListItemLink
                  to="/features"
                  primary="Features"
                  icon={<StarIcon />}
                />
              </>
            )}
            {currentUser && (
              <ListItemLink
                to="/dashboard"
                primary="Dashboard"
                icon={<DashboardIcon />}
              />
            )}
            {currentUser && !currentUser.plan && (
              <ListItemLink
                to="/subscription"
                primary="Subscription"
                icon={<CreditCardIcon />}
              />
            )}

            <ListItem button onClick={() => app.auth().signOut()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItem>
          </>
        ) : (
          <ListItemLink to="/login" primary="Login" icon={<LogoutIcon />} />
        )}

        <Divider />

        <ListItemLink to="/" primary="Home" icon={<HomeIcon />} />
        <ListItemLink
          to="/playbook"
          primary="Playbook"
          icon={<ArticleIcon />}
        />
        <ListItemLink to="/about" primary="About" icon={<AboutIcon />} />
        <ListItemLink to="/faq" primary="FAQs" icon={<QuestionIcon />} />

        {/* <ListItem button onClick={() => toggleNest(!isNestOpen)}>
          <ListItemIcon>
            <AboutIcon />
          </ListItemIcon>
          <ListItemText primary="Inbox" />
          {isNestOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isNestOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Starred" />
            </ListItem>
          </List>
        </Collapse> */}
        {/* <ListItemLink to="/jobs" primary="Jobs" icon={<JobsIcon />} /> */}
        <Divider />
        <ListItem
          button
          onClick={() => {
            window.location.href = 'mailto:hello@mentalhealthleague.com';
          }}>
          {/* <ListItemIcon>
            <MailIcon />
          </ListItemIcon> */}

          <ListItemText
            primary="Contact Us"
            secondary={
              <Typography
                component="span"
                variant="body2"
                color="textSecondary">
                hello@mentalhealthleague.com
              </Typography>
            }
          />
        </ListItem>
      </List>
    </div>
  );

  const showMenuLinks =
    pathname !== '/survey' &&
    pathname !== '/checkout' &&
    pathname !== '/plans' &&
    pathname !== '/offer' &&
    pathname !== '/login' &&
    pathname !== '/join' &&
    pathname !== '/men' &&
    pathname !== '/foggydogs' &&
    pathname !== '/login?redirect=checkout';

  const allowhomepagelink =
    pathname !== '/anxietees' &&
    pathname !== '/join' &&
    pathname !== '/men' &&
    pathname !== '/foggydogs';

  return (
    <>
      <AppBar
        position={pathname === '/' ? 'fixed' : 'absolute'}
        color="transparent"
        className={classes.appBar}
        style={{ zIndex: pathname === '/survey' ? -1 : 100 }}>
        {/* <Crescent /> */}
        <Toolbar>
          {currentUser && showMenuLinks && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(anchor, true)}>
              <MenuIcon />
            </IconButton>
          )}

          {props.title && (
            <Typography variant="h6" className={classes.title}>
              {props.title}
            </Typography>
          )}

          {pathname !== '/' && (
            <Logo padding={10} allowhomepagelink={!allowhomepagelink} />
          )}

          {showMenuLinks && (
            <Slide appear={true} direction="down" in={!currentUser}>
              <Button
                size="small"
                style={{ position: 'absolute', right: '15px' }}
                variant="text"
                onClick={() => history.push('/login')}>
                Login
              </Button>
            </Slide>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}>
        {list(anchor)}
      </Drawer>
    </>
  );
};

export default Nav;
