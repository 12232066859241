import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router';
import { StaticPage, aboutSteps, staticPages } from './static';
const AboutScreen: React.FC = () => {
  let { path } = useRouteMatch();
  const firstAboutStepUrl = staticPages[aboutSteps[0]].url;

  return (
    <Switch>
      <Route path={`${path}/:pageId`} render={() => <StaticPage about />} />
      <Route
        exact
        path={`${path}`}
        render={() => <Redirect to={firstAboutStepUrl} />}
      />
    </Switch>
  );
};

export default AboutScreen;
