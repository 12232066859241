import React from 'react';
import { Avatar as MuiAvatar, AvatarProps } from '@material-ui/core';
import { imageDir, ImageNames } from '../models/Image';

type Props = {
  source?: string;
  src?: ImageNames;
  alt?: string;
};

const Avatar: React.FC<Props & AvatarProps> = ({
  src,
  source,
  alt,
  ...props
}) => {
  return (
    <MuiAvatar
      style={{ height: 60, width: 60, marginRight: 10 }}
      src={source ? source : src ? imageDir[src].source.safari : undefined}
      alt={src ? imageDir[src].alt : alt ? alt : ''}
      {...props}
    />
  );
};

export default Avatar;
