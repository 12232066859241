import firebase from 'firebase/app';
import { ApplicationError } from '../models/Errors';
import { docForPlayer, fromPlayer } from '../models/FirebasePlayer';
import { EditablePlayerFields } from '../models/Player';
import logger from '../services/LoggerService';
import BaseService from './BaseService';
import { PlayerService } from './interfaces';
import { SurveyAnswers, Team } from 'types';

class UpdatePlayerService extends BaseService implements PlayerService {
  public async perform(
    id: string,
    fields: EditablePlayerFields,
  ): Promise<void> {
    const doc = docForPlayer(id);

    const updates = fromPlayer(fields);

    try {
      await doc.update({
        ...updates,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {
      logger.error(`Failed to update player '${id}': ${error}`);
      throw new ApplicationError('Unable to update player');
    }
  }

  public updateCurrentPlayer = async (
    fields: EditablePlayerFields,
  ): Promise<void> => {
    return this.perform(this.currentPlayer.id, fields);
  };

  public joinToTeam = async ({
    answers,
    team,
  }: {
    answers?: SurveyAnswers;
    team?: Team;
  }): Promise<void> => {
    await firebase.functions().httpsCallable('onJoinPlayerToTeam')({
      answers,
      team,
    });
  };
}

export default UpdatePlayerService;
