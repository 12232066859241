import React, { createContext } from 'react';
import { StreamApp } from 'react-activity-feed';

type Props = {
  children: any;
  streamToken: string;
};

export const Stream = createContext({});

export const StreamProvider: React.FC<Props> = ({ children, streamToken }) => {
  return streamToken &&
    process.env.REACT_APP_STREAM_API_KEY &&
    process.env.REACT_APP_STREAM_APP_ID ? (
    <StreamApp
      sharedFeeds={[]}
      apiKey={process.env.REACT_APP_STREAM_API_KEY}
      appId={process.env.REACT_APP_STREAM_APP_ID}
      token={streamToken}
      errorHandler={() => {}}>
      <Stream.Consumer>
        {ctx => <Stream.Provider value={ctx}>{children}</Stream.Provider>}
      </Stream.Consumer>
    </StreamApp>
  ) : null;
};
