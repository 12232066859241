import { ReactionResponse } from 'getstream';
import React from 'react';
// import { ViewStyle } from 'react-native';
// import { ReactionToggleIcon } from 'react-activity-feed';
import { Button } from '@material-ui/core';
import { ThumbUpAltOutlined as ThumbUpAltOutlinedIcon } from '@material-ui/icons';
import { theme } from '../../assets/styles/theme';

interface Props {
  label?: string;
  size?: number;
  reaction: ReactionResponse;
  // containerStyle?: ViewStyle;
  containerStyle?: any;
  onPress?: () => void;
}

const CheerReactionComponent: React.FC<Props> = ({
  reaction,
  onPress,
  containerStyle = {},
  size = 24,
  label = 'Cheer',
}) => {
  const { children_counts: counts } = reaction;

  // const labelFunction = (params: { count: number }): string => {
  //   return params.count > 0 ? `${label} (${params.count})` : `${label}`;
  // };

  const active = counts ? counts.cheer > 0 : false;

  // const ownReactions = reaction.own_children || {};

  const cheerColor = active
    ? theme.palette.primary.dark
    : theme.palette.primary.light;

  return (
    <Button
      startIcon={<ThumbUpAltOutlinedIcon />}
      onClick={onPress}
      aria-label="cheer-activity"
      style={{ color: cheerColor }}>
      {counts && counts.cheer && counts.cheer}
      {counts && counts.cheer < 1 ? ' cheers' : ' cheer'}
    </Button>
    // <ReactionToggleIcon
    //   own_reactions={ownReactions}
    //   height={size}
    //   width={size}
    //   counts={counts}
    //   kind={'cheer'}
    //   onClick={onPress}
    //   activeIcon={require('../../assets/images/icons/bullhorn-active.png')}
    //   inactiveIcon={require('../../assets/images/icons/bullhorn-inactive.png')}
    //   labelFunction={labelFunction}
    //   styles={{
    //     container: containerStyle,
    //     text: {
    //       color: active ? 'green' : 'red',
    //     },
    //   }}
    // />
  );
};

export default CheerReactionComponent;
