import React from 'react';
// import { Experiment, Variant } from 'react-optimize';
import Landing6 from './Landing6';
import Landing7 from './Landing7';
import { useRouteMatch } from 'react-router-dom';

const CollegeScreen = () => {
  let match = useRouteMatch('/college/:version');
  let version = '6';
  if (match && match.params) {
    version = match.params.version;
  }

  const Version = +version === 7 ? Landing7 : Landing6;

  return <Version />;
};

export default CollegeScreen;
