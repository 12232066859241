import React from 'react';
import { Typography } from '@material-ui/core';

const TheMindsetScreen: React.FC = () => {
  return (
    <>
      <div
        style={{
          position: 'relative',
          paddingBottom: '56.25%',
          height: 0,
          overflow: 'hidden',
          maxWidth: '100%',
          marginBottom: '20px',
        }}>
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          title="Join the MHL"
          src="https://www.youtube.com/embed/RB9-UYgbfVM"
        />
      </div>

      <Typography gutterBottom>
        A lot of people think that having mental health problems means you’re
        weak or broken in some way...or that the problems aren’t even real. So
        they're ashamed to get treatment or even admit they have a problem; and
        even if they do, they keep their progress to themselves.
      </Typography>

      <Typography gutterBottom>
        We think it's time to adopt a new mindset – to come together, turn that
        shame into strength, and tackle mental health as a team.
      </Typography>

      <Typography gutterBottom variant="h5" color="primary">
        Mental health is a sport. You're the star player competing against the
        world's toughest opponent – your mind.
      </Typography>

      <Typography gutterBottom>
        Every day is a game. Every month is a new season.
      </Typography>
      <Typography gutterBottom>
        Treatment is the training you need to win the championship of life.
        Therapists and counselors are coaches, psychiatrists are trainers, and
        medicine is supplements.
      </Typography>
      <Typography gutterBottom>
        Now the definition of a "win" will be different for everyone and so will
        the game plan...
      </Typography>
      <Typography gutterBottom variant="h5" color="primary">
        But the commonality is that there's NO OFF-SEASON.
      </Typography>
      <Typography gutterBottom>
        You have to play 365 days a year. You won’t win every game — no athlete
        ever does. What’s important is that you learn from each game and get
        back on the field the next day, for yourself and your biggest fans.
      </Typography>
      <Typography gutterBottom>
        And when you're feeling down, know you've got a team behind you. Because
        alone you're tough, but with the team you can't be beaten.
      </Typography>
    </>
  );
};

export default TheMindsetScreen;
