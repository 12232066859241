import React from 'react';
import { Page, LoginCustom } from '../components';
import { useUrlParams, useCurrentUser, useHistory } from '../hooks';
import { useLocation, Redirect } from 'react-router-dom';

interface LocationState {
  from?: {
    pathname: string;
  };
}

const LoginScreen = () => {
  const { user: currentUser } = useCurrentUser();
  const history = useHistory();

  let params = useUrlParams();
  let location = useLocation();
  let state: LocationState | undefined | null = location.state as LocationState;

  let redirect = params.get('redirect') // if redirect comes from URL (homepage)
    ? '/' + params.get('redirect')
    : state && state.from // If they landed on login page from PrivateRoute
    ? state.from.pathname
    : currentUser && !!currentUser.teamsCoached
    ? '/team'
    : '/dashboard'; // Else just go to homepage

  console.log('STATE', state);
  console.log('REDIRECT', params.get('redirect'));
  console.log('REDIRECT CALC', redirect);

  if (currentUser?.teamsCoached) {
    history.push('team');
  } else if (currentUser) {
    history.push(redirect);
  } else if (currentUser) {
    history.push('/dashboard');
  }

  return (
    <Page disableGutters maxWidth="xs">
      <LoginCustom redirect={redirect} />
    </Page>
  );
};

export default LoginScreen;
