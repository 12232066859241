import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { useContent } from '../../hooks';

type Props = {
  value: string;
  onChange:
    | ((
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode,
      ) => void)
    | undefined;
};

// Functions for creation and editing articles.
const DiscussionSelect: React.FC<Props> = ({ value, onChange }) => {
  const { discussionsArr } = useContent();

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="status">Discussion Id</InputLabel>
      <Select
        labelId="discussion-id"
        id="discussion-id"
        value={value ? value : discussionsArr && discussionsArr[0].id}
        onChange={onChange}>
        {discussionsArr &&
          Object.values(discussionsArr).map((discussion, idx) => (
            <MenuItem
              key={idx}
              // name={discussion.pages[0].title}
              value={discussion.id}>
              {discussion.post.content}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default DiscussionSelect;
