import React, { useState, useEffect } from 'react';
import {
  Headline,
  Page,
  Box,
  Button,
  Card,
  Grid,
  TextField,
  PaginationButtons,
} from '../../components';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import {
  useContainer,
  usePagination,
  useHistory,
  useRouteMatch,
} from '../../hooks';
import { DiscussionServiceType, Discussion } from '../../services/interfaces';
import DiscussionScreen from './DiscussionScreen';
import { brandColors } from '../../assets/styles/theme';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scroll: {
      height: `calc(100vh - 90px)`,
      overflow: 'scroll',
    },
  }),
);

const DiscussionsScreen: React.FC = () => {
  const history = useHistory();
  const container = useContainer();
  const classes = useStyles();
  const service = container.getInstance<DiscussionServiceType>(
    'discussionService',
  );
  const { route2 } = useRouteMatch();

  const { items, isLoading, getNext, getPrev, page } = usePagination<
    Discussion
  >(service.query, {
    limit: 10,
  });

  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const [adding, toggleAdding] = useState(false);
  const [discussions, setDiscussions] = useState<Discussion[]>([]);

  const handleAdd = async () => {
    try {
      setError('');
      toggleAdding(true);
      const newDiscussion = await service.create(text);
      const newState = [...discussions];
      newState.unshift(newDiscussion);
      setDiscussions(newState);
      setText('');
      setError('Discussion created!');
    } catch (err) {
      setError(err);
    }
    toggleAdding(false);
  };

  useEffect(() => {
    if (items) {
      setDiscussions(items);
    }
  }, [items]);

  return (
    <Page fullPage maxWidth="xl" loading={isLoading} error={error}>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={6} className={classes.scroll}>
          <Headline variant="h6" gutterBottom>
            Discussions
          </Headline>
          <Card padding>
            <TextField
              multiline
              rows={3}
              label="Add a discussion..."
              value={text}
              onChange={e => setText(e.target.value)}
            />
            <Button
              fullWidth
              text="Create"
              onPress={handleAdd}
              disabled={adding}
            />
          </Card>

          <Divider />
          <List>
            {discussions &&
              discussions.map((item, idx) => (
                <Box key={idx}>
                  <ListItem
                    divider
                    selected={route2 === item.id}
                    alignItems="flex-start"
                    // onClick={() => selectGameplan(item)}
                    onClick={() => history.push(`/discussions/${item.id}`)}
                    style={
                      route2 === item.id
                        ? {
                            borderBottom: `3px solid ${
                              brandColors.brandPrimary
                            }`,
                          }
                        : undefined
                    }>
                    <ListItemText
                      primary={item.post.content}
                      primaryTypographyProps={{
                        style:
                          route2 === item.id
                            ? { color: brandColors.brandPrimary }
                            : undefined,
                      }}
                    />
                  </ListItem>
                </Box>
              ))}
          </List>
          <PaginationButtons
            getNext={getNext}
            getPrev={getPrev}
            page={page}
            items={items.length}
          />
        </Grid>
        <Grid item xs={6} className={classes.scroll}>
          <DiscussionScreen />
        </Grid>
      </Grid>
    </Page>
  );
};

export default DiscussionsScreen;
