import moment from 'moment';

enum DateFormat {
  Default = 'ddd MMM Do, YYYY',
}

enum Result {
  Win = 'win',
  Loss = 'loss',
  Tie = 'tie',
}

class Scorecard {
  readonly id?: string;
  readonly date: moment.Moment;
  readonly result?: Result;
  readonly why?: string;
  readonly plays?: string[];
  readonly shared?: 'true' | 'false' | 'false - Not on team' | undefined;
  readonly flagged?: boolean;

  readonly createdAt?: Date;

  constructor(
    date: moment.Moment,
    result?: Result,
    why?: string,
    createdAt?: Date,
    plays?: string[],
    id?: string,
    shared?: 'true' | 'false' | 'false - Not on team' | undefined,
    flagged?: boolean,
  ) {
    this.date = date;
    this.result = result;
    this.why = why;
    this.createdAt = createdAt;
    this.plays = plays;
    this.id = id;
    this.shared = shared;
    this.flagged = flagged;
  }

  public isPersisted(): boolean {
    return this.createdAt !== undefined;
  }

  public static fromScorecard(
    scorecard: Scorecard,
    newDate?: moment.Moment,
    newResult?: Result,
    newWhy?: string,
    newCreatedAt?: Date,
    newPlays?: string[],
  ): Scorecard {
    const { date, result, createdAt, plays, why } = scorecard;
    return new Scorecard(
      newDate || date,
      newResult || result,
      newWhy || why,
      newCreatedAt || createdAt,
      newPlays || plays,
    );
  }
}

export default Scorecard;
export { DateFormat, Result };
