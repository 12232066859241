import { Mascot, MascotId } from 'types';

const mascotList: Record<MascotId, Mascot> = {
  [MascotId.Anxietees]: {
    id: MascotId.Anxietees,
    name: 'Anxietees',
    description: 'Battling Anxiety',
    tagline: 'All Wins, No Worries',
    logo: require('../assets/images/teams/anxietees-mascot.png'),
    wordmarkLight: require('../assets/images/teams/anxietees-wordmark-light.png'),
    wordmarkDark: require('../assets/images/teams/anxietees-wordmark-dark.png'),
    colorDark: '#21362C',
    colorAlt: '#45763C',
    colorNeutral: '#869EA1',
  },
  [MascotId.FoggyDogs]: {
    id: MascotId.FoggyDogs,
    name: 'Foggy Dogs',
    description: 'Battling Depression',
    tagline: 'Rise Above The Fog',
    logo: require('../assets/images/teams/foggy-dogs-mascot.png'),
    wordmarkLight: require('../assets/images/teams/foggy-dogs-wordmark-light.png'),
    wordmarkDark: require('../assets/images/teams/foggy-dogs-wordmark-dark.png'),
    colorDark: '#18355E',
    colorAlt: '#F58C29',
    colorNeutral: '#8B9091',
  },
  [MascotId.BipolarBears]: {
    id: MascotId.BipolarBears,
    name: 'Bipolar Bears',
    description: 'Battling Bipolar',
    tagline: 'Up, Down, But Never Out',
    logo: require('../assets/images/teams/bipolar-bears-mascot.png'),
    wordmarkLight: require('../assets/images/teams/bipolar-bears-wordmark-light.png'),
    wordmarkDark: require('../assets/images/teams/bipolar-bears-wordmark-dark.png'),
    colorDark: '#000',
    colorAlt: '#8D9A9E',
    colorNeutral: '#fff',
  },
  [MascotId.ADHBees]: {
    id: MascotId.ADHBees,
    name: 'ADHBees',
    description: 'Battling ADHD',
    tagline: 'The Hive Always Thrives',
    logo: require('../assets/images/teams/adhbees-mascot.png'),
    wordmarkLight: require('../assets/images/teams/adhbees-wordmark-light.png'),
    wordmarkDark: require('../assets/images/teams/adhbees-wordmark-dark.png'),
    colorDark: '#691B33', //'#8F092B',
    colorAlt: '#FFA100',
    colorNeutral: '#82A8AB',
  },
  [MascotId.MHL]: {
    id: MascotId.MHL,
    name: 'MHL',
    description: 'For all champions',
    tagline: 'There is no off-season',
    logo: require('../assets/images/teams/mhl-mascot.png'),
    wordmarkLight: require('../assets/images/teams/mhl-wordmark-light.png'),
    wordmarkDark: require('../assets/images/teams/mhl-wordmark-dark.png'),
    colorDark: '#0b2240',
    colorAlt: '#E7004C',
    colorNeutral: '#B79D4B',
  },
};

const defaultMascot: Mascot = mascotList[MascotId.MHL];

export const mascotArr = Object.values(mascotList);

export default mascotList;
export { defaultMascot };
