import React from 'react';
import MuiBox, { BoxProps as MuiBoxProps } from '@material-ui/core/Box';

export interface BoxProps extends MuiBoxProps {
  row?: boolean;
}

const Box: React.FC<BoxProps> = ({ row = false, children, ...props }) => {
  return (
    <MuiBox
      display={row ? 'flex' : props.display ? props.display : 'block'}
      flexDirection={
        row ? 'row' : props.flexDirection ? props.flexDirection : undefined
      }
      {...props}>
      {children}
    </MuiBox>
  );
};

export default Box;
