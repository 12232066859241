import React from 'react';
import ArticleScreen from '../article/ArticleScreen';
import DiscussionScreen from '../discussion/DiscussionScreen';
import { useRouteMatch } from '../../hooks';
import { Paragraph } from '../../components';
import RelectionPreview from './ReflectionPreview';

const ExerciseView = () => {
  const { route3 } = useRouteMatch();
  const match = route3 && route3.split('--');
  const type = match && match[0];
  const id = match && match[1];

  return !match ? (
    <Paragraph>No exercise selected</Paragraph>
  ) : type === 'article' ? (
    <ArticleScreen noFooter id={id} />
  ) : type === 'discussion' ? (
    <DiscussionScreen id={id} />
  ) : type === 'reflection' ? (
    <RelectionPreview id={id} />
  ) : (
    <Paragraph>Invalid exercise type selected</Paragraph>
  );
};

export default ExerciseView;
