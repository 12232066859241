import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useContent } from '../../hooks';

type Props = {
  value: string;
  onChange:
    | ((
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode,
      ) => void)
    | undefined;
};

// Functions for creation and editing articles.
const ReflectionSelect: React.FC<Props> = ({ value, onChange }) => {
  const { reflectionsArr } = useContent();

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="status">Reflection Id</InputLabel>
      <Select
        labelId="reflection-id"
        id="reflection-id"
        value={value ? value : reflectionsArr && reflectionsArr[0].id}
        onChange={onChange}>
        {reflectionsArr &&
          reflectionsArr.map((reflection, idx) => (
            <MenuItem key={idx} value={reflection.id}>
              {reflection.title}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ReflectionSelect;
