import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
// import svgLogo from '../assets/teams/logo.svg';

type Props = {
  width?: number;
  padding?: number;
  allowhomepagelink?: boolean;
  background?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  logo: ({ width, padding, background }: Props) => ({
    width: background ? '200%' : width ? width : 55,
    zIndex: background ? -10 : 10,
    position: background ? 'fixed' : 'relative',
    top: background ? '-7%' : 'inherit',
    left: background ? '-50%' : 'inherit',
    margin: '0 auto',
    padding: padding ? `${padding}px 0 0` : 0,
    [theme.breakpoints.up('md')]: {
      width: background ? '200%' : width ? width : 65,
      top: background ? '-60%' : 'inherit',
    },
  }),
}));

const Logo: React.FC<Props> = props => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <Box className={classes.logo}>
      <Link
        onClick={
          props.allowhomepagelink || props.background
            ? undefined
            : () => history.push('/')
        }>
        <svg viewBox="0 0 376.27 447.8">
          <title>{'MHL-logo_FC'}</title>
          <path
            d="M182.65 440.55c-.92-.2-92.58-21-154-101.92a103 103 0 01-20.82-61.89V50.15l12.4-7c5.9-3.31 60.63-32.31 167.6-32.31s161.71 29 167.59 32.31l12.41 7v226.59A103 103 0 01347 338.63c-61.41 80.94-153.07 101.72-154 101.92l-5.18 1.13z"
            fill={props.background ? '#ffffff' : '#0a2240'}
          />
          <path
            d="M172 209.45c-8.23 0-26.74 12.39-26.74 35s18.24 38.62 18.51 53.8c.07 4-5.91 3.93-5.91 0 0-9.28-25.45-32-25.45-55.31 0-4.62-3.89-4.68-5.91-2.53-6.08 6.48-12.77 21.8-12.77 40.55 0 44.83 38.09 69.25 57.84 69.25 11 0 11.66-13.8 2.5-15.48-32.08-5.89-45.58-31.62-45.58-43.27 0-3.75 4.84-3.74 5.9 0 2.47 8.69 17.93 28.66 31.62 28.66 7.66 0 14.44-4.38 14.44-12.69v-10.89c0-23.85-19.53-29.89-19.53-62.87 0-4.64 5.9-4.64 5.9 0 0 10 4 21 8.57 21 2.6 0 5.06-.92 5.06-5.06v-31.35c-.01-4.16-2.22-8.81-8.45-8.81zm77.17 31c-2-2.15-5.9-2.09-5.9 2.53 0 23.27-25.46 46-25.46 55.31 0 3.93-6 4-5.9 0 .27-15.18 18.51-31.18 18.51-53.8s-18.51-35-26.75-35c-6.23 0-8.44 4.65-8.44 8.81v31.39c0 4.14 2.46 5.06 5.05 5.06 4.57 0 8.58-11 8.58-21 0-4.64 5.9-4.64 5.9 0 0 33-19.53 39-19.53 62.87v10.91c0 8.31 6.77 12.69 14.43 12.69 13.7 0 29.16-20 31.62-28.66 1.07-3.74 5.91-3.75 5.91 0 0 11.65-13.5 37.38-45.58 43.27-9.16 1.68-8.46 15.48 2.5 15.48 19.74 0 57.84-24.42 57.84-69.25-.01-18.83-6.7-34.15-12.79-40.63z"
            fill={props.background ? '#f9f9f9' : '#e7004c'}
          />
          <path
            d="M348.1 56.21c-2.21-1.21-55.24-30.39-160.27-30.39S29.77 55 27.56 56.21l-4.77 2.69V274a97 97 0 0020.05 59.08c56.71 73.36 139.5 92.11 143 92.87l2 .44 2-.44c3.49-.76 86.28-19.51 143-92.87A97 97 0 00352.87 274V58.9zM343.51 274a87.17 87.17 0 01-18.09 53.35c-55.29 71.52-137.59 89.46-137.59 89.46s-82.3-17.94-137.59-89.46A87.17 87.17 0 0132.15 274V64.37S84 35.18 187.83 35.18s155.68 29.19 155.68 29.19zm-155.68-90.91a98.44 98.44 0 1098.43 98.43 98.54 98.54 0 00-98.43-98.43zm0 187.51a89.08 89.08 0 1189.07-89.08 89.18 89.18 0 01-89.07 89.08zM168.72 62.7c1.37 5.91 2.06 8.87 3.43 14.79v80.93c7.91-.26 11.86-.31 19.77-.24v-40.47c10 .09 15 .26 24.92.88v40.46c7.9.49 11.84.82 19.72 1.63V64.84c-7.88-.82-11.82-1.14-19.72-1.63v40.47c-10-.62-15-.79-24.92-.88V62.33c-9.28-.08-13.92 0-23.2.37zm-70.38 104c5.93-1.15 8.91-1.68 14.87-2.63q8.19-36.87 16.46-73.72l.61-.08v71.38c7.87-1 11.81-1.36 19.7-2V63.84c-11.32.91-17 1.54-28.24 3.13q-6.92 33.39-13.76 66.81l-.61.1q-7.07-31-14.13-62c-12 2.46-18 3.88-29.88 7.13l3.35 14V174c5.87-1.55 8.81-2.28 14.71-3.65V98.94l.6-.14q8.18 33.96 16.32 67.93zm160.27-3.23A430 430 0 01309 174l3.35-14c-13.56-3.71-20.4-5.31-34.14-8V71.05c-9.26-1.81-13.91-2.6-23.23-3.94 1.47 6.09 2.21 9.13 3.68 15.23z"
            fill={props.background ? '#f9f9f9' : '#fff'}
          />
        </svg>
      </Link>
    </Box>
  );
};

export default Logo;
