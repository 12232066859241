import { Player } from 'types';

export interface UserContext {
  user: Player | null;
  loading: boolean;
  activePlan: boolean;
  isCoach: boolean;
}

export const initialState: UserContext = {
  user: null,
  loading: true,
  activePlan: false,
  isCoach: false,
};
