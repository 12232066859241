import * as firebase from 'firebase/app';
import { MascotId } from './Mascot';
import Player from './Player';
import { PlaysObj } from '../services';
import moment from 'moment';
import {UserPlan} from 'types'

const playerCollectionKey = 'players';

interface FirebasePlayer {
  id: string;
  teamId?: string;
  streamToken?: string;
  firstScorecardOn?: string;
  latestScorecardOn?: string;
  firstScorecardPlayOn?: string;
  hasFinishedOnboarding?: boolean;
  teamsCoached?: { [key: string]: boolean };
  bio?: string;
  avatar?: string;
  name: string;
  fcmTokens: string[];
  updatedAt: firebase.firestore.Timestamp;
  scorecardsCount: number;
  scorecardPlaysEnabled: boolean;
  birthday?: string;
  email?: string;
  scorecardPlaysList?: PlaysObj;
  welcomed?: boolean;
  mascotId?: MascotId;
  utcOffset?: number;
  plan?: UserPlan;
  stripeId?: string;
  kpiEnabled?: boolean;
  kpiLabel?: string;
}

function docForPlayer(id: string): firebase.firestore.DocumentReference {
  return firebase
    .firestore()
    .collection(playerCollectionKey)
    .doc(id);
}

function seasonDoc(
  id: string,
  month: string,
): firebase.firestore.DocumentReference {
  return firebase
    .firestore()
    .collection(playerCollectionKey)
    .doc(id)
    .collection('seasons')
    .doc(month);
}

function scorecardDoc(
  id: string,
  date: string,
): firebase.firestore.DocumentReference {
  return firebase
    .firestore()
    .collection(playerCollectionKey)
    .doc(id)
    .collection('seasons')
    .doc(moment(date).format('YYYY-MM'))
    .collection('scorecards')
    .doc(moment(date).format('DD'));
}

function fromPlayer(player: Partial<Player>): Partial<FirebasePlayer> {
  const firebasePlayer: Partial<FirebasePlayer> = {};
  if (player.id) {
    firebasePlayer.id = player.id;
  }

  if (player.team) {
    firebasePlayer.teamId = player.team.id;
  }

  if (player.firstScorecardOn) {
    firebasePlayer.firstScorecardOn = player.firstScorecardOn.format(
      'YYYY-MM-DD',
    );
  }

  if (player.latestScorecardOn) {
    firebasePlayer.latestScorecardOn = player.latestScorecardOn.format(
      'YYYY-MM-DD',
    );
  }

  if (player.firstScorecardPlayOn) {
    firebasePlayer.firstScorecardPlayOn = player.firstScorecardPlayOn.format(
      'YYYY-MM-DD',
    );
  }

  if (player.hasFinishedOnboarding) {
    firebasePlayer.hasFinishedOnboarding = player.hasFinishedOnboarding;
  }

  if (player.bio) {
    firebasePlayer.bio = player.bio;
  }

  if (player.avatar) {
    firebasePlayer.avatar = player.avatar;
  }

  if (player.fcmTokens) {
    firebasePlayer.fcmTokens = player.fcmTokens;
  }

  if (player.name) {
    firebasePlayer.name = player.name;
  }

  if (player.scorecardPlaysEnabled) {
    firebasePlayer.scorecardPlaysEnabled = player.scorecardPlaysEnabled;
  }

  if (player.birthday) {
    firebasePlayer.birthday = player.birthday;
  }

  if (player.email) {
    firebasePlayer.email = player.email;
  }

  if (player.streamToken) {
    firebasePlayer.streamToken = player.streamToken;
  }

  if (player.scorecardPlaysList) {
    firebasePlayer.scorecardPlaysList = player.scorecardPlaysList;
  }

  if (player.welcomed) {
    firebasePlayer.welcomed = player.welcomed;
  }

  if (player.mascot) {
    firebasePlayer.mascotId = player.mascot.id;
  }

  if (player.utcOffset) {
    firebasePlayer.utcOffset = player.utcOffset;
  }

  if (player.plan) {
    firebasePlayer.plan = player.plan;
  }

  if (player.stripeId) {
    firebasePlayer.stripeId = player.stripeId;
  }

  if (player.kpiEnabled !== undefined) {
    firebasePlayer.kpiEnabled = player.kpiEnabled;
  }

  if (player.kpiLabel) {
    firebasePlayer.kpiLabel = player.kpiLabel;
  }

  return firebasePlayer;
}

export default FirebasePlayer;
export { docForPlayer, scorecardDoc, seasonDoc, fromPlayer };
