import React from 'react';
import { Star as StarIcon } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      color: theme.palette.warning.main,
    },
  }),
);

type Props = {
  center?: boolean;
};

const Stars: React.FC<Props> = ({ center }) => {
  const classes = useStyles();

  return (
    <div style={{ textAlign: center ? 'center' : 'inherit' }}>
      <StarIcon className={classes.action} />
      <StarIcon className={classes.action} />
      <StarIcon className={classes.action} />
      <StarIcon className={classes.action} />
      <StarIcon className={classes.action} />
    </div>
  );
};

export default Stars;
