// import remoteConfig, {
//   FirebaseRemoteConfigTypes,
// } from '@react-native-firebase/remote-config';
import BaseService from '../BaseService';
// import defaultValues from './defaultValues';
// import { RemoteConfigService } from '../interfaces';
// import { ApplicationError } from '../../models/Errors';

class RemoteConfig extends BaseService {
  private remoteConfig: any;
  private initialization: Promise<void>;
  constructor(args: any) {
    super(args);
    // this.remoteConfig = remoteConfig();
    this.initialization = this.init();
  }

  private init = async (): Promise<void> => {
    // if (__DEV__) {
    //   remoteConfig().setConfigSettings({ isDeveloperModeEnabled: true });
    // }
    // await remoteConfig().setDefaults(defaultValues);
    // return remoteConfig()
    //   .fetch(0)
    //   .then(() => {
    //     // return remoteConfig().activateFetched();
    //     return remoteConfig().fetchAndActivate();
    //   })
    //   .then(activated => {
    //     if (activated) {
    //       console.log('Remote config activated');
    //     }
    //     return;
    //   });
    return Promise.resolve();
  };

  //   public getValue = <T>(valueName: string): Promise<T> => {
  public getValue = <T>(valueName: string): any => {
    //     return this.initialization.then(() => {
    //       const theValue = remoteConfig().getValue(valueName);
    //       try {
    //         try {
    //           return JSON.parse(theValue.value);
    //         } catch {
    //           return theValue.value;
    //         }
    //       } catch (error) {
    //         throw new ApplicationError(
    //           `Failed to retrieve config value ${valueName}`,
    //         );
    //       }
    //     });
    // return Promise.resolve();
  };
}

export default RemoteConfig;
