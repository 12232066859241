import React, { useState, useEffect } from 'react';
import { useCurrentUser, useRouteMatch, useHistory } from '../../hooks';
import { slugify } from '../helpers';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  ListItem,
  List,
} from '@material-ui/core';
import { Spinner, Box, Page, Grid, Headline, Loading } from '../../components';
import ScheduledPostsList from './ScheduledPostsList';
import GetStreamFeed from './GetStreamFeed';
import PlayersScreen from '../player/PlayersScreen';
import { StreamProvider } from '../../context';
import { Team } from 'types';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { brandColors } from '../../assets/styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideNav: {
      overflowY: 'scroll',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
    scroll: {
      overflowY: 'scroll',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '95%',
    },
  }),
);

const mainHeight = {
  height: 'calc(100vh - 87px)',
  fallbacks: [
    { height: '-moz-calc(100vh - 87px)' },
    { height: '-webkit-calc(100vh - 87px)' },
    { height: '-o-calc(100vh - 87px)' },
  ],
};

const TeamScreen: React.FC = () => {
  // Change Team
  const classes = useStyles();
  const { user: currentUser } = useCurrentUser();
  const [teamsCoached, setTeamsCoached] = useState<Team[] | null>(null);
  const [teamId, setTeamId] = useState<string | undefined>(
    currentUser?.team?.id,
  );
  const { route2 } = useRouteMatch();
  const history = useHistory();

  // Loading in useEffect to allow time for CurrentPlayer to load
  useEffect(() => {
    if (currentUser?.team) {
      setTeamId(currentUser?.team.id);
    }

    if (currentUser?.teamsCoached) {
      setTeamsCoached(Object.values(currentUser?.teamsCoached));
    } else {
      setTeamsCoached(null);
    }
  }, [currentUser]);

  const handleTeamChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: any }>,
  ) => {
    const newTeamId = event.target.value;
    setTeamId(newTeamId);
  };

  // return !currentUser?.streamToken || !teamsCoached || !teamId ? (
  //   <Spinner />
  // ) : (

  return (
    <Loading isLoading={!currentUser?.streamToken || !teamsCoached || !teamId}>
      {(): React.ReactElement => {
        if (!currentUser || !teamId || !currentUser.streamToken) {
          return <Spinner />;
        }

        const tabs: { [key: string]: React.ReactNode } = {
          Feed: (
            <GetStreamFeed
              teamId={teamId}
              className={classes.scroll}
              style={mainHeight}
            />
          ),
          Players: (
            <PlayersScreen
              teamId={teamId}
              className={classes.scroll}
              style={mainHeight}
              headline
            />
          ),
          'Scheduled Posts': <ScheduledPostsList teamId={teamId} />,
        };

        const tabLabels = Object.keys(tabs);
        const [tab, setTab] = useState(tabLabels[0]);

        useEffect(() => {
          if (route2) {
            setTab(tabLabels.filter(label => route2 === slugify(label))[0]);
          }
        }, [route2]);

        return (
          <StreamProvider streamToken={currentUser.streamToken}>
            <Page fullPage maxWidth="xl">
              {teamId && (
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={3}>
                    <Headline variant="h6">TEAM</Headline>
                    <FormControl fullWidth>
                      <InputLabel id="team-select">
                        {!teamId ? 'Select Team' : 'Team'}
                      </InputLabel>
                      <Select
                        labelId="team-select"
                        id="team-select"
                        value={teamId}
                        variant="filled"
                        onChange={handleTeamChange}>
                        {teamsCoached &&
                          teamsCoached.map((team: any) => (
                            <MenuItem key={team.id} value={team.id}>
                              {team.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <List>
                      {tabLabels.map((item, idx) => (
                        <Box key={idx}>
                          <ListItem
                            divider
                            selected={item === tab}
                            // onClick={() => setTab(item)}
                            onClick={() =>
                              history.push(`/team/${slugify(item)}`)
                            }
                            style={
                              item === tab
                                ? {
                                    borderBottom: `3px solid ${brandColors.brandPrimary}`,
                                  }
                                : undefined
                            }>
                            <ListItemText
                              primary={item}
                              primaryTypographyProps={{
                                style:
                                  item === tab
                                    ? { color: brandColors.brandPrimary }
                                    : undefined,
                              }}
                            />
                          </ListItem>
                        </Box>
                      ))}
                    </List>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    className={classes.scroll}
                    style={mainHeight}>
                    {tabs[tab]}
                  </Grid>
                </Grid>
              )}
            </Page>
          </StreamProvider>
        );
      }}
    </Loading>
  );
};

export default TeamScreen;
