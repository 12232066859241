import { UserData, ActivityData, ReactionResponse } from 'react-activity-feed';
import moment from 'moment';
import { DateFormat } from '../models/Scorecard';

const notifyIfNotOwner = (
  target: { user_id: string },
  fromId: string,
  to?: string[],
): string[] => {
  // Attempt to notify to field for discussion that was getting a GetStream API post error
  const arr = [];

  if (to) {
    to.forEach(mention => {
      if (mention !== `notification:${fromId}`) {
        arr.push(mention);
      }
    });
  }

  if (target.user_id !== fromId) {
    arr.push(`notification:${target.user_id}`);
  }

  return arr;
};

const getFeedsToNotifyFromActivity = (
  targetActivity: any,
  fromUserId: string,
): string[] => {
  const targetUserId =
    targetActivity.verb === 'welcome'
      ? targetActivity.object
      : targetActivity.actor.id;

  const to = targetActivity.to ? targetActivity.to : undefined;

  return notifyIfNotOwner({ user_id: targetUserId }, fromUserId, to);
};
const getFeedsToNotifyFromReaction = (
  targetReaction: ReactionResponse,
  fromUserId: string,
): string[] => {
  return notifyIfNotOwner(targetReaction, fromUserId);
};

export const getFeedsToNotify = (
  target: ActivityData | ReactionResponse | any,
  fromUserId: string,
): string[] => {
  if ((target as ActivityData).verb) {
    return getFeedsToNotifyFromActivity(target as ActivityData, fromUserId);
  } else {
    return getFeedsToNotifyFromReaction(target as ReactionResponse, fromUserId);
  }
};

export const isPlayerTeamCoach = (user: UserData, teamId: string): boolean => {
  return user.teamsCoached ? !!user.teamsCoached[teamId] : false;
};

export const getActivityDateString = (activity: ActivityData): string => {
  const today = moment();
  const yesterday = moment(today).subtract(1, 'day');

  let dateText: string;
  if (today.isSame(activity.date, 'day')) {
    dateText = 'Today';
  } else if (yesterday.isSame(activity.date, 'day')) {
    dateText = 'Yesterday';
  } else {
    dateText = activity.date.format(DateFormat.Default);
  }

  return dateText;
};

export const slugify = (str: string) => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
