import { ReactionResponse } from 'getstream';
import React from 'react';
import {
  ActivityData,
  RemoveChildReactionCallbackFunction,
  RemoveReactionCallbackFunction,
} from 'react-activity-feed';

import { ApplicationError } from '../../models/Errors';
import logger from '../../services/LoggerService';
import tracker, { EventName } from '../../services/TrackerService';
import CommentItemComponent from './CommentItemComponent';

interface Props {
  reaction: ReactionResponse;
  activity: ActivityData;
  onRemoveReaction: RemoveReactionCallbackFunction;
  onAddCommentReaction: (
    kind: 'comment' | 'cheer',
    comment: ReactionResponse,
    text?: string,
    onSuccess?: () => void,
  ) => Promise<void>;
  onRemoveChildReaction: RemoveChildReactionCallbackFunction;
}

const CommentItemController: React.FC<Props> = ({
  reaction,
  activity,
  onRemoveReaction,
  onAddCommentReaction,
  onRemoveChildReaction,
}) => {
  const onDeleteComment = async (
    reactionTarget: ReactionResponse,
  ): Promise<void> => {
    try {
      await onRemoveReaction(reactionTarget.kind, activity, reactionTarget.id);
      tracker.track(EventName.DeleteComment);
    } catch (error) {
      logger.error(`Failed to delete comment: ${error}`);
      return Promise.reject(new ApplicationError('Unable to remove comment.'));
    }

    return Promise.resolve();
  };
  const onDeleteCommentReaction = async (
    commentParent: ReactionResponse,
    targetReaction: ReactionResponse,
  ): Promise<void> => {
    try {
      await onRemoveChildReaction(
        targetReaction.kind,
        commentParent,
        targetReaction.id,
      );
      tracker.track(EventName.RemoveCommentReaction);
    } catch (error) {
      logger.error(`Failed to delete comment reaction: ${error}`);
      return Promise.reject(
        new ApplicationError('Unable to remove comment reaction.'),
      );
    }

    return Promise.resolve();
  };
  return (
    <CommentItemComponent
      comment={reaction}
      onDeleteComment={onDeleteComment}
      onAddCommentReaction={onAddCommentReaction}
      onDeleteCommentReaction={onDeleteCommentReaction}
      commentEnabled
    />
  );
};

export default CommentItemController;
