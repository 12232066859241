import React from 'react';
import Box from './Box';
import Headline from './Headline';

import Paragraph from './Paragraph';
import { brandColors } from '../assets/styles/colors';

type Props = {
  text: string;
  subheader?: string;
  sidebar?: boolean;
  center?: boolean;
};

const PageHeading: React.FC<Props> = ({ sidebar, text, center, subheader }) => {
  return (
    <Box
      mb={2}
      mt={2}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: center ? 'center' : 'flex-start',
      }}>
      <Box
        style={{
          borderBottom: `5px solid ${brandColors.brandPrimary}`,
          width: '55px',
          marginBottom: '10px',
        }}
      />
      <Headline center={false} gutterBottom={false}>
        {text}
      </Headline>
      {subheader && (
        <Paragraph slim gutterBottom>
          {subheader}
        </Paragraph>
      )}
    </Box>
  );
};

export default PageHeading;
