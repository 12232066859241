import BaseService from './BaseService';
import { ApplicationError } from '../models/Errors';
import firebase from 'firebase/app';
import {
  ArticleServiceType,
  SubscriptionListener,
  Unsubscriber,
} from './interfaces';
import { Article, PublishStatus, ArticlesObj } from 'types';

const COLLECTION = 'articles';
const collection = firebase.firestore().collection(COLLECTION);

class ArticleService extends BaseService implements ArticleServiceType {
  importArticles = async (articles: {
    [key: string]: { [key: string]: any };
  }): Promise<void> => {
    const titles = Object.keys(articles);
    const content = Object.values(articles);

    for (let i = 0; i < titles.length; i++) {
      const adder = {
        ...content[i],
        slug: titles[i],
        authorId: '',
        status: 'published',
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      try {
        const docRef = collection.doc(titles[i]);
        const doc = await docRef.get();

        if (doc.exists) {
          await collection.doc(titles[i]).update(adder);
        } else {
          await collection.doc(titles[i]).set(adder);
        }
      } catch (err) {
        this.logger.error(`Error importing articles ${err}`);
      }
    }
  };

  public getAll = async (): Promise<ArticlesObj> => {
    const query = collection;

    const articles: ArticlesObj = {};

    try {
      await query.get().then(snapshots =>
        snapshots.docs.forEach(doc => {
          const data = doc.data() as Article;
          if (data.status !== PublishStatus.Deleted) {
            articles[doc.id] = { ...data };
          }
        }),
      );

      return articles;
    } catch {
      throw new ApplicationError('Unable to retrieve list');
    }
  };

  public getArticle = async (docId: string): Promise<Article> => {
    try {
      const docRef = collection.doc(docId);
      const doc = await docRef.get();

      if (doc.exists) {
        return doc.data() as Article;
      } else {
        throw new ApplicationError('Article does not exist');
      }
    } catch (err) {
      throw new ApplicationError('Error fetching this article. Try again.');
    }
  };

  updateArticle = async (
    articleId: string,
    updates: Partial<Article>,
  ): Promise<void> => {
    try {
      const docRef = collection.doc(articleId);
      const doc = await docRef.get();

      if (doc.exists) {
        await docRef.update(updates);
      } else {
        throw new ApplicationError('Article does not exist');
      }
    } catch (err) {
      this.logger.error('Article does not exist');
      throw new ApplicationError('Article does not exist');
    }
  };

  public deleteArticle = async (articleId: string): Promise<void> => {
    try {
      await this.updateArticle(articleId, { status: PublishStatus.Deleted });
    } catch {
      this.logger.error('Article does not exist');
      throw new ApplicationError('Unable to delete article');
    }
  };

  public listenToArticles = (
    listener: SubscriptionListener<ArticlesObj | null>,
  ): Unsubscriber => {
    const articles: ArticlesObj = {};

    const subscription = collection.onSnapshot(snapshot => {
      snapshot.docs.forEach(doc => {
        const data = doc.data() as Article;

        articles[doc.id] = { ...data };
      });
      listener(articles);
    });

    return subscription;
  };

  public addOrEditArticle = async (article: Article) => {
    try {
      const docRef = collection.doc(article.slug);
      const doc = await docRef.get();

      if (doc.exists) {
        await docRef.update({ ...article, updatedAt: new Date() });
      } else {
        await docRef.set({
          ...article,
          updatedAt: new Date(),
          createdAt: new Date(),
        });
      }
    } catch (err) {
      this.logger.error(`Error adding article ${err}`);
    }
  };
}

export default ArticleService;
