import Mascot from './Mascot';

enum Visibility {
  Public = 'public',
  Private = 'private',
}

interface Team {
  id: string;
  name: string;
  mascot: Mascot;
  visibility: Visibility;
  default?: boolean;
}

export default Team;
export { Visibility };
