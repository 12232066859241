import React, { useState } from 'react';
import { ActivityData, UserResponse } from 'react-activity-feed';
import { getActivityDateString } from '../helpers';
import {
  Typography,
  Avatar,
  CardHeader,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useMutation } from '../../hooks';
import ScorecardService from '../../services/scorecard/Scorecard';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import UserTitle from './UserTitle';
import { Icon, Feature, Box } from '../../components';
import { IconNames } from 'types';
interface Props {
  activity: ActivityData;
  actor: UserResponse;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: theme.palette.primary.light,
    },
    body: {
      paddingTop: 0,
    },
  }),
);

const ScheduledActivityContent: React.FC<Props> = ({ activity, actor }) => {
  const classes = useStyles();

  const [hidden, setHidden] = useState(!!activity.hidden);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const scorecardService = new ScorecardService();
  const { loading: mutating, mutate } = useMutation(() =>
    scorecardService.toggleActivityVisibility(activity),
  );

  // Extra Actions Menu
  const handlePressActions = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CardHeader
        avatar={
          <Avatar
            aria-label={actor.data.name}
            className={classes.avatar}
            src={actor.data.avatar}
          />
        }
        title={<UserTitle user={actor.data} uid={actor.id} />}
        subheader={getActivityDateString(activity)}
        action={
          <Feature feature="changeActivityVisibility">
            <Box flexDirection="row" alignItems="flex-start">
              {hidden && <Icon name={IconNames.Hidden} />}
              <IconButton
                aria-controls="settings"
                aria-haspopup="true"
                onClick={handlePressActions}>
                <Icon name={IconNames.More} />
              </IconButton>
              <Menu
                id="settings"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem
                  disabled={mutating}
                  onClick={() => mutate(() => setHidden(!hidden))}>
                  {hidden ? 'Unhide Post' : 'Hide Post'}{' '}
                </MenuItem>
              </Menu>
            </Box>
          </Feature>
        }
      />
      <CardContent className={classes.body}>
        <Typography variant="body2" color="textSecondary" component="p">
          {activity.message ? activity.message : activity.object}
        </Typography>
      </CardContent>
    </>
  );
};

export default ScheduledActivityContent;
