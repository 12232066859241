import moment from 'moment';

import Game from './Game';
import { Result } from './Scorecard';

interface PlayResult {
  wins: number;
  losses: number;
  ties: number;
}

class PlayStat {
  private startDate: moment.Moment;
  private endDate: moment.Moment;
  private _playsResults: {
    [playId: string]: PlayResult;
  } = {};

  private totalWins = 0;
  private totalLosses = 0;
  private totalTies = 0;

  constructor(startDate: moment.Moment, endDate: moment.Moment) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
  private getOrCreatePlayResult(playId: string): PlayResult {
    let playResult = this._playsResults[playId];
    if (!playResult) {
      this._playsResults[playId] = playResult = {
        wins: 0,
        losses: 0,
        ties: 0,
      };
    }
    return playResult;
  }

  private resultPercentage(playsCount: number, totalCount: number): number {
    return totalCount > 0 ? Math.floor((playsCount * 100) / totalCount) : 0;
  }

  winsPercentage(playId: string): number {
    return this.resultPercentage(
      this.getOrCreatePlayResult(playId).wins,
      this.totalWins,
    );
  }
  lossesPercentage(playId: string): number {
    return this.resultPercentage(
      this.getOrCreatePlayResult(playId).losses,
      this.totalLosses,
    );
  }
  tiesPercentage(playId: string): number {
    return this.resultPercentage(
      this.getOrCreatePlayResult(playId).ties,
      this.totalTies,
    );
  }

  completionPercentage(playId: string): number {
    const playResult = this.getOrCreatePlayResult(playId);
    const daysInPeriod = this.endDate.diff(this.startDate, 'days') + 1;
    const playsWasCompletedCount =
      playResult.losses + playResult.ties + playResult.wins;
    return daysInPeriod > 0
      ? Math.floor((playsWasCompletedCount * 100) / daysInPeriod)
      : 0;
  }

  public addResult(playId: string, result: Result | undefined): void {
    const playResult = this.getOrCreatePlayResult(playId);
    switch (result) {
      case Result.Win: {
        playResult.wins++;
        break;
      }
      case Result.Loss: {
        playResult.losses++;
        break;
      }
      case Result.Tie: {
        playResult.ties++;
        break;
      }
    }
  }

  public increaseTotalWins(): void {
    this.totalWins++;
  }

  public increaseTotalLosses(): void {
    this.totalLosses++;
  }
  public increaseTotalTies(): void {
    this.totalTies++;
  }
}

class Stats {
  private startDate: moment.Moment;
  private endDate: moment.Moment;

  public wins: number;
  public losses: number;
  public ties: number;
  public plays: PlayStat;

  constructor(startDate: moment.Moment, endDate: moment.Moment) {
    this.startDate = startDate;
    this.endDate = endDate;

    this.wins = 0;
    this.losses = 0;
    this.ties = 0;

    this.plays = new PlayStat(startDate, endDate);
  }

  get completionPercent(): number {
    const completionCount = this.wins + this.losses + this.ties;
    // const totalDays = this.endDate.diff(this.startDate, 'days') + 1;
    const totalDays = moment().diff(this.startDate, 'days');

    const compPercent = totalDays > 0 ? completionCount / totalDays : 0;
    return compPercent > 1 ? 1 : compPercent;
  }

  get winPercent(): number {
    const completionCount = this.wins + this.losses + this.ties;
    return completionCount > 0 ? this.wins / completionCount : 0;
  }

  get trueWinPercent(): number {
    const days = this.endDate.diff(this.startDate, 'days');
    return days > 0 ? this.wins / days : 0;
  }

  public record(game: Game): void {
    if (!game.scorecard) {
      return;
    } else if (game.scorecard.plays) {
      for (const playId of game.scorecard.plays) {
        this.plays.addResult(playId, game.scorecard.result);
      }
    }
    switch (game.scorecard.result) {
      case Result.Win: {
        this.wins += 1;
        this.plays.increaseTotalWins();
        break;
      }
      case Result.Tie: {
        this.ties += 1;
        this.plays.increaseTotalTies();
        break;
      }
      case Result.Loss: {
        this.losses += 1;
        this.plays.increaseTotalLosses();
        break;
      }
    }
  }
}

export default Stats;
