import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useContent } from '../../hooks';

type Props = {
  value: string;
  onChange:
    | ((
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode,
      ) => void)
    | undefined;
};

// Functions for creation and editing articles.
const ArticleSelect: React.FC<Props> = ({ value, onChange }) => {
  const { articles } = useContent();
  const articleArr = articles && Object.values(articles);

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="status">Article Id</InputLabel>
      <Select
        labelId="article-id"
        id="article-id"
        value={value ? value : articleArr && articleArr[0].id}
        onChange={onChange}>
        {articleArr &&
          articleArr.map((article, idx) => (
            <MenuItem key={idx} value={article.id}>
              {article.pages[0].title}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ArticleSelect;
