import React, { PropsWithChildren } from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import { brandColors } from '../../assets/styles/theme';

// interface Props {
//   onPress: any;
//   selected: boolean;
//   primary: string | Element;
//   secondary?: string | Element;
// }

interface Props<T> {
  selected: boolean;
  onPress: any; //(id: string) => void;
  primary?: keyof T;
  secondary?: keyof T;
  item: T;
}

// const DataListItem: React.FC<Props> = props => {
const DataListItem = <T extends { id: string; [key: string]: any }>(
  props: PropsWithChildren<Props<T>>,
) => {
  const { onPress, selected, primary, secondary, item } = props;

  return (
    <ListItem
      divider
      selected={selected}
      alignItems="flex-start"
      onClick={() => onPress(item.id)}
      style={
        selected
          ? {
              borderBottom: `3px solid ${brandColors.brandPrimary}`,
            }
          : undefined
      }>
      <ListItemText
        primary={
          primary && typeof item[primary] === 'string' ? item[primary] : item.id
        }
        secondary={secondary && item[secondary]}
        primaryTypographyProps={{
          style: {
            color: selected
              ? brandColors.brandPrimary
              : brandColors.brandSecondary,
            fontWeight: 'bold',
            lineHeight: '1.3rem',
            marginBottom: '3px',
          },
        }}
        secondaryTypographyProps={{
          style: { color: brandColors.lightTextColor },
        }}
      />
    </ListItem>
  );
};

export default DataListItem;
