import React from 'react';
import { List, Typography } from '@material-ui/core';
import { PlayerItemLink } from './';
import { TeamPlayer } from '../models/TeamPlayer';
import { useRouteMatch } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'scroll',
    },
  }),
);

const sideNav = {
  height: 'calc(100vh - 171px)',
  fallbacks: [
    { height: '-moz-calc(100vh - 171px)' },
    { height: '-webkit-calc(100vh - 171px)' },
    { height: '-o-calc(100vh - 171px)' },
  ],
};

interface Props {
  players: TeamPlayer[] | null;
  selectPlayer(player: TeamPlayer): void;
}

const PlayerList: React.FC<Props> = ({ players, selectPlayer }) => {
  const classes = useStyles();
  let {
    // path,
    url,
  } = useRouteMatch();

  return (
    <List className={classes.root} style={sideNav}>
      {players ? (
        players.map(player => (
          <PlayerItemLink
            to={`${url}/${player.id}`}
            player={player}
            selectPlayer={selectPlayer}
            key={player.id}
          />
        ))
      ) : (
        <Typography>No players</Typography>
      )}
    </List>
  );
};

export default PlayerList;
