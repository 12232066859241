import app from '../base';
import { MascotId } from 'types';
import { Visibility } from './Team';
import { firestore } from 'firebase/app';

const teamCollectionKey = 'teams';

interface FirebaseTeam {
  name: string;
  mascotId: MascotId;
  visibility: Visibility;
  createdAt: firestore.Timestamp;
}

function teamCollection(): firestore.CollectionReference {
  return app.firestore().collection(teamCollectionKey);
}

function docForTeam(id: string): firestore.DocumentReference {
  return teamCollection().doc(id);
}

function isFirebaseTeam(data: object): data is FirebaseTeam {
  const team = data as FirebaseTeam;

  return (
    team.name !== undefined &&
    team.mascotId !== undefined &&
    Object.values(MascotId).includes(team.mascotId) &&
    team.visibility !== undefined &&
    Object.values(Visibility).includes(team.visibility) &&
    team.createdAt !== undefined
  );
}

export default FirebaseTeam;
export { docForTeam, isFirebaseTeam, teamCollection };
