import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { BrandColors, brandColors } from '../assets/styles/colors';

export interface HeadineProps {
  gb?: number;
  gt?: number;
  gv?: number;
  size?: number;
  center?: boolean;
  small?: boolean;
  color?: BrandColors;
  gutterBottom?: boolean;
}

const Headline: React.FC<HeadineProps & TypographyProps> = ({
  style,
  children,
  size,
  gt,
  gb,
  gv,
  small,
  center,
  gutterBottom = true,
  ...props
}) => {
  return (
    <Typography
      component={'h2'}
      align={center ? 'center' : 'inherit'}
      variant={props.variant ? props.variant : small ? 'h6' : 'h3'}
      display={props.display}
      color={props.color}
      className={props.className}
      gutterBottom={gutterBottom ? gutterBottom : true}>
      {children}
    </Typography>
  );
};

export default Headline;
