import React from 'react';
import { Divider as MuiDivider, DividerProps } from '@material-ui/core';

const Divider: React.FC<DividerProps> = ({ children, ...props }) => {
  return !children ? (
    <MuiDivider {...props} />
  ) : (
    <MuiDivider {...props}>{children}</MuiDivider>
  );
};

export default Divider;
