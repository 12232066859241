import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { Button, Page, Headline, Paragraph } from '../components';
// import UpdateProfileService from '../services/UpdateProfileService';
// import Gameplan from '../services/Gameplan';
// import { SurveyAnswers } from 'types';
import { SurveyType } from '../flows/survey/SurveyScreen';
import { Apple as AppleIcon } from '@material-ui/icons';
import ReactPixel from 'react-facebook-pixel';
import { useUrlParams } from '../hooks';
import moment from 'moment';
// import logger from '../services/LoggerService';
// import { ProfileService } from '../services';
// import { useContainer, useMutation, useCurrentUser } from '../hooks';

interface Props {
  //   question: Question;
  //   updateForm: (newState: any) => void;
  parentState: SurveyType;
  //   currentStep: number;
}

const DownloadScreen: React.FC<Props> = ({ parentState }) => {
  let params = useUrlParams();
  let trialLength = params.get('trial');
  // const { currentUser } = useCurrentUser();
  // Hack to save birthday from Signup into database
  // let savedBirthday: string | null = null;
  // const getBirthday = async (): Promise<void> => {
  //   try {
  //     const value = await sessionStorage.getItem('birthday');
  //     if (value !== null) {
  //       savedBirthday = value;
  //       sessionStorage.removeItem('birthday');
  //     }
  //   } catch (err) {
  //     logger.error(`Failed to get birthday from async storage: ${err}`);
  //   }
  // };
  // getBirthday();

  // const container = useContainer();
  // const updateProfile = container.getInstance<ProfileService>('profileService');
  // const { loading, error, mutate } = useMutation((name: string) =>
  //   updateProfile.perform({
  //     birthday: savedBirthday ? savedBirthday : '',
  //   }),
  // );
  // useEffect(() => {
  //   console.log('savedBday', savedBirthday);
  //   if (savedBirthday) {
  //     mutate();
  //   }
  // }, [savedBirthday, currentUser]);

  // SAVE SURVEY & CREATE GAMEPLAN
  // const gameplanService = new Gameplan();
  // const { loading, error, mutate } = useMutation(() =>
  //   gameplanService.createCurrentPlayerGamePlan(parentState.form),
  // );
  // const [mounted, setMounted] = useState(false);
  // useEffect(
  //   function createGamePlan() {
  //     setMounted(true);
  //     if (!mounted) {
  //       mutate();
  //     }
  //   },
  //   [setMounted, mutate, mounted],
  // );

  // return loading ? (
  //   <CircularProgress />
  // ) : (
  return (
    <Page>
      {/* <Card raised> */}
      {/* {error && (
        <Typography color="error" gutterBottom>
          {error}
        </Typography>
      )} */}
      <Headline center gutterBottom>
        You're In!
      </Headline>
      <Paragraph center slim gutterBottom>
        Click below to download the app and get started.
      </Paragraph>

      <Button
        variant="contained"
        color="secondary"
        fullWidth
        size="large"
        onClick={() => {
          ReactPixel.track('Subscribe', {});
          window.location.href =
            'https://apps.apple.com/us/app/mhl-mental-health-league/id1479870559';
        }}
        startIcon={<AppleIcon />}>
        Download iOS
      </Button>
      {/* </Card> */}
      <Box mt={3}>
        <hr />
      </Box>
      <Box mt={3}>
        <Typography color="textSecondary" variant="caption" component="p">
          Your trial ends on{' '}
          {moment()
            .add(trialLength, 'days')
            .format('MMM DD, yyyy')}
          . Login to website to cancel. For customer support, email{' '}
          <a href="mailto:hello@mentalhealthleague.com">
            hello@mentalhealthleague.com
          </a>
        </Typography>
      </Box>
    </Page>
  );
};

export default DownloadScreen;
