import { ExerciseConditions, GamePlanTemplate } from 'types';
import BaseService from './BaseService';
import { GamePlanServiceType } from './interfaces';
import { ApplicationError } from '../models/Errors';

const checkCondition: {
  [key in ExerciseConditions]: (context: {
    notificationsEnabled: boolean;
  }) => boolean
} = {
  notifications_disabled: ({ notificationsEnabled }) => !notificationsEnabled,
};

const COLLECTION = 'gameplans';

class GamePlanService extends BaseService implements GamePlanServiceType {
  public collection = this.firestore.collection(COLLECTION);
  public query = this.collection.orderBy('name', 'desc');

  public getAll = async (): Promise<GamePlanTemplate[]> => {
    const docs: GamePlanTemplate[] = [];

    try {
      await this.query.get().then(snapshots =>
        snapshots.docs.forEach(doc => {
          const data = doc.data() as GamePlanTemplate;
          docs.push({ ...data });
        }),
      );

      return docs;
    } catch {
      throw new ApplicationError('Unable to retrieve gameplans');
    }
  };

  public create = async (gameplan: GamePlanTemplate): Promise<void> => {
    try {
      const docRef = this.collection.doc(gameplan.id);
      const doc = await docRef.get();

      if (doc.exists) {
        throw new ApplicationError('Gameplan with this ID already exists.');
      } else {
        await docRef.set({
          ...gameplan,
          updatedAt: new Date(),
          createdAt: new Date(),
        });
      }
    } catch (err) {
      this.logger.error(err);
      if (err.message === 'Gameplan with this ID already exists.') {
        throw new ApplicationError(err);
      } else {
        throw new ApplicationError('Error creating this gameplan. Try again.');
      }
    }
  };

  public edit = async (gameplan: GamePlanTemplate): Promise<void> => {
    try {
      const docRef = this.collection.doc(gameplan.id);
      const doc = await docRef.get();

      await docRef.set({
        ...gameplan,
        updatedAt: new Date(),
        createdAt: new Date(),
      });
    } catch (err) {
      this.logger.error(err);
      if (err.message === 'Gameplan with this ID already exists.') {
        throw new ApplicationError(err);
      } else {
        throw new ApplicationError('Error creating this gameplan. Try again.');
      }
    }
  };

  //   public listenToGameplans = (
  //     listener: SubscriptionListener<PlayerGamePlan | null>,
  //   ): Unsubscriber => {
  //     const subscription = this.firestore
  //       .collection('gameplans')
  //       .onSnapshot(gameplanDoc => {
  //         if (gameplanDoc) {
  //           this.mapGameplanDocToEntity(gameplanDoc).then(listener);
  //         } else {
  //           listener(null);
  //         }
  //       });
  //     return subscription;
  //   };

  //   private mapGameplanDocToEntity = (
  //     gameplanDoc: firebase.firestore.DocumentSnapshot,
  //   ): Promise<PlayerGamePlan> => {
  //     return Promise.all([firebase.messaging().hasPermission()]).then(
  //       ([notificationsEnabled]) => {
  //         const gameplanData = gameplanDoc.data();
  //         const gameplanRoutine = {};
  //         for (const [date, routine] of Object.entries(
  //           gameplanData.routineByDate,
  //         )) {
  //           const exercises = {};
  //           for (const [day, exercise] of Object.entries(routine.exercises)) {
  //             if (exercise.condition) {
  //               const meetsCondition = checkCondition[exercise.condition]({
  //                 notificationsEnabled,
  //               });
  //               if (!meetsCondition) {
  //                 continue;
  //               }
  //             }
  //             const exerciseRecord = { ...exercise };
  //             if (exercise.type === 'scorecard' && exerciseRecord.completed) {
  //               const scorecardData = exerciseRecord.extra.scorecard;
  //               exerciseRecord.extra.scorecard = new Scorecard(
  //                 moment(scorecardData.date),
  //                 scorecardData.result,
  //                 scorecardData.why,
  //                 new Date(scorecardData.createdAt),
  //                 scorecardData.plays,
  //               );
  //             } else if (
  //               exercise.type === 'scorecard' &&
  //               !exerciseRecord.completed
  //             ) {
  //               exerciseRecord.extra.scorecard = new Scorecard(moment(date));
  //             }
  //             exercises[day] = exerciseRecord;
  //           }
  //           gameplanRoutine[date] = {
  //             ...routine,
  //             exercises,
  //             enabled: moment(routine.date.toDate())
  //               // .hour(15) This would alter hour so it game would get enabled at certain time of day - issue is first day scorecard
  //               .isSameOrBefore(
  //                 undefined, // Compares to curent time - only the day
  //                 'day',
  //               ),
  //             date: routine.date.toDate(),
  //           };
  //         }
  //         const gamePlan: PlayerGamePlan = {
  //           completed: gameplanData.completed,
  //           finalExercise: gameplanData.finalExercise,
  //           routineByDate: gameplanRoutine,
  //           id: gameplanDoc.id,
  //           name: gameplanData.name,
  //           start: gameplanData.start.toDate(),
  //           end: gameplanData.end.toDate(),
  //         };
  //         return gamePlan;
  //       },
  //     );
  //   };
  //   public subscribeToActiveGamePlan = (
  //     listener: SubscriptionListener<PlayerGamePlan | null>,
  //   ): Unsubscriber => {
  //     const subscription = this.firestore
  //       .collection(`players/${this.currentPlayer.id}/gameplans`)
  //       .where('completed', '==', false)
  //       .limit(1)
  //       .onSnapshot(snapshot => {
  //         const [activeGamePlanSnap] = snapshot.docs;
  //         if (activeGamePlanSnap && activeGamePlanSnap.exists) {
  //           this.mapGameplanDocToEntity(activeGamePlanSnap).then(listener);
  //           // listener(null); Was causing gameplan to flash null after any change causing SeasonScreen to show Season view
  //         } else {
  //           listener(null);
  //         }
  //       });
  //     return subscription;
  //   };

  //   public completeExercise = (
  //     exerciseNumber: number,
  //     exercise: ExerciseRecord,
  //     dailyRoutine: DailyRoutine,
  //     gameplan: PlayerGamePlan,
  //   ): Promise<void> => {
  //     const updateCompletePath = `routineByDate.${moment(
  //       dailyRoutine.date,
  //     ).format('YYYY-MM-DD')}.exercises.${exerciseNumber}.completed`;
  //     const updateCompleteAtPath = `routineByDate.${moment(
  //       dailyRoutine.date,
  //     ).format('YYYY-MM-DD')}.exercises.${exerciseNumber}.completedAt`;
  //     return this.firestore
  //       .doc(`players/${this.currentPlayer.id}/gameplans/${gameplan.id}`)
  //       .update({
  //         [updateCompletePath]: true,
  //         [updateCompleteAtPath]: Date.now(),
  //       });
  //   };

  //   public completeCurrentPlayerGamePlan = (
  //     gameplan: PlayerGamePlan,
  //   ): Promise<void> => {
  //     return this.firestore
  //       .doc(`players/${this.currentPlayer.id}/gameplans/${gameplan.id}`)
  //       .update({
  //         completed: true,
  //       });
  //   };

  //   public createCurrentPlayerGamePlan = async (
  //     answers: SurveyAnswers,
  //   ): Promise<void> => {
  //     await firebase.functions().httpsCallable('onCreatePlayerGamePlan')({
  //       answers,
  //       tzOffset: moment().utcOffset(),
  //     });
  //   };

  //   public subscribeToTrainingCamp = (
  //     listener: SubscriptionListener<PlayerGamePlan | null>,
  //   ): Unsubscriber => {
  //     const subscription = this.firestore
  //       .doc(`players/${this.currentPlayer.id}/gameplans/training-camp`)
  //       .onSnapshot(gameplanDoc => {
  //         if (gameplanDoc.exists) {
  //           this.mapGameplanDocToEntity(gameplanDoc).then(listener);
  //         } else {
  //           listener(null);
  //         }
  //       });
  //     return subscription;
  //   };
}

export default GamePlanService;
