import React from 'react';
import { Typography } from '@material-ui/core';

const TheStoryScreen: React.FC = () => {
  return (
    <>
      <img
        style={{ marginBottom: '20px' }}
        alt="The Story of MHL"
        src={require('../../assets/images/landing/the-story.jpg')}
      />

      <Typography gutterBottom>
        After a decade of trying to manage the ups and downs of my bipolar and
        ADHD alone, I knew I needed to try something new.
      </Typography>
      <Typography gutterBottom>
        Therapy was great, but I couldn't afford to do it more than a few times
        a month.
      </Typography>
      <Typography gutterBottom>
        Meditation and other mental health apps were helpful, but as soon as I
        got to a better place, I'd stop using them until I started spiraling
        again.
      </Typography>
      <Typography variant="h5" gutterBottom>
        I needed accountability.
      </Typography>
      <Typography gutterBottom>
        Digging into psychology on accountability, I found that people who
        workout with friends can often achieve similar results as they would
        with a trainer because they hold each other accountable and keep one
        another motivated.
      </Typography>
      <Typography gutterBottom>
        So I thought...why aren’t we doing the same for mental health? And the
        MHL was born.
      </Typography>
      <Typography gutterBottom>
        With the MHL, you'll keep a daily scorecard, complete with journaling
        and habit tracking, that will help you understand how your mind works
        and how you can improve.
      </Typography>
      <Typography gutterBottom>
        You'll be able to share that scorecard with a dedicated team and coach,
        who will be there to give you advice and support daily.
      </Typography>
      <Typography gutterBottom>
        It's a simple exercise that can have big results.
      </Typography>
      <Typography>Hope to see you on the field,</Typography>

      <Typography gutterBottom>
        <b>Mike Vosters, MHL Founder</b>
      </Typography>
    </>
  );
};

export default TheStoryScreen;
