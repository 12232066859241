import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Chip,
  Divider,
  ListItemAvatar,
  Typography,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { TeamPlayer } from '../models/TeamPlayer';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginTop: -3,
    },
  }),
);

interface PlayerItemLinkProps {
  player: TeamPlayer;
  to: string;
  selectPlayer(player: TeamPlayer): void;
}

const PlayerItemLink: React.FC<PlayerItemLinkProps> = ({
  player,
  to,
  selectPlayer,
}) => {
  const classes = useStyles();
  const lastScorecardFromNow = player.latestScorecardOn
    ? moment(player.latestScorecardOn).fromNow()
    : 'Never';

  const isNewPlayer =
    moment().diff(player.createdAt, 'days') < 7 ? true : false;

  // USE IF YOU WANT TO RERENDER THE URL
  //   const renderLink = React.useMemo(
  //     () =>
  //       React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
  //         <RouterLink to={to} ref={ref} {...itemProps} />
  //       )),
  //     [to],
  //   );

  return (
    <>
      <ListItem
        alignItems="flex-start"
        button
        // component={renderLink}
        onClick={() => selectPlayer(player)}>
        <ListItemAvatar>
          {player.avatar ? (
            <Avatar alt={player.name} src={player.avatar} />
          ) : (
            <Avatar />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={player.name}
          secondary={
            <>
              <Typography
                component="span"
                variant="body2"
                color="textSecondary">
                {lastScorecardFromNow}
                {'  '}
              </Typography>
              {isNewPlayer && (
                <Chip
                  className={classes.chip}
                  color="secondary"
                  label="New Player!"
                  component="span"
                  size="small"
                  variant="outlined"
                />
              )}
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default PlayerItemLink;
