import { useHistory } from './useHistory';

type NavigationService = {
  navigate: (routeName: string, params?: {}) => void;
  goBack: () => void;
};

interface Params {
  [key: string]: any;
  //   content?: Content;
  //   category?: Category;
}

export const useNavigation = (): NavigationService => {
  const history = useHistory();

  return {
    navigate: (routeName: string, params?: Params) => {
      history.push(routeName, params);
    },
    goBack: () => {
      history.goBack();
    },
  };
};
