import React, { useState } from 'react';
import {
  ActivityData,
  AddChildReactionCallbackFunction,
  AddReactionCallbackFunction,
  BaseReaction,
  ReactionList,
  RemoveChildReactionCallbackFunction,
  RemoveReactionCallbackFunction,
  ToggleReactionCallbackFunction,
  ToggleChildReactionCallbackFunction,
  ReactionResponse,
} from 'react-activity-feed';
import { Grid, CardActions, Button } from '@material-ui/core';
import { ChatBubbleOutline as ChatBubbleOutlineIcon } from '@material-ui/icons';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ApplicationError } from '../../models/Errors';
import logger from '../../services/LoggerService';
import tracker, { EventName } from '../../services/TrackerService';
import CheerComponent from './CheerComponent';
import CommentBoxComponent from './CommentBoxComponent';
import CommentItemController from './CommentItemController';
import { getFeedsToNotify } from '../helpers';
import { mhlNavyLightbg } from '../../assets/styles/theme';

interface Props {
  userId: string;
  activity: ActivityData;
  reaction?: BaseReaction;
  onAddReaction: AddReactionCallbackFunction;
  onToggleReaction: ToggleReactionCallbackFunction;
  onRemoveReaction: RemoveReactionCallbackFunction;
  onAddChildReaction: AddChildReactionCallbackFunction;
  onRemoveChildReaction: RemoveChildReactionCallbackFunction;
  onToggleChildReaction: ToggleChildReactionCallbackFunction;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    comments: {
      backgroundColor: mhlNavyLightbg,
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    body: {
      paddingTop: 0,
    },
  }),
);

const ActivityFooterComponent: React.FC<Props> = ({
  userId,
  activity,
  reaction,
  onAddReaction,
  onToggleReaction,
  onRemoveReaction,
  onAddChildReaction,
  onRemoveChildReaction,
  onToggleChildReaction,
}) => {
  const classes = useStyles();
  const [showCommentBox, toggleCommentBox] = useState<boolean>(false);
  const handleCommentToggle = () => {
    toggleCommentBox(!showCommentBox);
  };

  const onAddComment = async (comment: string): Promise<void> => {
    try {
      await onAddReaction(
        'comment',
        activity,
        {
          text: comment,
        },
        {
          userId: userId,
          targetFeeds: getFeedsToNotify(activity, userId),
        },
      );

      toggleCommentBox(!showCommentBox);
      tracker.track(EventName.AddComment);
    } catch (error) {
      logger.error(`Failed to add comment: ${error}`);
      return Promise.reject(new ApplicationError('Unable to save comment'));
    }

    return Promise.resolve();
  };

  const onToggleCheer = async (): Promise<void> => {
    try {
      await onToggleReaction(
        'cheer',
        activity,
        {},
        {
          userId: userId,
          targetFeeds: getFeedsToNotify(activity, userId),
        },
      );
      tracker.track(EventName.ToggleCheer);
    } catch (error) {
      logger.error(`Failed to toggle cheer: ${error}`);
      return Promise.reject(new ApplicationError('Unable to toggle cheer.'));
    }

    return Promise.resolve();
  };

  const onAddCommentReaction = async (
    kind: 'comment' | 'cheer',
    comment: ReactionResponse,
    text?: string,
    onSuccess?: () => void,
  ): Promise<void> => {
    try {
      if (kind === 'cheer') {
        await onToggleChildReaction(
          kind,
          comment,
          {},
          {
            userId: userId,
            targetFeeds: getFeedsToNotify(comment, userId),
          },
        );
      } else if (kind === 'comment') {
        await onAddChildReaction(
          kind,
          comment,
          {
            text,
          },
          {
            userId: userId,
            targetFeeds: getFeedsToNotify(comment, userId),
          },
        );
      }
      onSuccess && onSuccess();
      tracker.track(EventName.AddCommentReaction);
    } catch (error) {
      logger.error(`Failed to add comment: ${error}`);
      return Promise.reject(new ApplicationError('Unable to save comment'));
    }
  };

  return (
    <>
      <CardActions disableSpacing style={{ justifyContent: 'space-evenly' }}>
        <CheerComponent activity={activity} onToggleCheer={onToggleCheer} />
        <Button
          startIcon={<ChatBubbleOutlineIcon />}
          onClick={handleCommentToggle}
          aria-label="toggle comment box">
          {activity.reaction_counts &&
            activity.reaction_counts.comment &&
            activity.reaction_counts.comment}
          {activity.reaction_counts && activity.reaction_counts.comment < 1
            ? ' comments'
            : ' comment'}
        </Button>
      </CardActions>

      <CommentBoxComponent
        onAddComment={onAddComment}
        showCommentBox={showCommentBox}
      />

      <Grid item className={classes.comments}>
        <ReactionList
          activityId={activity.id}
          reactionKind={'comment'}
          Reaction={({
            reaction,
          }: {
            reaction: ReactionResponse;
          }): React.ReactElement => (
            <CommentItemController
              activity={activity}
              onRemoveReaction={onRemoveReaction}
              onRemoveChildReaction={onRemoveChildReaction}
              onAddCommentReaction={onAddCommentReaction}
              reaction={reaction}
            />
          )}
          // Paginator={LoadMoreComponent}
          // flatListProps={{ ItemSeparatorComponent: Divider }}
        />
      </Grid>
    </>
  );
};

export default ActivityFooterComponent;
