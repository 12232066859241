import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { StyleSheetLike } from 'react-activity-feed';
import { ImageNames, imageDir } from '../models/Image';

type Props = {
  width?: number;
  padding?: number;
  src: ImageNames;
  imageStyle?: StyleSheetLike;
};

const Image: React.FC<Props & BoxProps> = ({
  src,
  width,
  padding,
  imageStyle,
  ...props
}) => {
  return (
    <Box {...props} style={imageStyle}>
      <picture>
        <source srcSet={imageDir[src].source.webp} type="image/webp" />
        {/* <source srcSet={imageDir[src].src.webp} /> */}
        <img src={imageDir[src].source.safari} alt={imageDir[src].alt} />
      </picture>
    </Box>
  );
};

export default Image;

// Eventually add multiple sizes for srcset
// <picture>
//   <source srcset="
//     /uploads/img_small.webp 1x,
//     /uploads/img_big.webp 2x" type="image/webp">
//   <source srcset="
//     /uploads/img_small.jpg 1x,
//     /uploads/img_big.jpg 2x" type="image/jpeg">
//   <img src="/uploads/img_small.jpg">
// </picture>
