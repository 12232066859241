import React, { useState, useEffect } from 'react';
import {
  Page,
  Headline,
  Paragraph,
  Grid,
  CardButton,
  PaginationButtons,
  Button,
  List,
} from '../../components';
import ExerciseView from './ExerciseView';
import {
  Typography,
  Box,
  Divider,
  // ListItem,
  // List,
  // ListItemText,
} from '@material-ui/core';
import { GamePlanTemplate, OldExercise } from 'types';
import {
  useContainer,
  useHistory,
  usePagination,
  useRouteMatch,
} from '../../hooks';
import { GamePlanServiceType } from '../../services/interfaces';
import { capitalize } from '../helpers';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { brandColors } from '../../assets/styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scroll: {
      height: `calc(100vh - 90px)`,
      overflow: 'scroll',
    },
  }),
);

// Functions for creation and editing articles.
const GamePlanListScreen: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const container = useContainer();
  const { route2, route3 } = useRouteMatch();

  const [gameplan, selectGameplan] = useState<GamePlanTemplate | null>(null);

  const service = container.getInstance<GamePlanServiceType>('gameplanService');

  const { items, isLoading, getNext, getPrev, page } = usePagination<
    GamePlanTemplate
  >(service.query, {
    limit: 10,
  });

  useEffect(() => {
    // If a gameplan has been selected, it's id will be route2, so we need to select it when that route changes
    if (route2) {
      selectGameplan(items.filter(gp => gp.id === route2)[0]);
    }
  }, [items, route2]);

  const handleExercise = (exercise: OldExercise) => {
    if (exercise.options) {
      if (exercise.type === 'article') {
        history.push(
          `/gameplans/${route2}/article--${exercise.options.article}`,
        );
      } else if (exercise.type === 'discussion') {
        history.push(
          `/gameplans/${route2}/discussion--${exercise.options.discussion}`,
        );
      } else if (exercise.type === 'reflection') {
        history.push(
          `/gameplans/${route2}/reflection--${exercise.options.reflection}`,
        );
      }
    }
  };

  const handleSelect = (id: string) => {
    history.push(`/gameplans/${id}`);
  };

  return (
    <Page fullPage maxWidth="xl" loading={isLoading}>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={2} className={classes.scroll}>
          <Headline variant="h6" gutterBottom>
            Gameplans
          </Headline>
          <Button
            text="Create"
            size="small"
            fullWidth
            onPress={() => history.push('/gameplans/create')}
          />
          <Divider />
          <List<GamePlanTemplate>
            items={items}
            onPress={handleSelect}
            selected={route2}
          />
        </Grid>
        <Grid item xs={4} className={classes.scroll}>
          {gameplan ? (
            <>
              <Box>
                <Headline gutterBottom variant="h5">
                  {gameplan.name}
                </Headline>
              </Box>

              <Button
                text="Edit"
                size="small"
                variant="outlined"
                fullWidth
                onPress={() =>
                  history.push({
                    pathname: `/gameplans/edit/${gameplan.id}`,
                    state: { editing: gameplan },
                  })
                }
              />

              {Object.keys(gameplan.exercisesByDay)
                .sort((a, b) => +a - +b)
                .map((day, i) => (
                  <Box key={i}>
                    <Paragraph bold>Day {day}</Paragraph>

                    {gameplan.exercisesByDay[day].map(
                      (exercise: OldExercise, idx) => (
                        <CardButton
                          key={idx}
                          bordered="lightTextColor"
                          label={capitalize(exercise.type)}
                          text={exercise.name}
                          onPress={() => handleExercise(exercise)}

                          // to={`/gameplans/${exercise.options.article}`}
                        />
                      ),
                    )}
                  </Box>
                ))}
            </>
          ) : (
            <Typography>Click to select a game plan</Typography>
          )}
          <PaginationButtons
            getNext={getNext}
            getPrev={getPrev}
            page={page}
            items={items.length}
          />
        </Grid>
        <Grid item xs={6} className={classes.scroll}>
          {!!route3 && <ExerciseView />}
        </Grid>
      </Grid>
    </Page>
  );
};

export default GamePlanListScreen;
