import React from 'react';
import { Select } from '../../components';
import { IconNames } from 'types';

type Props = {
  value: IconNames | undefined;
  onChange: (e: any) => void;
};

// Functions for creation and editing articles.
const IconSelect: React.FC<Props> = ({ value, onChange }) => {
  const options = Object.values(IconNames);

  return (
    <Select<IconNames>
      title="Icon"
      type="icon"
      options={options}
      selected={!value ? options[0] : value}
      onChange={onChange}
    />
  );
};

export default IconSelect;
