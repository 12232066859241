import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Button, Page, Headline, Paragraph } from '../components';
import { Apple as AppleIcon } from '@material-ui/icons';
import { useHistory, useCurrentUser } from '../hooks';
import SupportEmail from './landing/components/SupportEmail';
import app from '../base';
import moment from 'moment';

const DashboardScreen: React.FC = () => {
  const history = useHistory();
  const { user: currentUser } = useCurrentUser();

  return (
    <Page fullPage>
      <Headline center>Dashboard</Headline>
      {currentUser && (
        <>
          <Paragraph center>{currentUser.email}</Paragraph>
          <Paragraph center>{currentUser.id}</Paragraph>
          <Paragraph center gutterBottom>
            Status:{' '}
            {currentUser?.plan
              ? currentUser.plan?.status?.charAt(0).toUpperCase() +
                currentUser.plan?.status?.slice(1)
              : 'Not Subscribed'}
          </Paragraph>
          {currentUser.plan && currentUser.plan.status === 'trialing' && (
            <Typography
              variant="caption"
              component="p"
              gutterBottom
              color="textSecondary"
              style={{ marginTop: -16, fontSize: 12, textAlign: 'center' }}>
              Trial Ends:{' '}
              {moment(currentUser.plan.nextRenewalDate).format('MM-DD-YYYY')}
            </Typography>
          )}
        </>
      )}
      {currentUser && currentUser.isPro ? (
        <>
          <Box mb={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              size="large"
              text="Download iOS"
              onClick={() => {
                window.location.href =
                  'https://apps.apple.com/us/app/mhl-mental-health-league/id1479870559';
              }}
              startIcon={<AppleIcon />}
            />
          </Box>
          <Box mb={2}>
            <Button
              fullWidth
              text="Manage Subscription"
              onClick={() => history.push('/subscription')}
            />
          </Box>
        </>
      ) : (
        <Box mb={2}>
          <Button
            fullWidth
            text="Choose Plan"
            onClick={() => history.push('/plans')}
          />
        </Box>
      )}
      {currentUser && currentUser.isCoach && (
        <>
          <Box mb={2}>
            <Button
              fullWidth
              text="Teams"
              onClick={() => history.push('/team')}
            />
          </Box>

          <Box mb={2}>
            <Button
              fullWidth
              text="Articles"
              onClick={() => history.push('/articles')}
            />
          </Box>
        </>
      )}

      <Box mb={2}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          size="large"
          text="Sign Out"
          onClick={() => app.auth().signOut()}
        />
      </Box>
      <Box mb={2}>
        <SupportEmail />
      </Box>
    </Page>
  );
};

export default DashboardScreen;
