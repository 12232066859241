export type ImageNames = keyof typeof imageDir;

type ImageDirectory = {
  [key: string]: {
    alt: string;
    source: {
      webp: string;
      safari: string;
    };
  };
};

export const imageDir: ImageDirectory = {
  coaches: {
    alt: 'MHL Coaches',
    source: {
      webp: require('../assets/images/landing/coaches.webp'),
      safari: require('../assets/images/landing/coaches.png'),
    },
  },
  'coaches-two': {
    alt: 'MHL Coaches',
    source: {
      webp: require('../assets/images/landing/coaches-two-2.webp'),
      safari: require('../assets/images/landing/coaches-two-2.jpg'),
    },
  },

  anxietees: {
    alt: 'Team Anxietees',
    source: {
      webp: require('../assets/images/teams/anxietees.webp'),
      safari: require('../assets/images/teams/anxietees.png'),
    },
  },
  'bipolar-bears': {
    alt: 'Team Bipolar Bears',
    source: {
      webp: require('../assets/images/teams/bipolar-bears.webp'),
      safari: require('../assets/images/teams/bipolar-bears.png'),
    },
  },
  adhbees: {
    alt: 'Team ADHBees',
    source: {
      webp: require('../assets/images/teams/adhbees.webp'),
      safari: require('../assets/images/teams/adhbees.png'),
    },
  },
  mhl: {
    alt: 'Mental Health League',
    source: {
      webp: require('../assets/images/teams/mhl.webp'),
      safari: require('../assets/images/teams/mhl.png'),
    },
  },
  'foggy-dogs': {
    alt: 'Team Foggy Dogs',
    source: {
      webp: require('../assets/images/teams/foggy-dogs.webp'),
      safari: require('../assets/images/teams/foggy-dogs.png'),
    },
  },
  bootcamp: {
    alt: 'MHL 2k21 Bootcamp',
    source: {
      webp: require('../assets/images/landing/mhl-2k21-bootcamp-logo.webp'),
      safari: require('../assets/images/landing/mhl-2k21-bootcamp-logo.jpg'),
    },
  },
  'anx-big-rect': {
    alt: 'Team Anxietees',
    source: {
      webp: require('../assets/images/teams/anx-big-rect.webp'),
      safari: require('../assets/images/teams/anx-big.jpg'),
    },
  },
  'bb-big-rect': {
    alt: 'Team Anxietees',
    source: {
      webp: require('../assets/images/teams/bb-big-rect.webp'),
      safari: require('../assets/images/teams/bb-big.jpg'),
    },
  },
  'fd-big-rect': {
    alt: 'Team Anxietees',
    source: {
      webp: require('../assets/images/teams/fd-big-rect.webp'),
      safari: require('../assets/images/teams/fd-big.jpg'),
    },
  },
  'adhb-big-rect': {
    alt: 'Team Anxietees',
    source: {
      webp: require('../assets/images/teams/adhb-big-rect.webp'),
      safari: require('../assets/images/teams/adhb-big.jpg'),
    },
  },
  'adhb-mascot': {
    alt: 'Team ADHBees',
    source: {
      webp: require('../assets/images/teams/adhbees-mascot.png'),
      safari: require('../assets/images/teams/adhbees-mascot.png'),
    },
  },
  'all-mascots': {
    alt: 'Team Anxietees',
    source: {
      webp: require('../assets/images/teams/all-mascots.webp'),
      safari: require('../assets/images/teams/all-mascots.jpg'),
    },
  },
  'hero-foggy': {
    alt: 'Mental Health League Players',
    source: {
      webp: require('../assets/images/landing/hero-foggy.webp'),
      safari: require('../assets/images/landing/hero-foggy.png'),
    },
  },
  'hero-vosters': {
    alt: 'Mental Health League Celebration',
    source: {
      webp: require('../assets/images/landing/hero-vosters.webp'),
      safari: require('../assets/images/landing/hero-vosters.png'),
    },
  },
  'three-screenshots': {
    alt: 'MHL App Screenshots',
    source: {
      webp: require('../assets/images/landing/three-screenshots.webp'),
      safari: require('../assets/images/landing/three-screenshots.png'),
    },
  },
  'app-scorecard': {
    alt: 'MHL App Scorecard',
    source: {
      webp: require('../assets/images/landing/app-scorecard-v2.webp'),
      safari: require('../assets/images/landing/app-scorecard-v2.png'),
    },
  },
  'app-team': {
    alt: 'MHL Profile Screenshot',
    source: {
      webp: require('../assets/images/landing/app-team-v2.webp'),
      safari: require('../assets/images/landing/app-team-v2.png'),
    },
  },
  'prof-alan': {
    alt: 'MHL Profile Screenshot',
    source: {
      webp: require('../assets/images/landing/prof-alan.webp'),
      safari: require('../assets/images/landing/prof-alan.png'),
    },
  },
  'prof-logan': {
    alt: 'MHL Profile Screenshot',
    source: {
      webp: require('../assets/images/landing/prof-logan.webp'),
      safari: require('../assets/images/landing/prof-logan.png'),
    },
  },
  'vosters-prof': {
    alt: 'Vosters',
    source: {
      webp: require('../assets/images/landing/vosters-prof.webp'),
      safari: require('../assets/images/landing/vosters-prof.png'),
    },
  },
  'alan-stats': {
    alt: 'Alan R Stats',
    source: {
      webp: require('../assets/images/landing/alan-stats.webp'),
      safari: require('../assets/images/landing/alan-stats.jpg'),
    },
  },
  mhl_asset_1: {
    alt: 'Alan R Stats',
    source: {
      webp: require('../assets/images/landing/mhl_asset_1.webp'),
      safari: require('../assets/images/landing/mhl_asset_1.png'),
    },
  },
  app_team_anx: {
    alt: 'Anxietees Digital Journal',
    source: {
      webp: require('../assets/images/landing/app-team-anx.webp'),
      safari: require('../assets/images/landing/app-team-anx.png'),
    },
  },
  app_team_fd: {
    alt: 'Anxietees Digital Journal',
    source: {
      webp: require('../assets/images/landing/app-team-fd.webp'),
      safari: require('../assets/images/landing/app-team-fd.png'),
    },
  },
  mhl_asset_2: {
    alt: 'Alan R Stats',
    source: {
      webp: require('../assets/images/landing/mhl_asset_2.webp'),
      safari: require('../assets/images/landing/mhl_asset_2.png'),
    },
  },
  mhl_asset_2_cropped: {
    alt: 'Two Screenshots of MHL App',
    source: {
      webp: require('../assets/images/landing/mhl_asset_2_cropped.png'),
      safari: require('../assets/images/landing/mhl_asset_2_cropped.png'),
    },
  },
  mhl_asset_3: {
    alt: 'Alan R Stats',
    source: {
      webp: require('../assets/images/landing/mhl_asset_3.webp'),
      safari: require('../assets/images/landing/mhl_asset_3.png'),
    },
  },
};
