import React from 'react';
import { useLocation } from '../../hooks';
import GamePlanCreateScreen from './GamePlanCreateScreen';
import { GamePlanTemplate } from 'types';

const GamePlanEditScreen: React.FC = () => {
  // Pull existing gameplan object from state if it's being edited
  let { state: routeState } = useLocation<{ editing: GamePlanTemplate }>();

  console.log('GP STATE', routeState);

  let editing: undefined | GamePlanTemplate = routeState && routeState.editing;

  return <GamePlanCreateScreen editing={editing} />;
};

export default GamePlanEditScreen;
