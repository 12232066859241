import { useRouteMatch as ogUseRouteMatch } from 'react-router-dom';

type Params = {
  route1: string | undefined;
  route2: string | undefined;
  route3: string | undefined;
};

export const useRouteMatch = (): Params => {
  // If the URL is 3 parts, use that. If not, use the 2 parts.
  // This seems dumb, but if you only put 3 parts, then it comes back null if there's not 3 parts
  const match: {
    params: Params;
  } | null = ogUseRouteMatch([`/:route1/:route2/:route3`, `/:route1/:route2`]);

  let params: Params = {
    route1: undefined,
    route2: undefined,
    route3: undefined,
  };

  if (match) {
    params = match.params;
  }
  return params;
};
