import React from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  action: {
    color: '#B79D4B',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

type TierDescription = {
  title: string;
  subtitle: string;
};

interface Tier {
  title: string;
  subtitle: string;
  price: string;
  subheader?: string;
  description: TierDescription[];
  buttonText: string;
  buttonVariant: 'text' | 'outlined' | 'contained' | undefined;
}
const tiers: Tier[] = [
  {
    title: 'Covid Special - 50% off',
    subtitle: '7-day Free Trial - Cancel anytime',
    price: '5',
    description: [
      {
        title: 'Daily personal coaching',
        subtitle:
          'Each team is led by multiple trained behavior change coaches with backgrounds in psychology, social work, or counseling',
      },
      {
        title: 'Daily progress tracking',
        subtitle:
          "Just like any other sport, you'll have a daily scorecard complete with habit tracking and other metrics that helps you learn and improve",
      },
      {
        title: 'A curated supportive team',
        subtitle:
          "Fill out a survey and we'll pair you with a curated team of players similar to you for daily motivation and support",
      },
      {
        title: '28-day educational game plan',
        subtitle:
          'Daily educational and motivational content from coaches and psychologists to teach you terminology, coping skills, and exercises',
      },
      {
        title: 'Weekly Team Huddles on Zoom',
        subtitle:
          'Optionally join community calls each week where we discuss our wins & learnings, and set goals for the following week',
      },
      {
        title: '95% chance of improvement',
        subtitle:
          '95% of our players report that they improved their mental health in just 28 days – thanks to the support and accountability they received from their team and coach',
      },
    ],
    buttonText: 'Join Now',
    buttonVariant: 'contained',
  },
  {
    title: 'Nicole Edit',
    subtitle: '7-day Free Trial - Cancel anytime',
    price: '5',
    description: [
      {
        title: '28-Day Bootcamp',
        subtitle:
          'A month-long mental health bootcamp covering all the MHL fundamentals – complete with daily coaching, education, motivation, and team discussions.',
      },
      {
        title: 'Track your progress',
        subtitle:
          'Use our daily scorecard to track your habits and behaviors to learn and improve.',
      },
      {
        title: 'Work daily with a coach',
        subtitle:
          'Get one-on-one feedback on progress from coaches with backgrounds in psychology, social work, and behavior change.',
      },
      {
        title: 'Join a curated community',
        subtitle:
          'Hear stories of other people managing their mental health, and cheer each other on through our social feed.',
      },
      {
        title: 'Complete daily lessons',
        subtitle:
          'Use educational and motivational content from coaches and psychologists each day to process, learn and create habit shifts.',
      },
      {
        title: 'Dive deeper in weekly group huddles',
        subtitle:
          'Join community calls each week where we discuss our wins, learnings, and set goals for the following week.',
      },
    ],
    buttonText: 'Join Now',
    buttonVariant: 'contained',
  },
  {
    title: 'Therapy Notecook',
    subtitle: '7-day Free Trial - Cancel anytime',
    price: '5',
    description: [
      {
        title: 'Is the MHL a replacement for therapy?',
        subtitle:
          'The MHL is not therapy or a replacement for therapy with a licensed professional. It is designed to provide information in regard to the subject matter covered. It is sold with the understanding that the MHL is not engaged in rendering psychological, financial, legal, or other professional services. If expert assistance for counseling is needed, the services of a competent professional should be sought.',
      },
      {
        title: 'How will this help with anxiety?',
        subtitle:
          'With the MHL, you will practice becoming aware of the relationship between your thoughts and your feelings, empowering you to challenge and adjust them over time. We have included multiple CBT resources throughout the book and appendix to also further support your needs. Other tools, tips, and reflection questions are also available to help you discover which therapist tools work best for you.',
      },
      {
        title: 'Can CBT be used to treat depression?',
        subtitle:
          'Yes -- although research on CBT shows the strongest efficacy to treat anxiety disorders, CBT has been researched to treat depression and other mental illnesses as well. In research conducted by Kennard et al. (2008), CBT reduced the risk for relapse by eightfold compared to antidepressant medication among youths. ',
      },
      {
        title: 'When should I use this journal?',
        subtitle:
          'Whenever you feel anxious or stressed, but there’s no pressure. You can use this however often you feel comfortable, whether that’s daily or on an as-needed basis.',
      },
      {
        title: 'What is the purpose of this journal?',
        subtitle:
          'The purpose of this journal is to help you process your emotions and experiences in a constructive way. It is designed to give you full autonomy; you can start however you want, go in order or not, and pick up whenever or wherever. We generally recommend using the journal entries whenever you feel anxious or stressed in order to best identify, track, and improve patterns in your thinking.',
      },
    ],
    buttonText: 'Join Now',
    buttonVariant: 'contained',
  },
  // {
  //   title: 'Coach',
  //   price: '40',
  //   description: [
  //     'Everything included in the Team plan',
  //     '*PLUS*',
  //     'A dedicated coach that that provides one-on-one support with weekly check-ins and goalsetting',
  //   ],
  //   buttonText: 'Get Started',
  //   buttonVariant: 'outlined',
  // },
];

type Props = {
  tier?: number;
};

const FeaturesAccordion: React.FC<Props> = ({ tier }) => {
  const classes = useStyles();

  return (
    <>
      {tiers[tier && tiers[tier] ? tier : 1].description.map((line, idx) => (
        <Accordion elevation={0} key={idx}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography className={classes.heading} color="textPrimary">
              {line.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{line.subtitle}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default FeaturesAccordion;
