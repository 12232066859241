import React, { ReactElement } from 'react';
import { Grid, GridProps } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Paragraph from './Paragraph';
import Headline from './Headline';
import Image from './Image';
import { ImageNames } from '../models/Image';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    xsColumnReverse: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    rowReverseMd: {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row-reverse',
      },
    },
    heroImg: {
      padding: '10px 23px 0px 22px !important',
      marginBottom: '-19px',
      [theme.breakpoints.up('md')]: {
        padding: '0px !important',
        marginBottom: '0px',
        '& img': {
          position: 'absolute',
          bottom: '-80px',
          right: '0',
          width: '50%',
          overflowX: 'hidden',
          maxHeight: '600px',
          maxWidth: '782px',
        },
      },
    },
  }),
);

interface Props {
  src: ImageNames;
  title: string | React.ReactNode;
  callout?: string;
  body: string | React.ReactNode;
  reverse?: boolean;
  imageStyle?: CSSProperties;
  button?: React.ReactElement; //{ text: string; onClick: () => void; icon?: any };
  reverseAll?: boolean;
}

const SectionHalves: React.FC<Props & GridProps> = ({
  children,
  src,
  title,
  body,
  callout,
  reverseAll,
  reverse,
  imageStyle,
  button,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={4}
      direction={reverseAll ? 'row-reverse' : undefined}
      className={
        reverse
          ? classes.rowReverseMd
          : reverseAll
          ? classes.xsColumnReverse
          : undefined
      }
      alignItems="center">
      <Grid
        md={6}
        item
        className={
          src === 'hero-vosters' || src === 'hero-foggy'
            ? classes.heroImg
            : undefined
        }>
        <Image src={src} style={imageStyle} />
      </Grid>
      <Grid md={6} item>
        {typeof title === 'string' ? <Headline>{title}</Headline> : title}
        {typeof body === 'string' ? (
          <Paragraph gutterBottom>{body}</Paragraph>
        ) : (
          body
        )}
        {callout && (
          <Paragraph color="secondary" variant="body2">
            <i>{callout}</i>
          </Paragraph>
        )}
        {button && button}
      </Grid>
    </Grid>
  );
};

export default SectionHalves;
