class ApplicationError extends Error {
  constructor(message = 'An applicaiton error occurred.') {
    super(message);
  }
}

class AuthenticationError extends ApplicationError {
  constructor(message = 'An authentication error occurred.') {
    super(message);
  }
}

class NoUserError extends AuthenticationError {
  constructor(message = 'Current user is not available.') {
    super(message);
  }
}

class InvalidPlayerProfileError extends ApplicationError {
  constructor(
    message = 'Your player profile is not setup correctly. Please try again.',
  ) {
    super(message);
  }
}

class UnimplementedError extends ApplicationError {
  constructor(message = 'This functionality has not been implemented.') {
    super(message);
  }
}

export {
  ApplicationError,
  AuthenticationError,
  NoUserError,
  UnimplementedError,
  InvalidPlayerProfileError,
};
