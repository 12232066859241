import React from 'react';
import { Box } from '@material-ui/core';
import {
  Section,
  SectionHalves,
  Footer,
  NavPadding,
  Paragraph,
} from '../../components';
import TextCta from './components/TextCta';
import FeaturesAccordion from './components/FeaturesAccordion';
import Testimonial from './components/Testimonial';
import HowItWorks from './components/HowItWorks';
import Syllabus from './components/Syllabus';
import WhyMhl from './components/WhyMhl';
import CtaButton from './components/CtaButton';

const Landing4: React.FC = () => {
  const ctaButton = <CtaButton />;

  return (
    <>
      <NavPadding />
      <Box>
        <Section height="lg">
          <SectionHalves
            src="hero-vosters"
            title="Tackle your mental health with a team."
            body="Apply to join MHL’s next draft class to get daily mental health education, motivation, and accountability."
            callout="Only a few spots remaining!"
            button={ctaButton}
            reverseAll
          />
        </Section>
        <Section background="primary" height="sm">
          <Testimonial />
        </Section>
        <Section height="lg">
          <SectionHalves
            src="three-screenshots"
            title="What You Get"
            reverse
            body={
              <>
                <Paragraph gutterBottom>
                  Daily support...because with mental health, there is no
                  off-season.
                </Paragraph>
                <FeaturesAccordion />
              </>
            }
          />
        </Section>
        <Section background="offWhite" height="lg">
          <HowItWorks button={ctaButton} />
        </Section>
        <Section height="lg">
          <Syllabus alignItems="flex-start" />
        </Section>
        <Section background="primary" height="sm">
          <Testimonial num={1} />
        </Section>
        <Section height="lg">
          <WhyMhl alignItems="flex-start" />
        </Section>
        <Section height="lg" background="offWhite">
          <SectionHalves
            src="coaches"
            title="Meet Our Coaches"
            reverse
            body="Just like the pros, you deserve a coaching staff to get your mind right. MHL coaches are a group of social workers and counselors that provide daily check-ins, pro tips, and pep talks to pick you up when you’re down and celebrate when you’re up."
          />
        </Section>
        <Section height="sm" background="primary">
          <TextCta button={ctaButton} />
        </Section>
        <Footer />
      </Box>
    </>
  );
};

export default Landing4;
