import React, { useState, useEffect } from 'react';
import { ProgressBar, Paragraph } from '../../../components';
import { useHistory } from 'react-router-dom';
import { useMutation, useLead } from '../../../hooks';
// import { SurveysService } from '../../../services';
import { SurveyType } from '../../survey/SurveyScreen';
import Surveys from '../../../services/SurveysService';

type Props = {
  center?: boolean;
  parentState: SurveyType;
  isShort?: boolean;
};

const CalculatingBars: React.FC<Props> = ({ center, parentState, isShort }) => {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const { setLead } = useLead();

  const timing = isShort ? 200 : 200;

  // const container = useContainer();
  // const service = container.getInstance<SurveysService>('surveysService');
  const service = new Surveys();
  const { mutate } = useMutation(() => service.saveLead(parentState.form));

  useEffect(() => {
    // Save survey data to Leads
    if (parentState.form.email) {
      setLead(parentState.form.email);
    }
    mutate();

    // Fake timing of progress bars
    function progress() {
      setValue(oldValue => {
        if (oldValue === timing) {
          history.push('/bootcamp');
        }
        const diff = Math.random() * 10;
        return Math.min(oldValue + diff, timing);
      });
    }

    const timer = setInterval(progress, timing);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return isShort ? (
    <>
      {value < 100 ? (
        <Paragraph center variant="subtitle2">
          Prepping your locker...
        </Paragraph>
      ) : (
        <Paragraph center variant="subtitle2">
          Boomshakalaka!
        </Paragraph>
      )}
      <ProgressBar value={value > 100 ? 100 : value} />
      <ProgressBar value={value - 100} color="secondary" />
    </>
  ) : (
    <>
      <Paragraph center variant="subtitle2">
        Finding your team...
      </Paragraph>

      <ProgressBar value={value} />
    </>
  );
};

export default CalculatingBars;
