import palette, { Palette } from './palette';

export const lightColors: Palette = {
  base: palette.white,
  inverse: palette.navy,
  primary: palette.red,
  secondary: palette.gold,
  info: palette.bronze,
  text: palette.gray,
  neutral: palette.silver,
};

export type Colors = keyof typeof lightColors;
