import React from 'react';
import { Box } from '@material-ui/core';
import {
  Section,
  SectionHalves,
  Footer,
  NavPadding,
  Paragraph,
} from '../../components';
import TextCta from './components/TextCta';
import FeaturesAccordion from './components/FeaturesAccordion';
import Testimonial from './components/Testimonial';
import HowItWorks from './components/HowItWorks';
import Syllabus from './components/Syllabus';
import WhyMhl from './components/WhyMhl';
import CtaButton from './components/CtaButton';

const Landing8: React.FC = () => {
  const ctaButton = <CtaButton />;

  return (
    <>
      <NavPadding />
      <Box>
        <Section height="lg">
          <SectionHalves
            src="mhl_asset_2"
            title="Tackle your mental health as a team."
            body="Join MHL's mental health app for daily tracking, coaching and team support."
            callout="50% off this week only!"
            button={ctaButton}
            reverseAll
          />
        </Section>
        <Section background="primary" height="sm">
          <Testimonial num={1} />
        </Section>
        <Section height="lg">
          <SectionHalves
            src="mhl_asset_1"
            title="Emotional fitness for mental health champions."
            reverse
            body={
              <>
                <Paragraph gutterBottom>
                  The Mental Health League is a new digital platform that uses
                  community and coaching to build emotional fitness skills.
                </Paragraph>
                <FeaturesAccordion />
              </>
            }
          />
        </Section>
        <Section background="offWhite" height="lg">
          <HowItWorks button={ctaButton} />
        </Section>
        <Section height="lg">
          <Syllabus alignItems="flex-start" />
        </Section>
        <Section background="primary" height="sm">
          <Testimonial num={2} />
        </Section>
        <Section height="lg">
          <WhyMhl alignItems="flex-start" />
        </Section>
        <Section height="lg" background="offWhite">
          <SectionHalves
            src="coaches"
            title="Meet Our Coaches"
            reverse
            body="Just like the pros, you deserve a coaching staff to get your mind right. MHL coaches are a group of social workers and counselors that provide daily check-ins, pro tips, and pep talks to pick you up when you’re down and celebrate when you’re up."
          />
        </Section>
        <Section height="sm" background="primary">
          <TextCta button={ctaButton} />
        </Section>
        <Footer />
      </Box>
    </>
  );
};

export default Landing8;
