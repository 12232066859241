import { CelebrationText, CelebrationFilter } from 'types';

export const celebrations: CelebrationText[] = [
  { headline: 'BOOM SHAKALAKA!', type: CelebrationFilter.Win },
  { headline: 'NEVER GIVE UP!', type: CelebrationFilter.Loss },
  { headline: 'TIME FOR A COMEBACK!', type: CelebrationFilter.Loss },
  { headline: "YOU'VE GOT THIS!", type: CelebrationFilter.Loss },
  { headline: 'KEEP GOING!', type: CelebrationFilter.Loss },
  { headline: 'NEVER SURRENDER!', type: CelebrationFilter.Loss },
  { headline: "YOU'RE ON FIRE!!!", type: CelebrationFilter.Win },
  { headline: 'BOOYA KASHA!!!', type: CelebrationFilter.Win },
  { headline: 'G.O.A.T. STATUS!!!', type: CelebrationFilter.Win },
  { headline: 'CONGRAT ULATIONS!', type: CelebrationFilter.Win },
  { headline: 'OH BABY! OH BABY!', type: CelebrationFilter.Win },
  { headline: "YOU'RE CRUSHING IT!", type: CelebrationFilter.Win },
  { headline: 'OH HECK YEAH!', type: CelebrationFilter.Win },
  { headline: 'NOTHING BUT NET!', type: CelebrationFilter.Win },
  { headline: 'IS IT HOT IN HERE?', type: CelebrationFilter.Win },
  { headline: 'KEEP GOING!', type: CelebrationFilter.Tie },
  { headline: 'SOLID GAME!', type: CelebrationFilter.Tie },
  { headline: 'KEEPIN IT STEADY!', type: CelebrationFilter.Tie },
  { headline: 'NEVER SURRENDER!', type: CelebrationFilter.Tie },
];

// export const celebrations: CelebrationText[] = [
//   { top: 'BOOM', bottom: 'SHAKALAKA!', type: 'win' },
//   { top: 'NEVER', bottom: 'GIVE UP!', type: ScorecardResult.Loss },
//   { top: 'TIME FOR A', bottom: 'COMEBACK!', type: 'loss' },
//   { top: "YOU'VE GOT", bottom: 'THIS!', type: 'loss' },
//   { top: 'KEEP', bottom: 'GOING!', type: 'loss' },
//   { top: 'NEVER', bottom: 'SURRENDER!', type: 'loss' },
//   { top: "YOU'RE ON", bottom: 'FIRE!!!', type: 'win' },
//   { top: 'BOOYA', bottom: 'KASHA!!!', type: 'win' },
//   { top: 'G.O.A.T.', bottom: 'STATUS!!!', type: 'win' },
//   { top: 'CONGRAT', bottom: 'ULATIONS!', type: 'win' },
//   { top: 'OH BABY!', bottom: 'OH BABY!', type: 'win' },
//   { top: "YOU'RE", bottom: 'CRUSHING IT!', type: 'win' },
//   { top: 'OH HECK', bottom: 'YEAH!', type: 'win' },
//   { top: 'NOTHING', bottom: 'BUT NET!', type: 'win' },
//   { top: 'IS IT HOT', bottom: 'IN HERE?', type: 'win' },
//   { top: 'KEEP', bottom: 'GOING!', type: 'tie' },
//   { top: 'SOLID', bottom: 'GAME!', type: 'tie' },
//   { top: 'KEEPIN IT', bottom: 'STEADY!', type: 'tie' },
//   { top: 'NEVER', bottom: 'SURRENDER!', type: 'tie' },
// ];
