import React, {  useState } from 'react';
import {
  Avatar,
  CircularProgress,
  Chip,
  Container,
  Divider,
  Typography,
  Grid,
} from '@material-ui/core';
import { useContainer, useCache } from '../../hooks';
import { TeamPlayer } from '../../models/TeamPlayer';
import { Tabs } from '../../components';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import GameCollection from '../../models/GameCollection';
import {  GamesService } from '../../services/interfaces';
import moment from 'moment';
import CoachesNotesTab from './CoachesNotesTab';
import GamesTab from './GamesTab';
import BioTab from './BioTab';

interface Props {
  player: TeamPlayer;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    question: {
      paddingTop: '10px',
      color: '#999',
    },
    header: {
      marginTop: '20px',
    },
    root: {
      // overflowY: 'scroll',
      // height: 'calc(~"100vh - 202px")',
      // height: '70vh',
    },
    result: {
      fontWeight: 700,
    },
    why: {
      fontSize: '.8rem',
    },
    plays: {
      fontSize: '.8rem',
    },
    scorecard: {
      marginBottom: '20px',
    },
    spinner: {
      marginTop: '20px',
    },
    top: {
      alignItems: 'flex-start',
    },
    chip: {
      marginRight: '7px',
    }
  }),
);

const PlayerProfile: React.FC<Props> = ({ player }) => {
  const classes = useStyles();
  
  const container = useContainer();  
  const [month] = useState(moment().format('YYYY-MM'));
  const gamesService = container.getInstance<GamesService>(
    'gamesService',
  );
  const { data:games } = useCache<GameCollection>(
    gamesService.getPlayerGamesAndStats, [player.id, month])

  const lastScorecard = player.latestScorecardOn
    ? moment(player.latestScorecardOn).format('ddd, MMM DD')
    : 'Never';
  const lastScorecardFromNow = player.latestScorecardOn
    ? moment(player.latestScorecardOn).fromNow()
    : '';
    const isNewPlayer =
    moment().diff(player.createdAt, 'days') < 7 ? true : false;

  // CACHED API CALL
  // useEffect(() => {
  //   // cacheID is how a cache is identified against a unique request
  //   const cacheID = player.id;
  //   // look in cache and set response if present
  //   if (CACHE[cacheID] !== undefined) {
  //     setGames(CACHE[cacheID]['games']);
  //     setSurveys(CACHE[cacheID]['surveys']);
  //     setLoading(false);
  //   } else {
  //     // else make sure loading set to true
  //     setLoading(true);
  //     CACHE[cacheID] = {};
  //     // fetch new data
  //     (async function() {
  //       CACHE[cacheID]['surveys'] = await surveysService.getAnswersForSurvey(
  //         player.id,
  //       );
  //       setSurveys(CACHE[cacheID]['surveys']);

  //       CACHE[cacheID]['games'] = await surveysService.getPlayerGamesAndStats(
  //         player.id,
  //       );
  //       setGames(CACHE[cacheID]['games']);

  //       setLoading(false);
  //     })();
  //   }
  // }, [player, surveysService]);

  return (
    <Container className={classes.root}>
      <Avatar src={player.avatar} />
      <Typography variant="h4">{player.name}</Typography>
      <Typography variant="subtitle1">
        <Typography component="span" color="primary">
          {player.email}
        </Typography>{' '}
        - {player.bio ? player.bio : 'No bio'}
      </Typography>
      {player && player.createdAt && (
        <>
        {isNewPlayer && (
          <Chip
          className={classes.chip}
          color="secondary"
          label="New Player"
          size="small"
          variant="outlined"
          />
        )}
        <Typography component="span" variant="subtitle2" className={classes.question}>
          {`Joined | ${player.createdAt.format('ddd, MMM DD')} - ${player.createdAt?.fromNow()}`}
        </Typography>
        </>
      )}
      <Typography variant="subtitle2" className={classes.question}>
        {`Last Scorecard | ${lastScorecard}   -   ${lastScorecardFromNow}`}
      </Typography>
      {!games ? (
        <CircularProgress className={classes.spinner} />
      ) : (
        <>
          {games && (
            <Typography
              variant="subtitle1"
              className={classes.header}
              gutterBottom>
              {`${games.completionStreak} Comp Streak  |  ${games.stats.wins}-
              ${games.stats.losses}-${games.stats.ties}  | 
              ${Math.floor(games.stats.completionPercent * 100)}% Comp`}
            </Typography>
          )}

          <Grid container spacing={2} className={classes.top}>
            <Grid item xs={12} sm={8}>
              <Tabs
                panels={{
                  Games: <GamesTab userId={player.id} games={games} />,
                  Bio: <BioTab userId={player.id} />,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" className={classes.header} gutterBottom>
                Notes
              </Typography>
              <Divider />
              <CoachesNotesTab
                userId={player.id}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default PlayerProfile;
