import React from 'react';
import { Card as MuiCard, CardProps } from '@material-ui/core';
// import variables from '../assets/native-base-theme/variables/mhl';
import { brandColors } from '../assets/styles/theme';

interface Props {
  bordered?:
    | 'brandPrimary'
    | 'brandInfo'
    | 'textColor'
    | 'brandDark'
    | 'lightTextColor';
  padding?: boolean;
  onPress?(): void;
  rounded?: boolean;
  gutterBottom?: boolean;
}

const Card: React.FC<Props & CardProps> = ({
  bordered,
  children,
  rounded,
  padding = false,
  style,
  gutterBottom = true,
  ...props
}) => {
  const borderWidth = bordered ? '3px' : 0;
  const borderColor = bordered ? brandColors[bordered] : 'white';

  return (
    <MuiCard
      style={{
        padding: padding ? '5%' : '0px',
        marginBottom: gutterBottom ? '20px' : '0px',
        borderTop: `${borderWidth} ${borderColor} solid`,
        ...style,
      }}
      {...props}>
      {children}
    </MuiCard>
  );
};

export default Card;
