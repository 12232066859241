import {
  IdDoc,
  SubscriptionListener,
  Unsubscriber,
  CollectionReference,
  Subscription,
  SurveyAnswers,
  ReflectionRecord,
  ArticleRecord,
  Recap,
  ReflectionExercise,
  ArticleExercise,
  CoachesNote,
  CoachesNotePayload,
  Comment,
  AssistType,
  UserProfile,
} from './types';
import Team from './Team';
import { EditablePlayerFields } from './Player';
import { Collections } from './FirebaseTypes';
import { TotalsMap, Week } from './AnalyticsTypes';
import { Article, FeaturedType } from './ArticleTypes';
import { NewAccount } from './NewAccount';

export type RemoteConfigValues = keyof typeof defaultValues;
export interface RemoteConfigService {
  getValue<T>(valueName: RemoteConfigValues): Promise<T>;
}

export enum EventName {
  Login = 'login',
  Logout = 'logout',
  SignUp = 'sign_up',
  PasswordReset = 'password_reset',
  AddScorecard = 'add_scorecard',
  AddComment = 'add_comment',
  AddCommentReaction = 'add_comment_reaction',
  RemoveCommentReaction = 'remove_comment_reaction',
  DeleteComment = 'delete_comment',
  ToggleCheer = 'toggle_cheer',
  RateArticle = 'rate_article',
  EditPlay = 'edit_play',
  AddPlay = 'add_play',
  DeletePlay = 'delete_play',
  TogglePlay = 'toggle_play',
  ViewProTip = 'view_pro_tip',
  ViewUserProfile = 'view_user_profile',
  ShareArticle = 'share_article',
  RatingShown = 'rating_shown',
  RatingSuccess = 'rating_success',
}

export interface TrackingService {
  track(name: EventName, params?: { [key: string]: any }): void;
}

export interface ProfileServiceType {
  perform(profile: UserProfile): Promise<void>;
}

export interface SignUpService {
  perform(account: NewAccount): Promise<void>;
}

export type Surveys = typeof defaultValues.surveys;
export interface SurveysService {
  getAnswersForSurvey(
    userId: string,
  ): Promise<
    { [key in keyof Surveys]: { name: string; answers: SurveyAnswers } } | {}
  >;
}

export type RecapObj = {
  [key: string]: Recap;
};

export interface SaveExerciseReturn {
  docRef: DocumentReference;
  isNewDoc: boolean;
}

export interface RecapServiceType {
  updateRatings(id: string, rating: number, explanation?: string): void;
  saveExerciseRecord(
    record: Omit<ReflectionRecord | ArticleRecord, 'completedAt'>,
  ): Promise<S>;
  saveReflectionRecord(
    record: Omit<ReflectionRecord, 'completedAt'>,
  ): Promise<void>;
  getWeeklyRecap(isoWeek: number): Promise<Recap>;
  getMonthOfRecaps(isoWeek: number): Promise<RecapObj>;
  getReflection(id: string): Promise<ReflectionExercise>;
}

export interface PlayerServiceType {
  perform(id: string, fields: EditablePlayerFields): Promise<void>;
  updateCurrentPlayer(fields: EditablePlayerFields): Promise<void>;
  joinToTeam(params: { answers?: SurveyAnswers; team?: Team }): Promise<void>;
  welcomeToTeam(): Promise<void>;
}

export interface RateServiceType {
  rateApp(inApp?: boolean): void;
}

export interface FirestoreServiceType {
  addOrUpdateDoc<T extends IdDoc>(
    collection: Collections | CollectionReference<T>,
    doc: T,
  ): Promise<void>;
  listenToCollection<T, Query>(
    listener: SubscriptionListener<T>, //<T | null>,
    collection: string | Query,
    type?: 'array' | 'object',
  ): Unsubscriber;
}

export interface AnalyticsServiceType {
  getTotals(): Promise<TotalsMap>;
  updateTotals(): Promise<void>;
  addWeek(dateStr: string): Promise<void>;
  addSeason(dateStr: string): Promise<void>;
  get(): Promise<Week[]>;
  totalsDoc: Query;
  seasonQuery: Query;
  weeklyQuery: Query;
  listenToWeeks: Subscription<Week[]>;
  listenToSeasons: Subscription<Week[]>;
}

export interface StreamPostServiceType {
  savePost(recap: Recap): Promise<void>;
}

export interface GamesServiceType {
  getPlayerGamesAndStats(
    userId: string,
    month: string,
  ): Promise<GameCollection>;
}

export interface CoachesNotesService {
  getCoachesNotes(userId: string): Promise<CoachesNote[]>;
  updateOrCreateNote(note: CoachesNotePayload): Promise<void>;
  deleteNote(userId: string, noteId: string): Promise<void>;
  subscribeToCoachesNotes(
    listener: SubscriptionListener<CoachesNote[]>,
    userId: { userId: string },
  ): () => void;
}

export interface ToggleServiceType {
  get(path: TogglePath): Promise<boolean>;
  updateOrCreate(path: TogglePath): Promise<void>;
  subscribe(
    listener: SubscriptionListener<Toggle[]>,
    path: TogglePath,
  ): () => void;
}

export interface CommentServiceType {
  updateOrCreate(note: Partial<Comment>): Promise<void>;
  delete(userId: string, noteId: string): Promise<void>;
  subscribe(
    listener: SubscriptionListener<Comment[]>,
    articleId: string,
  ): () => void;
}

export interface LikeServiceType {
  updateOrCreate(note: Partial<Comment>): Promise<void>;
  delete(userId: string, noteId: string): Promise<void>;
  subscribe(
    listener: SubscriptionListener<Comment[]>,
    articleId: string,
  ): () => void;
}

export interface ArticleServiceType {
  saveArticleExercise(article: ArticleExercise): Promise<void>;
  importArticles(articles: {
    [key: string]: { [key: string]: any };
  }): Promise<void>;
  getArticles(): Promise<Article[]>;
  getArticle(articleId: string): Promise<Article>;
  listenToArticles(
    setState: React.Dispatch<Article[]>,
    setLoading: React.Dispatch<boolean>,
  ): void;
  deleteArticle(articleId?: string): Promise<void>;
  updateArticle(articleId: string, updates: Partial<Article>): Promise<void>;
  addOrEditArticle(article: Article): Promise<void>;
  updateRatings(articleId: string, isHelpful: boolean): Promise<void>;
  getFeatured(): Promise<FeaturedType>;
}

export interface StatsServiceType {
  addAssist(type: AssistType): Promise<void>;
  removeAssist(type: AssistType): Promise<void>;
  saveScorecardStats(games: GameCollection): Promise<void>;
}

export interface LocalStateServiceType {
  resetStorage(): Promise<void>;
  setStorage(
    key: string,
    value: string | { [key: string]: string } | string[],
  ): Promise<void>;
  getStorage(key: string): Promise<string | null>;
  removeStorage(key: string): Promise<void>;
}
