import { useState, useEffect } from 'react';
import { useContainer } from './useContainer';
import { RemoteConfigService } from '../services';
import { useCurrentUser } from './useCurrentUser';
import { Player } from 'types';

export type Features =
  | 'scorecardPlays'
  | 'joinTeamEnabled'
  | 'teamCombine'
  | 'teamSelection'
  | 'changeActivityVisibility'
  | 'redoOnboarding'
  | 'usersProfiles';

interface Handler {
  (opts: { config: RemoteConfigService; user: Player | null }):
    | Promise<boolean>
    | boolean;
}
type Handlers = { [key in Features]: Handler };

const featureCheckHandlers: Handlers = {
  scorecardPlays: async ({ user }): Promise<boolean> => {
    return !!user?.scorecardPlaysEnabled;
  },
  joinTeamEnabled: async ({ config, user }) => {
    const minCount = await config.getValue<number>('min_scorecards_to_team');
    return !!user?.scorecardsCount && user.scorecardsCount >= minCount;
  },
  teamCombine: async ({ config }) => {
    const enabled = await config.getValue<boolean>('team_combine_enabled');
    return enabled;
  },
  teamSelection: async ({ config }) => {
    const enabled = await config.getValue<boolean>('team_selection_enabled');
    return enabled;
  },
  changeActivityVisibility: ({ user }) => !!user?.teamsCoached,
  redoOnboarding: ({ user }) => !!user?.teamsCoached,
  usersProfiles: ({ user }) => !!user?.teamsCoached,
};

export interface UseFeatureResponse {
  enabled: boolean;
  loading: boolean;
}

export const useFeature = (feature: Features): UseFeatureResponse => {
  const { user } = useCurrentUser();
  const container = useContainer();
  const config = container.getInstance<RemoteConfigService>('remoteConfig');
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const response = featureCheckHandlers[feature]({ config, user });
    if (typeof response === 'undefined') {
      setEnabled(false);
    } else if (response instanceof Promise) {
      setLoading(true);
      response
        .then(result => setEnabled(result))
        .catch(() => setEnabled(false))
        .finally(() => setLoading(false));
    } else {
      setEnabled(response);
    }
  }, [feature, config, user]);
  return {
    enabled,
    loading,
  };
};
