import React, { useState, useEffect } from 'react';
import {
  Page,
  Button,
  Headline,
  Paragraph,
  RichText,
  Card,
  Footer,
  Section,
  Avatar,
  Box,
} from '../../components';
import { useHistory, useRouteMatch, useContainer } from '../../hooks';
import { ArticleServiceType } from '../../services/interfaces';
import TextCta from '../landing/components/TextCta';
import { Article } from 'types';
import { ApplicationError } from '../../models/Errors';

type Props = {
  noFooter?: boolean;
  id?: string;
};

const ArticleScreen: React.FC<Props> = ({ noFooter = false, id }) => {
  const history = useHistory();
  const container = useContainer();

  // Setting data to state so view re-renders upon url change - /gameplans use case
  const [article, setArticle] = useState<Article | undefined>();
  const [error, setError] = useState<ApplicationError | undefined>();
  const [loading, setLoading] = useState(true);

  // Match pulls from the article slug for the URL - used in playbook article view
  // Unless it's passed in as a prop such as from ExerciseView within Gameplan screen
  const { route2 } = useRouteMatch();
  const match = id ? id : route2;

  const articleService = container.getInstance<ArticleServiceType>(
    'articleService',
  );

  useEffect(() => {
    // Update article when match url changes - sadly couldn't figure out how to get useQuery to re-fire
    if (match) {
      const getNew = async (): Promise<void> => {
        setLoading(true);
        setError(undefined);
        try {
          setArticle(await articleService.getArticle(match));
        } catch (err) {
          setError(new ApplicationError('Could not find this article'));
          setArticle(undefined);
        }
        setLoading(false);
      };
      getNew();
    }
  }, [match]);

  return (
    <>
      <Page fullPage error={error} loading={loading}>
        {article ? (
          <Card padding>
            {article.pages.length > 0 ? (
              article.pages.map((page, idx) => (
                <div key={idx}>
                  <Box mt={noFooter ? 0 : 2}>
                    <Headline gutterBottom align="center">
                      {page.title}
                    </Headline>
                  </Box>

                  {article && !!article.author && idx === 0 && (
                    <Box row mt={1} mb={2}>
                      {article.authorImg && (
                        <Avatar
                          source={article.authorImg}
                          style={{ marginRight: 10 }}
                        />
                      )}
                      <Paragraph light>
                        {!article.authorImg && 'By '}
                        {article.author}
                      </Paragraph>
                    </Box>
                  )}

                  {page.imageURL ? (
                    <Box mb={2}>
                      <img alt={page.title} src={page.imageURL} />
                    </Box>
                  ) : (
                    <Paragraph gutterBottom>No Image</Paragraph>
                  )}

                  {typeof page.body === 'string' ? (
                    page.body.split('\n').map((p, idx) => (
                      <Paragraph key={idx} gutterBottom>
                        {p}
                      </Paragraph>
                    ))
                  ) : (
                    <RichText state={page.body} />
                  )}

                  {article.externalLink && page.next_btn && (
                    <Box mt={3}>
                      <Button
                        text={page.next_btn}
                        href={article.externalLink}
                      />
                    </Box>
                  )}
                </div>
              ))
            ) : (
              <Paragraph>No pages</Paragraph>
            )}
          </Card>
        ) : (
          <Paragraph>Article Not Found!</Paragraph>
        )}
      </Page>
      {!noFooter && (
        <>
          <Section height="sm" background="primary" mt={6}>
            <TextCta
              button={
                <Button text="Learn More" onClick={() => history.push('/')} />
              }
            />
          </Section>
          <Footer />
        </>
      )}
    </>
  );
};

export default ArticleScreen;
