import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import {
  Section,
  Box,
  SectionHalves,
  Headline,
  Paragraph,
  Footer,
  Page,
  Card,
  Button,
  Confetti,
  Logo,
} from '../../components';
import {
  EmojiEvents as TrophyIcon,
  Apple as AppleIcon,
  Android as AndroidIcon,
} from '@material-ui/icons';
import Testimonial from './components/Testimonial';
import TextCta from './components/TextCta';
import CtaButton from './components/CtaButton';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { brandColors } from '../../assets/styles/colors';
import '../../assets/styles/App.scss';
import { CSSTransition } from 'react-transition-group';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headlineP1: {
      marginLeft: '-10px',
      '-webkit-text-stroke': `1px white`,
    },
    headlineP2: {
      color: 'white',
      '-webkit-text-stroke': `1px ${brandColors.brandPrimary}`,
      marginLeft: '-3px',
    },
    hero: {
      [theme.breakpoints.down('md')]: {
        minHeight: '465px',
        height: '80vh',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      },
    },
  }),
);
type ButtonProps = {
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
  state: boolean;
};

const ConfettiButton: React.FC<ButtonProps> = ({ toggle, state }) => {
  return (
    <Button
      text="Get Started"
      onPress={() => {
        toggle(!state);
        window.scrollTo(0, 0);
      }}
      startIcon={<TrophyIcon />}
    />
  );
};

const Landing9: React.FC = () => {
  const ctaButton = <CtaButton ctas="os" />;
  const classes = useStyles();
  const [celebrate, toggleCelebrate] = useState(false);

  return (
    <>
      <CSSTransition
        in={celebrate}
        timeout={300}
        classNames="alert"
        unmountOnExit>
        <Box className="page-overlay" display="flex">
          <Headline center>Congrats!</Headline>
          <Paragraph
            gutterBottom
            component="h6"
            variant="subtitle2"
            align="center">
            The hardest part of getting better is often just getting started.
            Time for your championship season to begin.
          </Paragraph>
          <Box textAlign="center">{ctaButton}</Box>
          {/* <Button
            text="Celebrate"
            onPress={() => toggleCelebrate(!celebrate)}
          /> */}
          <Confetti />
        </Box>
      </CSSTransition>

      <Logo background />
      {/* 
      <CSSTransition
        in={!celebrate}
        timeout={300}
        classNames="alert"
        unmountOnExit>
        <> */}
      <Section height="lg" className={classes.hero}>
        <Logo width={110} />
        <Box mb={1} textAlign="center">
          <Headline display="inline" variant="h1">
            MHL
          </Headline>
          <Headline
            className={classes.headlineP1}
            color="secondary"
            display="inline"
            variant="h1">
            2K
          </Headline>
          <Headline
            className={classes.headlineP2}
            color="secondary"
            display="inline"
            variant="h1">
            21
          </Headline>
        </Box>
        <Paragraph gutterBottom component="h6" variant="h6" align="center">
          GET OUT OF YOUR HEAD.
          <br />
          GET IN THE GAME.
        </Paragraph>

        <Box textAlign="center" mb={3}>
          {/* <Button text="Fireworks" onPress={handleNext} /> */}
          {/* {ctaButton} */}
          <ConfettiButton toggle={toggleCelebrate} state={celebrate} />
        </Box>
      </Section>

      <Section background="primary" height="sm">
        <Testimonial num={1} />
      </Section>

      <Section height="lg">
        <SectionHalves
          src="mhl_asset_2"
          //@ts-ignore
          title={
            <Headline>
              THERE IS NO
              <br />
              OFF-SEASON.
            </Headline>
          }
          body="That’s why the MHL app gives you the DAILY education, motivation, and live coaching you need to tackle your mental health. Join us to maintain your winning streak or to stage an epic comeback."
          button={<ConfettiButton toggle={toggleCelebrate} state={celebrate} />}
        />
      </Section>

      <Section background="offWhite">
        <SectionHalves
          src="app-scorecard"
          title="TRACK YOUR PROGRESS."
          body="MHL’s daily scorecard is a digital journal and habit tracker to help you reflect on key trends and stats, and learn which plays to help you win each day. It’s a simple practice, but can be absolute game changer."
          reverse
          button={<ConfettiButton toggle={toggleCelebrate} state={celebrate} />}
        />
      </Section>

      <Section>
        <SectionHalves
          src="app-team"
          title="TACKLE YOUR MENTAL HEALTH WITH A TEAM."
          body={
            <>
              <Typography gutterBottom>
                Get daily education, motivation, and feedback from our coaching
                staff, and connect and share tips with other players in our
                social feed.
              </Typography>
              <Typography color="primary">
                <b>What You Get</b>
              </Typography>
              <ul style={{ marginBottom: '60px' }}>
                <Typography component="li" variant="body2">
                  Supportive, curated team
                </Typography>
                <Typography component="li" variant="body2">
                  Daily tracking and stats
                </Typography>
                <Typography component="li" variant="body2">
                  Personal feedback from coaches
                </Typography>
                <Typography component="li" variant="body2">
                  Daily education and training
                </Typography>
                <Typography component="li" variant="body2">
                  A mindset for long-term change
                </Typography>
              </ul>
            </>
          }
          imageStyle={{ marginBottom: '14px' }}
        />
      </Section>

      <Section background="primary" height="sm">
        <Testimonial />
      </Section>

      <Section height="sm" background="offWhite">
        <TextCta
          button={<ConfettiButton toggle={toggleCelebrate} state={celebrate} />}
        />
      </Section>
      <Footer />
    </>
    // </CSSTransition>
    // </>
  );
};

export default Landing9;
