import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Section,
  SectionHalves,
  Image,
  Callout,
  NavPadding,
  Headline,
  Paragraph,
  Footer,
} from '../../components';
import Testimonials from './components/Testimonials';
import CtaButton from './components/CtaButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        padding: '0 10px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: 'calc(100vh - 122px)',
        padding: '0 32px 64px 64px',
      },
    },
    center: {
      textAlign: 'center',
    },
    heroImg: {
      padding: '10px 23px 0px 22px !important',
      marginBottom: '-4px',
      [theme.breakpoints.up('md')]: {
        padding: '0px !important',
        marginBottom: '0px',
        '& img': {
          position: 'absolute',
          bottom: '0',
          right: '0',
          width: '57%',
          overflowX: 'hidden',
        },
      },
    },
    button: {
      // margin: '20px 0px 0 0',
      padding: '15px 25px',
      fontSize: '1.1375rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0',
        // borderWidth: '1px',
        // borderStyle: 'solid',
        // borderImageSlice: '1',
        // borderImageSource: `linear-gradient(to right , ${
        //   theme.palette.secondary.dark
        // }, ${theme.palette.secondary.main})`,
        width: '100%',
      },
    },
  }),
);

const Landing1: React.FC = () => {
  const classes = useStyles();

  const ctaButton = <CtaButton />;

  // const ctaButton = {
  //   text: 'Apply Now',
  //   onClick: () => history.push('/survey?version=short'),
  //   icon: <TrophyIcon />,
  // };

  // const twoButtons = (
  //   <>
  //     <Button
  //       variant="contained"
  //       color="secondary"
  //       size="large"
  //       className={classes.button}
  //       onClick={() => history.push('/plans')}
  //       // onClick={() => history.push('/login?redirect=checkout', ['/checkout'])}
  //       startIcon={<AppleIcon />}>
  //       Start Your Free Trial
  //     </Button>

  //     <Button
  //       className={classes.button}
  //       onClick={() => history.push('/android')}
  //       startIcon={<AndroidIcon />}>
  //       {' '}
  //       Looking for Android?
  //     </Button>
  //   </>
  // );

  return (
    <>
      <NavPadding />
      <Grid container spacing={0} className={`${classes.hero}`}>
        <Grid md={5} item>
          <Typography component="h1" variant="h4">
            Tackle mental health as a team.
            {/* Join A Team. Win More Days. */}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {/* Beat your stress and sadness with a team rooting for you along the
            way. */}
            Download the MHL app to track your mood, beat isolation, and manage
            your mental health each and every day
            {/* Download the MHL app to get daily mental health tracking, training,
            and team support for anxiety, depression, ADHD, and bipolar. */}
          </Typography>

          {ctaButton}

          {/* <Button
            variant="contained"
            color="secondary"
            size="large"
            className={classes.button}
            // onClick={() => history.push('/login')}
            onClick={() => {
              window.location.href =
                'https://apps.apple.com/us/app/mhl-mental-health-league/id1479870559';
            }}
            startIcon={<AppleIcon />}>
            Get iOS
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            className={classes.button}
            startIcon={<AndroidIcon />}
            onClick={() => history.push('/android')}>
            Get Android
          </Button> */}
        </Grid>
        <Grid md={6} item className={classes.heroImg}>
          <Image src="hero-vosters" />
          {/* <img
            alt="MHL Training Celebration"
            src={require('../../assets/images/landing/hero-vosters.webp')}
          /> */}
        </Grid>
      </Grid>

      <Callout
        title="Team-Based Mental Health"
        inverse
        body="MHL's app combines sports and clinical psychology to help you overcome your anxiety and depression."
      />

      <Section>
        <SectionHalves
          src="app-scorecard"
          title="Track your mental health each day."
          body="A digital daily journal to track your mental health each day.
        Reflect on key trends and stats, and learn new gameplays to help you
        win each day."
          reverse
        />
      </Section>

      <Section>
        <SectionHalves
          src="app-team"
          title="Stay accountable with your team."
          body={
            <>
              <Typography variant="subtitle1">
                Hear stories of other people managing their mental health, and
                cheer each other on through our social feed.
              </Typography>
              <Typography color="primary">
                <b>What You Get</b>
              </Typography>
              <ul style={{ marginBottom: '60px' }}>
                <Typography component="li" variant="body2">
                  Supportive, curated team
                </Typography>
                <Typography component="li" variant="body2">
                  Daily tracking and stats
                </Typography>
                <Typography component="li" variant="body2">
                  Daily education and training
                </Typography>
                <Typography component="li" variant="body2">
                  A mindset for long-term change
                </Typography>
              </ul>
            </>
          }
          imageStyle={{ marginBottom: '14px' }}
        />
      </Section>

      <Testimonials />

      <Section background="offWhite">
        <Grid container spacing={0} style={{ padding: '0 10px' }}>
          <Grid item xs={12} className={classes.center}>
            <Headline gutterBottom>Try the MHL free for 7 days</Headline>
            <Paragraph gutterBottom>
              We're confident you'll feel more motivated, gain accountability,
              think more positively, and gain a better understanding of how your
              mind works.
            </Paragraph>
            {ctaButton}
          </Grid>
        </Grid>
      </Section>
      <Footer />
    </>
  );
};

export default Landing1;
