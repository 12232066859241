export enum MascotId {
  MHL = 'mhl',
  ADHBees = 'adhbees',
  Anxietees = 'anxietees',
  BipolarBears = 'bipolar_bears',
  FoggyDogs = 'foggy_dogs',
}

export interface Mascot {
  id: MascotId;
  name: string;
  description: string;
  tagline: string;
  logo: string;
  wordmarkLight: string; // React.FC |  ImageSourcePropType;
  wordmarkDark: string; // ImageSourcePropType;
  colorDark: string;
  colorAlt: string;
  colorNeutral: string;
}
