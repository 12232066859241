import React from 'react';
import { Grid, GridProps, Box } from '@material-ui/core';
import { Headline, Paragraph } from '../../../components';

const WhyMhl: React.FC<GridProps> = ({ ...props }) => {
  return (
    <Grid container spacing={4} alignItems="center" {...props}>
      <Grid item sm={12}>
        <Headline gutterBottom>Why MHL?</Headline>
        <Paragraph>
          We built the MHL to bridge the gap between infrequent in-person
          therapies and impersonal apps.
        </Paragraph>
      </Grid>
      <Grid md={4} item>
        <Box mt={1}>
          <Headline variant="h6" gutterBottom>
            Affordable
          </Headline>
          <Paragraph>
            For one fourth of the cost of one therapy session, you get daily
            support from a coaching staff plus a team of new friends along with
            tools and education. It’s the move.
          </Paragraph>
        </Box>
      </Grid>
      <Grid md={4} item>
        <Box mt={1}>
          <Headline variant="h6" gutterBottom>
            Effective
          </Headline>
          <Paragraph>
            95% of our players say they felt more motivated, accountable, and
            educated about their mental health.
          </Paragraph>
        </Box>
      </Grid>
      <Grid md={4} item>
        <Box mt={1}>
          <Headline variant="h6" gutterBottom>
            Sports
          </Headline>
          <Paragraph>
            With the MHL, mental health is a sport. Every day is a game. And
            you’re the star player training to compete against your invisible
            opponents.
          </Paragraph>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WhyMhl;
