import React from 'react';
import { Button, Input, Typography } from '@material-ui/core';
import { Page } from '../components';
import { Android as AndroidIcon } from '@material-ui/icons';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gutterTop: {
      marginTop: '20px',
    },
  }),
);
const AndroidScreen: React.FC = () => {
  const classes = useStyles();
  return (
    <Page>
      <Typography variant="h5">
        <AndroidIcon /> Android Coming Soon
      </Typography>
      <Typography gutterBottom>
        Be the first to know when MHL is released for Android
      </Typography>

      <form
        action="https://mentalhealthleague.us18.list-manage.com/subscribe/post?u=84eb9de52a7d5b0c0347af2aa&amp;id=dd70e8f298"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank">
        <Input
          autoFocus
          placeholder="Enter your email..."
          name="EMAIL"
          id="mce-EMAIL"
          fullWidth
          className={classes.gutterTop}
        />
        <Button
          variant="contained"
          type="submit"
          name="subscribe"
          color="secondary"
          fullWidth
          size="large"
          id="mc-embedded-subscribe"
          className={classes.gutterTop}>
          Join Waitlist
        </Button>
        {/* <div id="mce-responses">
            <div id="mce-error-response" style={{ display: 'none' }} />
            <div id="mce-success-response" style={{ display: 'none' }} />
          </div> */}
      </form>
    </Page>
  );
};

export default AndroidScreen;
