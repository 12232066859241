import React, { useState } from 'react';
import { Page, Box, Button, PageHeading, Carousel } from '../../components';
import Container from '@material-ui/core/Container';
// import { Carousel } from 'react-responsive-carousel';
import { CarouselProvider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import '../../assets/styles/Carousel.scss';
import mascotList from '../../models/MascotList';
import { Mascot } from 'types';

const ChooseTeamScreen: React.FC = () => {
  const mascots: Mascot[] = Object.values(mascotList);
  const [selectedMascot, toggleMascot] = useState<Mascot>(mascots[0]);

  const handleToggle = (idx: number): void => {
    toggleMascot(mascots[idx]);
  };

  return (
    <Page fullPage maxWidth="xl" disableGutters>
      <PageHeading text="Select Mascot" center />

      <CarouselProvider
        naturalSlideWidth={755}
        naturalSlideHeight={788}
        totalSlides={5}
        isIntrinsicHeight>
        <Carousel items={mascots} setState={handleToggle} />
      </CarouselProvider>

      <Container maxWidth="sm">
        <Box mx={1}>
          <Button fullWidth text="Select" />
        </Box>
      </Container>
    </Page>
  );
};

export default ChooseTeamScreen;
