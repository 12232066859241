// import { Scorecard } from './scorecard';
import StreamClientService from './StreamClientService';
import { Player } from 'types';
import { buildLogger } from './LoggerService';
import { buildTracker } from './TrackerService';
import CoachesNotes from './CoachesNotesService';
import UpdatePlayerService from './UpdatePlayerService';
import AnalyticsService from './AnalyticsService';
import { buildFirestore } from './FirebaseService';
// import { buildNavigationService } from './navigationService';
// import { Navigator } from './interfaces';
import ScheduledPosts from './ScheduledPosts';
import DiscussionService from './DiscussionService';
// import GamePlan from './GamePlan';
import RemoteConfig from './RemoteConfig';
import ArticleService from './ArticleService';
import ArticleFeaturesService from './ArticleFeaturesService';
// import SignUp from './SignUpService';
import UpdateProfileService from './UpdateProfileService';
import Survey from './SurveysService';
import Games from './GamesService';
import GamePlanService from './GamePlanService';
import FirestoreService from './FirestoreService';

interface DependencyDescriptor {
  dependencies: string[];
  factory?: (...args: any) => any;
  class?: new (...args: any) => any;
}

export let dependenciesInstances: { [key: string]: any } = {};

const BASE_SERVICE_DEPENDENCIES = [
  'currentPlayer',
  'streamClient',
  'logger',
  'tracker',
  'updatePlayerService',
  'firestore',
];

const dependenciesDescriptors = {
  firestore: {
    factory: buildFirestore,
    dependencies: [],
  },
  streamClient: {
    factory: StreamClientService,
    dependencies: ['currentPlayer'],
  },
  logger: {
    factory: buildLogger,
    dependencies: [],
  },
  tracker: {
    factory: buildTracker,
    dependencies: [],
  },
  updatePlayerService: {
    class: UpdatePlayerService,
    dependencies: ['currentPlayer', 'firestore'],
  },
  firestoreService: {
    class: FirestoreService,
    dependencies: BASE_SERVICE_DEPENDENCIES,
  },
  //   scorecardService: {
  //     class: Scorecard,
  //     dependencies: BASE_SERVICE_DEPENDENCIES,
  //   },
  //   navigationService: {
  //     factory: buildNavigationService,
  //     dependencies: ['navigator'],
  //   },
  scheduledPosts: {
    class: ScheduledPosts,
    dependencies: BASE_SERVICE_DEPENDENCIES,
  },
  discussionService: {
    class: DiscussionService,
    dependencies: ['firestore', 'currentPlayer', 'logger', 'streamClient'],
  },
  gameplanService: {
    class: GamePlanService,
    dependencies: ['firestore', 'logger'],
  },
  remoteConfig: {
    class: RemoteConfig,
    dependencies: BASE_SERVICE_DEPENDENCIES,
  },
  profileService: {
    class: UpdateProfileService,
    dependencies: ['updatePlayerService'],
  },
  articleService: {
    class: ArticleService,
    dependencies: ['firestore'],
  },
  articleFeaturesService: {
    class: ArticleFeaturesService,
    dependencies: ['firestore', 'logger'],
  },
  surveysService: {
    class: Survey,
    dependencies: ['remoteConfig'],
  },
  gamesService: {
    class: Games,
    dependencies: ['logger'],
  },
  coachesNotes: {
    class: CoachesNotes,
    dependencies: BASE_SERVICE_DEPENDENCIES,
  },
  analyticsService: {
    class: AnalyticsService,
    dependencies: [...BASE_SERVICE_DEPENDENCIES, 'firestoreService'],
  },
};
export type DependencyName = keyof typeof dependenciesDescriptors;
type DescriptorMap = { [key in DependencyName]: DependencyDescriptor };

const buildDependency = <T>(
  name: DependencyName,
  getDependency: <T>(name: DependencyName) => T,
): T => {
  const dependencyDescriptor = (dependenciesDescriptors as DescriptorMap)[name];

  if (!dependencyDescriptor)
    throw new Error(
      `Missing dependency descriptor for: ${dependencyDescriptor}. Forgot to include it in dependencies's container?`,
    );

  const constructorParams: { [key: string]: any } = {};
  for (const dependencyName of dependencyDescriptor.dependencies) {
    const dependencyInstance = getDependency(dependencyName as DependencyName);
    if (!dependencyInstance)
      throw new Error(
        `Missing dependency instance for: ${dependencyName}. Check order in dependencies descriptors`,
      );
    constructorParams[dependencyName] = dependencyInstance;
  }

  let instance;
  if (dependencyDescriptor.factory) {
    instance = dependencyDescriptor.factory(constructorParams);
  } else if (dependencyDescriptor.class) {
    instance = new dependencyDescriptor.class(constructorParams);
  }
  dependenciesInstances[name] = instance;
  return instance;
};

const getOrCreateDependency = <T>(name: DependencyName): T => {
  let dependencyInstance = dependenciesInstances[name];
  if (!dependencyInstance) {
    dependencyInstance = dependenciesInstances[name] = buildDependency(
      name,
      getOrCreateDependency,
    );
  }
  return dependencyInstance;
};

type Params = {
  currentPlayer?: Player;
  // navigator?: Navigator;
};

export interface Container {
  getInstance<T>(name: DependencyName): T;
}

export function buildContainer({ currentPlayer }: Params): Container {
  dependenciesInstances = {};
  dependenciesInstances.currentPlayer = currentPlayer;
  // dependenciesInstances.navigator = navigator;
  return {
    getInstance<T>(name: DependencyName): T {
      return getOrCreateDependency(name);
    },
  };
}
