import React from 'react';
import { Typography } from '@material-ui/core';
import { Section, SectionHalves, NavPadding, Footer } from '../../components';
import Testimonial from './components/Testimonial';
import Testimonials from './components/Testimonials';
import TextCta from './components/TextCta';
import CtaButton from './components/CtaButton';

const Landing5: React.FC = () => {
  const ctaButton = <CtaButton />;

  return (
    <>
      <NavPadding />
      <Section height="lg">
        <SectionHalves
          src="hero-vosters"
          title="Join the MHL Men's League"
          body="Get the accountability and motivation you need to tackle your mental health with daily tracking, training, and team support"
          reverseAll
          button={ctaButton}
        />
      </Section>
      <Section background="primary" height="sm">
        <Testimonial />
      </Section>

      <Section>
        <SectionHalves
          src="app-scorecard"
          title="Track your mental health each day."
          body="A digital daily journal to track your mental health each day.
        Reflect on key trends and stats, and learn new gameplays to help you
        win each day."
          reverse
        />
      </Section>

      <Section>
        <SectionHalves
          src="app-team"
          title="Stay accountable with your team."
          body={
            <>
              <Typography variant="subtitle1">
                Hear stories of other people managing their mental health, and
                cheer each other on through our social feed.
              </Typography>
              <Typography color="primary">
                <b>What You Get</b>
              </Typography>
              <ul style={{ marginBottom: '60px' }}>
                <Typography component="li" variant="body2">
                  Supportive, curated team
                </Typography>
                <Typography component="li" variant="body2">
                  Daily tracking and stats
                </Typography>
                <Typography component="li" variant="body2">
                  Daily education and training
                </Typography>
                <Typography component="li" variant="body2">
                  A mindset for long-term change
                </Typography>
              </ul>
            </>
          }
          imageStyle={{ marginBottom: '14px' }}
        />
      </Section>

      <Testimonials />

      <Section height="sm" background="offWhite">
        <TextCta button={ctaButton} />
      </Section>
      <Footer />
    </>
  );
};

export default Landing5;
