import React, { useState, useEffect } from 'react';
import { useMutation, useSubscription, useContainer } from '../../hooks';
import { CoachesNote } from 'types';
import { TextField, Spinner } from '../../components';
import {
  Avatar,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { CoachesNotesService } from '../../services';
import { coachesNoteQuery } from '../../services/CoachesNotesService';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

interface Props {
  userId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
  }),
);

const CoachesNotesTab: React.FC<Props> = ({ userId }) => {
  const classes = useStyles();
  const container = useContainer();
  const coachesNotesService = container.getInstance<CoachesNotesService>(
    'coachesNotes',
  );
  const { loading: notesLoading, data } = useSubscription<CoachesNote[]>(
    coachesNoteQuery(userId, coachesNotesService),
  );

  const [content, setContent] = useState('');
  const [id, setId] = useState<string>('');
  const [toDelete, setToDelete] = useState<undefined | string>();

  const { loading: adding, error: addError, mutate: addMutate } = useMutation(
    () =>
      coachesNotesService.updateOrCreateNote({
        id,
        content,
        updatedAt: moment(),
        userId,
      }),
  );

  const { mutate: deleteMutate, loading: deleting } = useMutation(() =>
    coachesNotesService.deleteNote(userId, toDelete ? toDelete : ''),
  );

  const handleAdd = (): void => {
    addMutate();
    setContent('');
    if (id) {
      setId('');
    }
  };

  const handleEdit = (editId: string, editContent: string): void => {
    setContent(editContent);
    setId(editId);
  };

  useEffect(
    function deleteSelectedNote() {
      if (toDelete) {
        deleteMutate();
        setToDelete(undefined);
      }
    },
    [toDelete, deleteMutate],
  );

  return notesLoading ? (
    <Spinner />
  ) : (
    <>
      <Grid container justify="space-around" spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="filled-multiline-static"
            label={!id ? 'Write Note' : 'Edit Note'}
            multiline
            fullWidth
            rows={3}
            value={content}
            onChange={event => setContent(event.target.value)}
            onKeyPress={handleAdd}
            disabled={adding}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            disabled={notesLoading}
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            // loading={noteAddLoading}
            onClick={handleAdd}>
            {!id ? 'Add Note' : 'Edit Note'}
          </Button>
          {addError && <Typography>{addError} </Typography>}
        </Grid>
      </Grid>
      <List className={classes.root}>
        {data &&
          data.map(note => (
            <div key={note.id}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" />
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary">
                        {note.coachName}
                      </Typography>
                    </>
                  }
                  secondary={
                    <>
                      <Typography>{note.content}</Typography>

                      <Button
                        disabled={deleting}
                        onClick={(): void => {
                          handleEdit(note.id, note.content);
                        }}>
                        Edit
                      </Button>
                      <Button
                        disabled={deleting}
                        onClick={(): void => {
                          setToDelete(note.id);
                        }}>
                        Delete
                      </Button>
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          ))}
      </List>
    </>
  );
};

export default CoachesNotesTab;
