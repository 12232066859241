import React from 'react';
import {
  Avatar,
  Divider,
  ListItemAvatar,
  Typography,
  ListItem,
  ListItemText,
  Chip,
} from '@material-ui/core';
import { Article } from 'types';

interface PlayerItemLinkProps {
  article: Article;
  to: string;
  selectArticle(article: Article): void;
}

const ArticleItemLink: React.FC<PlayerItemLinkProps> = ({
  article,
  to,
  selectArticle,
}) => {
  return (
    <>
      <ListItem
        alignItems="flex-start"
        button
        // component={renderLink}
        onClick={() => selectArticle(article)}>
        <ListItemAvatar>
          {article.pages && article.pages[0].imageURL ? (
            <Avatar alt={article.slug} src={article.pages[0].imageURL} />
          ) : (
            <Avatar />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={article.slug ? article.slug : 'Slug N/A'}
          secondary={
            <>
              <Typography
                component="span"
                variant="body2"
                color="textSecondary">
                {article.author}
              </Typography>
              {!!article && !!article.status && <Chip label={article.status} />}
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default ArticleItemLink;
