import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from '../base';
import { Box, CardContent, Collapse, Typography } from '@material-ui/core';
import { Button, TextField, Card } from './';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import logger from '../services/LoggerService';
import { fireFbEvent, fireGaEvent } from '../services/AnalyticsService';
import { useLead } from '../hooks';
import { ErrorMessage } from '../components';

type Props = {
  redirect?: string | null | undefined;
  raised?: boolean;
};

const LoginCustom: React.FC<Props> = ({ redirect, raised }) => {
  const history = useHistory();
  const [auths, setAuths] = useState<null | string[]>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const minBirthday = moment()
    .subtract(13, 'years')
    .toDate();
  const [birthday, setBirthday] = useState<Date>(minBirthday);

  const handleCheckEmail = async () => {
    setLoading(true);
    const existingLogins = await firebase
      .auth()
      .fetchSignInMethodsForEmail(email);

    setAuths(existingLogins);
    setLoading(false);
  };

  const handleLogin = useCallback(async () => {
    setLoading(true);
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(currentUser => {
          try {
            if (redirect) {
              history.push(redirect);
            } else {
              // Show regular user UI.
              history.push('/dashboard');
            }
            // Need to fire signup event for GA
            // tracker.track(EventName.SignUp);
            // currentUser.user?.getIdTokenResult().then(idTokenResult => {
            //   // Confirm the user is an Admin.
            //   if (redirect) {
            //     history.push(redirect);
            //   } else if (idTokenResult.claims.role === 'admin') {
            //     // Show admin UI.
            //     history.push('/team');
            //   } else {
            //     // Show regular user UI.
            //     history.push('/account');
            //   }
            // })
          } catch (err) {
            logger.error(`TOKEN ERR - ${err}`);
          }
        });
    } catch (err) {
      if (err.code === 'auth/wrong-password') {
        setError(err.message);
      } else {
        setError(err.message);
      }
      logger.error(`Unable to Signup from website – ${err.message}`);
      setLoading(false);
    }
  }, [history, email, password]);

  const handleSignup = useCallback(async () => {
    setLoading(true);

    if (password.length < 7) {
      setError('Password must be at least 7 characters long.');
      setLoading(false);
      return;
    }

    if (birthday > minBirthday) {
      setError('You are not old enough to join the MHL.');
      setEmail('');
      setPassword('');
      setAuths(null);
      setLoading(false);
      return;
    }

    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      // await sessionStorage.setItem('mhlBday', moment(birthday).format('YYYY-MM-DD'));

      fireFbEvent('CompleteRegistration', {
        value: '0.00',
        currency: 'USD',
      });

      fireGaEvent('User', 'Create Account');

      history.push(redirect ? redirect : '/dashboard');
    } catch (err) {
      logger.error(`Unable to Signup from website – ${err}`);
      setError(err);
      setLoading(false);
    }
  }, [history, email, password, birthday, minBirthday, redirect]);

  let handleStep: any = handleCheckEmail;
  let headline = 'Enter email...';
  // let step: string = 'verifyEmail';

  if (auths && auths.length === 0) {
    // step = 'signup';
    handleStep = handleSignup;
    headline = 'Create Account';
  } else if (auths && auths.length > 0 && password) {
    // step = 'login';
    handleStep = handleLogin;
  } else if (auths) {
    headline = 'Login';
  }

  // Use lead email if it exists
  const { lead } = useLead();
  useEffect(() => {
    if (lead) {
      setEmail(lead);
    }
  }, []);

  return (
    <Card padding elevation={raised === false ? 0 : 3}>
      <CardContent>
        <Typography align="center" variant="h5" gutterBottom>
          {headline}
        </Typography>
        <TextField
          id="email-input"
          gutterTop={1}
          autoFocus
          label="Email"
          type="email"
          box={'mt={5}'}
          value={email}
          onKeyPress={handleStep}
          changeState={setEmail}
        />
        <Collapse in={!!auths} timeout="auto">
          {auths && (
            <TextField
              gutterTop={3}
              id="password-input"
              autoFocus
              label="Password"
              type="password"
              value={password}
              autoComplete="current-password"
              onKeyPress={handleStep}
              onChange={e => setPassword(e.target.value && e.target.value)}
            />
          )}

          {/* Display birthday to users who do not have an account */}
          {auths && auths.length === 0 && (
            <Box mt={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  fullWidth
                  disableFuture
                  openTo="year"
                  format="MM/DD/yyyy"
                  label="Birthday"
                  views={['year', 'month', 'date']}
                  value={birthday}
                  onChange={event => event && setBirthday(event.toDate())}
                />
              </MuiPickersUtilsProvider>
            </Box>
          )}
        </Collapse>
        <Box mt={3}>
          <Button
            fullWidth
            size="large"
            disabled={!email}
            loading={loading}
            variant="contained"
            onClick={handleStep}
            color="secondary"
            type="submit">
            Submit
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => history.push('/forgot-password')}
            variant="text"
            size="small"
            style={{ color: '#999' }}
            fullWidth
            text="Forgot password?"
          />
        </Box>

        {error && (
          <Box mt={2}>
            <ErrorMessage error={error} />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default LoginCustom;
