import { NavigationAction } from 'react-navigation';
import { SurveyAnswers, Team, GamePlanTemplate } from 'types';
import { EditablePlayerFields } from '../models/Player';
import Scorecard from '../models/Scorecard';
import UserProfile from '../models/UserProfile';
import defaultValues from './RemoteConfig/defaultValues';
import NewAccount from '../models/NewAccount';
import { ActivityData } from 'getstream';
import GameCollection from '../models/GameCollection';
import {
  Article,
  FeaturedType,
  FeaturesObj,
  ArticlesObj,
  IdDoc,
  Collections,
  ReflectionRecord,
  ReflectionExercise,
  Reflection,
} from 'types';
import firebase, { firestore } from 'firebase/app';

export type FbUser = firebase.User;
export type DocumentReference = firestore.DocumentReference;
export type DocumentSnapshot = firestore.DocumentSnapshot;
export type DocumentData = firestore.DocumentData;
export type Query = firestore.Query<DocumentData>;
export type QueryDocumentSnapshot = firestore.QueryDocumentSnapshot[];
export type CollectionReference<T> = firestore.CollectionReference<T>;
export type QuerySnapshot<T> = firestore.QuerySnapshot<T>;

export type SubscriptionListener<T> = (data: T) => void;
export type Unsubscriber = () => void;

export interface FirestoreServiceType {
  addOrUpdateDoc<T extends IdDoc>(
    collection: Collections,
    doc: T,
  ): Promise<void>;
  listenToCollection<T>(
    listener: SubscriptionListener<T | null>,
    collection: string | Query,
    type?: 'array' | 'object',
  ): Unsubscriber;
}

export type RecapObj = {
  [key: string]: Recap;
};

export interface ScorecardService {
  addOrUpdateScorecard(
    scorecard: Scorecard,
    shareWithTeam: boolean,
  ): Promise<void>;
  toggleActivityVisibility(activityData: ActivityData): Promise<void>;
}

export interface NavigationService {
  navigate(route: string, params?: { [key: string]: any }): void;
  goBack(): void;
}

export type IconGroup = {
  title: string;
  icons: string[];
};

export type Page = {
  body: string;
  imageUrl: string;
  next_btn: string;
  title: string;
};

export type PlaysObj = { [key: string]: ScorecardPlay };
export interface ArticleServiceType {
  importArticles(articles: {
    [key: string]: { [key: string]: any };
  }): Promise<void>;
  getAll(): Promise<ArticlesObj>;
  getArticle(articleId: string): Promise<Article>;
  listenToArticles(
    listener: SubscriptionListener<ArticlesObj | null>,
  ): Unsubscriber;
  deleteArticle(articleId?: string): Promise<void>;
  updateArticle(articleId: string, updates: Partial<Article>): Promise<void>;
  addOrEditArticle(article: Article): Promise<void>;
}

export type FeaturedUpdate = Pick<FeaturedType, 'date' | 'articles'>;

export interface ArticleFeaturesServiceType {
  // getFeatures(): Promise<Article[]>;
  listenToFeatures(
    listener: SubscriptionListener<FeaturesObj | null>,
  ): Unsubscriber;
  deleteFeature(articleId?: string): Promise<void>;
  updateFeature(articleId: string, updates: Partial<Article>): Promise<void>;
  addOrEditFeature(feature: FeaturedUpdate): Promise<void>;
}

export interface Navigator {
  dispatch(action: NavigationAction): void;
}

export type ScheduledPostPayload = {
  id?: string;
  date: Date;
  time: Date;
  content: string;
  teamId: string;
  userId?: string;
  activity?: ActivityData;
  status?: 'pending' | 'success';
};

export type ScheduledPost = {
  id: string;
  runAt: Date;
  status: 'pending' | 'success' | 'error';
  userId: string;
  teamId: string;
  post: {
    content: string;
  };
  activity?: ActivityData;
};

export interface ScheduledPostsService {
  updateOrCreatePost(scheduledPost: ScheduledPostPayload): Promise<void>;
  findAll(teamId?: string): Promise<ScheduledPost[]>;
  delete(postId?: string): Promise<void>;
  subscribeToAll(
    listener: SubscriptionListener<any[]>,
    opts: { teamId: string },
  ): Unsubscriber;
}

export type Discussion = {
  id: string;
  userId: string;
  teamId: string;
  hidden: boolean;
  status: 'pending' | 'success' | 'error';
  post: {
    content: string;
  };
  activity: ActivityData;
  updatedAt: Date;
  createdAt: Date;
};
export interface DiscussionServiceType {
  query: firebase.firestore.Query;
  get(id: string): Promise<Discussion>;
  createInitialDoc(item: string): Promise<Omit<Discussion, 'activity'>>;
  create(item: string): Promise<Discussion>;
  addToStream(item: Omit<Discussion, 'activity'>): Promise<stream.ActivityData>;
  update(item: Partial<Discussion>): Promise<Discussion>;
  getAll(): Promise<Discussion[]>;
  delete(id?: string): Promise<void>;
}

export interface RecapServiceType {
  saveRecap(recap: ReflectionRecord): Promise<void>;
  getWeeklyRecap(isoWeek: number): Promise<Recap>;
  getReflection(id: string): Promise<ReflectionExercise>;
  getAll(): Promise<ReflectionExercise[]>;
}
export interface GamePlanServiceType {
  query: firebase.firestore.Query;
  getAll(): Promise<GamePlanTemplate[]>;
  create(gameplan: GamePlanTemplate): Promise<void>;
  edit(gameplan: GamePlanTemplate): Promise<void>;
}

export type RemoteConfigValues = keyof typeof defaultValues;
export interface RemoteConfigService {
  getValue<T>(valueName: RemoteConfigValues): Promise<T>;
}

export enum EventName {
  Login = 'login',
  SignUp = 'sign_up',
  PasswordReset = 'password_reset',
  AddScorecard = 'add_scorecard',
  AddComment = 'add_comment',
  AddCommentReaction = 'add_comment_reaction',
  RemoveCommentReaction = 'remove_comment_reaction',
  DeleteComment = 'delete_comment',
  ToggleCheer = 'toggle_cheer',
  RateArticle = 'rate_article',
}

export interface TrackingService {
  track(name: EventName, params?: { [key: string]: any }): void;
}

export interface PlayerService {
  perform(id: string, fields: EditablePlayerFields): Promise<void>;
  updateCurrentPlayer(fields: EditablePlayerFields): Promise<void>;
  joinToTeam(params: { answers?: SurveyAnswers; team?: Team }): Promise<void>;
}

export interface ProfileService {
  perform(profile: UserProfile): Promise<void>;
}

export interface SignUpService {
  perform(account: NewAccount): Promise<void>;
}

export interface ScorecardPlay {
  id: string;
  name: string;
  icon: string;
  enabled: boolean;
}

export type AnswerOpt = string | string[] | number | number[];

export type Answer = {
  [key: string]: AnswerOpt;
};

export type Recap = {
  date: string;
  surveyId: string;
  answers: Answer;
  createdAt: Date;
  updatedAt: Date;
  shared: 'private' | 'coach';
};

// export interface RecapServiceType {
//   saveRecap(recap: Recap): Promise<void>;
// }

export interface StreamPostServiceType {
  savePost(recap: Recap): Promise<void>;
}

export type Surveys = typeof defaultValues.surveys;
export interface SurveysService {
  getAnswersForSurvey(
    userId: string,
  ): Promise<
    { [key in keyof Surveys]: { name: string; answers: SurveyAnswers } } | {}
  >;
  saveSurvey(
    surveyTitle: string,
    fields: { [key: string]: any },
  ): Promise<void>;
  saveLead(fields: { [key: string]: any }): Promise<void>;
}

export interface GamesService {
  getPlayerGamesAndStats(
    userId: string,
    month: string,
  ): Promise<GameCollection>;
}

export type CoachesNotePayload = {
  id: string;
  content: string;
  updatedAt: moment.Moment;
  userId: string;
};

export type CoachesNote = {
  id: string;
  coachId: string;
  coachName: string;
  content: string;
  updatedAt: moment.Moment;
  userId: string;
};
export interface CoachesNotesService {
  getCoachesNotes(userId: string): Promise<CoachesNote[]>;
  updateOrCreateNote(note: CoachesNotePayload): Promise<void>;
  deleteNote(userId: string, noteId: string): Promise<void>;
  subscribeToCoachesNotes(
    listener: SubscriptionListener<CoachesNote[]>,
    userId: { userId: string },
  ): () => void;
}
