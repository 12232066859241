// import firebase from '../../base';
// import functions from '@react-native-firebase/functions';
// import BaseService, { BaseServiceContructorOptions } from '../BaseService';
import Scorecard from '../../models/Scorecard';
// import Season from '../../models/Season';
import { ApplicationError } from '../../models/Errors';
import FirebaseScorecard from '../../models/FirebaseScorecard';
// import { scorecardCollection } from './collection';
// import { EventName } from '../interfaces';
import { CollectionEntry, ActivityData } from 'react-activity-feed';
// import AsyncStorage from 'src/services/scorecard/node_modules/@react-native-community/async-storage';
import firebase from 'firebase/app';

// class ScorecardService extends BaseService {
class ScorecardService {
  // constructor(options: BaseServiceContructorOptions) {
  // super(options);
  // }

  private async addScorecard(
    newScorecard: FirebaseScorecard,
    scorecard: Scorecard,
    scorecardDoc: firebase.firestore.DocumentSnapshot,
    shareWithTeam: boolean,
  ): Promise<void> {
    try {
      await scorecardDoc.ref.set(newScorecard);
      if (shareWithTeam) {
        await this.addScorecardToStream(scorecardDoc, newScorecard);
      }
      await this.updateFirstScorecardDate(scorecard);
      await this.updateFirstScorecardPlayDate(scorecard);
      // this.tracker.track(EventName.AddScorecard);
    } catch (error) {
      // this.logger.error(`Failed to save scorecard: ${error}`);
      throw new ApplicationError('Unable to save scorecard');
    }
  }

  private async updateScorecard(
    newScorecard: FirebaseScorecard,
    scorecardDoc: firebase.firestore.DocumentSnapshot,
    shareWithTeam: boolean,
  ): Promise<void> {
    try {
      await scorecardDoc.ref.set(newScorecard, { merge: true });
    } catch (error) {
      // this.logger.error(`Failed to update scorecard: ${error}`);
      return Promise.reject(
        new ApplicationError('Unable to update personal scorecard'),
      );
    }
    if (shareWithTeam) {
      // const streamId = scorecardDoc.ref.path.replace(/\//g, '-');
      // try {
      //   await this.streamClient.collections.update(
      //     'scorecard',
      //     streamId,
      //     newScorecard,
      //   );
      // } catch (error) {
      //   this.logger.error(`Failed to update scorecard activity: ${error}`);
      //   return Promise.reject(
      //     new ApplicationError('Unable to update team scorecard'),
      //   );
      // }
      // CHECK FOR TRIGGER WORDS AND HIDE IF TRUE
      // Doesn't work, either need activity id or the activity's time to partial update
      // const shouldHide = newScorecard.flagged;
      // if (shouldHide) {
      //   console.log('TRIGGER CLOUD HIDE ACTIVITY', shouldHide);
      //   this.hideFlaggedActivity(streamId);
      // }
    }
  }

  private async updateFirstScorecardDate(scorecard: Scorecard): Promise<void> {
    // if (this.currentPlayer.firstScorecardOn) {
    //   return;
    // }
    // try {
    //   const playerUpdates = { firstScorecardOn: scorecard.date };
    //   await this.updatePlayerService.perform(
    //     this.currentPlayer.id,
    //     playerUpdates,
    //   );
    // } catch (error) {
    //   this.logger.warn(
    //     `Failed to update player's firstScorecardOn date: ${error}`,
    //   );
    // }
  }

  private async updateFirstScorecardPlayDate(
    scorecard: Scorecard,
  ): Promise<void> {
    // const currentPlayOnDateIsBefore =
    //   this.currentPlayer.firstScorecardPlayOn &&
    //   this.currentPlayer.firstScorecardPlayOn.isSameOrBefore(scorecard.date);
    // if (currentPlayOnDateIsBefore) {
    //   return;
    // }
    // try {
    //   const playerUpdates = { firstScorecardPlayOn: scorecard.date };
    //   await this.updatePlayerService.perform(
    //     this.currentPlayer.id,
    //     playerUpdates,
    //   );
    // } catch (error) {
    //   this.logger.warn(
    //     `Failed to update player's firstScorecardPlayOn date: ${error}`,
    //   );
    // }
  }

  private async addScorecardToStream(
    scorecardDoc: firebase.firestore.DocumentSnapshot,
    newScorecard: FirebaseScorecard,
  ): Promise<void> {
    // const collection = await this.addScorecardToStreamCollection(
    //   scorecardDoc,
    //   newScorecard,
    // );
    // await this.addScorecardActivity(collection, newScorecard);
  }

  private isTrigger = async (
    scorecard: FirebaseScorecard | Scorecard,
  ): Promise<boolean> => {
    // Be cafe with apostrophes, use ’ not '
    let triggerWords = [
      'can’t do this',
      'can’t take this',
      'kill myself',
      'i’m done',
      'i’m over it',
      'end it all',
      'want to die',
      'want this to be over',
      'want it to stop',
      'cutting',
      'goodbye',
      'gun',
      'won’t be back',
      'want it to end',
      'cutting',
      'suicide',
    ];

    // Get remoteConfig words from Local Storage that was saved on SeasonScreen
    try {
      const wordsStringified = await localStorage.getItem('triggerWords');
      if (wordsStringified) {
        triggerWords = JSON.parse(wordsStringified);
      }
    } catch (err) {
      // this.logger.error(
      //   `Failed to get trigger words from async storage: ${err}`,
      // );
    }

    let shouldHide = false;
    let idx = 0;
    while (scorecard.why && shouldHide === false && idx < triggerWords.length) {
      shouldHide = scorecard.why.toLowerCase().includes(triggerWords[idx]);
      idx++;
    }

    return shouldHide;
  };

  private async addScorecardActivity(
    collection: CollectionEntry,
    newScorecard: FirebaseScorecard,
  ): Promise<void> {
    try {
      // const streamUser = this.streamClient.user(this.currentPlayer.id);
      // const shouldHide = newScorecard.flagged;
      // const activity: stream.NewActivity = {
      //   actor: streamUser,
      //   verb: 'post',
      //   object: collection,
      //   foreign_id: collection.id, // eslint-disable-line @typescript-eslint/camelcase
      //   time: new Date(newScorecard.createdAt).toISOString(),
      //   hidden: shouldHide,
      // };
      // await this.streamClient
      //   .feed(
      //     'team',
      //     this.currentPlayer.team.id,
      //     this.currentPlayer.streamToken,
      //   )
      //   .addActivity(activity);
    } catch (error) {
      // this.logger.error(`Failed to save scorecard activity: ${error}`);
      throw new ApplicationError('Unable to post scorecard to feed');
    }
  }

  private async addScorecardToStreamCollection(
    scorecardDoc: firebase.firestore.DocumentSnapshot,
    newScorecard: FirebaseScorecard,
    // ): Promise<CollectionEntry> {
  ): Promise<void> {
    // const streamId = scorecardDoc.ref.path.replace(/\//g, '-');
    // let collectionObject: stream.CollectionEntry;
    try {
      // collectionObject = await this.streamClient.collections.add(
      //   'scorecard',
      //   streamId,
      //   newScorecard,
      // );
    } catch (error) {
      // Try to handle the case where the object already exists
      // TODO: Ideally we could cast this error to a StreamApiError and test that
      if (error.error && error.error.status_code === 409) {
        try {
          // collectionObject = await this.streamClient.collections.update(
          //   'scorecard',
          //   streamId,
          //   newScorecard,
          // );
        } catch (innerError) {
          // this.logger.error(
          //   `Failed to update collection object '${streamId}' after failed save: ${innerError}`,
          // );
          throw new ApplicationError('Unable to save team scorecard');
        }
      } else {
        // this.logger.error(
        //   `Failed to save collection object '${streamId}': ${error}`,
        // );
        throw new ApplicationError('Unable to save team scorecard');
      }
    }
    // return collectionObject;
  }

  public async addOrUpdateScorecard(
    scorecard: Scorecard,
    shareEnabled = true,
    // ): Promise<FirebaseScorecard> {
  ): Promise<void> {
    // const season = Season.forDate(scorecard.date);
    // const scorecardDocument = await scorecardCollection(
    //   this.currentPlayer.id,
    //   season,
    // )
    //   .doc(scorecard.date.format('DD'))
    //   .get();
    // const shareWithTeam = shareEnabled && !!this.currentPlayer.team;
    // Store for analytics, to analyze user sharing preferences, still need to add further option for "required to share"
    // const sharedStatus = !this.currentPlayer.team
    //   ? 'false - Not on team'
    //   : shareWithTeam.toString();
    // const shouldHide = await this.isTrigger(scorecard);
    // const newScorecard: FirebaseScorecard = {
    //   date: scorecard.date.format('YYYY-MM-DD'),
    //   result: scorecard.result,
    //   why: scorecard.why,
    //   plays: scorecard.plays,
    //   shared: sharedStatus,
    //   flagged: shouldHide,
    //   createdAt: Date.now(),
    //   updatedAt: Date.now(),
    // };
    // if (scorecardDocument.exists) {
    //   await this.updateScorecard(
    //     newScorecard,
    //     scorecardDocument,
    //     shareWithTeam,
    //   );
    // } else {
    //   await this.addScorecard(
    //     newScorecard,
    //     scorecard,
    //     scorecardDocument,
    //     shareWithTeam,
    //   );
    // }
    // return newScorecard;
  }

  public toggleActivityVisibility = async (
    activityData: ActivityData,
  ): Promise<void> => {
    await firebase.functions().httpsCallable('onToggleActivityVisibility')(
      activityData,
    );
  };

  // public hideFlaggedActivity = async (foreignId: string): Promise<void> => {
  //   await functions().httpsCallable('onHideFlaggedActivity')(foreignId);
  // };
}

export default ScorecardService;
