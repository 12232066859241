import React, { useState, useEffect } from 'react';
import {
  Page,
  TextField,
  Button,
  ErrorMessage,
  RichEditor,
  ToggleButton,
} from '../../components';
import { Typography, Box } from '@material-ui/core';
import logger from '../../services/LoggerService';
import { Article, Page as PageType, PublishStatus } from 'types';
import { useContainer, useHistory, useLocation } from '../../hooks';
import { ArticleServiceType } from '../../services/interfaces';
import { slugify } from '../helpers';
import { PublishStatusSelect } from '../forms';

type Props = {
  setCurrentArticle: React.Dispatch<Article | undefined>;
  handleDelete(articleId: string): void;
};

const defaultPage: PageType = {
  body: '',
  imageURL: '',
  title: '',
  next_btn: 'Got it!',
};

const defaultArticle: Article = {
  id: '',
  slug: '',
  rating: true,
  challengeEnabled: false,
  pages: [defaultPage],
  commentsEnabled: true,
  sharingEnabled: true,
  status: PublishStatus.Published,
};

// Functions for creation and editing articles.
const ArticleCreateScreen: React.FC<Props> = ({
  setCurrentArticle,
  handleDelete,
}) => {
  const history = useHistory();
  // const { setContent } = useContent();
  // If editing, article will be passed in via router state
  // const { state: prop }: { state: { article: Article } } = useLocation();
  const { state: prop } = useLocation<{ article: Article }>();
  const editing = prop && prop.article ? prop.article : null;

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState<Article>(
    editing ? editing : defaultArticle,
  );

  const updateState = (update: Partial<Article>) => {
    const newState: Article = { ...state, ...update };

    setState(newState);
  };

  const updatePage = (idx: number, update: Partial<PageType>) => {
    // Copy State
    const newState: Article = { ...state };

    // Update page
    newState.pages[idx] = { ...newState.pages[idx], ...update };

    // Update state
    setState(newState);
  };

  // Auto convert titles into url-friendly slugs
  useEffect(() => {
    if (!editing) {
      const slugger = slugify(state.pages[0].title);
      setState({ ...state, slug: slugger, id: slugger });
    }
  }, [state.pages[0].title]);

  // Lazy hack to get user profile pics inserted for authors
  useEffect(() => {
    if (state.author) {
      if (state.author.includes('Vosters')) {
        setState({
          ...state,
          authorImg:
            'https://firebasestorage.googleapis.com/v0/b/mhl-production.appspot.com/o/users-avatars%2FO98NVsMlDaTGFg6JWilJyAXvzMn1?alt=media&token=f8f9505a-0809-4ee6-9776-9e386b98e8a8',
        });
      }

      if (state.author.includes('Wade')) {
        setState({
          ...state,
          authorImg:
            'https://firebasestorage.googleapis.com/v0/b/mhl-production.appspot.com/o/misc%2Fwade-prof-pic.jpg?alt=media&token=d79faa93-b5e9-44a9-97f9-ff3010b4953e',
        });
      }

      if (state.author.includes('Lia')) {
        setState({
          ...state,
          authorImg:
            'https://firebasestorage.googleapis.com/v0/b/mhl-production.appspot.com/o/users-avatars%2FfZcHv9XIbSS9ULE4n9rNShMOZfV2?alt=media&token=45b8ee09-6d55-4089-bc99-ea00e713aefc',
        });
      }
    }
  }, [state.author]);

  const container = useContainer();
  const service = container.getInstance<ArticleServiceType>('articleService');

  const handleSave = async () => {
    if (!state.slug || !state.status || !state.pages[0].title) {
      setError('Must fill out all fields');
    } else {
      setLoading(true);
      try {
        service.addOrEditArticle(state);

        // setContent({ article: state });

        setState(defaultArticle);
        setError('Article saved!');
        window.scrollTo(0, 0);
      } catch (err) {
        setLoading(false);
        logger.error(`Error adding article - ${err}`);
        setError(err);
      }
      window.scrollTo(0, 0);
    }
  };

  return (
    <Page fullPage>
      <Button
        color="secondary"
        text="‹ Go Back"
        fullWidth
        variant="text"
        onClick={() => history.push('/articles')}
      />
      <Typography gutterBottom align="center" variant="h4">
        Create Article
      </Typography>
      <Box mb={2}>
        <ErrorMessage center error={error} />
      </Box>

      {state.pages.map((page, idx) => (
        <div key={idx}>
          <TextField
            id="article-title"
            label="Page Title"
            fullWidth
            value={state.pages[idx].title}
            onChange={event => updatePage(idx, { title: event.target.value })}
            variant="filled"
          />
          <TextField
            id="article-image"
            label="Image URL"
            fullWidth
            value={state.pages[idx].imageURL}
            onChange={event =>
              updatePage(idx, { imageURL: event.target.value })
            }
            variant="filled"
          />
          <img
            src={
              state.pages[idx].imageURL
                ? state.pages[idx].imageURL
                : 'https://apps4rent.com/support/wp-content/plugins/accelerated-mobile-pages/images/SD-default-image.png'
            }
            style={{ width: 600, height: 315 }}
            alt="Article Preview"
          />

          <RichEditor
            state={state.pages[idx].body}
            // @ts-ignore
            setState={e => updatePage(idx, { body: e })}
          />

          <TextField
            id="article-next-btn"
            label="Next Button"
            fullWidth
            value={state.pages[idx].next_btn}
            onChange={event =>
              updatePage(idx, { next_btn: event.target.value })
            }
            variant="filled"
          />
        </div>
      ))}

      <TextField
        id="external-link"
        label="External Link"
        fullWidth
        value={state.externalLink}
        onChange={event =>
          setState({ ...state, externalLink: event.target.value })
        }
        variant="filled"
      />

      <PublishStatusSelect
        value={state.status}
        onChange={e => setState({ ...state, status: e as PublishStatus })}
      />

      <ToggleButton
        type="boolean"
        label="Comments enabled?"
        value={state.commentsEnabled}
        setValue={bool => setState({ ...state, commentsEnabled: bool })}
      />

      <ToggleButton
        type="boolean"
        label="Sharing enabled?"
        value={state.sharingEnabled}
        setValue={bool => setState({ ...state, sharingEnabled: bool })}
      />

      <ToggleButton
        type="boolean"
        label="Rating enabled?"
        value={state.rating}
        setValue={bool => setState({ ...state, rating: bool })}
      />

      <ToggleButton
        type="boolean"
        label="Challenge Completion enabled?"
        value={state.challengeEnabled}
        setValue={bool => setState({ ...state, challengeEnabled: bool })}
      />

      <TextField
        id="author"
        label="Author"
        fullWidth
        value={state.author}
        onChange={event => setState({ ...state, author: event.target.value })}
        // onKeyPress={handleAdd}
        // disabled={adding}
        variant="filled"
      />
      <TextField
        id="slug"
        label="Slug"
        fullWidth
        value={state.slug}
        onChange={event =>
          setState({
            ...state,
            slug: event.target.value,
            id: event.target.value,
          })
        }
        // onKeyPress={handleAdd}
        // disabled={adding}
        variant="filled"
      />
      <Button text="Save" color="secondary" fullWidth onClick={handleSave} />
    </Page>
  );
};

export default ArticleCreateScreen;
