import React from 'react';
import { Typography } from '@material-ui/core';
import { Page } from '../../components';
import { staticPages, aboutSteps } from '.';
import { useParams, useLocation, Redirect } from 'react-router';
import { Stepper } from '../../components';

type Props = {
  about: boolean;
};

const StaticPage: React.FC<Props> = ({ about }) => {
  // About pages have :pageId param
  let { pageId } = useParams();
  let { pathname } = useLocation();

  // If not an about page, assign the pathname to pageId
  if (!pageId) {
    pageId = pathname.slice(1);
  }

  // Fix to catch incorrect terms and privacy URLs coming from MHL app
  if (pageId === 'pages/mhl-app-terms-conditions') {
    return <Redirect to="/terms" />;
  }
  if (pageId === 'pages/mhl-app-privacy-policy') {
    return <Redirect to="/privacy" />;
  }

  let page = staticPages[pageId];
  let Body = page.body;
  const pageContent = (
    <>
      <Typography gutterBottom align="center" component="h1" variant="h3">
        {page.title}
      </Typography>
      <Body />
    </>
  );

  return (
    <Page fullPage={true}>
      {about ? (
        <Stepper about steps={aboutSteps}>
          {pageContent}
        </Stepper>
      ) : (
        pageContent
      )}
    </Page>
  );
};

export default StaticPage;
