import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Section, SectionHalves, NavPadding } from '../../components';
import TeamGrid from './components/TeamGrid';
import TextCta from './components/TextCta';
import Testimonials from './components/Testimonials';
import CtaButton from './components/CtaButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: 'calc(100vh - 122px)',
        padding: '0 32px 64px 64px',
      },
    },
    gradient: {
      position: 'relative',
      background: `linear-gradient(90deg, ${
        theme.palette.secondary.main
      } 30%, ${theme.palette.primary.main} 90%)`,
      color: 'white !important',
      '& h1': {
        // color: 'white',
      },
      '& h6': {
        // color: 'white',
      },
    },
    callout: {
      textAlign: 'center',
      background: theme.palette.primary.main,
      [theme.breakpoints.up('md')]: {
        padding: '30px 5%',
      },
      '& h3': {
        color: theme.palette.secondary.main,
      },
      '& h2': {
        color: 'white',
      },
      '& h4': {
        color: theme.palette.secondary.main,
      },
      '& h1': {
        color: 'white',
      },
      '& h6': {
        color: 'white',
      },
    },
    heroImg: {
      padding: '10px 23px 26px 22px !important',
      [theme.breakpoints.up('md')]: {
        padding: '0px !important',
        '& img': {
          position: 'absolute',
          bottom: '0',
          right: '0',
          width: '57%',
          overflowX: 'hidden',
        },
      },
    },
    button: {
      // margin: '20px 0px 0 0',
      padding: '15px 25px',
      fontSize: '1.1375rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0',
        // borderWidth: '1px',
        // borderStyle: 'solid',
        // borderImageSlice: '1',
        // borderImageSource: `linear-gradient(to right , ${
        //   theme.palette.secondary.dark
        // }, ${theme.palette.secondary.main})`,
        width: '100%',
      },
    },
    center: {
      textAlign: 'center',
    },
    avatarImg: {
      margin: '0 auto 20px',
      width: '80px',
      height: '80px',
    },
    extraSpace: {
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(8)}px !important`,
      },
    },
    action: {
      color: 'yellow',
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[700],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
  }),
);

const Landing3: React.FC = () => {
  const classes = useStyles();

  const ctaButton = <CtaButton />;

  return (
    <>
      <NavPadding />
      <Grid container spacing={4} className={`${classes.hero}`}>
        <Grid md={5} item>
          <Typography component="h1" variant="h4">
            Stop Spiraling. Start Smiling.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Get the motivation, education, and accountability you need to tackle
            your mental health in just 28 days.
          </Typography>

          {ctaButton}
        </Grid>

        <Box display={{ md: 'none' }}>
          <Grid item className={classes.heroImg}>
            <TeamGrid />
          </Grid>
        </Box>

        <Box display={{ xs: 'none', md: 'block' }}>
          <Grid md={6} item className={classes.heroImg}>
            <img
              alt="MHL Training Celebration"
              src={require('../../assets/images/landing/hero-foggy.webp')}
            />
          </Grid>
        </Box>
      </Grid>

      <Section>
        <SectionHalves
          src="three-screenshots"
          title="Join A Team. Win More Days."
          body={
            <>
              <Typography gutterBottom>
                Tired of the emotional rollercoaster? Struggling to stick to
                your self-care? You're not alone. Join an MHL team and have a
                coach and team cheering you on each and every day.
              </Typography>
            </>
          }
          reverse
        />
      </Section>

      <Testimonials />

      <TextCta button={ctaButton} />
    </>
  );
};

export default Landing3;
