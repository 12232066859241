export default {
  huddle: {
    enabled: 'true',
    headline: 'Team Huddle Tonight at 8:30pm EST',
    dayOfWeek: '3',
    hour: '20',
    minute: '30',
    url:
      'https://us02web.zoom.us/meeting/register/tZYkfuqsqzItGNVlZskNkeZ0Fjto74pYb1Od',
  },
  articles: {
    'day-two': {
      rating: true,
      pages: [
        {
          title: 'Get Ready For Day Two',
          body:
            'PAGE 1 - Body of article, Body of article,Body of article,Body of article,Body of article,Body of article,',
          next_btn: 'Next',
          imageURL:
            'https://prod-ripcut-delivery.disney-plus.net/v1/variant/disney/384A7FA32B856192DEFE752A3757B296B64D718DEDC837725569F129256297AA/scale?aspectRatio=1.78&format=jpeg',
        },
        {
          title: 'Get Ready For Day Two',
          body:
            'PAGE 2 - Body of article, Body of article,Body of article,Body of article,Body of article,Body of article,',
          next_btn: 'Next',
          imageURL:
            'https://prod-ripcut-delivery.disney-plus.net/v1/variant/disney/384A7FA32B856192DEFE752A3757B296B64D718DEDC837725569F129256297AA/scale?aspectRatio=1.78&format=jpeg',
        },
      ],
    },
    'understand-your-stats-old': {
      rating: false,
      pages: [
        {
          title: 'Understand Your Stats',
          body:
            "Regardless of how you define your wins, the real measure of success is getting on the field (process, not result), and the stat we call Completion Rate.\n\nWait...what’s Completion Rate & Completion Streak?\n\nAt the top of the main screen, you’ll see \"% comp\" and \"Comp Streak\". These are both Completion metrics. If you fill out your day's scorecard, you've completed that day. More than record, these are what really matter – because with mental health, there is no off-season.\n\nAssuming you're like me, you start taking care of yourself, get to a good place, and then stop taking care of yourself, and then back to the struggle you go. Consistency is the real win. Being aware of how you're doing is the real win. So whether you're winning or losing, we encourage you to stay on the field.",
          next_btn: 'Next',
        },
      ],
    },
    'understand-your-stats': {
      rating: false,
      pages: [
        {
          title: 'What Is Completion Rate?',
          body:
            "While each day you'll track if you won or lost, COMPLETION RATE is the real winner's metric...because with mental health, there is no off-season.\n\nWait...what’s Completion Rate?\n\nCompletion Rate is the % of days that you've filled out your scorecard; while Completion Streak is the number of days in a row that you've filled out your scorecard.\n\nWhy do these matter? Because consistency is key.\n\nAssuming you're like me, you start taking care of yourself, get to a good place, then stop taking care of yourself, and back to the struggle you go. Consistency is the real win. Being aware of how you're doing is a real win. So whether you're winning or losing, we encourage you to stay on the field and keep score each day.",
          next_btn: 'Next',
        },
      ],
    },
    'join-your-team': {
      rating: false,
      pages: [
        {
          title: 'Join Your Team!',
          body:
            "Once you've filled out your first scorecard, go to the team tab and join a team.\n\nFor now, there's two options – the Anxietees or the Foggy Dogs.\n\nBoth teams will receive the same support, we just need two teams since we had so many people join the Training Camp.",
          next_btn: 'Got It',
        },
      ],
    },
    'stay-consistent': {
      rating: false,
      pages: [
        {
          title: 'Stay Consistent',
          body:
            'To help keep you consistent, you can opt-in to receive push notifications that remind you to fill out your scorecard each day.',
          next_btn: 'Activate Notifications',
        },
      ],
    },
    'whats-next': {
      rating: false,
      pages: [
        {
          title: "What's Next?",
          body:
            "Training Camp was there to help you establish a new mindset towards your mental health, gain a better understanding of what makes you win and lose each day, and turn those learnings into a game plan.\n\nWe hope you accomplished all that and more. From here on out, you can keep tracking in the app, but you'll no longer be required to share your scorecard each day.\n\nWe’ll update you when additional training plans are available.",
          next_btn: 'I’m Ready To Keep Playing',
        },
      ],
    },
    'reframe-your-losses': {
      rating: true,
      pages: [
        {
          title: 'Reframe Your Losses',
          body:
            'One of the best skills to help you win more days is reframing. When we change our point of view on any given situation, the facts remain the same, but a deliberate shift is made in how we see it.\n\nWhen you reframe, you replace cynical, energy-draining thoughts with more responsible ones. A good example of this is looking at things as challenges, instead of as problems.\n\nFor instance, if you’re on a losing streak, it’s easy to just write it off saying you’re not any good. You can reframe that by acknowledging that even the GOATs have bad games, and to focus on what you can learn from the loss and how you can use those learnings to improve the next game.\n\nThink of the biggest thing you’re struggling with right now. Right down how you typically think about it. Then write another version of it re-framed. You’ll be amazed at how much changing the way you talk to yourself can change the way you feel.',
          next_btn: 'Got It',
        },
      ],
    },
    'understand-your-triggers': {
      rating: true,
      pages: [
        {
          title: 'Understand Your Triggers',
          body:
            'To become one of the greats, you need to gain a deep understanding of your triggers.\n\n Triggers are anything in your environment (an action, a smell, etc) that elicit an automatic response.\n\nA physical example is when you smell french fries, you get hungry.\n\nAn emotional example is when you see social post about a friend getting a promotion, you could get jealous.\n\nWhile we can’t control triggers, we can control our responses. But before we can control our responses, we have to know what our triggers are.\n\nWhen you fill out your daily scorecard (or ideally even throughout the day), think about when you felt happy to sad, then dig deeper and think about what happened right before that happened? And what happened before that?\n\nOver time, you’ll discover patterns and gain a better understanding of what’s driving some of your emotions. From there, you can work to remove the trigger or change your response to those triggers – which we’ll discuss more later.',
          next_btn: 'Got It',
        },
      ],
    },
    'your-mind-is-running': {
      rating: true,
      pages: [
        {
          title: 'Your Mind Is Running',
          body:
            'Real talk: our mind is designed for sprints, not marathons. Usually, our minds think and process based on what’s right in front of us. But when you find your mind constantly thinking over the same stressful thoughts, it’s like your mind is running the Boston Marathon.\n\nThis is called rumination. Rumination is when you’re replaying a problem over and over again in your head – which often leads to Anxiety and Depression. When this happens, your mind can’t jump the everyday hurdles of life, and you’re likely to feel stressed, anxious, and fearful all the time.\n\nThe best way to get off the track is to first acknowledge the emotions behind the idea itself. Give it space by focusing on it while taking a few, deep breaths. Then, consider whether the thought itself is fact or fiction. Sometimes, rumination gives a worrisome idea time and space to grow, when in reality, it was just another short and specific thought that can pass. Lastly, give it time to work itself through without self-judgment, and try to hone your attention back to the here and now.\n\nIf rumination causes severe distress, we recommend texting the Crisis Text Line for 24/7 support. Text HOME to 741 741.',
          next_btn: 'Got It',
        },
      ],
    },
    'explaining-play-statistics': {
      rating: true,
      pages: [
        {
          title: 'Explaining Play Statistics',
          body:
            "*FILL OUT TODAY'S SCORECARD TO UNLOCK PLAY TRACKING*\n\nOnce you’ve run some plays, you’ll see a plays stat breakdown on your stats tab. While it doesn’t look like much, this very module can create great change.\n\nWhat the columns mean…\n- Comp % = Completion Percentage | The % of days you ran a certain play\n- W% = Win Percentage | The % of winning days where you ran a certain play\n- L% = Loss Percentage | The % of losing days where you ran a certain play\n- T% = Tie Percentage | The % of tieing days where you ran a certain play\n\nOver time, you’ll get a clear picture of how certain things affect the outcome of your day. Does meditation make you more likely to win? Or is social time the cornerstone habit that can rally any comeback?\n\nLearn what you can from these statistics, but also know that they don’t always tell the full story. You may win a lot of day when you drink, but crash the next day. You may lose more on workdays than weekends, but that doesn’t mean you should quit your job.\n\nThink critically. Dig deep. Stay motivated. We’re rooting for you.",
          next_btn: 'Got It',
        },
      ],
    },
    'what-plays-taught-me': {
      rating: true,
      pages: [
        {
          title: 'What Plays Taught Me',
          body:
            'Hey, I’m Mike. I was diagnosed with Bipolar Type II and ADHD in college and have spent the next decade learning how to manage it.\n\nA few years ago, I was going through a career change and was getting absolutely crushed by depression. To combat it, I started keeping a daily scorecard and tracking the plays I ran each day. I didn’t know what exactly would come of it, but as a stats nerd I figured it’d be interesting if nothing else.\n\nAnd it changed my life.\n\nAfter a month, the stats told a story I couldn’t ignore. Pretty much any day that I spent quality time with loved ones, I won. Even if I’d been spiraling all day, it didn’t matter. I’d come back.\n\nAfter that, the biggest game changer was learning. I was 40% more likely to win the day if I read or learned something new.\n\nIf I drank alcohol, I won those days (likely because I was probably doing so with friends), but lost the day after because I didn’t get my work done.\n\nWhile they seem obvious, for some reason seeing the stats sparked me to make real change. I started prioritizing time with loved one, whereas before I had been denying myself of it saying I didn’t deserve it until I’d gotten a new job (*thought distortion*).  Instead of listening to music on the subway, I started listening to audiobooks. When I went out with friends, I tried not to drink, or at minimum cut my consumption down a lot. I also stopped guilting myself on days I didn’t exercise as while it increased my winning percentage, it wasn’t the end all be all. I optimized my time for the things that helped the most, and did the best I could on everything else.\n\nAnd things started turning around. That’s not to say it was easy or that I didn’t have off days, but my record definitely improved.',
          next_btn: 'Got It',
        },
      ],
    },
    'back-to-the-tapes': {
      rating: true,
      pages: [
        {
          title: 'Back To The Tapes',
          body:
            'We know that our thoughts define our actions. But sometimes, what we think isn’t necessarily what actually happened, and we respond to situations based on fiction, not fact. When we’re stressed, anxious or depressed, we tend to think things are worse than they actually are, causing our reaction to be in rooted in fear or desperation than productivity. Not good for you or your friends.\n\nOne way to avoid this is to rewind the tapes. Before you jump to a conclusion in a situation, like leaving a conversation with your boss and thinking “he hates me”, press pause. Then, replay the situation and notice two things:\n\n- What evidence do I have that is in favor of that thought?\n- What evidence do I have that’s against that thought?\n\nUsually, this thoughtful pause and careful consideration help you respond to what happened in a more nuanced manner. Regardless, it may help you feel calmer and in control through the process. Practice it today to keep yourself accountable in the future.',
          next_btn: 'Got It',
        },
      ],
    },
    comparisons: {
      rating: true,
      pages: [
        {
          title: 'Comparisons',
          body:
            'Playing the comparison game means you’ve lost focus on your own progress. When we start comparing ourselves to others, we discredit the growth we’re making on our own, and create unrealistic expectations for how we should live. No one can be you better than yourself, so when you notice yourself comparing, use these steps to get back in the zone:\n\n- Notice what triggers you to start comparing (like while browsing social media, or at the workplace or gym) and remove yourself from that space, if possible\n- Remember that what you see is the championships, not the practice sessions.\n- Note what you’re grateful for in your own life, regardless of what others are doing.\n\nTomorrow, try moving through these three steps the next time you start comparing.',
          next_btn: 'Got It',
        },
      ],
    },
    testimonials: {
      rating: true,
      pages: [
        {
          title: 'Testimonials',
          body:
            "New gameplans are hard to stick to, but if you trust the process, you'll going to love the way you feel. But don't take our word for it...\n\n'The MHL really made me realize what made me tick. It helped me determine what is healthy for me and what is not. I got to find out what really causes my losses on a daily basis.\n\n'I’ve felt more accountable since joining the team. I feel like I’m relearning how to deal with the situations that have given me trouble in the past.'\n\n'The MHL made me able to pinpoint trigger points better. It made me sit down and think how the day actually was rather than just generally saying good or bad.'",
          next_btn: 'Got It',
        },
      ],
    },
    'halfway-mark': {
      rating: true,
      pages: [
        {
          title: 'Halfway Mark',
          body:
            'You’re officially halfway through this training camp! That means you’re just a few days away from fully forming these habits into your everyday practice. Need encouragement? Want more support? Head to the team tab on the app and get inspired by your teammates. We’re all in this together.',
          next_btn: 'Got It',
        },
      ],
    },
    'one-more-week': {
      rating: true,
      pages: [
        {
          title: 'One More Week',
          body:
            "Heads up! We're coming down the home stretch. With just one week left, take a few minutes to reflect on what you've learned so far, and set an intention on what you want to get out of the next week.",
          next_btn: 'Got It',
        },
      ],
    },
    'youre-doing-great': {
      rating: true,
      pages: [
        {
          title: "You're Doing Great",
          body:
            "We just wanted to let you know that you're doing great. That's all. Let's finish these last few days strong.",
          next_btn: 'Got It',
        },
      ],
    },
    'quote-bob-feller': {
      rating: true,
      pages: [
        {
          title: 'Every Day Is A New Game',
          body:
            "'Every day is a new opportunity. You can build on yesterday’s success or put its failures behind and start over again. That’s the way life is, with a new game every day, and that’s the way baseball is.' - Bob Fellern\n\nEvery day is a new game, every month a new season. Don't let the past get you down. Just focus on making today the best it can be.",
          next_btn: 'Got It',
        },
      ],
    },
    'quote-michael-jordan': {
      rating: true,
      pages: [
        {
          title: 'What Would MJ Do?',
          body:
            "'I've missed more than 9000 shots in my career. I've lost almost 300 games. 26 times, I've been trusted to take the game winning shot and missed. I've failed over and over and over again in my life, and that is why I succeed.' - Michael Jordan\n\nEven the GOATs fail sometimes. What's really important is that you stay on the field and keep trying. You are not your losses. ",
          imageURL:
            'https://firebasestorage.googleapis.com/v0/b/mhl-production.appspot.com/o/articles%2FMichael_Jordan_1.jpg?alt=media&token=557a3c26-d880-4b34-994a-185c4339c525',
          next_btn: 'Got It',
        },
      ],
    },
    'inner-critic': {
      rating: true,
      pages: [
        {
          title: 'Taming Your Inner Critic',
          body:
            'Sometimes we can be our own biggest fan. Other times, we’re the worst inner critic. And winning the day can feel impossible if that voice inside you tells you that you can’t. Let’s spend today paying attention to your inner voice. Note when the language goes from friendly to foul, and write down words and phrases you’d like to hear instead. This way, the next time they start booing and jeering from the cheap seats, you can tell them to shape up, or throw them out the stadium.',
          next_btn: 'Got It',
        },
      ],
    },
    'in-case-of-emergency': {
      rating: true,
      pages: [
        {
          title: 'In Case Of Emergency',
          body:
            'Thank you for being a part of the MHL. We’re honored to have you on our team and to be a part of your mental health journey.\n\nWhile the MHL is here to help you understand your triggers and win more days, we can’t help you resolve bigger crises. \n\nIf you need more help and someone to talk to, we recommend texting the Crisis Text Line for FREE 24/7 support. Just text HOME to 741741 and you’ll be connected with someone from their team.',
          next_btn: 'Got It',
        },
      ],
    },
    'redefine-your-win': {
      rating: true,
      pages: [
        {
          title: '(Re-)Define Your Win',
          body:
            "What does the word Win mean to you? Does it represent outcomes or the effort you took behind them? Does it signify how you feel, or what you do? Here at the MHL there’s nothing in our rulebook that defines a win. That’s completely up to you. But it’s helpful to have an idea ahead of time so you can measure that consistently, and aim for it as you keep working towards your greatness.\n\nJust as important, know when to re-define your win. Life is known to throw curveballs, and when that happens it's important to adjust. If you're in the thick of depression, a win could just be getting out of bed. When you focus on small wins, they of the snowball into bigger wins.",
          next_btn: 'Got It',
        },
      ],
    },
    'keystone-habit': {
      rating: true,
      pages: [
        {
          title: 'Keystone Habit',
          body:
            'Struggling to get started? Start the day off by sticking to just ONE habit that puts you in a good mood. It doesn’t have to take a lot of time – maybe it’s making the bed before you leave, or setting out your clothes for the next day before you fall asleep. This one practice – referred to as a keystone habit – may spark more, and help you feel more in control.',
          next_btn: 'Got It',
        },
      ],
    },
    'realistic-goals': {
      rating: true,
      pages: [
        {
          title: 'Realistic Goals',
          body:
            'Let’s level for a second – are the goals you set for yourself realistic? Sometimes we set ourselves up for failure because we want to do it all. And that’s inspiring! But sometimes, also overwhelming. Before the day starts, ask yourself: do these goals motivate me? Am I grounded enough to achieve my goals? Is there anything I need to do for me before I can achieve these goals? Reframe your expectations by grounding them in real life, and you can achieve anything.',
          next_btn: 'Got It',
        },
      ],
    },
    'help-somebody': {
      rating: true,
      pages: [
        {
          title: 'Who Can You Help?',
          body:
            "Training Camp is almost over. Since you've made it this far, you're doing great.\n\nSo your assignment today is to help somebody else.\n\nDo one kind thing for one of your best teammates or biggest fans.\n\nCall them and tell them you love them. Buy them flowers. Cook them dinner. Give them a hug. Teach them something that's helped you. Or simply ask them how they're doing and just listen.\n\nKindness is contagious. Make somebody's day.",
          next_btn: 'Got It',
        },
      ],
    },
    'no-zero-days': {
      rating: true,
      pages: [
        {
          title: 'No Zero Days',
          body:
            "Psychology doesn't always have to come from the classroom. One of our favorite concepts comes from ryan01 on Reddit - 'No More Zero Days'.\n\nA zero day is a day in which you have done absolutely nothing towards your goal or dream. I like to think of life as binary, either you’re right (1) or you’re wrong (0), you’re doing something (1) or you’re not (0). The idea of a non-zero day is to do something every single day to move towards your goal. To build a string of 1’s instead of 0’s.\n\nDidn't’ do anything all  day and it’s 11:58 PM? Write one sentence. One push up. Read one page of that chapter. One. Because one is non zero.\n\nIn a lot of cases, when something happens in our day and we can’t make it to the gym, or we’re too tired to code or write that essay, we tend to just give up. Once we accumulate two to three days of doing nothing, it’s so much easier to just give up entirely, and soon we begin to get caught up in other things and begin to rationalize quitting to ourselves. “Well there’s no point going to the gym if I can’t go every day” or “What’s the point in writing for just 5 minutes? I’m never going to finish my book this way”. All of us are guilty of this in one way or the other.\n\nThe way to make real lasting change is to ensure you never have a zero day. So find a way to have consistent non-zero days, and you will find your motivation increases as you go.",
          next_btn: 'Got It',
        },
      ],
    },
    'staying-positive': {
      rating: true,
      pages: [
        {
          title: 'Staying Positive',
          body:
            'As we become more aware of how we feel, we may notice that we become more critical and less joyful. This is normal; increased awareness makes us more nuanced. It’s like becoming a seasoned pro in the sport of life.\n\nOne way to keep ourselves in spaces of joy is by scheduling it into the day. Sounds counterintuitive, but scheduling a pleasant activity during the day reminds our body how to respond when other situations come into our life. It’s especially important to have these scheduled when we feel depressed or anxious.\n\nIn today’s reflection, choose ten minutes in your day tomorrow you can do something joyful. It could be petting a dog, getting ice cream, or listening to your favorite song and dancing in your underwear. Your brain will thank you!',
          next_btn: 'Got It',
        },
      ],
    },
    'beating-thought-distortions': {
      rating: true,
      pages: [
        {
          title: 'Beating Thought Distortions',
          body:
            "Your toughest competitor you’ll face is your own mind. While it can help you do amazing things, it’s got some bad habits. We call these bad habits Thought Distortions, and the first step to overcoming them is to recognize them.\n\nHere are some examples...\n\n- My boss gave me some feedback, they’re definitely going to fire me.\nThat girl shot me down, I’m such a loser.\nI’ve been depressed for months, this is just how I am.\n\nAll of the above are examples of lies we tell ourselves when the truth is actually much less harsh. Our brains are hardwired to focus on the negative as a protection mechanism. Here are some categories that you should work to recognize and correct.\n\n1) Delusional Thinking\nYou convince yourself of something you really don’t believe to justify a decision. Example. 'One drink doesn’t make a difference.'\n\n2) Exaggerated Thinking\nYou make a situation into something bigger than it is. Example. 'I didn’t fill out my scorecard yesterday, I need to quit the MHL'\n\n3) Justifications\nYou link two unrelated ideas to justify a decision. Ex. 'I’m already happy, I don’t need to workout.'\n\nNow pull out a piece of paper. Yes, seriously do it now. Write down a thought you distortion you had this week and how you can re-frame it.",
          next_btn: 'Got It',
        },
      ],
    },
    huddle: {
      rating: false,
      pages: [
        {
          title: 'Team Huddle Tonight',
          body:
            'Every Wednesday, we get together on Zoom to discuss our weekly wins, learnings, and goals for the following week.\n\nStart at 8:30pm EST and lasts no more than an hour.',
          next_btn: 'RSVP & JOIN',
        },
      ],
    },
    'good-teammate': {
      rating: true,
      pages: [
        {
          title: 'How To Be A Good Teammate',
          body:
            'Society teaches us to suffer in silence. It’s told us that struggling with mental health makes us weak. But when we suffer alone, we’re less likely to find the strength we need to push through.\n\nThere’s science to prove it, too. A study by Aetna proved that people who share their mental health journeys with others are more likely to receive treatment and see improvements. That’s why everyone in the MHL is on a team.\n\nSo what makes a good teammate?\n\nSuzanne Bell, PhD, an associate professor of industrial/organizational (I/O) psychology at DePaul University in Chicago, says that there are clear ABCs of teamwork: attitude, behavior and cognitive states.\n\nWe know that the MHL is full of people that are determined to change their relationship to mental health (attitude), and are committed to checking in daily and sharing with their team (behavior). We also know that this team together will keep ourselves focused, diligent, and self-aware (cognitive state).\n\nHere’s how to put that in practice each day on the court:\n\n1. Provide motivation and encouragement to your teammates when responding to their posts\n\n2.Stay committed to the daily content and sharing – not just for yourself, but the rest of the team\n\n3. Treat each day like the championship. Take this as seriously as you can each day.',
          next_btn: 'Got It',
        },
      ],
    },
  },
  min_scorecards_to_team: 0,
  mhl_combine: [
    {
      id: 'How would you rate your current mental health?',
      title: 'The MHL Combine',
      question: 'How would you rate your current mental health?',
      type: 'single',
      options: [
        '1 - Poor',
        '2 - Not Good',
        '3 - Okay',
        '4 - Good',
        '5 - Great',
      ],
      button: 'next',
    },
    {
      title: 'The MHL Combine',
      id: 'Which opponents are you competing against?',
      type: 'multiple',
      question: 'Which opponents are you competing against?',
      options: [
        'Anxiety / Stress',
        'Depression',
        'Bipolar',
        'ADHD',
        'PTSD',
        'No Specific Opponent',
        'Other',
      ],
      button: 'Next',
    },
    {
      title: 'The MHL Combine',
      id: 'What are you hoping to improve upon?',
      type: 'multiple',
      question: 'What are you hoping to improve upon?',
      options: [
        'Accountability To Stick To My Self-Care',
        'A Positive, Supportive Team',
        'Psychological Hacks To Change My Behavior',
        'To Learn More About My Triggers',
        "To Know That I'm Not Alone",
        'None Of The Above',
      ],
      button: 'Submit',
    },
  ],
  team_combine: [
    {
      id: 'Gender?',
      title: 'Team Combine',
      question: 'Gender?',
      type: 'single',
      options: ['Male', 'Female', 'Non-Binary'],
      button: 'next',
    },
    {
      title: 'Team Combine',
      id: "What's your employment status?",
      type: 'single',
      question: "What's your employment status?",
      options: [
        'Student',
        'Self-Employed',
        'Employed',
        'Unemployed',
        'Retired',
      ],
      button: 'Next',
    },
    {
      id: 'What is your sexuality?',
      title: 'Team Combine',
      question: 'What is your sexuality?',
      type: 'single',
      options: ['Straight', 'Gay', 'Lesbian', 'Bisexual', 'Other'],
      button: 'next',
    },
    {
      id: 'Where do you live?',
      title: 'Team Combine',
      question: 'Where do you live?',
      type: 'single',
      options: ['City', 'Suburbs', 'Rural'],
      button: 'next',
    },
    {
      id: 'Relationship Status?',
      title: 'Team Combine',
      question: 'Relationship Status?',
      type: 'single',
      options: [
        'Single',
        'In A Relationship',
        'Married',
        'Separated',
        'Divorced',
        'Widowed',
        "It's Complicated",
      ],
      button: 'next',
    },
    {
      id: 'Do you have children?',
      title: 'Team Combine',
      question: 'Do you have children?',
      type: 'single',
      options: ['Yes, I have Children', "No, I Don't Have Children"],
      button: 'next',
    },
    {
      title: 'Team Combine',
      id: 'How do you identify?',
      type: 'multiple',
      question: 'How do you identify?',
      options: [
        'Black / African Descent',
        'White / Caucasian',
        'Hispanic / Latino',
        'Asian',
        'Native Hawaiian / Pacific Islander',
        'Indigenous',
        'Other',
      ],
      button: 'Submit',
    },
  ],
  team_combine_enabled: true,
  team_selection_enabled: true,
  plays: [
    { id: 'meditate', name: 'Meditate', icon: 'brain' },
    { id: 'exercise', name: 'Exercise', icon: 'running' },
    { id: 'medication', name: 'Medication', icon: 'pills' },
    { id: 'read', name: 'Read', icon: 'book' },
    { id: 'drank-alcohol', name: 'Drank Alcohol', icon: 'cocktail' },
    { id: 'socialized', name: 'Socialized', icon: 'user-friends' },
    { id: 'work', name: 'Work', icon: 'desktop' },
    { id: 'ate-healthy', name: 'Ate Healthy', icon: 'carrot' },
    { id: 'journaled', name: 'Journaled', icon: 'pencil-alt' },
  ],
  surveys: {
    'team-combine': 'Team Combine',
    'training-camp': 'MHL Combine',
  },
  feedback_survey: 'https://mentalhealthleague.typeform.com/to/bnumns',
};
