import BaseService from './BaseService';
import { Discussion, DiscussionServiceType } from './interfaces';
import { ApplicationError } from '../models/Errors';
import * as stream from 'getstream';
import { ActivityData } from 'react-activity-feed';

const COLLECTION = 'discussions';

class DiscussionService extends BaseService implements DiscussionServiceType {
  private collection = this.firestore.collection(COLLECTION);
  public query = this.collection.orderBy('updatedAt');

  public get = async (id: string): Promise<Discussion> => {
    try {
      const docRef = this.collection.doc(id);
      const doc = await docRef.get();

      if (doc.exists) {
        return doc.data() as Discussion;
      } else {
        // this.logger.error('Fetched article does not exist');
        throw new ApplicationError('ScheduledPost does not exist');
      }
    } catch (err) {
      throw new ApplicationError(
        'Error fetching this ScheduledPost. Try again.',
      );
    }
  };

  // Create the discussion doc first before adding to stream so that you can store the id as a foreign Id
  public createInitialDoc = async (
    item: string,
  ): Promise<Omit<Discussion, 'activity'>> => {
    try {
      // Add doc with generated id, so we can then add the id to the doc
      const docRef = this.collection.doc();

      const newItem: Omit<Discussion, 'activity'> = {
        post: { content: item },
        userId: this.currentPlayer.id,
        teamId: 'PjzVhh60y46DRxkNsvjA', // Empty team we're using just for discussion posts so they don't clog up team feeds
        status: 'pending',
        hidden: false,
        updatedAt: new Date(),
        createdAt: new Date(),
        id: docRef.id,
      };

      // Save the data to the document
      await docRef.set(newItem);

      // Return full Discussion obj to be used for GetStream addition
      return newItem;
    } catch {
      throw new ApplicationError('Error creating discussion');
    }
  };

  public addToStream = async (
    item: Omit<Discussion, 'activity'>,
  ): Promise<stream.ActivityData> => {
    const teamFeed = this.streamClient.feed('team', item.teamId);

    // Hardcoding so Wade or me get put in To: field if the other creates the discussion
    const vostersUid = process.env.REACT_APP_VOSTERS_UID;
    const wadesUid = process.env.REACT_APP_WADE_UID;
    const otherCoachId =
      this.currentPlayer.id === vostersUid ? wadesUid : vostersUid;

    try {
      const newActivity: stream.NewActivity = {
        actor: this.streamClient.user(this.currentPlayer.id),
        verb: 'discussion',
        type: 'discussion',
        message: item.post.content,
        object: {
          actor: { data: { teamId: item.teamId } },
        },
        foreign_id: item.id,
        time: new Date().toISOString(),
        to: [`notification:${otherCoachId}`],
      };
      const res = (await teamFeed.addActivity(
        newActivity,
      )) as stream.ActivityData;

      // Return GetStream activity so it can be added to the doc in Firestore
      return res;
    } catch (err) {
      this.logger.error(`Error adding discussion to stream - ${err}`);
      throw new ApplicationError('Error creating stream activity');
    }
  };

  public update = async (
    item: Pick<Discussion, 'activity'>,
  ): Promise<Discussion> => {
    try {
      const docRef = this.collection.doc(item.activity.foreign_id);
      const doc = await docRef.get();

      if (!doc.exists) {
        throw new ApplicationError(
          'This discussion does not exist. Unable to update.',
        );
      }

      // Parse getStream object which returns as a stringified object for some reason
      if (item.activity) {
        if (typeof item.activity.object === 'string') {
          item.activity.object = JSON.parse(item.activity.object);
        }
      }

      await docRef.update({
        ...item,
        updatedAt: new Date(),
        status: 'success',
      });

      return (await doc.data()) as Discussion;
    } catch (err) {
      this.logger.error(err);
      if (err.message === 'This discussion does not exist. Unable to update.') {
        throw new ApplicationError(err);
      } else {
        throw new ApplicationError('Error updating discussion');
      }
    }
  };

  public create = async (item: string): Promise<Discussion> => {
    try {
      const doc = await this.createInitialDoc(item);
      const streamActivity = await this.addToStream(doc);
      return await this.update({ activity: streamActivity });
    } catch (err) {
      this.logger.error('Error creating discussion');
      throw new ApplicationError(err.message);
    }
  };

  public getAll = async (): Promise<Discussion[]> => {
    try {
      return await this.collection
        .orderBy('createdAt', 'desc')
        .get()
        .then(snapshots =>
          snapshots.docs.map(
            doc =>
              ({
                ...doc.data(),
              } as Discussion),
          ),
        );
    } catch {
      throw new ApplicationError('Unable to retrieve list');
    }
  };

  public delete = async (id?: string): Promise<void> => {
    try {
      await this.collection.doc(id).delete();
    } catch {
      throw new ApplicationError('Unable to delete post');
    }
  };
}

export default DiscussionService;
