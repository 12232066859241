import React from 'react';
import { Box, Avatar, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: '25%',
      height: 'auto',
      // [theme.breakpoints.up('sm')]: {
      //   width: '20%',
      //   // marginBottom: '0%',
      // },
    },
  }),
);

const TeamGrid: React.FC = () => {
  const classes = useStyles();

  return (
    <Box mb={6}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="subtitle2"
            align="center"
            style={{ fontWeight: 700 }}>
            Now recruiting for...
          </Typography>
        </Grid>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <Avatar
            variant="rounded"
            className={classes.img}
            src={require('../../../assets/images/teams/anx-big-2.webp')}
          />
          <Avatar
            variant="rounded"
            className={classes.img}
            src={require('../../../assets/images/teams/fd-big-2.webp')}
          />
          <Avatar
            variant="rounded"
            className={classes.img}
            src={require('../../../assets/images/teams/bb-big-2.webp')}
          />
          <Avatar
            variant="rounded"
            className={classes.img}
            src={require('../../../assets/images/teams/adhb-big-2.webp')}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default TeamGrid;
