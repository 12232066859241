import React, { lazy, Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './assets/styles/theme';
import './assets/styles/App.scss';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Nav, Spinner, ScrollToTop } from './components';
import {
  // StreamProvider,
  // NotificationsProvider,
  ServicesProvider,
  LeadProvider,
  CurrentPlayerProvider,
  ContentProvider,
} from './context';
import { LandingScreen, PrivateRoute, CheckoutScreen } from './flows';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutStepper from './flows/checkout';
import Landing5 from './flows/landing/Landing5';
import CollegeScreen from './flows/landing/CollegeScreen';
import LandingAnxietees from './flows/landing/LandingAnxietees';
import {
  LoginScreen,
  PlansScreen,
  DownloadScreen,
  FaqsScreen,
  TeamScreen,
  ExerciseCreateScreen,
  ChooseTeamScreen,
  DiscussionsScreen,
  SurveyScreen,
  GamePlanListScreen,
  AnalyticsScreen,
  ForgotPasswordScreen,
  ExerciseListScreen,
  GamePlanCreateScreen,
  GamePlanEditScreen,
  ArticleCreateScreen,
  ArticleScreen,
  ArticlesScreen,
  FeaturesScreen,
  PlaybookScreen,
  StaticPage,
  StripePortal,
  PlayersScreen,
  OfferScreen,
  BootcampScreen,
  SubscriptionScreen,
  AboutScreen,
  JobsScreen,
  AndroidScreen,
  DashboardScreen,
} from './flows';

let stripePromise: any = null;
if (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
}

// Splitting code to reduce load time
// const DownloadScreen = lazy(() => import('./flows/DownloadScreen'));

const history = createBrowserHistory();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CurrentPlayerProvider>
        <LeadProvider>
          <ServicesProvider>
            <ContentProvider>
              <Elements stripe={stripePromise}>
                <Router history={history}>
                  <Suspense fallback={<Spinner />}>
                    <ScrollToTop />
                    <Nav />
                    <Switch>
                      <PrivateRoute
                        coach
                        path="/discussions/:id"
                        component={DiscussionsScreen}
                      />
                      <PrivateRoute
                        coach
                        path="/discussions"
                        component={DiscussionsScreen}
                      />
                      <PrivateRoute
                        coach
                        path="/choose-team"
                        component={ChooseTeamScreen}
                      />
                      <PrivateRoute
                        coach
                        path="/team/:tab"
                        component={TeamScreen}
                      />
                      <PrivateRoute coach path="/team" component={TeamScreen} />
                      <PrivateRoute
                        coach
                        path="/players"
                        component={PlayersScreen}
                      />
                      <PrivateRoute
                        exact
                        coach
                        path="/articles/create"
                        component={ArticleCreateScreen}
                      />
                      <PrivateRoute
                        exact
                        coach
                        path="/articles/:id"
                        component={ArticlesScreen}
                      />
                      <PrivateRoute
                        exact
                        coach
                        path="/articles"
                        component={ArticlesScreen}
                      />

                      <PrivateRoute
                        exact
                        coach
                        path="/analytics"
                        component={AnalyticsScreen}
                      />

                      <PrivateRoute
                        exact
                        coach
                        path="/gameplans/edit/:id"
                        component={GamePlanEditScreen}
                      />
                      <PrivateRoute
                        exact
                        coach
                        path="/gameplans/create"
                        component={GamePlanCreateScreen}
                      />

                      <PrivateRoute
                        exact
                        coach
                        path="/gameplans/:gameplan/:id"
                        component={GamePlanListScreen}
                      />
                      <PrivateRoute
                        exact
                        coach
                        path="/gameplans/:gameplan"
                        component={GamePlanListScreen}
                      />
                      <PrivateRoute
                        exact
                        coach
                        path="/gameplans"
                        component={GamePlanListScreen}
                      />

                      <PrivateRoute
                        exact
                        coach
                        path="/exercises/create"
                        component={ExerciseCreateScreen}
                      />

                      <PrivateRoute
                        exact
                        coach
                        path="/exercises/:exercise"
                        component={ExerciseListScreen}
                      />
                      <PrivateRoute
                        exact
                        coach
                        path="/exercises"
                        component={ExerciseListScreen}
                      />

                      <Route
                        coach
                        exact
                        path="/features"
                        component={FeaturesScreen}
                      />

                      <Route path="/survey" component={SurveyScreen} />
                      <Route path="/plans" component={PlansScreen} />
                      <Route path="/men" component={Landing5} />
                      <Route path="/students" component={CollegeScreen} />
                      <Route
                        path="/friends"
                        render={() => <PlansScreen friends />}
                      />
                      <Route path="/android" component={AndroidScreen} />
                      <Route
                        exact
                        path="/download"
                        component={DownloadScreen}
                      />
                      <Route path="/login" component={LoginScreen} />
                      <Route
                        path="/forgot-password"
                        component={ForgotPasswordScreen}
                      />

                      <Route path="/checkout" component={CheckoutScreen} />
                      <Route
                        path="/checkoutStepper"
                        component={CheckoutStepper}
                      />
                      <Route path="/join" component={LandingScreen} />
                      <Route path="/jobs" component={JobsScreen} />
                      <Route path="/anxietees" component={LandingAnxietees} />
                      <Route
                        path="/foggydogs"
                        render={() => <LandingAnxietees team="fd" />}
                      />
                      <Route path="/about" component={AboutScreen} />
                      <Route path="/faq" component={FaqsScreen} />
                      <Route
                        path="/pages/mhl-app-terms-conditions"
                        component={StaticPage}
                      />
                      <Route
                        path="/pages/mhl-app-privacy-policy"
                        component={StaticPage}
                      />
                      <Route path="/terms" component={StaticPage} />
                      <Route path="/privacy" component={StaticPage} />
                      <Route path="/offer" component={OfferScreen} />
                      <Route path="/bootcamp" component={BootcampScreen} />
                      <Route path="/stripe" component={StripePortal} />
                      <PrivateRoute
                        path="/dashboard"
                        component={DashboardScreen}
                      />
                      <Route
                        path="/subscription"
                        component={SubscriptionScreen}
                      />
                      <Route path="/playbook/:id" component={ArticleScreen} />
                      <Route path="/playbook" component={PlaybookScreen} />
                      <Route path="/" component={LandingScreen} />
                    </Switch>
                  </Suspense>
                </Router>
              </Elements>
            </ContentProvider>
          </ServicesProvider>
        </LeadProvider>
      </CurrentPlayerProvider>
    </ThemeProvider>
  );
};

export default App;
