import React, { useEffect, useState, useRef } from 'react';
import { Spinner } from '../components';
import ObserveUserService from '../services/ObserveCurrentPlayer';
import { initialState, UserContext } from './initialStates/userState';
import { Player } from 'types';
import { isPlanActive } from 'common';

export const CurrentPlayer = React.createContext<UserContext>(initialState);

export const CurrentPlayerProvider = ({ children }: any) => {
  const [user, setUser] = useState<Player | null>(null);
  const [loading, setLoading] = useState(true);
  // const [container, setContainer] = useState<Container | null>(null);
  const observeUser = useRef(new ObserveUserService(setUser, setLoading));

  useEffect(() => {
    // On mount, subscribe to auth and playerDoc listeners
    observeUser.current.subscribe();

    // On logout, unsubscribe to auth and playerDoc listeners
    return observeUser.current.unsubscribe;
  }, []);

  if ((loading && !user) || (!!user && !user?.streamToken)) {
    return <Spinner />;
  }

  // // Hack to allow coaches to get past PrivateRoutes on refresh
  // const [currentAuth, setCurrentAuth] = useState<User | null>(
  //   app.auth().currentUser,
  // );
  // const [currentUser, setCurrentUser] = useState<Player | null>(null);
  // const [loading, setLoading] = useState(true);

  // let playerDocUnsubscriber: React.MutableRefObject<Unsubscriber | null> = useRef(
  //   null,
  // );

  // useEffect(() => {
  //   // On mount, subscribe to auth and playerDoc listeners
  //   const authUnsubscriber = app.auth().onAuthStateChanged(user => {
  //     setCurrentAuth(user);

  //     // Unsubscribe from previous userDoc listener if exists
  //     if (playerDocUnsubscriber.current) {
  //       playerDocUnsubscriber.current();
  //     }

  //     // If user logged in, subscribe to their player document
  //     if (user) {
  //       setLoading(true);
  //       subscribeToPlayerDoc(user);
  //     } else {
  //       setCurrentUser(null);
  //       setLoading(false);
  //     }
  //   });

  //   // If app.tsx unmounts, cleanup all subscriptions
  //   return function cleanup() {
  //     if (playerDocUnsubscriber.current) {
  //       playerDocUnsubscriber.current();
  //     }
  //     authUnsubscriber();
  //   };
  // }, []);

  // const subscribeToPlayerDoc = async (user: User) => {
  //   playerDocUnsubscriber.current = docForPlayer(user.uid).onSnapshot(
  //     async (snapshot: firestore.DocumentSnapshot) => {
  //       const firePlayer = snapshot.data() as FirebasePlayer;

  //       if (!user.email) {
  //         logger.error('Player does not have an email');
  //         return Promise.resolve();
  //       }

  //       if (!firePlayer || !user) {
  //         // logger.error(
  //         //   'Player is not logged in or has not completed onboarding',
  //         // );
  //         return Promise.resolve();
  //       }

  //       let team: Team | undefined;
  //       if (firePlayer && firePlayer.teamId) {
  //         let firebaseTeam: FirebaseTeam | undefined;

  //         try {
  //           const teamSnapshot = await docForTeam(firePlayer.teamId).get();
  //           const teamData = teamSnapshot.data() || {};
  //           if (isFirebaseTeam(teamData)) {
  //             firebaseTeam = teamData as FirebaseTeam;
  //           }
  //         } catch (error) {
  //           logger.error(
  //             `Failed to load team id '${firePlayer.teamId}' for player '${
  //               firePlayer.id
  //             }': ${error}`,
  //           );
  //         }

  //         if (firebaseTeam) {
  //           const mascot = mascotList[firebaseTeam.mascotId] || defaultMascot;
  //           team = {
  //             id: firePlayer.teamId,
  //             name: firebaseTeam.name,
  //             mascot: mascot,
  //             visibility: firebaseTeam.visibility,
  //           };
  //         }
  //       }

  //       let chosenMascot: Mascot | undefined;
  //       if (firePlayer.mascotId) {
  //         chosenMascot = mascotList[firePlayer.mascotId];
  //       } else if (team && team.mascot) {
  //         chosenMascot = team.mascot;
  //       }

  //       let teamsCoached: { [teamId: string]: Team } | undefined;
  //       if (firePlayer && firePlayer.teamsCoached) {
  //         teamsCoached = {};
  //         for (const teamSnapshot of await Promise.all(
  //           Object.keys(firePlayer.teamsCoached).map(teamId =>
  //             docForTeam(teamId).get(),
  //           ),
  //         )) {
  //           const coachedTeam = teamSnapshot.data() as FirebaseTeam;

  //           if (!coachedTeam) continue;
  //           const mascot = mascotList[coachedTeam.mascotId];
  //           if (mascot) {
  //             teamsCoached[teamSnapshot.id] = {
  //               id: teamSnapshot.id,
  //               name: coachedTeam.name,
  //               visibility: coachedTeam.visibility,
  //               mascot,
  //             };
  //           }
  //         }
  //       }

  //       const newPlayer = new Player(
  //         user.uid,
  //         user.email,
  //         (firePlayer ? firePlayer.name : user.displayName) || '',
  //         team || undefined,
  //         snapshot.exists ? firePlayer.streamToken : undefined,
  //         snapshot.exists ? firePlayer.firstScorecardOn : undefined,
  //         snapshot.exists ? firePlayer.hasFinishedOnboarding : undefined,
  //         teamsCoached,
  //         snapshot.exists ? firePlayer.bio : undefined,
  //         snapshot.exists ? firePlayer.avatar : undefined,
  //         snapshot.exists ? firePlayer.firstScorecardPlayOn : undefined,
  //         snapshot.exists ? firePlayer.scorecardsCount : undefined,
  //         snapshot.exists ? firePlayer.scorecardPlaysEnabled : undefined,
  //         snapshot.exists ? firePlayer.latestScorecardOn : undefined,
  //         snapshot.exists ? firePlayer.fcmTokens : undefined,
  //         snapshot.exists ? firePlayer.birthday : undefined,
  //         snapshot.exists && !!firePlayer.scorecardPlaysList
  //           ? firePlayer.scorecardPlaysList
  //           : defaultPlays,
  //         snapshot.exists ? firePlayer.welcomed : undefined,
  //         snapshot.exists ? chosenMascot : undefined,
  //         snapshot.exists ? firePlayer.utcOffset : undefined,
  //         snapshot.exists ? firePlayer.plan : undefined,
  //         snapshot.exists ? firePlayer.stripeId : undefined,
  //       );
  //       setCurrentUser(newPlayer);
  //       setLoading(false);

  //       // Set GA Firebase User Properties
  //       try {
  //         firebase.analytics().setUserProperties({
  //           Role: newPlayer.teamsCoached ? 'Coach' : 'Player',
  //           Team: newPlayer.team ? newPlayer.team.name : 'No Team',
  //           Mascot: newPlayer.team ? newPlayer.team.mascot.name : 'No Team',
  //           PlaysEnabled: newPlayer.scorecardPlaysEnabled
  //             ? 'PlaysOn'
  //             : 'PlaysOff',
  //         });
  //       } catch (err) {
  //         logger.error(`GA Set Properties Error - ${err}`);
  //       }
  //     },
  //   );
  // };

  // if (loading) {
  //   return <Spinner />;
  // }

  return (
    <CurrentPlayer.Provider
      value={{
        user,
        loading,
        activePlan: isPlanActive(!user ? undefined : user.plan),
        isCoach: !!user && user.isCoach,
      }}>
      {children}
    </CurrentPlayer.Provider>
  );
};
