import React from 'react';
import { Typography } from '@material-ui/core';
import Stars from './Stars';
import { Paragraph, Image, Box } from '../../../components';

const testimonials = [
  {
    quote:
      "The MHL literally changed my life. I've met people and learned things that will stick with me for years to come",
    player: 'Alan R',
    image: 'prof-alan',
  },
  {
    quote:
      'The MHL takes the elusive thing that is mental health and makes it understandable, with coaching and team support that keep you accountable and always improving. One of the best mental health apps on the market!',
    player: 'Logan C',
    image: 'prof-logan',
  },
  {
    quote:
      'The MHL really made me realize what made me tick. It helped me determine what is healthy for me and what is not. It literally changed my life.',
    player: 'Alan R',
    image: 'prof-alan',
  },
];

type Props = {
  num?: number;
  size?: 'lg' | 'sm';
};

const Testimonial: React.FC<Props> = ({ num = 2, size }) => {
  const testimon = testimonials[num];

  return (
    <Box pt={3} pb={2}>
      {testimon.image && (
        <Box mb={2} display="flex" justifyContent="center">
          <Image src={testimon.image} imageStyle={{ width: '120px' }} />
        </Box>
      )}
      <Stars center />
      <Typography
        component="h3"
        variant={size === 'sm' ? 'subtitle2' : 'h5'}
        color="primary"
        align="center"
        style={{ fontWeight: 700 }}>
        “{testimon.quote}”
      </Typography>
      <Paragraph center>- {testimon.player}</Paragraph>
    </Box>
  );
};

export default Testimonial;
