import React from 'react';
import Box from '@material-ui/core/Box';

type Props = {
  mt?: number;
};

const NavPadding: React.FC<Props> = ({ mt }) => <Box mt={mt ? mt : 12} />;

export default NavPadding;
