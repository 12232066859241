import firebase from '../base';
import { NoUserError } from '../models/Errors';
import ModelError from '../models/ModelError';
import UserProfile from '../models/UserProfile';
import { FileUploadService } from '../services';
import { PlayerService, ProfileService } from './interfaces';

class UpdateProfileService implements ProfileService {
  private updatePlayerService: PlayerService;
  constructor(options: { updatePlayerService: PlayerService }) {
    this.updatePlayerService = options.updatePlayerService;
  }
  public async perform(profile: UserProfile): Promise<void> {
    const error = this.validate(profile);
    if (error) {
      throw error;
    }
    const firebaseUser = firebase.auth().currentUser;
    if (!firebaseUser) {
      throw new NoUserError();
    }
    const { name, bio = '', avatar, birthday } = profile;

    const updatedUser: UserProfile = {};

    if (name) {
      updatedUser.name = name;
    }
    if (bio) {
      updatedUser.bio = bio;
    }
    if (birthday) {
      updatedUser.birthday = birthday;
    }

    if (avatar) {
      const avatarURL = await FileUploadService.uploadAvatar(avatar);
      updatedUser.avatar = avatarURL;
    }

    await this.updatePlayerService.updateCurrentPlayer(updatedUser);
  }

  private validate(profile: UserProfile): ModelError<UserProfile> | null {
    const error = new ModelError<UserProfile>();

    if (profile.name !== undefined && profile.name.length < 3) {
      error.addError('name', 'Username must be at least 3 characters long.');
    }

    console.log('validate error', error);

    return error.hasErrors() ? error : null;
  }
}

export default UpdateProfileService;
