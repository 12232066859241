import React, { useState, useEffect } from 'react';
import { Spinner, PlayerList, Grid, Headline } from '../../components';
import {
  Container,
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { GridProps } from '@material-ui/core/Grid';
import { useCache } from '../../hooks';
import { TeamPlayer } from '../../models/TeamPlayer';
import Team from '../../models/Team';
import ListTeamsService from '../../services/ListTeamsService';
import { findAllPlayersByTeam } from '../../services/playerService';
import PlayerProfile from './PlayerProfile';

type Props = {
  teamId?: string;
  headline?: boolean;
};

const PlayersScreen: React.FC<Props & GridProps> = ({
  teamId: defaultTeamId,
  headline,
  ...props
}) => {
  const [currentPlayer, setCurrentPlayer] = useState<TeamPlayer | null>(null);
  const [teamId, setTeamId] = useState<string>(
    defaultTeamId !== undefined ? defaultTeamId : 'All Teams',
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { loading, data } = useCache<TeamPlayer[]>(
    findAllPlayersByTeam,
    teamId !== 'All Teams' ? [teamId] : [undefined],
  );

  const teamsService = new ListTeamsService();

  const { loading: teamsLoading, data: teams } = useCache<Team[]>(
    teamsService.perform,
    [],
  );

  const [searchResults, setSearchResults] = useState<TeamPlayer[]>([]);

  const handleSearchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchTerm(event.target.value as string);
  };

  const selectPlayer = (player: TeamPlayer): void => {
    setCurrentPlayer(player);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTeamId(event.target.value as string);
  };

  useEffect(() => {
    if (data) {
      const results = data.filter(player =>
        player.name.toLowerCase().includes(searchTerm),
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, data]);

  // Reset filter when used on team page
  useEffect(() => {
    setTeamId(defaultTeamId ? defaultTeamId : 'All Teams');
  }, [defaultTeamId]);

  return loading && teamsLoading ? (
    <Spinner />
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} {...props}>
          {headline && (
            <Headline gutterBottom variant="h4">
              Players
            </Headline>
          )}
          <form noValidate autoComplete="off">
            <FormControl>
              <TextField
                id="filled-basic"
                onChange={e => handleSearchChange(e)}
                label="Search..."
                variant="filled"
              />
            </FormControl>
            {!defaultTeamId && (
              <FormControl>
                <InputLabel id="demo-simple-select-label">Team</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={teamId}
                  onChange={handleChange}>
                  <MenuItem value="All Teams">All Teams</MenuItem>
                  {teams &&
                    teams.map((team: Team) => (
                      <MenuItem key={team.id} value={team.id}>
                        {team.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </form>
          <PlayerList players={searchResults} selectPlayer={selectPlayer} />
        </Grid>
        <Grid item xs={8} {...props}>
          {currentPlayer === null ? (
            <Container>
              <h3>Please select a player.</h3>
            </Container>
          ) : (
            <>
              <PlayerProfile player={currentPlayer} />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PlayersScreen;
