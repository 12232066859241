import { useState, useEffect, useCallback } from 'react';
import { Query } from '../services/interfaces';
import { Subscription } from 'types';

export interface SubscriptionResult<T> {
  loading: boolean;
  data: null | T;
  error: null | any;
}

export const useSubscription = <T>(
  query: Subscription<T>,
  collection?: string | Query,
  type?: 'array' | 'object',
): SubscriptionResult<T> => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState(null);

  const subscribe = useCallback(
    (...args): (() => void) => {
      setLoading(true);
      const handleNextData = (nextData: T): void => {
        setLoading(false);
        setData(nextData);
      };
      setError(null);
      return query(handleNextData, collection, type, ...args);
    },
    [query, collection],
  );

  useEffect(
    function subscribeOnMountOrChange() {
      return subscribe();
    },
    [subscribe],
  );

  return {
    loading,
    error,
    data,
  };
};
