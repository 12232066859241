import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Section, SectionHalves, Callout, NavPadding } from '../../components';
import {
  Star as StarIcon,
  Apple as AppleIcon,
  Android as AndroidIcon,
  // EmojiEvents as TrophyIcon,
} from '@material-ui/icons';
import TeamGrid from './components/TeamGrid';
import Testimonials from './components/Testimonials';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: 'calc(100vh - 122px)',
        padding: '0 32px 64px 64px',
      },
    },
    gradient: {
      position: 'relative',
      background: `linear-gradient(90deg, ${
        theme.palette.secondary.main
      } 30%, ${theme.palette.primary.main} 90%)`,
      color: 'white !important',
      '& h1': {
        // color: 'white',
      },
      '& h6': {
        // color: 'white',
      },
    },
    callout: {
      textAlign: 'center',
      background: theme.palette.primary.main,
      [theme.breakpoints.up('md')]: {
        padding: '30px 5%',
      },
      '& h3': {
        color: theme.palette.secondary.main,
      },
      '& h2': {
        color: 'white',
      },
      '& h4': {
        color: theme.palette.secondary.main,
      },
      '& h1': {
        color: 'white',
      },
      '& h6': {
        color: 'white',
      },
    },
    heroImg: {
      padding: '10px 23px 26px 22px !important',
      [theme.breakpoints.up('md')]: {
        padding: '0px !important',
        '& img': {
          position: 'absolute',
          bottom: '0',
          right: '0',
          width: '57%',
          overflowX: 'hidden',
        },
      },
    },
    button: {
      // margin: '20px 0px 0 0',
      padding: '15px 25px',
      fontSize: '1.1375rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0',
        // borderWidth: '1px',
        // borderStyle: 'solid',
        // borderImageSlice: '1',
        // borderImageSource: `linear-gradient(to right , ${
        //   theme.palette.secondary.dark
        // }, ${theme.palette.secondary.main})`,
        width: '100%',
      },
    },
    center: {
      textAlign: 'center',
    },
    avatarImg: {
      margin: '0 auto 20px',
      width: '80px',
      height: '80px',
    },
    extraSpace: {
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(8)}px !important`,
      },
    },
    action: {
      color: 'yellow',
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[700],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
  }),
);

const Landing2: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const twoButtons = (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        className={classes.button}
        onClick={() => history.push('/plans')}
        // onClick={() => history.push('/login?redirect=checkout', ['/checkout'])}
        startIcon={<AppleIcon />}>
        Start Your Free Trial
      </Button>

      <Button
        className={classes.button}
        onClick={() => history.push('/android')}
        startIcon={<AndroidIcon />}>
        {' '}
        Looking for Android?
      </Button>
    </>
  );

  return (
    <>
      <NavPadding />
      <Grid container spacing={8} className={`${classes.hero}`}>
        <Grid md={5} item>
          <Typography component="h1" variant="h4">
            Stop Spiraling. Start Smiling.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Get the motivation, education, and positivity you need to tackle
            your mental health in just 28 days. Active teams for anxiety,
            depression, bipolar and ADHD.
          </Typography>

          {twoButtons}
        </Grid>
        <Grid md={6} item className={classes.heroImg}>
          <img
            alt="MHL Training Celebration"
            src={require('../../assets/images/landing/hero-foggy.webp')}
          />
        </Grid>
      </Grid>

      <Callout
        inverse
        title={
          <>
            <StarIcon className={classes.action} />
            <StarIcon className={classes.action} />
            <StarIcon className={classes.action} />
            <StarIcon className={classes.action} />
            <StarIcon className={classes.action} />
          </>
        }
        body="“The MHL gives me the accountability and motivation I need to stay on top of my mental health.”"
      />

      <Section>
        <SectionHalves
          src="three-screenshots"
          title="Join A Team. Win More Days."
          body={
            <>
              <Typography gutterBottom>
                Tired of the emotional rollercoaster? Struggling to stick to
                your self-care? You're not alone. Join an MHL team and have a
                coach and team cheering you on each and every day.
              </Typography>
            </>
          }
          reverse
        />
      </Section>

      <TeamGrid />

      <Testimonials />

      <Grid container spacing={8}>
        <Grid item xs={12} className={classes.center}>
          <Box mt={8} mb={8}>
            <Typography component="h1" variant="h3">
              Try the MHL free for 7 days
            </Typography>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              We're confident you'll feel more motivated, gain accountability,
              think more positively, and gain a better understanding of how your
              mind works.
            </Typography>

            {twoButtons}

            {/* <Card>
              <CardHeader
                title="Pro"
                subheader="$5 / Mo"
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                action={<StarIcon />}
                className={classes.cardHeader}
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography component="h2" variant="h3" color="textPrimary">
                    $5
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    /mo
                  </Typography>
                </div>
                <ul>
                  <Typography component="li" variant="body2" align="left">
                    Daily motivation from a supportive team just like you
                  </Typography>
                  <Typography component="li" variant="body2">
                    Daily personal responses from trained team coaches on your
                    progress
                  </Typography>
                  <Typography component="li" variant="body2">
                    Daily scorecard that helps you reflect on your wins and
                    learn from your losses
                  </Typography>
                  <Typography component="li" variant="body2">
                    Daily educational and motivational content from coaches and
                    psychologists
                  </Typography>
                  <Typography component="li" variant="body2">
                    Weekly Team Huddles on Zoom where we review the week and set
                    goals for the next one
                  </Typography>
                  <Typography component="li" variant="body2">
                    The mindset and accountability you need to stick to
                    self-care and manage your emotions
                  </Typography>
                </ul>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push('/checkout')}>
                  Join The League
                </Button>
              </CardActions>
            </Card> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Landing2;
